import React, { FC, useState, ChangeEvent, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { UseFormRegisterReturn, UseFormWatch } from 'react-hook-form'
import { RenderKuchikomiText } from './render_kuchikomi_text'

interface KuchikomiInputTextareaProps {
  register: UseFormRegisterReturn
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  placeholder?: string
}

export const KuchikomiInputTextarea: FC<KuchikomiInputTextareaProps> = ({
  register,
  watch,
  placeholder = 'クチコミを書く',
}) => {
  const [text, setText] = useState<string>('')

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
    register.onChange(event) // react-hook-formのonChangeをトリガー
  }

  useEffect(() => {
    const currentMessage = watch('message')
    if (currentMessage != text) {
      setText(currentMessage)
    }
  }, [watch('message')])

  return (
    <div className="w-full relative">
      {/* 表示するテキスト */}
      <div className="absolute top-0 left-0 w-full h-[100%] p-2 z-0 border border-main-lightGray rounded-lg">
        <RenderKuchikomiText inputText={text} isCreatePage={true} />
      </div>
      {/* 透明になってる実際に入力している部分 */}
      <TextareaAutosize
        {...register}
        className="relative w-full h-40 rounded-lg p-2 z-50 outline-none"
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
        style={{
          color: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
          caretColor: 'black',
        }}
      />
    </div>
  )
}
