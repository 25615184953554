import { useCallback, useState } from 'react'
import { Kuchikomi } from '../../../model/Kuchikomi'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { useAuth } from '../../../hooks/use_auth'
import { useLoading } from '../../../providers/loading_provider'

type UseBookmarkKuchikomisProps = {
  sdgsCategory: string | null
  getLimit?: number
}

type Handler = {
  load: (sdgsCategory: string | null, cursor?: number) => Promise<void>
  like: (kuchikomiId: string, point: number) => Promise<void>
  bookmark: (kuchikomiId: string, set: boolean) => Promise<void>
  reply: (kuchikomiId: string, message: string) => Promise<void>
  delete: (kuchikomiId: string) => Promise<void>
}

const useBookmarkKuchikomis = ({
  getLimit = 20,
  ...props
}: UseBookmarkKuchikomisProps): [
  Array<Kuchikomi>,
  number,
  number,
  boolean,
  string | null,
  Handler,
] => {
  const { showSnackBar } = useSnackBar()
  const apiClient = getAppClient()
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const { showLoading, hideLoading } = useLoading()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentSdgsCategory, setCurrentSdgsCategory] = useState<string | null>(
    props.sdgsCategory
  )
  const [kuchikomis, setKuchikomis] = useState<Array<Kuchikomi>>([])
  const { user } = useAuth()

  const load = useCallback(
    async (sdgsCategory: string | null, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)

      setCurrentSdgsCategory(sdgsCategory)

      await apiClient.mypage
        .bookmarkKuchikomiList({
          sdgsCategory: sdgsCategory ?? undefined,
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setKuchikomis(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => setIsLoading(false))
    },
    [totalSize, cursor, kuchikomis]
  )

  const like = useCallback(
    async (kuchikomiId: string, point: number) => {
      if (!user.isLogined()) {
        return
      }

      setIsLoading(true)

      // 先に更新
      const nextKuhikomis = [...kuchikomis]
      const targetIdx = nextKuhikomis.findIndex(
        (item) => item.id === kuchikomiId
      )
      const beforeLiked = nextKuhikomis[targetIdx].liked
      nextKuhikomis[targetIdx].likeCount += point
      nextKuhikomis[targetIdx].liked = true
      setKuchikomis(nextKuhikomis)

      await apiClient.kuchikomis
        .likeUpdate(kuchikomiId, { point: point })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)

          // 元に戻す
          const nextKuhikomis = [...kuchikomis]
          if (!beforeLiked) {
            nextKuhikomis[targetIdx].liked = false
          }
          nextKuhikomis[targetIdx].likeCount -= point
          setKuchikomis(nextKuhikomis)

          return null
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [kuchikomis]
  )

  /**
   * ブックマークする
   * ローディング画面は出さず、非同期で処理する
   */
  const bookmark = useCallback(
    async (kuchikomiId: string, set: boolean) => {
      if (!user.isLogined()) {
        return
      }

      // 先に画面を更新
      const nextKuchikomise = [...kuchikomis]
      const targetIdx = nextKuchikomise.findIndex(
        (item) => item.id === kuchikomiId
      )
      nextKuchikomise[targetIdx].bookmarked = set
      setKuchikomis(nextKuchikomise)

      await apiClient.kuchikomis
        .bookmarkUpdate(kuchikomiId, { set: set })
        .catch((e) => {
          console.error(e)
          return
        })
    },
    [kuchikomis]
  )

  /**
   * 返信する
   */
  const reply = useCallback(
    async (kuchikomiId: string, message: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      setIsLoading(true)
      await apiClient.kuchikomis
        .replyUpdate(kuchikomiId, { message: message })
        .then((res) => {
          const createReply = res.data.reply

          const nextKuchikomis = [...kuchikomis]
          const targetIdx = nextKuchikomis.findIndex(
            (item) => item.id === kuchikomiId
          )
          nextKuchikomis[targetIdx].replies.push(createReply)
          setKuchikomis(nextKuchikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
          setIsLoading(false)
        })
    },
    [kuchikomis]
  )

  const deleteKuchikomi = useCallback(
    async (kuchikomiId: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .deleteCreate(kuchikomiId)
        .then(() => {
          const nextKuhikomis = kuchikomis.filter(
            (item) => item.id !== kuchikomiId
          )
          setKuchikomis(nextKuhikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomis]
  )

  return [
    kuchikomis,
    totalSize,
    cursor,
    isLoading,
    currentSdgsCategory,
    {
      load: load,
      like: like,
      bookmark: bookmark,
      reply: reply,
      delete: deleteKuchikomi,
    },
  ]
}

export default useBookmarkKuchikomis
