import React, { FC, useEffect, useRef, useState } from 'react'
import { Kuchikomi, KuchikomiReply } from '../../model/Kuchikomi'
import { getKuchikomiDate } from '../../utils/datetime_util'
import CommentButton from '../buttons/comment_button'
import { useAuth } from '../../hooks/use_auth'
import KuchikomiReplyForm from '../kuchikomi/kuchikomi_reply_form'
import { RenderKuchikomiText } from '../kuchikomi/render_kuchikomi_text'
import { useNavigate } from 'react-router-dom'
import { checkEnableReply } from '../../utils/kuchikomi_util'

/**
 * クチコミの返信カード
 */
const KuchikomiReplyCard: FC<{
  kuchikomiReply: KuchikomiReply
  kuchikomi: Kuchikomi
  isSp: boolean
  sendReply: (replyMessage: string) => void
}> = ({ kuchikomiReply, isSp, sendReply, kuchikomi }) => {
  const { user } = useAuth()
  const KuchikomiRef = useRef<HTMLDivElement>(null)
  const [isLarge, setIsLarge] = useState<boolean>(false)
  const [openLarge, setOpenLarge] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isCreatingReply, setIsCreatingReply] = useState<boolean>(false)
  const sendReplyAndClose = (replyMessage: string) => {
    sendReply(replyMessage)
    setIsCreatingReply(false)
  }

  useEffect(() => {
    if (KuchikomiRef.current && KuchikomiRef.current.clientHeight > 200) {
      setIsLarge(true)
    } else {
      setIsLarge(false)
    }
  }, [KuchikomiRef])

  return (
    <div className="mb-9">
      <div className="flex items-center mb-2">
        <div
          className="w-8 h-8 rounded-full bg-center bg-cover mr-2"
          style={{ backgroundImage: `url(${kuchikomiReply.user.iconUrl})` }}
        />
        <p className="font-bold text-sm sm:text-base mr-3">
          {kuchikomiReply.user.displayName}
        </p>
        <p className="text-main-lightGray sm:text-sm text-xs">
          {getKuchikomiDate(kuchikomiReply.createdAt)}
        </p>
      </div>
      {/* クチコミ */}
      <div className="mb-6 pl-10">
        <p
          className={`text-sm overflow-y-hidden ${
            isLarge && !openLarge ? 'max-h-[210px]' : ''
          }`}
          ref={KuchikomiRef}
        >
          <RenderKuchikomiText
            inputText={kuchikomiReply.comment}
            onClickHashtag={(hashtag) => {
              const removeHash = hashtag.replace('#', '')
              navigate(`/search/kuchikomi?keyword=${removeHash}`)
            }}
          />
        </p>
        {isLarge && (
          <div className="pt-3 flex items-center text-main-blue ">
            <span className="text-xs mr-1">{openLarge ? '▲' : '▼'}</span>
            {openLarge ? (
              <p
                onClick={() => setOpenLarge(false)}
                className="text-sm cursor-pointer"
              >
                閉じる
              </p>
            ) : (
              <p
                onClick={() => setOpenLarge(true)}
                className="text-sm cursor-pointer"
              >
                続きを見る
              </p>
            )}
          </div>
        )}
      </div>
      {/* ボタン */}
      <div className="flex justify-start pl-7">
        {user.isLogined() && checkEnableReply(user, kuchikomi) && (
          <div className="ml-3 flex flex-col justify-center mr-2">
            <CommentButton
              isActive={false}
              isSp={isSp}
              onClick={() => setIsCreatingReply(true)}
            />
          </div>
        )}
      </div>
      {/* クチコミの返信を作成中の時 */}
      {isCreatingReply && user.isLogined() && (
        <div className="py-4 sm:px-6">
          <KuchikomiReplyForm
            close={() => setIsCreatingReply(false)}
            sendReply={sendReplyAndClose}
            initialMessage={`@${kuchikomiReply.user.displayName}\n`}
          ></KuchikomiReplyForm>
        </div>
      )}
    </div>
  )
}
export default KuchikomiReplyCard
