import React from 'react'

export const LoadingIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.01"
        height="19.01"
        viewBox="0 0 19.01 19.01"
      >
        <path
          id="FontAwsome_redo-alt_"
          data-name="FontAwsome (redo-alt)"
          d="M17.522,8a9.472,9.472,0,0,1,6.548,2.632L25.44,9.263a.92.92,0,0,1,1.57.65v5.138a.92.92,0,0,1-.92.92H20.952a.92.92,0,0,1-.65-1.57l1.6-1.6a6.438,6.438,0,1,0-.157,9.55.459.459,0,0,1,.627.021l1.52,1.52a.461.461,0,0,1-.018.668A9.5,9.5,0,1,1,17.522,8Z"
          transform="translate(-8 -8)"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}
