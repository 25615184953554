import React from 'react'

export const ThreeDotsIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="4"
        viewBox="0 0 18 4"
      >
        <g
          id="グループ_930"
          data-name="グループ 930"
          transform="translate(-833.867 -928.068)"
        >
          <circle
            id="楕円形_14"
            data-name="楕円形 14"
            cx="2"
            cy="2"
            r="2"
            transform="translate(847.867 928.068)"
            fill="#b9b9b9"
          />
          <circle
            id="楕円形_16"
            data-name="楕円形 16"
            cx="2"
            cy="2"
            r="2"
            transform="translate(833.867 928.068)"
            fill="#b9b9b9"
          />
          <circle
            id="楕円形_15"
            data-name="楕円形 15"
            cx="2"
            cy="2"
            r="2"
            transform="translate(840.867 928.068)"
            fill="#b9b9b9"
          />
        </g>
      </svg>
    </div>
  )
}
