export const FormErrorMessage = {
  required: '必須の入力項目です。',
  maxLength: (number: number) =>
    `${String(number)}文字以内で入力してください。`,
  minLength: (number: number) =>
    `${String(number)}文字以上で入力してください。`,
  phoneNumber: 'xxx-xxxx-xxxの形式で入力してください。',
  stockCode: 'xxx-xxxxの形式で入力してください。',
  integer: '数値を入力してください。',
  email: 'メールアドレスを入力してください。',
  passwordNotMatch: 'パスワードが一致しません。',
}
