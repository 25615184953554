import React, { FC, useEffect } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import CommonTemplate from '../../components/templates/common_template'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/loading/loading_spinner'
import useKuchikomiDetail from './hooks/use_kuchikomi_detail'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import Head2 from '../../components/heads/head2'

const KuchikomiDetailPage: FC = () => {
  const pathParams = useParams<{ id: string }>()
  const isSp = useSpBreakPoint()
  const [kuhchikomi, loadingKuchikomi, handler] = useKuchikomiDetail()

  useEffect(() => {
    if (pathParams.id) {
      handler.load(pathParams.id)
    }
  }, [pathParams.id])

  const content = (
    <>
      <CommonTemplate
        isPadding={!isSp}
        isSp={isSp}
        isShowPostButton={false}
        additonalTopNode
      >
        <div className="mb-4">
          <Head2 title={`クチコミ詳細`} subTitle={''} />
        </div>

        {loadingKuchikomi ? (
          <div className="py-8">
            <LoadingSpinner className="mx-auto" />
          </div>
        ) : (
          <>
            {kuhchikomi ? (
              <>
                <KuchikomiCard
                  kuchikomi={kuhchikomi}
                  sendLike={handler.like}
                  sendReply={handler.reply}
                  onClickBookmark={handler.bookmark}
                  deleteKuchikomi={handler.delete}
                  rounded={true}
                />
              </>
            ) : (
              <>ページが見つかりませんでした。</>
            )}
          </>
        )}
      </CommonTemplate>
    </>
  )
  return content
}

export default KuchikomiDetailPage
