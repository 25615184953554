import React, { FC } from 'react'

export const CommentIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.843"
        height="19.985"
        viewBox="0 0 22.843 19.985"
      >
        <path
          id="FontAwsome_comment_"
          data-name="FontAwsome (comment)"
          d="M11.357,32C5.049,32-.063,36.153-.063,41.279A8.2,8.2,0,0,0,2.3,46.913a11.32,11.32,0,0,1-2.07,3.265,1.071,1.071,0,0,0,.781,1.807A10.824,10.824,0,0,0,7.213,49.92a13.778,13.778,0,0,0,4.144.638c6.308,0,11.42-4.153,11.42-9.279S17.665,32,11.357,32Zm0,16.417a11.592,11.592,0,0,1-3.5-.54l-1.013-.321-.87.616a9.593,9.593,0,0,1-2.565,1.294A11.189,11.189,0,0,0,4.3,47.672l.473-1.254-.919-.972a6.136,6.136,0,0,1-1.775-4.167c0-3.935,4.162-7.138,9.279-7.138s9.279,3.2,9.279,7.138S16.474,48.417,11.357,48.417Z"
          transform="translate(0.066 -32)"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}

export const FilledCommentIcon: FC<{
  size?: 'sm' | 'md'
  color?: 'gray' | 'blue'
}> = ({ size = 'sm', color = 'gray' }) => {
  let width = '13.593'
  let height = '12.33'
  let fill = '#b9b9b9'

  switch (size) {
    case 'sm':
      width = '13.593'
      height = '12.33'
      break
    case 'md':
      width = '16'
      height = '16'
      break
  }

  switch (color) {
    case 'gray':
      fill = '#b9b9b9'
      break
    case 'blue':
      fill = '#002be3'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 22.843 19.985"
      >
        <path
          id="FontAwsome_comment_"
          data-name="FontAwsome (comment)"
          d="M11.357,32C5.049,32-.063,36.153-.063,41.279A8.2,8.2,0,0,0,2.3,46.913a11.32,11.32,0,0,1-2.07,3.265,1.071,1.071,0,0,0,.781,1.807A10.824,10.824,0,0,0,7.213,49.92a13.778,13.778,0,0,0,4.144.638c6.308,0,11.42-4.153,11.42-9.279S17.665,32,11.357,32Z"
          transform="translate(0.066 -32)"
          fill={fill}
        />
      </svg>
    </div>
  )
}
