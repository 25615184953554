import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { EnterpriseRanking } from '../../../model/Ranking'
import {
  RankingEnterpriseStat,
  RankingPeriod,
} from '../../../clients/api_client'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type UseEnterpriseRankingProps = {
  period: RankingPeriod
  stat: RankingEnterpriseStat
  group?: string
  groupValue?: string
  getLimit?: number
}

type Handler = {
  search: (
    period: RankingPeriod,
    stat: RankingEnterpriseStat,
    group?: string,
    groupValue?: string,
    cursor?: number
  ) => Promise<void>
}

const useEnterpriseRanking = ({
  getLimit = 20,
  ...props
}: UseEnterpriseRankingProps): [
  Array<EnterpriseRanking>,
  RankingPeriod,
  RankingEnterpriseStat,
  string | undefined,
  string | undefined,
  number,
  number,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState<RankingPeriod>(
    props.period
  )
  const [currentStat, setCurrentStat] = useState<RankingEnterpriseStat>(
    props.stat
  )
  const [currentGroup, setCurrentGroup] = useState<string | undefined>(
    props.group
  )
  const [currentGroupValue, setCurrentGroupValue] = useState<
    string | undefined
  >(props.groupValue)

  const [ranking, setRanking] = useState<Array<EnterpriseRanking>>([])

  const search = useCallback(
    async (
      period: RankingPeriod,
      stat: RankingEnterpriseStat,
      group?: string,
      groupValue?: string,
      cursor = 0
    ) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)

      setCurrentPeriod(period)
      setCurrentStat(stat)
      setCurrentGroup(group)
      setCurrentGroupValue(groupValue)

      await apiClient.ranking
        .enterpriseList({
          period: period,
          stat: stat,
          group: group,
          groupValue: groupValue,
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setRanking(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, ranking]
  )

  useEffect(() => {
    search(
      RankingPeriod.All,
      RankingEnterpriseStat.Evaluation,
      undefined,
      undefined
    )
  }, [])

  return [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    totalSize,
    cursor,
    isLoading,
    { search: search },
  ]
}

export default useEnterpriseRanking
