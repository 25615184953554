import React, { FC } from 'react'
import useEnterpriseRanking from './hooks/use_enterprise_ranking'
import FilterButton from '../../components/buttons/filter_button'
import CommonTemplate from '../../components/templates/common_template'
import Select from '../../components/select/select'
import useCodes from '../../hooks/use_codes'
import Head2 from '../../components/heads/head2'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import { EnterpriseRankingCard } from '../../components/card/enterprise_card'
import { RankingEnterpriseStat, RankingPeriod } from '../../clients/api_client'
import { RankingEnterpriseStatCode, RankingPeriodCode } from '../../model/Code'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import RankingTab from './components/tab/ranking_tab'

const EnterpriseRankingPage: FC = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [codes] = useCodes()
  const [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    size,
    currentCursor,
    rankingLoading,
    rankingHandler,
  ] = useEnterpriseRanking({
    period: RankingPeriod.All,
    stat: RankingEnterpriseStat.Evaluation,
    getLimit: PAGE_SIZE,
  })

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const head = (
    <div className="mb-4">
      <Head2
        title={`企業・団体ランキング`}
        subTitle={
          ranking.length > 0
            ? `全件${String(size)}中 ${currentCursor + 1}~${
                currentCursor + ranking.length
              }件`
            : ''
        }
      />
    </div>
  )

  const filterPeriodButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingPeriodCodes.map((period: RankingPeriodCode) => {
        return (
          <div key={`ranking-period-${period.code}`} className="pr-2 pb-2">
            <FilterButton
              label={period.label}
              value={period.code}
              isActive={period.code === currentPeriod.toString()}
              onClick={() =>
                rankingHandler.search(
                  period.code,
                  currentStat,
                  currentGroup,
                  currentGroupValue
                )
              }
            />
          </div>
        )
      })}
    </div>
  )

  const filterStatButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingEnterpriseStatCodes.map(
        (stat: RankingEnterpriseStatCode) => {
          return (
            <div key={`ranking-stat-${stat.code}`} className="pr-2 pb-2">
              <FilterButton
                label={stat.label}
                value={stat.code}
                isActive={stat.code === currentStat.toString()}
                onClick={() =>
                  rankingHandler.search(
                    currentPeriod,
                    stat.code,
                    currentGroup,
                    currentGroupValue
                  )
                }
              />
            </div>
          )
        }
      )}
    </div>
  )

  const filterGroupButtons = (
    <div className="flex justify-start flex-wrap">
      <div className="pr-2 pb-2">
        <Select
          id="enterprise"
          placeholder="企業・団体カテゴリー"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            rankingHandler.search(
              currentPeriod,
              currentStat,
              'enterprise',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.enterpriseCodes.map((item) => {
            return {
              value: item.code,
              label: item.value,
            }
          })}
        />
      </div>
      <div className="pr-2 pb-2">
        <Select
          id="industry"
          placeholder="業種カテゴリー"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            rankingHandler.search(
              currentPeriod,
              currentStat,
              'industry',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.industryCodes.map((item) => {
            return {
              value: item.code,
              label: item.value,
            }
          })}
        />
      </div>
      <div className="pr-2 pb-2">
        <Select
          id="prefecture"
          placeholder="都道府県別"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            rankingHandler.search(
              currentPeriod,
              currentStat,
              'prefecture',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.prefectures.map((prefecture) => {
            return {
              value: prefecture,
              label: prefecture,
            }
          })}
        />
      </div>
      <div className="pr-2 pb-2 max-w-full">
        <Select
          id="sdgs"
          placeholder="SDGsカテゴリー"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            rankingHandler.search(
              currentPeriod,
              currentStat,
              'sdgs',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.sdgsCategories.map((item) => {
            return {
              value: item.code,
              label: `${item.code}.${item.value}`,
            }
          })}
        />
      </div>
    </div>
  )

  const seachResultElem = (
    <div className="flex md:justify-between justify-center flex-wrap">
      {ranking.map((item, index) => {
        return (
          <div
            key={`ranking-${String(index)}`}
            className="md:w-[47.5%] w-full min-w-[280px] pb-4"
          >
            <EnterpriseRankingCard ranking={item} />
          </div>
        )
      })}
      {ranking.length === 0 && (
        <div className="text-center w-full">該当する企業・団体がありません</div>
      )}
    </div>
  )

  const paginationCursor =
    ranking.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  const rankigTab = (
    <RankingTab activeIndex={0} isSp={isSp}>
      <p className="font-bold mb-1">ランキングの種類</p>
      <div className="mb-2">
        <div className="pb-1">{filterPeriodButtons}</div>
        <div className="pb-1">{filterStatButtons}</div>
      </div>
      <p className="font-bold mb-1">カテゴリーで絞る</p>
      <div>{filterGroupButtons}</div>
    </RankingTab>
  )

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp} additonalTopNode>
      <div className="pb-4">
        {rankigTab}
        <div className="px-4 sm:px-0">
          <div className="pb-2 py-12" ref={IndexSectionRef}>
            {head}
          </div>
          {rankingLoading ? (
            <LoadingSpinner className="mx-auto" />
          ) : (
            <>
              <div className="pb-8">{seachResultElem}</div>
              <div className="pb-8">{paginationCursor}</div>
            </>
          )}
        </div>
      </div>
    </CommonTemplate>
  )

  return content
}

export default EnterpriseRankingPage
