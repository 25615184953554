import React, { FC } from 'react'
import { ThreeDotsIcon } from '../../images/icons/three_dots_icon'

type ThreeDotsButtonProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const ThreeDotsButton: FC<ThreeDotsButtonProps> = (props) => {
  return (
    <div onClick={props.onClick} className="cursor-pointer">
      <ThreeDotsIcon />
    </div>
  )
}

export default ThreeDotsButton
