import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import User from '../../../model/User'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
}

const useRelatedUsers = (): [Array<User>, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const { showSnackBar } = useSnackBar()
  const [users, setUserDetail] = useState<Array<User>>([])

  const load = useCallback(async (id: string) => {
    setIsLoading(true)
    await apiClient.users
      .relatedUsersDetail(id, { limit: 17 })
      .then((res) => {
        const data = res.data.data
        setUserDetail(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return [users, isLoading, { load: load }]
}

export default useRelatedUsers
