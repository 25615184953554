import { useContext } from 'react'
import { AuthContext } from '../providers/auth_provider'

// AuthContextをhookとして利用できるようにする
export const useAuth = () => {
  return useContext(AuthContext)
}

// ログイン後のページでユーザー情報を取得するためのカスタムフック
export const useAuthAuthenticated = () => {
  const { user } = useContext(AuthContext)

  if (!user.isLogined()) {
    throw new Error('User is not authenticated')
  }

  return { user }
}
