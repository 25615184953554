import React, { FC } from 'react'

export const FollowerIcon: FC<{
  size?: 'sm' | 'md'
  color?: 'gray' | 'blue'
}> = ({ size = 'sm', color = 'gray' }) => {
  let width = '9.58'
  let height = '12.054'
  let fill = '#b9b9b9'

  switch (size) {
    case 'sm':
      width = '9.58'
      height = '12.054'
      break
    case 'md':
      width = '16'
      height = '16'
      break
  }

  switch (color) {
    case 'gray':
      fill = '#b9b9b9'
      break
    case 'blue':
      fill = '#002be3'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        id="グループ_749"
        data-name="グループ 749"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 9.58 12.054"
      >
        <ellipse
          id="楕円形_9"
          data-name="楕円形 9"
          cx="2.826"
          cy="2.826"
          rx="2.826"
          ry="2.826"
          transform="translate(1.964 0)"
          fill={fill}
        />
        <path
          id="パス_773"
          data-name="パス 773"
          d="M4.79,0C7.436,0,9.58.219,9.58,2.864V6.176H0V2.864C0,.219,2.145,0,4.79,0Z"
          transform="translate(0 5.878)"
          fill={fill}
        />
      </svg>
    </div>
  )
}
