import React, { FC } from 'react'

type FavoriteIconProps = {
  isActive: boolean
}

export const FavoriteIcon: FC<FavoriteIconProps> = (props) => {
  return props.isActive ? <Active /> : <Disactive />
}

export const FilledFavoriteIcon: FC<{
  size?: 'sm' | 'md'
  color?: 'gray' | 'blue'
}> = ({ size = 'sm', color = 'gray' }) => {
  let width = '13.593'
  let height = '12.33'
  let fill = '#b9b9b9'

  switch (size) {
    case 'sm':
      width = '13.593'
      height = '12.33'
      break
    case 'md':
      width = '16'
      height = '16'
      break
  }

  switch (color) {
    case 'gray':
      fill = '#b9b9b9'
      break
    case 'blue':
      fill = '#002be3'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 13.593 12.33"
      >
        <path
          id="パス_772"
          data-name="パス 772"
          d="M220.331,855s7.1-4.825,7.123-8.052-1.2-4.364-3.169-4.273a5.873,5.873,0,0,0-3.581,2.048s-1.667-2.168-3.549-2.048-3.25.746-3.294,4.273S220.331,855,220.331,855Z"
          transform="translate(-213.862 -842.668)"
          fill={fill}
        />
      </svg>
    </div>
  )
}

const Disactive = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.834"
        height="19.986"
        viewBox="0 0 22.834 19.986"
      >
        <path
          id="FontAwsome_heart_"
          data-name="FontAwsome (heart)"
          d="M20.443,33.42a6.718,6.718,0,0,0-9.027.669A6.713,6.713,0,0,0,2.39,33.42a6.748,6.748,0,0,0-.473,9.865l7.822,7.97a2.342,2.342,0,0,0,3.354,0l7.822-7.97A6.753,6.753,0,0,0,20.443,33.42Zm-1.052,8.362-7.822,7.97a.183.183,0,0,1-.3,0l-7.822-7.97a4.6,4.6,0,0,1,.326-6.721,4.522,4.522,0,0,1,6.088.468l1.561,1.592,1.561-1.592a4.519,4.519,0,0,1,6.088-.473A4.619,4.619,0,0,1,19.391,41.782Z"
          transform="translate(0 -31.978)"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}

const Active = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.834"
        height="19.986"
        viewBox="0 0 22.834 19.986"
      >
        <path
          id="FontAwsome_heart_"
          data-name="FontAwsome (heart)"
          d="M20.443,33.42a6.718,6.718,0,0,0-9.027.669A6.713,6.713,0,0,0,2.39,33.42a6.748,6.748,0,0,0-.473,9.865l7.822,7.97a2.342,2.342,0,0,0,3.354,0l7.822-7.97A6.753,6.753,0,0,0,20.443,33.42Z"
          transform="translate(0 -31.978)"
          fill="#e32929"
        />
      </svg>
    </div>
  )
}
