import React, { FC } from 'react'
import Information from '../../model/Information'
import { formatDate } from '../../utils/datetime_util'
import { Link } from 'react-router-dom'

type SimpleInformationProps = {
  informations: Array<Information>
}

const SimpleInformation: FC<SimpleInformationProps> = (props) => {
  return (
    <div className="rounded-xl bg-white py-4 px-6 w-full">
      {props.informations.map((item, index) => {
        return (
          <div key={`information-${String(index)}`}>
            <Link to={`/information/${item.id}`}>
              <div className="sm:flex sm:justify-start w-full py-4">
                <div className="flex justify-start min-w-[96px]">
                  <div className="sm:w-[50%] min-w-[48px] pr-4">
                    <p className="text-sm sm:text-main-black text-main-lightGray font-bold">
                      お知らせ
                    </p>
                  </div>
                  <div className="sm:w-[50%] min-w-[96px] pr-4">
                    <p className="text-sm sm:text-main-blue text-main-lightGray">
                      {formatDate(new Date(item.createdAt))}
                    </p>
                  </div>
                </div>
                <div className="w-auto">
                  <p className="text-sm overflow-hidden text-ellipsis whitespace-nowrap font-bold">
                    {item.title}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default SimpleInformation
