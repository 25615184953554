import React from 'react'

export const FacebookIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.713"
        height="21.581"
        viewBox="0 0 21.713 21.581"
      >
        <path
          id="FontAwsome_facebook_"
          data-name="FontAwsome (facebook)"
          d="M29.713,18.856A10.856,10.856,0,1,0,17.16,29.581V21.995H14.4V18.856H17.16V16.464c0-2.721,1.62-4.223,4.1-4.223a16.708,16.708,0,0,1,2.43.212v2.67H22.322a1.569,1.569,0,0,0-1.769,1.7v2.038h3.011l-.482,3.138H20.553v7.587A10.86,10.86,0,0,0,29.713,18.856Z"
          transform="translate(-8 -8)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
