import React, { FC } from 'react'

type PagiNationCursorProps = {
  pageCount: number
  activePageNum: number
  numRange: number
  next: () => void
  prev: () => void
  jump: (pageNum: number) => void
}

const PagiNationCursor: FC<PagiNationCursorProps> = (props) => {
  // 画面に表示する数字の数は5つとなるようにする
  let displayNums = []
  if (props.activePageNum === 1 || props.activePageNum === 2) {
    displayNums = [1, 2, 3, props.pageCount]
  } else if (
    props.activePageNum === props.pageCount ||
    props.activePageNum === props.pageCount - 1
  ) {
    displayNums = [
      1,
      props.pageCount - 3,
      props.pageCount - 2,
      props.pageCount - 1,
      props.pageCount,
    ]
  } else {
    displayNums = [
      1,
      props.activePageNum - 1,
      props.activePageNum,
      props.activePageNum + 1,
      props.pageCount,
    ]
  }

  // pageCountよりも大きい数字が表示されないようにする
  displayNums = displayNums.filter((val) => val <= props.pageCount)

  const pageNumElems = Array.from(new Set(displayNums)).map((val, idx, ary) => {
    const btnStyle =
      val === props.activePageNum
        ? {
            color: 'white',
            backgroundColor: 'var(--color-main-blue)',
          }
        : {
            backgroundColor: 'white',
          }

    if (val === 1 || val - 1 === ary[idx - 1]) {
      return (
        <div key={`page-${String(val)}`} className="my-auto">
          <div
            className="w-8 sm:w-12 py-1 duration-300 cursor-pointer hover:bg-main-lightGray text-center flex flex-col border-l border-l-main-gray  border-r border-r-main-gray"
            style={btnStyle}
            onClick={() => props.jump(val)}
          >
            <p>{String(val)}</p>
          </div>
        </div>
      )
    } else {
      const dotVal = idx === ary.length - 1 ? ary[idx - 1] + 1 : val - 1
      return (
        <div key={`page-${String(dotVal)}`} className="my-auto">
          <div className="flex justify-center">
            <div>
              <div
                className="w-8 sm:w-12 py-1 duration-300 cursor-pointer hover:bg-main-lightGray text-center flex flex-col border-l border-l-main-gray  border-r border-r-main-gray"
                style={btnStyle}
                onClick={() => props.jump(dotVal)}
              >
                <p>...</p>
              </div>
            </div>
            <div
              key={`page-${String(val)}`}
              className="w-8 sm:w-12 py-1 duration-300 cursor-pointer hover:bg-main-lightGray text-center flex flex-col border-l border-l-main-gray  border-r border-r-main-gray"
              style={btnStyle}
              onClick={() => props.jump(val)}
            >
              <p>{String(val)}</p>
            </div>
          </div>
        </div>
      )
    }
  })

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center">
        <div
          className="cursor-pointer w-12 py-1 text-center rounded-tl-xl rounded-bl-xl bg-white"
          onClick={props.prev}
        >
          <p>{'<'}</p>
        </div>
      </div>
      {pageNumElems}
      <div className="flex flex-col justify-center">
        <div
          className="cursor-pointer w-12 py-1 text-center rounded-tr-xl rounded-br-xl bg-white"
          onClick={props.next}
        >
          <p>{'>'}</p>
        </div>
      </div>
    </div>
  )
}

export default PagiNationCursor
