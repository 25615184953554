import React, { FC } from 'react'
import {
  LicenseType,
  LicenseTypeValue,
  LicenseTypeLabel,
} from '../../../../enums/LicenseType'
import { Link, useNavigate } from 'react-router-dom'
import FlatButton from '../../../../components/buttons/flat_button'
import { useForm } from 'react-hook-form'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../../utils/network_util'
import { useSnackBar } from '../../../../providers/snack_bar_provider'
import { useLoading } from '../../../../providers/loading_provider'

type RegisterFormProps = {
  licenseType: LicenseTypeValue | null
  email: string
}

const RegisterForm: FC = () => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()
  const navigate = useNavigate()
  const { register, handleSubmit, setValue, watch } =
    useForm<RegisterFormProps>({
      defaultValues: {
        licenseType: null,
        email: '',
      },
      mode: 'onSubmit',
    })

  const sendEnterpriseEmail = async (data: RegisterFormProps) => {
    showLoading()
    await apiClient.enterprises
      .registerMailCreate({
        email: data.email,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        navigate('/register/enterprise/send')
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => hideLoading())
  }

  // 企業orユーザーの選択
  const memberShipSelectElem = (
    <div>
      <p className="font-bold py-4 text-center">
        登録するユーザーの種類をお選びください
      </p>
      <div className="py-2">
        <div className="bg-main-blue rounded-lg">
          <input
            id="register-user"
            type="button"
            className="hidden"
            value={LicenseType.NORMAL}
            onClick={() => setValue('licenseType', LicenseType.NORMAL)}
          />
          <label
            htmlFor="register-user"
            className="text-sm sm:text-base text-white text-center whitespace-nowrap px-4 py-2 leading-6 block  cursor-pointer"
          >
            <p className="mb-2 sm:mb-0">一般ユーザーとして登録する</p>
            <span className="text-xs">企業・団体の口コミを書きたい方</span>
          </label>
        </div>
      </div>
      {process.env.REACT_APP_ENV !== 'teaser' ? (
        <div className="py-2">
          <div className="bg-main-blue rounded-lg">
            <input
              id="register-corp"
              type="button"
              className="hidden"
              onClick={() => setValue('licenseType', LicenseType.CORP)}
            />
            <label
              htmlFor="register-corp"
              className="text-sm sm:text-base text-white text-center whitespace-nowrap px-4 py-2 leading-6 block cursor-pointer"
            >
              <p className="mb-2">企業・団体ユーザーとして登録する</p>
              <span className="text-xs flex flex-wrap items-center justify-center">
                <span className="leading-snug">
                  ユーザーとコミュニケーションが
                </span>
                <span className="leading-snug">取りたい企業・団体</span>
              </span>
            </label>
          </div>
        </div>
      ) : (
        // TODO:ティザーサイト用なのでいずれ消す
        <div className="py-2">
          <div className="bg-main-lightGray rounded-lg opacity-75">
            <input id="register-corp" type="button" className="hidden" />
            <label
              htmlFor="register-corp"
              className="text-sm sm:text-base text-white text-center whitespace-nowrap px-4 py-2 leading-6 block"
            >
              <p className="mb-2">企業・団体ユーザーとして登録する</p>
              <span className="text-xs flex flex-wrap items-center justify-center">
                <span className="leading-snug">
                  ユーザーとコミュニケーションが
                </span>
                <span className="leading-snug">取りたい企業・団体</span>
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  )

  // 会員登録
  const emailForm = (
    <div>
      <p className="font-bold text-black mb-4">
        会員種別：
        <span className="text-main-blue">
          {watch('licenseType') !== null &&
            LicenseTypeLabel[watch('licenseType') as LicenseTypeValue]}
        </span>
      </p>
      {watch('licenseType') === LicenseType.CORP ? (
        // 企業側
        <>
          <p className="font-bold">メールアドレスで登録</p>
          <div className="py-1">
            <label htmlFor="your-email" className="block text-xs mb-2">
              メールアドレス
            </label>
            <input
              id="your-email"
              type="email"
              className="w-full py-1 px-2 border border-gray-500 rounded-lg outline-none"
              placeholder="メールアドレスを入力してください"
              {...register('email', {
                required: 'メールアドレスを入力してください',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
            />
          </div>
          <div className="mt-4">
            <FlatButton
              onClick={handleSubmit(sendEnterpriseEmail)}
              size="md"
              className="mx-0 !max-w-none"
            >
              認証メールを送信する
            </FlatButton>
          </div>
        </>
      ) : (
        // ユーザー側
        <>
          <div className="bg-main-blue rounded-lg mb-8">
            <Link to="/register/user">
              <p className="text-white text-center py-2">
                メールアドレスで登録する
              </p>
            </Link>
          </div>
          <p className="font-bold">SNSアカウントで登録</p>
          <p className="text-main-lightGray text-sm">現在準備中です。</p>
        </>
      )}
    </div>
  )

  return <>{watch('licenseType') !== null ? emailForm : memberShipSelectElem}</>
}

export default RegisterForm
