import { useCallback, useState } from 'react'
import { getAppClient } from '../../../utils/network_util'
import { useLoading } from '../../../providers/loading_provider'
import { Notification } from '../../../model/Notification'

type Handler = {
  load: () => Promise<void>
}

const useNotification = (): [
  Array<Notification> | null,
  boolean,
  boolean,
  Handler,
] => {
  const GETLIMIT = 50
  const apiClient = getAppClient()

  const { isLoading, showLoading, hideLoading } = useLoading()
  const [hasMore, setHasMore] = useState(true)
  const [apiCursor, setApiCursor] = useState(0)
  const [notifications, setNotifications] = useState<Array<Notification>>([])

  const load = useCallback(async () => {
    await apiClient.mypage
      .notificationList({
        cursor: apiCursor,
        limit: GETLIMIT,
      })
      .then((res) => {
        const result = res.data
        if (result.total) {
          const nextNotifications = [...notifications].concat(result.data)
          setNotifications(nextNotifications)
          setApiCursor(() => apiCursor + result.data.length)
        } else {
          setHasMore(false)
        }
      })
      .catch((e) => {
        console.error(e)
        return
      })
  }, [apiCursor, notifications])

  return [notifications, isLoading, hasMore, { load: load }]
}

export default useNotification
