import React from 'react'

export const AccountIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30.855 30.855"
      >
        <path
          id="FontAwsome_user-circle_"
          data-name="FontAwsome (user-circle)"
          d="M15.428,13.972A5.972,5.972,0,1,0,21.4,19.944,5.974,5.974,0,0,0,15.428,13.972Zm0,8.958a2.986,2.986,0,1,1,2.986-2.986A2.987,2.987,0,0,1,15.428,22.93Zm0-14.93A15.428,15.428,0,1,0,30.855,23.428,15.425,15.425,0,0,0,15.428,8Zm0,27.869a12.371,12.371,0,0,1-8.093-3.011,5.359,5.359,0,0,1,4.33-2.457,12.656,12.656,0,0,0,3.764.6,12.46,12.46,0,0,0,3.764-.6,5.375,5.375,0,0,1,4.33,2.457A12.371,12.371,0,0,1,15.428,35.869Zm10.121-5.232a8.252,8.252,0,0,0-6.538-3.229,18.669,18.669,0,0,1-3.583.6,18.769,18.769,0,0,1-3.583-.6,8.27,8.27,0,0,0-6.538,3.229,12.435,12.435,0,1,1,22.563-7.21A12.353,12.353,0,0,1,25.549,30.638Z"
          transform="translate(0 -8)"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}
