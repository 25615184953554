import React, { FC } from 'react'
import User from '../../../../model/User'
import { FilledFavoriteIcon } from '../../../../images/icons/favorite_icon'
import { FollowerIcon } from '../../../../images/icons/follower_icon'
import { Link } from 'react-router-dom'
import FlatButton from '../../../../components/buttons/flat_button'

type UserCardProps = {
  user: User
  isFollower?: boolean
  onFollow?: (id: string, set: boolean) => Promise<void>
}

const UserCard: FC<UserCardProps> = ({ isFollower = false, ...props }) => {
  return (
    <Link to={`/users/${props.user.id}`}>
      <div className="bg-white flex justify-start p-4 border-b border-b-main-gray">
        <div className="flex justify-center pt-2">
          <div
            className="w-16 h-16 rounded-full bg-center bg-cover mr-4"
            style={{
              backgroundImage: `url(${props.user.iconUrl})`,
            }}
          ></div>
        </div>
        <div className="flex justify-between grow">
          <div>
            <p className="font-bold">{props.user.displayName}</p>
            <p className="text-xs text-main-lightGray">{props.user.profile}</p>
            <div className="flex justify-start">
              <div className="flex justify-start pr-4">
                <div className="flex flex-col justify-center pr-1">
                  <FilledFavoriteIcon size="md" />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-main-lightGray">
                    {props.user.givedPointCount}
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-col justify-center pr-1">
                  <FollowerIcon size="md" />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-main-lightGray">
                    {props.user.followerCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            {isFollower && (
              <div>
                <FlatButton
                  bgColor={props.user.yourFollower ? 'lightGray' : 'blue'}
                  onClick={(e) => {
                    e.preventDefault()
                    if (props.onFollow && !props.user.yourFollower) {
                      props.onFollow(props.user.id, !props.user.yourFollower)
                    }
                  }}
                >
                  <p className="text-sm px-2 leading-4">
                    {props.user.yourFollower ? 'フォロー中' : 'フォローする'}
                  </p>
                </FlatButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default UserCard
