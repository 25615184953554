import { useCallback, useState } from 'react'
import { Kuchikomi } from '../../../model/Kuchikomi'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { useAuth } from '../../../hooks/use_auth'

type Handler = {
  load: (kuchikomiId: string) => Promise<void>
  like: (kuchikomiId: string, point: number) => Promise<void>
  reply: (kuchikomiId: string, message: string) => Promise<void>
  bookmark: (kuchikomiId: string, set: boolean) => Promise<void>
  delete: (kuchikomiId: string) => Promise<void>
}

const useKuchikomiDetail = (): [Kuchikomi | null, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [kuchikomi, setKuchikomi] = useState<Kuchikomi | null>(null)
  const { user } = useAuth()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()

  const load = useCallback(async (kuchikomiId: string) => {
    setIsLoading(true)
    await apiClient.kuchikomis
      .detailCreate(kuchikomiId)
      .then((res) => {
        setKuchikomi(res.data.kuchikomi)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const like = useCallback(
    async (kuchikomiId: string, point: number) => {
      if (!user.isLogined() || !kuchikomi || kuchikomi.id !== kuchikomiId) {
        return
      }

      // 先に更新
      const nextKuchikomi = { ...kuchikomi }

      const beforeLiked = nextKuchikomi.liked
      nextKuchikomi.likeCount += point
      nextKuchikomi.liked = true
      setKuchikomi(nextKuchikomi)

      await apiClient.kuchikomis
        .likeUpdate(kuchikomi.id, { point: point })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)

          // 元に戻す
          const nextKuchikomi = { ...kuchikomi }
          if (!beforeLiked) {
            nextKuchikomi.liked = false
          }
          nextKuchikomi.likeCount -= point
          setKuchikomi(nextKuchikomi)

          return null
        })
    },
    [kuchikomi]
  )

  const reply = useCallback(
    async (kuchikomiId: string, message: string) => {
      if (!user.isLogined() || !kuchikomi || kuchikomi.id !== kuchikomiId) {
        return
      }

      showLoading()
      setIsLoading(true)
      await apiClient.kuchikomis
        .replyUpdate(kuchikomi.id, { message: message })
        .then((res) => {
          const reply = res.data.reply
          const nextKuhikomi = { ...kuchikomi }
          nextKuhikomi.replies.push(reply)
          setKuchikomi(nextKuhikomi)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
          setIsLoading(false)
        })
    },
    [kuchikomi]
  )

  /**
   * ブックマークする
   * ローディング画面は出さず、非同期で処理する
   */
  const bookmark = useCallback(
    async (kuchikomiId: string, set: boolean) => {
      if (!user.isLogined() || !kuchikomi || kuchikomi.id !== kuchikomiId) {
        return
      }

      // 先に画面を更新
      const nextKuhikomi = { ...kuchikomi }
      nextKuhikomi.bookmarked = set
      setKuchikomi(nextKuhikomi)

      await apiClient.kuchikomis
        .bookmarkUpdate(kuchikomi.id, { set: set })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
    },
    [kuchikomi]
  )

  const deleteKuchikomi = useCallback(
    async (kuchikomiId: string) => {
      if (!user.isLogined() || !kuchikomi || kuchikomi.id !== kuchikomiId) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .deleteCreate(kuchikomi.id)
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomi]
  )

  return [
    kuchikomi,
    isLoading,
    {
      load: load,
      like: like,
      bookmark: bookmark,
      reply: reply,
      delete: deleteKuchikomi,
    },
  ]
}

export default useKuchikomiDetail
