import React, { FC } from 'react'
import { BookmarkIcon } from '../../images/icons/bookmark_icon'

type BookmarkButtonProps = {
  isActive: boolean
  bookmarkCount?: number
  isSp?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const BookmarkButton: FC<BookmarkButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSp = false,
  ...props
}) => {
  let textColor = 'text-main-lightGray'
  if (props.isActive) {
    textColor = 'text-main-blue font-bold'
  }

  return (
    <div className="flex justify-start cursor-pointer" onClick={props.onClick}>
      <div className="flex flex-col justify-center mr-1">
        <BookmarkIcon isActive={props.isActive} />
      </div>
      <div className="flex flex-col justify-center">
        <p className={`${textColor} text-xs pl-1 whitespace-nowrap`}>
          ブックマーク
        </p>
      </div>
    </div>
  )
}

export default BookmarkButton
