import React, { useEffect } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useCodes from '../../hooks/use_codes'
import useUser from './hooks/use_user'
import useNotification from './hooks/use_notification'
import MyPageTemplate from './components/templates/mypage_template'
import InfiniteScroller from '../../components/features/infinite_scroller'
import NotificationCard from './components/cards/notification_card'

const MyPageNotification = () => {
  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [notifications, isLoading, hasMore, notificationHandler] =
    useNotification()
  useEffect(() => {
    if (user) {
      notificationHandler.load()
    }
  }, [user])

  const notificationElems = (
    <div>
      {notifications?.map((item, index) => {
        return (
          <div key={`notification-${String(index)}`}>
            <NotificationCard notification={item} isSp={isSp} />
          </div>
        )
      })}
    </div>
  )

  const content = (
    <MyPageTemplate
      head="通知"
      user={user}
      isSp={isSp}
      userLoading={userLoading}
    >
      <div className="rounded-xl overflow-hidden bg-white">
        <InfiniteScroller
          load={async () => {
            if (user) {
              await notificationHandler.load()
            }
          }}
          loading={isLoading}
          hasMore={hasMore}
        >
          <div>{notificationElems}</div>
        </InfiniteScroller>
      </div>
    </MyPageTemplate>
  )

  return content
}

export default MyPageNotification
