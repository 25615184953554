import { useCallback, useState } from 'react'
import User from '../../../model/User'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string, cursor?: number) => Promise<void>
  follow: (id: string, set: boolean) => Promise<void>
}

const useFollowers = (
  getLimit = 20
): [Array<User>, number, number, boolean, Handler] => {
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  const [loading, setLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [apiCursor, setApiCursor] = useState(0)
  const [followers, setFollows] = useState<Array<User>>([])

  const load = useCallback(
    async (id: string, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setLoading(true)
      await apiClient.users
        .followersDetail(id, { cursor: cursor, limit: getLimit })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setApiCursor(cursor)
            setFollows(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => setLoading(false))
    },
    [apiCursor, totalSize, followers]
  )

  const follow = useCallback(
    async (id: string, set: boolean) => {
      await apiClient.follow
        .userCreate({
          id: id,
          set: set,
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
    },
    [followers]
  )

  return [
    followers,
    totalSize,
    apiCursor,
    loading,
    { load: load, follow: follow },
  ]
}

export default useFollowers
