import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Enterprise from '../../../model/Enterprise'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
}

const useRelatedEnterprise = (): [Array<Enterprise>, boolean, Handler] => {
  const GETLIMIT = 5
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const { showSnackBar } = useSnackBar()
  const [enterprises, setEnterprises] = useState<Array<Enterprise>>([])

  const load = useCallback(
    async (id: string) => {
      setIsLoading(true)
      await apiClient.enterprises
        .relatedEnterprisesDetail(id, { limit: GETLIMIT })
        .then((res) => {
          const data = res.data.data
          setEnterprises(data)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [enterprises]
  )

  return [enterprises, isLoading, { load: load }]
}

export default useRelatedEnterprise
