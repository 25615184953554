import React, { FC } from 'react'

export const MainInputTag: FC<{ required: boolean }> = (props) => {
  const bg = props.required ? 'bg-main-red' : 'bg-main-darkGray'
  const text = props.required ? '必須' : '任意'

  return (
    <div className="flex flex-col justify-center">
      <div className={`rounded-md ${bg} w-fit h-fit`}>
        <p className="text-white text-xs text-center px-2 py-[2px]">{text}</p>
      </div>
    </div>
  )
}
