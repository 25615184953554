import React, { FC, ReactNode } from 'react'
import MainHeader from '../headers/main_header'
import MainFooter from '../footers/main_footer'
import SpNavigation from '../navigation/navigation'

type BaseTemplateProps = {
  children: ReactNode
  additonalTopNode?: ReactNode
  isPadding?: boolean
  isShowHeader?: boolean
  isSp?: boolean
}

const BaseTemplate: FC<BaseTemplateProps> = ({
  children,
  additonalTopNode,
  isPadding = true,
  isShowHeader = true,
  isSp = false,
}) => {
  const wrapperClass = isPadding ? 'w-11/12 max-w-6xl py-8 mx-auto' : 'w-full'

  return (
    <>
      <div className="relative w-full min-h-screen bg-main-gray">
        <MainHeader isSp={isSp} isShowHeader={isShowHeader} />
        {additonalTopNode}
        <div className={wrapperClass}>{children}</div>
        {isSp && isShowHeader ? <SpNavigation /> : null}
      </div>
      <MainFooter />
    </>
  )
}

export default BaseTemplate
