import React, { FC } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

const BASEINPUTWIDTH = 280

export const Tag: FC<{ required: boolean }> = (props) => {
  const bg = props.required ? 'bg-main-red' : 'bg-main-darkGray'
  const text = props.required ? '必須' : '任意'

  return (
    <div className="flex flex-col justify-center">
      <div className={`rounded-md ${bg} w-fit h-fit`}>
        <p className="text-white text-xs text-center px-2">{text}</p>
      </div>
    </div>
  )
}

export const ReadonlyInput: FC<{
  required: boolean
  label: string
  value: string
  description?: string
  unitStr?: string
  width?: string
}> = (props) => {
  return (
    <div className="py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <Tag required={props.required} />
      </div>
      <div className="flex justify-start">
        <p>{props.value}</p>
        {props.unitStr ? (
          <div className="flex flex-col justify-center">
            <p className="pl-1 whitespace-nowrap">{props.unitStr}</p>
          </div>
        ) : null}
      </div>
      {props.description && (
        <p className="text-main-lightGray text-sm py-2">{props.description}</p>
      )}
    </div>
  )
}

export const SimpleInput: FC<{
  required: boolean
  label: string
  description: string
  unitStr?: string
  type?: 'text' | 'email' | 'password' | 'number'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseFormRegisterReturn<any>
  width?: string
}> = (props) => {
  return (
    <div className="py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[12rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <Tag required={props.required} />
      </div>
      <div className="flex justify-start">
        <input
          type={props.type}
          className="rounded-lg px-2 py-1 outline-none border border-main-lightGray"
          style={{ width: props.width ?? BASEINPUTWIDTH }}
          {...props.options}
        />
        {props.unitStr ? (
          <div className="flex flex-col justify-center">
            <p className="pl-1 whitespace-nowrap">{props.unitStr}</p>
          </div>
        ) : null}
      </div>
      <p className="text-main-lightGray text-sm py-2">{props.description}</p>
    </div>
  )
}

export const SelectInputContainer: FC<{
  values: Array<{
    subTitle?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: UseFormRegisterReturn<any>
    checks: Array<{
      code: string
      label: string
    }>
  }>
  placeholder?: string
  brankLabel?: string
  title?: string
  key: string
  required: boolean
  width?: string
  className?: string
}> = (props) => {
  return (
    <div className={`w-fit py-2 ${props.className ?? 'pb-4'}`}>
      {props.title && (
        <div className="flex justify-start">
          <p className="w-[9rem] whitespace-nowrap font-bold">{props.title}</p>
          <Tag required={props.required} />
        </div>
      )}
      {props.values.map((outerItem, outerIndex) => {
        return (
          <div key={`${props.key}-outer-${String(outerIndex)}`}>
            <div>
              {outerItem.subTitle && (
                <p className="leading-6 font-bold">{outerItem.subTitle}</p>
              )}
            </div>
            <select
              className="rounded-lg px-2 py-3 outline-none border border-main-lightGray"
              {...outerItem.options}
              style={{ width: props.width ?? BASEINPUTWIDTH }}
            >
              <option value="" disabled selected className="hidden">
                {props.placeholder ?? '選択してください'}
              </option>
              {props.brankLabel && <option value="">{props.brankLabel}</option>}
              {outerItem.checks.map((innterItem, innerIndex) => {
                return (
                  <option
                    key={`${props.key}-${String(outerIndex)}-${String(
                      innerIndex
                    )}`}
                    value={innterItem.code}
                  >
                    {innterItem.label}
                  </option>
                )
              })}
            </select>
          </div>
        )
      })}
    </div>
  )
}

export const CheckInputContainer: FC<{
  values: Array<{
    subTitle?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: UseFormRegisterReturn<any>
    checks: Array<{
      code: string
      label: string
    }>
  }>
  title: string
  titleWidth?: string
  keyName: string
  type: 'radio' | 'checkbox'
  optionPosition?: 'flex' | 'block'
  required: boolean
}> = (props) => {
  return (
    <div className="w-fit py-2">
      <div className="flex justify-start">
        <p
          className={`whitespace-nowrap font-bold`}
          style={{
            width: props.titleWidth ?? '12rem',
          }}
        >
          {props.title}
        </p>
        <Tag required={props.required} />
      </div>
      <div className="sm:px-4">
        {props.values.map((outerItem, outerIndex) => {
          return (
            <div
              key={`${props.keyName}-outer-${String(outerIndex)}`}
              className="pb-4"
            >
              <div>
                {outerItem.subTitle && (
                  <p className="leading-6 font-bold">{outerItem.subTitle}</p>
                )}
              </div>
              <div
                className={`px-1 ${props.optionPosition === 'block' ? 'block' : 'flex justify-start flex-wrap'}`}
              >
                {outerItem.checks.map((innterItem, innerIndex) => {
                  return (
                    <div
                      key={`${props.keyName}-${String(outerIndex)}-${String(
                        innerIndex
                      )}`}
                      className="min-w-[25%] flex justify-start items-start pr-4"
                    >
                      <input
                        id={`${props.keyName}-${innterItem.code}`}
                        type={props.type}
                        value={innterItem.code}
                        className="cursor-pointer mt-[11px]"
                        {...outerItem.options}
                      />
                      <label
                        htmlFor={`${props.keyName}-${innterItem.code}`}
                        className="pl-2 cursor-pointer"
                      >
                        {innterItem.label}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const StackInput: FC<{
  required: boolean
  label1: string
  label2: string
  description: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options1: UseFormRegisterReturn<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options2: UseFormRegisterReturn<any>
}> = (props) => {
  return (
    <div className="py-2">
      <div className="md:flex justify-between">
        <div className="w-fit mb-7 md:mb-0">
          <div className="flex justify-start">
            <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
              {props.label1}
            </label>
            <Tag required={props.required} />
          </div>
          <input
            type="text"
            className="rounded-lg px-2 py-1 outline-none border border-main-lightGray"
            style={{ width: BASEINPUTWIDTH }}
            {...props.options1}
          />
        </div>
        <div className="w-fit">
          <div className="flex justify-start">
            <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
              {props.label2}
            </label>
            <Tag required={props.required} />
          </div>
          <input
            type="text"
            className="rounded-lg px-2 py-1 outline-none border border-main-lightGray"
            style={{ width: BASEINPUTWIDTH }}
            {...props.options2}
          />
        </div>
      </div>
      <p className="text-main-lightGray text-sm py-2">{props.description}</p>
    </div>
  )
}

export const SimpleTextarea: FC<{
  required: boolean
  label: string
  description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseFormRegisterReturn<any>
}> = (props) => {
  return (
    <div className="w-full py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <Tag required={props.required} />
      </div>
      <textarea
        className="w-full rounded-lg px-2 py-1 outline-none resize-none border border-main-lightGray"
        {...props.options}
      />
      {props.description && (
        <p className="text-main-lightGray text-sm py-2">{props.description}</p>
      )}
    </div>
  )
}

export const SimpleDateInput: FC<{
  required: boolean
  label: string
  description: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseFormRegisterReturn<any>
}> = (props) => {
  return (
    <div className="w-fit py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <Tag required={props.required} />
      </div>
      <input
        type="date"
        className="rounded-lg px-2 py-1 outline-none border border-main-lightGray"
        style={{ width: BASEINPUTWIDTH }}
        {...props.options}
      />
      <p className="text-main-lightGray text-sm py-2">{props.description}</p>
    </div>
  )
}
