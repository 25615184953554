import React, { FC } from 'react'
import { CommentIcon } from '../../images/icons/comment_icon'

type CommentButtonProps = {
  isActive: boolean
  isSp?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const CommentButton: FC<CommentButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSp = false,
  ...props
}) => {
  return (
    <div className="flex justify-start cursor-pointer" onClick={props.onClick}>
      <div className="flex flex-col justify-center">
        <CommentIcon />
      </div>
      <div className="flex flex-col justify-center">
        <p className="text-main-lightGray text-xs pl-1 whitespace-nowrap">
          返信する
        </p>
      </div>
    </div>
  )
}

export default CommentButton
