import React, { FC } from 'react'

type ChartIconProps = {
  color: 'WHITE' | 'LIGHTGRAY'
}

export const ChartIcon: FC<ChartIconProps> = (props) => {
  let fillColor = '#FFFFFF'
  switch (props.color) {
    case 'WHITE':
      break
    case 'LIGHTGRAY':
      fillColor = '#b9b9b9'
      break
    default:
      fillColor = '#FFFFFF'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="18"
        viewBox="0 0 24 18"
      >
        <path
          id="FontAwsome_chart-line_"
          data-name="FontAwsome (chart-line)"
          d="M23.25,79H3V64.75A.75.75,0,0,0,2.25,64H.75a.75.75,0,0,0-.75.75V80.5A1.5,1.5,0,0,0,1.5,82H23.25a.75.75,0,0,0,.75-.75v-1.5A.75.75,0,0,0,23.25,79Zm-1.5-13.5H16.216a1.125,1.125,0,0,0-.8,1.92l1.519,1.519L13.5,72.379,10.061,68.94a1.5,1.5,0,0,0-2.121,0L4.72,72.16a.75.75,0,0,0,0,1.061l1.06,1.06a.75.75,0,0,0,1.061,0L9,72.121l3.439,3.439a1.5,1.5,0,0,0,2.121,0l4.5-4.5,1.519,1.519a1.125,1.125,0,0,0,1.92-.8V66.25A.749.749,0,0,0,21.75,65.5Z"
          transform="translate(0 -64)"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}
