import { useCallback, useEffect, useState } from 'react'
import { useLoading } from '../../../providers/loading_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import User from '../../../model/User'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: () => Promise<void>
}

const useUser = (): [User | null, boolean, Handler] => {
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  const [isLoading, setIsLoading] = useState(true)

  const [user, setUser] = useState<User | null>(null)

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.mypage
      .userList()
      .then((res) => {
        const data = res.data
        setUser(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    load()
  }, [])

  return [user, isLoading, { load: load }]
}

export default useUser
