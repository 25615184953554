import React, { FC } from 'react'

const PrivacyPolicyContent: FC = () => {
  return (
    <>
      <div className="mb-12">
        <p>
          株式会社The
          Lodges（以下、「当社」といいます）は、お客様の個人情報を取り扱うにあたり、「個人情報の保護に関する法令」を遵守し、個人の権利利益を不当に侵害することがないようにプライバシーポリシー（個人情報保護規定）を定めています。
        </p>
        <p className="mb-8">
          当社は、当社サービスを提供する際にこの規定に基づき、企業・団体およびメディア等の方々（以下「お客様」という）の個人情報を取り扱います。
        </p>
        <p>個人情報保護管理責任者: 代表取締役 長澤 奏喜</p>
        <p>〒650-0035 兵庫県神戸市中央区浪花町56 KiP内</p>
        <p>E-mail: cs@the-lodges.jp</p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">個人情報の定義</h3>
        <p>
          ・個人情報とは、生存する個人に関する情報であり、氏名や生年月日などの特定の個人を識別することができる情報および個人識別符号を含むものを指します。
        </p>
        <p>
          ・個人データとは、個人情報データベースなどを構成する個人情報を指します。
        </p>
        <p>
          ・保有個人データとは、開示・訂正・追加・削除・利用停止・消去・第三者への提供停止が可能な個人データ（6ヶ月以内に消去することとなるもの等を除く）を指します。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">個人情報の取得</h3>
        <p>
          お客様の個人情報は、当社が提供するサービスの業務を遂行するために利用されます。これらの情報は、以下の利用目的に従って適法かつ公正な手段により活用されます。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">個人情報の利用</h3>
        <p>
          当社は、以下の利用目的のためにお預かりした個人情報を取り扱います。
        </p>
        <p>・お客様の本人認証のためのログイン時またはログイン後の利用</p>
        <p>・お客様への連絡や案内のためのお客様への連絡</p>
        <p>・お問い合わせへの対応</p>
        <p>・アンケートの実施やデータ分析によるサービスの改善</p>
        <p>・メールマガジンの配信</p>
        <p>・利用規約違反などの調査および違反対応</p>
        <p>・その他、上記の利用目的に付随する目的</p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">個人情報の第三者提供</h3>
        <p>
          当社は、お客様の同意なしにお客様の個人情報を第三者に開示することはありません。ただし、機密保持契約を締結している協力企業、提携会社、業務委託会社、または業務委託者に対して、個人情報を開示する場合があります。
        </p>
        <p>また、以下のような場合は、個人情報を開示することがあります。</p>
        <p>
          法令または裁判所その他の政府機関、地方公共団体より適法に開示を要求された場合
        </p>
        <p>人の生命、身体、財産の保護のために必要があると当社が判断した場合</p>
        <p>
          国の機関、地方公共団体、またはその委託を受けた者が法令に基づく業務を遂行するために協力する必要がある場合、または公共の利益のために特に必要があると当社が判断した場合
        </p>
        <p>
          お客様または当社の権利を確保するために必要であると当社が判断した場合
        </p>
        <p>その他特定の目的のためにお客様から同意を得た場合</p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">開示等の請求</h3>
        <p>
          当社は、個人情報保護法に基づき、お客様から保有個人データに関する開示、訂正・追加・削除、利用停止・消去、第三者提供の停止または利用目的の通知に関する請求があった場合、所定の手続きに従い、本人または代理権限を有する者であることを確認した上で、個人情報保護法に従って適切に対応します。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">情報提供の任意性</h3>
        <p>
          お客様が自身の個人情報を当社に提供するかどうかは、お客様の判断に委ねられます。ただし、個人情報の提供がない場合、当社のサービスを提供することができない場合がありますので、あらかじめご了承ください。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">当社ウェブサイト上のセキュリティ</h3>
        <p>
          当社のサイトではお客様の個人情報を、SSL(Secure Sockets
          Layer)と呼ばれる暗号化技術によって保護しています。
        </p>
        <p>
          また、当社のサイトでは、「クッキー」や「IPアドレス」を使用・取得しています。クッキーとは、利用者のコンピューターの識別やサイトへのアクセス回数などを記録するものであり、個人を特定できる情報は含んでおりません。なお、ブラウザの設定を変更することで、クッキーの受信拒否など可能ですが、その場合、ウェブサイトにおいてのサービスを正常にご利用いただけない場合があります。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">行動ターゲティング広告について</h3>
        <p>
          当社では以下のサイトと提携し、行動ターゲティング広告サービスを行っております。本広告サービスではお客様のコンピューターにクッキー(Cookie)を記録しておりますが、お客様個人を特定・識別する情報は一切含まれません。また、当社のサイトで記録されたクッキーは、当社の広告配信の目的のみに使用され、その他の目的や個人情報の収集には一切使用しません。本広告サービスを詳しくお知りになりたい方は、以下の提携先サイトへアクセスのうえご確認ください。本広告サービスをご希望でない方は、提携先サイトのオプトアウト（この広告を拒否して送付しないよう要請すること）のページにアクセスし、手順に沿って本広告サービスのクッキーを無効化してください。
        </p>
        <p className="break-all">
          Google https://www.google.co.jp/intl/ja/policies/technologies/ads/
        </p>
        <p className="break-all">
          Yahoo! JAPAN https://btoptout.yahoo.co.jp/optout/preferences.html
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">
          Googleアナリティクスのレポート機能を用いた属性情報等の使用について
        </h3>
        <p>
          当社では、当社サービスの企画・改善等のために、Google社の提供するアクセス解析サービス「アナリティクス」を利用し、本サービスの一機能である「ユーザー属性とインタレストカテゴリに関するレポート」を参考にすることがあります。Googleアナリティクスによるデータ収集を無効にしたい場合は、こちらで無効化を行ってください。
        </p>
        <p>Googleアナリティクスオプトアウト</p>
        <p className="break-all">
          (https://support.google.com/analytics/answer/9050852?hl=ja&ref_topic=2611283)
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">16歳未満の方へのお願い</h3>
        <p>
          16歳未満の方が、当社のサービスを利用するため個人情報を入力する際は、必ず保護者の方の同意を得て行なうようお願いいたします。
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">個人情報への安全対策の実施について</h3>
        <p>
          当社は、情報セキュリティ対策をはじめとする安全対策を実施し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩等が起こらないよう努め問題が発生した場合には是正措置を講じます。
        </p>
        <p>
          ただし、次のような事由による個人情報のリスクについては、当社は責任を負いかねます。
        </p>
        <p>お客様が当社以外のサービスを利用して個人情報を開示した場合</p>
        <p>
          お客様の利用端末から個人情報が漏洩した場合や、お客様が管理するパスワードの使用により個人情報が漏洩した場合
        </p>
        <p>
          お客様が提供した個人情報が誤っていたために、当該お客様以外の第三者に個人情報が漏れた場合
        </p>
      </div>
      <div className="mb-12">
        <h3 className="font-bold mb-4">プライバシーポリシーの変更について</h3>
        <p>
          当社は、個人情報の取り扱いに関する運用状況を適宜見直し、継続的な改善に努めます。必要があれば、本プライバシーポリシーを変更することがあります。変更後のプライバシーポリシーは、当社のウェブサイト上で随時公表されます。変更後のプライバシーポリシーは、当該公表時点から効力を生じます。
        </p>
      </div>
      <h3 className="font-bold mb-4">お問い合わせ窓口</h3>
      <p className="mb-8">
        当社における個人データの取扱いに関するお問い合わせや苦情に関しては、下記の窓口までお願いいたします。
      </p>
      <p>住所：〒650-0035 兵庫県神戸市中央区浪花町56 KiP内</p>
      <p>担当窓口：株式会社The Lodges 個人情報保護相談窓口</p>
      <p>E-mail: cs@the-lodges.jp</p>
    </>
  )
}

export default PrivacyPolicyContent
