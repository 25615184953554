import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { FormErrorMessage } from '../../constants/message'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

type PasswordResetFormProps = {
  password: string
  password_confirmation: string
}

const PasswordResetInputPage: FC = () => {
  const { showLoading, hideLoading } = useLoading()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const apiClient = getAppClient()
  const pathParams = useParams<{ token: string }>()
  const [email, setEmail] = useState<string | null>(null)

  const { register, handleSubmit, formState, watch } =
    useForm<PasswordResetFormProps>({
      mode: 'onSubmit',
    })

  const { errors } = formState
  const password = watch('password')
  const isSp = useSpBreakPoint()

  useEffect(() => {
    if (pathParams.token) {
      loadData(pathParams.token)
    }
  }, [pathParams.token])

  const loadData = async (token: string | undefined) => {
    if (!token) return

    await apiClient.passwords
      .getEmailCreate({
        token: token,
      })
      .then((response) => {
        setEmail(response.data.email)
        setLoading(false)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        navigate('/password/reset/request')
      })
  }

  // パスワードリセット実行
  const passwordReset = async (data: PasswordResetFormProps) => {
    if (!pathParams.token || !email) {
      showSnackBar(
        [
          'リクエスト内容が不正です。御手数ですが運営までお問い合わせください。',
        ],
        'error'
      )
      return
    }

    showLoading()
    await apiClient.passwords
      .resetStoreCreate({
        token: pathParams.token,
        email: email ?? '',
        password: data.password,
        password_confirmation: data.password_confirmation,
      })
      .then(() => {
        showSnackBar(['パスワードが正常にリセットされました。'], 'success')
        navigate('/password/reset/complete')
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <CommonTemplate isSp={isSp}>
      <div className="bg-white rounded-xl w-full max-w-xl mx-auto p-6">
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-8 text-left">
            パスワードリセット
          </h2>
        </div>
        {loading ? (
          <LoadingSpinner className="mx-auto" />
        ) : (
          <form onSubmit={handleSubmit(passwordReset)}>
            <p className="mb-2 font-bold text-left">メールアドレス</p>
            <input
              className="w-full mb-8 py-1 px-2 border border-gray-500 rounded-lg outline-none"
              type="email"
              value={email || ''}
              readOnly
            />

            <p className="mb-2 font-bold text-left">新しいパスワード</p>
            <input
              className="w-full mb-8 py-1 px-2 border border-gray-500 rounded-lg outline-none"
              type="password"
              placeholder="新しいパスワードを入力してください"
              {...register('password', {
                required: FormErrorMessage.required,
                minLength: {
                  value: 8,
                  message: FormErrorMessage.minLength(8),
                },
              })}
            />
            {errors.password && (
              <p className="error-message text-left">
                {errors.password.message}
              </p>
            )}

            <p className="mb-2 font-bold text-left">
              新しいパスワード（確認用）
            </p>
            <input
              className="w-full mb-8 py-1 px-2 border border-gray-500 rounded-lg outline-none"
              type="password"
              placeholder="新しいパスワードを再度入力してください"
              {...register('password_confirmation', {
                required: FormErrorMessage.required,
                validate: (value) =>
                  value === password || 'パスワードが一致しません',
              })}
            />
            {errors.password_confirmation && (
              <p className="error-message text-left">
                {errors.password_confirmation.message}
              </p>
            )}

            <div className="py-4 text-center">
              <FlatButton size="md" type="submit" bgColor="blue">
                送信
              </FlatButton>
            </div>
          </form>
        )}
      </div>
    </CommonTemplate>
  )
}

export default PasswordResetInputPage
