import React, { FC, useEffect, useState } from 'react'
import Head2 from '../../components/heads/head2'
import { FormErrorMessage } from '../../constants/message'
import { useForm } from 'react-hook-form'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import {
  CheckInputContainer,
  SelectInputContainer,
  SimpleDateInput,
  SimpleInput,
  SimpleTextarea,
  StackInput,
} from '../../components/form/form_item'
import useCodes from '../../hooks/use_codes'
import FlatButton from '../../components/buttons/flat_button'
import { useUpload } from '../../hooks/use_upload'
import LoadingSpinner from '../../components/loading/loading_spinner'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

type UpdateFormProps = {
  lastName: string
  firstName: string
  displayName: string
  gender: string
  birthDay: string
  iconUrl: string
  prefecture: string
  profile: string
  catchCopy: string
  occupationCode: string
  interestingSDGs: Array<string>
  homepageUrl: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  tiktokUrl: string
  email: string
  isPasswordChange: boolean
  password: string
  passwordConfirmation: string
}

const MyPageUpdateUserInfo: FC = () => {
  const { showLoading, hideLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(true)
  const { showSnackBar } = useSnackBar()
  const { upload } = useUpload()
  const [codes] = useCodes()
  const apiClient = getAppClient()
  const isSp = useSpBreakPoint()

  const initUserData = async () => {
    showLoading()
    const currentUserInfo = await apiClient.mypage
      .registerdUserInfoList()
      .then((res) => {
        return res.data
      })
      .finally(() => hideLoading())
    setValue('lastName', currentUserInfo.lastName ?? '')
    setValue('firstName', currentUserInfo.firstName ?? '')
    setValue('displayName', currentUserInfo.displayName ?? '')
    setValue('gender', currentUserInfo.gender?.code ?? '')
    setValue('prefecture', currentUserInfo.prefecture ?? '')
    setValue('birthDay', currentUserInfo.birthDay ?? '')
    setValue('iconUrl', currentUserInfo.iconUrl)
    setValue('profile', currentUserInfo.profile ?? '')
    setValue('catchCopy', currentUserInfo.catchCopy ?? '')
    setValue('occupationCode', currentUserInfo.occupationCode?.code ?? '')
    setValue(
      'interestingSDGs',
      currentUserInfo.interestingSDGs.map((item) => {
        return item.code
      })
    )
    setValue('homepageUrl', currentUserInfo.homepageUrl || '')
    setValue('twitterUrl', currentUserInfo.twitterUrl || '')
    setValue('facebookUrl', currentUserInfo.facebookUrl || '')
    setValue('instagramUrl', currentUserInfo.instagramUrl || '')
    setValue('tiktokUrl', currentUserInfo.tiktokUrl || '')
    setValue('email', currentUserInfo.email || '')
  }

  useEffect(() => {
    initUserData().then(() => {
      setIsLoading(false)
    })
  }, [])

  const { register, handleSubmit, watch, setValue, formState } =
    useForm<UpdateFormProps>({
      defaultValues: {
        lastName: '',
        firstName: '',
        displayName: '',
        gender: '',
        birthDay: '',
        iconUrl: `${process.env.REACT_APP_PR_BASE_URL}/assets/kuchikomi/img/default_user_profile.png`,
        profile: '',
        catchCopy: '',
        occupationCode: '',
        interestingSDGs: [],
        prefecture: '',
        homepageUrl: '',
        twitterUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        tiktokUrl: '',
        email: '',
        isPasswordChange: false,
        password: '',
        passwordConfirmation: '',
      },
      mode: 'onSubmit',
    })
  const { errors } = formState

  const uploadIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files?.length === 0) return

    showLoading()
    const nextFile = files[0]
    const url = await upload(nextFile)

    if (url !== null) {
      setValue('iconUrl', url)
    }
  }

  const updateUser = async (data: UpdateFormProps) => {
    if (data.isPasswordChange && data.password !== data.passwordConfirmation) {
      showSnackBar(['パスワードと確認用パスワードが一致しません'], 'error')
      return
    }

    showLoading()

    const responseData = await apiClient.users
      .updateUpdate({
        lastName: data.lastName,
        firstName: data.firstName,
        displayName: data.displayName,
        birthDay: data.birthDay,
        iconUrl: data.iconUrl,
        profile: data.profile,
        catchCopy: data.catchCopy,
        occupationCode: data.occupationCode,
        gender: data.gender,
        email: data.email,
        prefecture: data.prefecture,
        interestingSDGs: data.interestingSDGs.map((item) => item),
        homepageUrl: data.homepageUrl,
        twitterUrl: data.twitterUrl,
        facebookUrl: data.facebookUrl,
        instagramUrl: data.instagramUrl,
        tiktokUrl: data.tiktokUrl,
        isPasswordChange: data.isPasswordChange,
        password: data.password,
        // passwordConfirmation: data.passwordConfirmation,
      })
      .then((res) => {
        const data = res.data
        setValue('isPasswordChange', false)
        setValue('password', '')
        setValue('passwordConfirmation', '')
        return data
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        hideLoading()
      })

    if (responseData === null) {
      return
    }

    showSnackBar(['ユーザー情報を更新しました'], 'success')
  }

  const userForm = (
    <div>
      <div className="mb-8">
        <div className="flex justify-start mb-2">
          <div className="rounded-full w-fit">
            <div
              className="w-32 h-32 bg-cover rounded-full bg-center"
              style={{
                backgroundImage: `url(${watch('iconUrl')})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col justify-center ml-4">
            <label
              htmlFor="image-upload"
              className="text-main-lightGray p-2 border border-main-lightGray rounded-lg cursor-pointer"
            >
              データをアップロードする
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={uploadIcon}
            />
          </div>
        </div>
        <div>データ形式はjpg、pngのみ対応</div>
      </div>
      {errors.iconUrl && (
        <p className="error-message">{errors.iconUrl.message}</p>
      )}

      <StackInput
        required={false}
        label1="名前（姓）"
        label2="名前（名）"
        description="氏名をご入力ください。"
        options1={register('lastName', {
          // required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
        options2={register('firstName', {
          // required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
      />
      {errors.lastName && (
        <p className="error-message">{errors.lastName.message}</p>
      )}
      {errors.firstName && (
        <p className="error-message">{errors.firstName.message}</p>
      )}

      <SimpleInput
        required
        label="ユーザー名"
        description="ユーザー名をご入力ください。"
        options={register('displayName', {
          required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
      />
      {errors.displayName && (
        <p className="error-message">{errors.displayName.message}</p>
      )}

      <CheckInputContainer
        title="性別"
        keyName="gender"
        type="radio"
        required={false}
        values={[
          {
            options: register('gender', {
              // required: FormErrorMessage.required,
            }),
            checks: codes.genderCodes.map((item) => {
              return {
                code: item.code,
                label: item.value,
              }
            }),
          },
        ]}
      />
      {errors.gender && (
        <p className="error-message">{errors.gender.message}</p>
      )}

      <SelectInputContainer
        title="都道府県"
        key="prefecture"
        required={false}
        values={[
          {
            options: register('prefecture', {
              // required: FormErrorMessage.required,
            }),
            checks: codes.prefectures.map((p) => {
              return {
                code: p,
                label: p,
              }
            }),
          },
        ]}
      />
      {errors.prefecture && (
        <p className="error-message">{errors.prefecture.message}</p>
      )}

      <SimpleDateInput
        required={false}
        label="誕生日"
        description="誕生日をご入力ください。"
        options={register('birthDay', {
          // required: FormErrorMessage.required,
        })}
      />
      {errors.birthDay && (
        <p className="error-message">{errors.birthDay.message}</p>
      )}

      <div className="w-full relative">
        <SimpleTextarea
          required={false}
          label="プロフィール"
          description="プロフィールをご入力ください。"
          options={register('profile', {
            // required: FormErrorMessage.required,
            maxLength: {
              value: 3000,
              message: FormErrorMessage.maxLength(3000),
            },
          })}
        />
        {errors.profile && (
          <p className="error-message">{errors.profile.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleTextarea
          required={false}
          label="ヒトコト"
          description="ヒトコトをご入力ください。"
          options={register('catchCopy', {
            // required: FormErrorMessage.required,
            maxLength: {
              value: 200,
              message: FormErrorMessage.maxLength(200),
            },
          })}
        />
        {errors.catchCopy && (
          <p className="error-message">{errors.catchCopy.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="ホームページURL"
          description="ホームページURLをご入力ください。"
          options={register('homepageUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.homepageUrl && (
          <p className="error-message">{errors.homepageUrl.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="X（旧Twitter）URL"
          description="X（旧Twitter）URLをご入力ください。"
          options={register('twitterUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.twitterUrl && (
          <p className="error-message">{errors.twitterUrl.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="Facebook URL"
          description="Facebook URLをご入力ください。"
          options={register('facebookUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.facebookUrl && (
          <p className="error-message">{errors.facebookUrl.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="Instagram URL"
          description="Instagram URLをご入力ください。"
          options={register('instagramUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.instagramUrl && (
          <p className="error-message">{errors.instagramUrl.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="TikTok URL"
          description="TikTok URLをご入力ください。"
          options={register('tiktokUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.tiktokUrl && (
          <p className="error-message">{errors.tiktokUrl.message}</p>
        )}
      </div>

      <CheckInputContainer
        title="職種カテゴリー"
        keyName="occupation"
        type="radio"
        required={false}
        values={[
          {
            options: register('occupationCode', {
              // required: FormErrorMessage.required,
            }),
            checks: codes.occupationCodes.map((item) => {
              return {
                code: item.code,
                label: item.value,
              }
            }),
          },
        ]}
      />
      {errors.occupationCode && (
        <p className="error-message">{errors.occupationCode.message}</p>
      )}

      <CheckInputContainer
        title="SDGsカテゴリー"
        keyName="sdgs"
        type="checkbox"
        optionPosition="block"
        required={false}
        values={[
          {
            options: register('interestingSDGs', {
              // required: FormErrorMessage.required,
            }),
            checks: codes.sdgsCategories.map((item) => {
              return {
                code: item.code,
                label: `${item.code}.${item.value}`,
              }
            }),
          },
        ]}
      />
      {errors.interestingSDGs && (
        <p className="error-message">{errors.interestingSDGs.message}</p>
      )}

      <SimpleInput
        required
        label="メールアドレス"
        type="email"
        description="メールアドレスをご入力ください。"
        options={register('email', {
          maxLength: {
            value: 250,
            message: FormErrorMessage.maxLength(250),
          },
        })}
      />
      {errors.email && <p className="error-message">{errors.email.message}</p>}

      <label className="flex items-center">
        <input
          type="checkbox"
          {...register('isPasswordChange')}
          className="mr-2"
        />
        <span>パスワードを変更する</span>
      </label>
      {watch('isPasswordChange') && (
        <>
          <SimpleInput
            required
            label="パスワード"
            type="password"
            description="パスワードをご入力ください。"
            options={register('password', {
              maxLength: {
                value: 250,
                message: FormErrorMessage.maxLength(250),
              },
              minLength: {
                value: 8,
                message: FormErrorMessage.minLength(8),
              },
            })}
          />
          {errors.password && (
            <p className="error-message">{errors.password.message}</p>
          )}

          <SimpleInput
            required
            label="確認用パスワード"
            type="password"
            description="確認用パスワードをご入力ください。"
            options={register('passwordConfirmation', {
              maxLength: {
                value: 250,
                message: FormErrorMessage.maxLength(250),
              },
            })}
          />
          {errors.passwordConfirmation && (
            <p className="error-message">
              {errors.passwordConfirmation.message}
            </p>
          )}
        </>
      )}
      <div className="flex justify-center mt-8">
        <FlatButton
          size="md"
          bgColor="blue"
          type="submit"
          onClick={handleSubmit(updateUser)}
        >
          更新する
        </FlatButton>
      </div>
    </div>
  )

  const content = (
    <CommonTemplate isSp={isSp}>
      <div className="mb-8">
        <Head2 title="会員情報編集" />
      </div>

      {isLoading ? (
        <LoadingSpinner className="!mx-12" />
      ) : (
        <div className="max-w-[640px] w-full bg-white rounded-xl md:px-4 px-2 py-12">
          {userForm}
        </div>
      )}
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default MyPageUpdateUserInfo
