import React from 'react'

export const InstagramIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.713"
        height="21.708"
        viewBox="0 0 21.713 21.708"
      >
        <path
          id="FontAwsome_instagram_"
          data-name="FontAwsome (instagram)"
          d="M10.784,37.113a5.566,5.566,0,1,0,5.566,5.566A5.557,5.557,0,0,0,10.784,37.113Zm0,9.184A3.618,3.618,0,1,1,14.4,42.679,3.625,3.625,0,0,1,10.784,46.3Zm7.091-9.412a1.3,1.3,0,1,1-1.3-1.3A1.3,1.3,0,0,1,17.875,36.886ZM21.561,38.2a6.424,6.424,0,0,0-1.753-4.548A6.467,6.467,0,0,0,15.26,31.9c-1.792-.1-7.164-.1-8.956,0A6.457,6.457,0,0,0,1.755,33.65,6.445,6.445,0,0,0,0,38.2c-.1,1.792-.1,7.164,0,8.956A6.424,6.424,0,0,0,1.755,51.7,6.475,6.475,0,0,0,6.3,53.457c1.792.1,7.164.1,8.956,0A6.424,6.424,0,0,0,19.808,51.7a6.467,6.467,0,0,0,1.753-4.548c.1-1.792.1-7.159,0-8.952ZM19.246,49.078a3.663,3.663,0,0,1-2.064,2.063c-1.429.567-4.82.436-6.4.436s-4.975.126-6.4-.436a3.663,3.663,0,0,1-2.064-2.063c-.567-1.429-.436-4.82-.436-6.4s-.126-4.975.436-6.4a3.663,3.663,0,0,1,2.064-2.064c1.429-.567,4.82-.436,6.4-.436s4.975-.126,6.4.436a3.663,3.663,0,0,1,2.064,2.064c.567,1.429.436,4.82.436,6.4S19.813,47.654,19.246,49.078Z"
          transform="translate(0.075 -31.825)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
