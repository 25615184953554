import React, { FC } from 'react'

type WhiteModalContentProps = {
  children: React.ReactNode
  additionalClass?: string
}

const WhiteModalContent: FC<WhiteModalContentProps> = (props) => {
  return (
    <div
      className={`bg-white max-h-[85vh] rounded-md max-w-[90vw] overflow-y-auto overscroll-none ${
        props.additionalClass || ''
      }`}
    >
      {props.children}
    </div>
  )
}

export default WhiteModalContent
