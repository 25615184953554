import React, { useCallback, useState } from 'react'
import {
  useForm,
  UseFormRegister,
  UseFormHandleSubmit,
  UseFormWatch,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { Kuchikomi } from '../../../model/Kuchikomi'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { useAuth } from '../../../hooks/use_auth'

type SearchFormProps = {
  keyword: string
  sdgsCategory: string
  corporateCategory: string
  industry: string
  region: string
}

type FormHandler = {
  register: UseFormRegister<SearchFormProps>
  watch: UseFormWatch<SearchFormProps>
  handleSubmit: UseFormHandleSubmit<SearchFormProps, undefined>
  submit: (data: SearchFormProps, cursor?: number) => Promise<void>
  setValue: UseFormSetValue<SearchFormProps>
  getValues: UseFormGetValues<SearchFormProps>
}

type KuchikomiHandler = {
  like: (kuchikomiId: string, point: number) => Promise<void>
  bookmark: (kuchikomiId: string, set: boolean) => Promise<void>
  reply: (kuchikomiId: string, message: string) => Promise<void>
  delete: (kuchikomiId: string) => Promise<void>
}

const useKuchikomiSearchForm = ({
  getLimit = 20,
}): [
  Array<Kuchikomi>,
  number,
  number,
  boolean,
  boolean,
  FormHandler,
  KuchikomiHandler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const { user } = useAuth()
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const { showLoading, hideLoading } = useLoading()
  const [isLoading, setIsLoading] = useState<boolean>(false) // ローディング中かどうか
  const [isSearched, setIsSearched] = useState<boolean>(false) // 検索済みかどうか

  const { register, handleSubmit, watch, setValue, getValues } =
    useForm<SearchFormProps>({
      defaultValues: {
        keyword: '',
        sdgsCategory: '',
        corporateCategory: '',
        industry: '',
        region: '',
      },
      mode: 'onSubmit',
    })
  const [kuchikomis, setKuchikomis] = useState<Array<Kuchikomi>>([])

  const submit = useCallback(
    async (data: SearchFormProps, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      setIsSearched(true)

      await apiClient.kuchikomis
        .kuchikomisList({
          cursor: cursor,
          limit: getLimit,
          keyword: data.keyword,
          sdgsCategory: data.sdgsCategory,
          enterpriseCategory: data.corporateCategory,
          industry: data.industry,
          region: data.region,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setKuchikomis(result.data)
          }
          const addressQueryStr = new URLSearchParams(data).toString()
          window.history.pushState(
            null,
            '',
            window.location.pathname.split('?')[0] + '?' + addressQueryStr
          )
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    },
    [totalSize, cursor, kuchikomis]
  )

  const like = useCallback(
    async (kuchikomiId: string, point: number) => {
      if (!user.isLogined()) {
        return
      }

      // 先に更新
      const nextKuhikomis = [...kuchikomis]
      const targetIdx = nextKuhikomis.findIndex(
        (item) => item.id === kuchikomiId
      )
      const beforeLiked = nextKuhikomis[targetIdx].liked
      nextKuhikomis[targetIdx].likeCount += point
      nextKuhikomis[targetIdx].liked = true
      setKuchikomis(nextKuhikomis)

      await apiClient.kuchikomis
        .likeUpdate(kuchikomiId, { point: point })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)

          // 元に戻す
          const nextKuhikomis = [...kuchikomis]
          if (!beforeLiked) {
            nextKuhikomis[targetIdx].liked = false
          }
          nextKuhikomis[targetIdx].likeCount -= point
          setKuchikomis(nextKuhikomis)

          return null
        })
    },
    [kuchikomis]
  )

  /**
   * ブックマークする
   * ローディング画面は出さず、非同期で処理する
   */
  const bookmark = useCallback(
    async (kuchikomiId: string, set: boolean) => {
      if (!user.isLogined()) {
        return
      }

      // 先に画面を更新
      const nextKuhikomis = [...kuchikomis]
      const targetIdx = nextKuhikomis.findIndex(
        (item) => item.id === kuchikomiId
      )
      nextKuhikomis[targetIdx].bookmarked = set
      setKuchikomis(nextKuhikomis)

      await apiClient.kuchikomis
        .bookmarkUpdate(kuchikomiId, { set: set })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
    },
    [kuchikomis]
  )

  const deleteKuchikomi = useCallback(
    async (kuchikomiId: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .deleteCreate(kuchikomiId)
        .then(() => {
          const nextKuhikomis = kuchikomis.filter(
            (item) => item.id !== kuchikomiId
          )
          setKuchikomis(nextKuhikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomis]
  )

  /**
   * 返信する
   */
  const reply = useCallback(
    async (kuchikomiId: string, message: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .replyUpdate(kuchikomiId, { message: message })
        .then((res) => {
          const createReply = res.data.reply

          const nextKuhikomis = [...kuchikomis]
          const targetIdx = nextKuhikomis.findIndex(
            (item) => item.id === kuchikomiId
          )
          nextKuhikomis[targetIdx].replies.push(createReply)
          setKuchikomis(nextKuhikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomis]
  )

  return [
    kuchikomis,
    totalSize,
    cursor,
    isLoading,
    isSearched,
    {
      register: register,
      watch: watch,
      handleSubmit: handleSubmit,
      submit: submit,
      setValue: setValue,
      getValues: getValues,
    },
    { like: like, bookmark: bookmark, reply: reply, delete: deleteKuchikomi },
  ]
}

export default useKuchikomiSearchForm
