import React, { FC, useRef, useState } from 'react'
import User from '../../../../model/User'
import {
  MemberRankIcon,
  getMemberRankColor,
} from '../../../../images/icons/member_rank_icon'
import { GivedPointIcon } from '../../../../images/icons/gived_point_icon'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'
import { Link, useNavigate } from 'react-router-dom'
import ThreeDotsButton from '../../../../components/buttons/three_dots_button'
import { useClickAway } from 'react-use'
import { useAuth } from '../../../../hooks/use_auth'
import { useLoading } from '../../../../providers/loading_provider'
import { useSnackBar } from '../../../../providers/snack_bar_provider'

type MyPageNavigationProps = {
  user: User
  isSp?: boolean
}

const MyPageNavigation: FC<MyPageNavigationProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const toggleBtnRef = useRef<HTMLDivElement>(null)
  const [menuShow, setMenuShow] = useState(false)

  useClickAway(menuRef, (e) => {
    const menuElem = menuRef.current
    const btnElem = toggleBtnRef.current
    if (!menuElem || !btnElem) return
    if (
      !menuElem?.contains(e.target as Node) &&
      !btnElem?.contains(e.target as Node) &&
      menuShow
    ) {
      setMenuShow(false)
    }
  })

  return (
    <div className="rounded-xl bg-white pb-4">
      <div className="p-4 md:border-b md:border-b-main-gray md:block flex justify-between">
        <div className="md:block flex justify-start">
          <div>
            <div className="flex justify-center">
              <div
                className={`${
                  props.isSp ? 'w-16 h-16' : 'w-32 h-32'
                }  rounded-full bg-center bg-cover`}
                style={{
                  backgroundImage: `url(${props.user.iconUrl})`,
                }}
              ></div>
            </div>
            <div>
              <p className="font-bold">{props.user.displayName}</p>
            </div>
          </div>
          <div className="flex flex-col justify-between md:px-0 px-4">
            <div>
              <p className="text-xs lg:break-words break-all">
                {props.user.catchCopy}
              </p>
            </div>
            <div className="pt-4">
              <div className="flex flex-col justify-center mb-2">
                <div className="flex justify-start items-center">
                  <MemberRankIcon rank={props.user.memberRank} />
                  <p
                    className="pl-1 text-sm"
                    style={{
                      color: getMemberRankColor(props.user.memberRank),
                    }}
                  >
                    {props.user.memberRank.value}
                  </p>
                </div>
              </div>
              <div className="flex justify-start mb-2">
                <div className="flex flex-col justify-center w-6">
                  <GivedPointIcon />
                </div>
                <p className="text-xs text-main-blue whitespace-nowrap">
                  ポイント贈呈数：
                  {props.user.givedPointCount
                    ? props.user.givedPointCount.toLocaleString()
                    : 0}
                </p>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-col justify-center w-6">
                  <HoldPointIcon />
                </div>
                <p className="text-xs text-main-blue whitespace-nowrap">
                  ポイント保有数：
                  {props.user.holdPointCount
                    ? props.user.holdPointCount.toLocaleString()
                    : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div
            className="md:hidden w-fit"
            onClick={() => setMenuShow(!menuShow)}
            ref={toggleBtnRef}
          >
            <ThreeDotsButton />
          </div>
          {menuShow && (
            <div
              ref={menuRef}
              className="w-[250px] bg-white absolute outline-1 top-0 right-8 shadow-md rounded-lg z-10"
            >
              <NavigationMenu />
            </div>
          )}
        </div>
      </div>
      <div className="hidden md:block">
        <NavigationMenu />
      </div>
    </div>
  )
}

export default MyPageNavigation

const NavigationMenu = () => {
  const { user, signOut } = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()
  const navigate = useNavigate()

  const logout = async () => {
    showLoading()

    // 1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 1000))

    signOut()

    hideLoading()
    navigate('/')
    showSnackBar(['ログアウトしました'], 'success')
  }

  return (
    <div className="p-4">
      <div className="pb-2">
        <p className="font-bold">プロフィール</p>
        <ul>
          <li className="pl-4 py-1">
            <Link to="/mypage/edit-profile">プロフィール編集</Link>
          </li>
        </ul>
      </div>
      <div className="pb-2">
        <p className="font-bold">クチコミのサス活</p>
        <ul>
          <li className="pl-4 py-1">
            <Link to="/mypage/kuchikomi">あなたの新着クチコミ</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/mypage/kuchikomi/ranking">
              あなたのクチコミランキング
            </Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/mypage/follow">フォローしたユーザー</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/mypage/enterprise-follow">フォローした企業・団体</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/mypage/follower">フォロワー</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/mypage/bookmark">ブックマークしたクチコミ</Link>
          </li>
        </ul>
      </div>
      {/* <div className="pb-2">
        <p className="font-bold">ポイント交換</p>
        <ul>
          <li className="pl-4 py-1">
            <Link to="#">ポイント交換</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="#">ポイント交換履歴</Link>
          </li>
        </ul>
      </div> */}
      {/* <div className="pb-2">
        <p className="font-bold">アカウント</p>
        <ul>
          <li className="pl-4 py-1">
            <Link to="/mypage/notification">通知</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="#">プライバシーと安全</Link>
          </li>
        </ul>
      </div> */}
      <div className="pb-2">
        <p className="font-bold">その他</p>
        <ul>
          <li className="pl-4 py-1">
            <Link to="/policy/user">利用規約</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/policy/privacy">プライバシーポリシー</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/guide/community">コミュニティーガイドライン</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/guide/notice">留意事項</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/policy/law">特定商取引法に基づく表示</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/faq">よくある質問</Link>
          </li>
          <li className="pl-4 py-1">
            <Link to="/contact">問い合わせ</Link>
          </li>
          <li className="pl-4 py-1 cursor-pointer" onClick={logout}>
            ログアウト
          </li>
        </ul>
      </div>
    </div>
  )
}
