import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useBookmarkKuchikomis from './hooks/use_bookmark_kuchikomis'
import useCodes from '../../hooks/use_codes'
import Select from '../../components/select/select'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'

const MyPageBookmark = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [codes] = useCodes()
  const [user, userLoading] = useUser()
  const [initialLoad, setInitialLoad] = useState(false)
  const [currentSDGsVal, setCurrentSDGsVal] = useState<string | null>(null)
  const [
    kuchikomis,
    size,
    currentCursor,
    kuchikomiLoading,
    currentSdgsCategory,
    kuchikomiHandler,
  ] = useBookmarkKuchikomis({ sdgsCategory: null, getLimit: PAGE_SIZE })
  useEffect(() => {
    if (user) {
      kuchikomiHandler.load(null).then(() => setInitialLoad(true))
    }
  }, [user])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const filterGroupButtons = (
    <div className="flex justify-start flex-wrap">
      <div className="rounded-lg mr-3 bg-main-blue text-white px-4 w-fit flex items-center">
        新着
      </div>
      <div className="pr-2">
        <Select
          id="sdgs"
          placeholder="SDGsカテゴリー"
          currentValue={currentSdgsCategory ?? ''}
          currentGroup="sdgs"
          brankLabel="全て"
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setCurrentSDGsVal(e.currentTarget.value)
            kuchikomiHandler.load(e.currentTarget.value)
          }}
          items={codes.sdgsCategories.map((item) => {
            return {
              value: item.code,
              label: item.value,
            }
          })}
        />
      </div>
    </div>
  )

  const kuchikomiElems = (
    <>
      <div className="bg-white rounded-2xl overflow-hidden">
        {kuchikomis.map((item, index) => {
          return (
            <KuchikomiCard
              key={`kuchikomi-${String(index)}`}
              kuchikomi={item}
              borderBottom={index !== kuchikomis.length - 1}
              isSp={isSp}
              sendLike={kuchikomiHandler.like}
              onClickBookmark={kuchikomiHandler.bookmark}
              sendReply={kuchikomiHandler.reply}
              deleteKuchikomi={kuchikomiHandler.delete}
            />
          )
        })}
      </div>

      {kuchikomis.length === 0 && initialLoad && (
        <div className="text-center ">ブックマークしたクチコミはありません</div>
      )}
    </>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(currentSDGsVal, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(currentSDGsVal, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          kuchikomiHandler.load(currentSDGsVal, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="ブックマークしたクチコミ"
      user={user}
      isSp={isSp}
      subHead={
        kuchikomis.length > 0
          ? `全件${String(size)}中 ${currentCursor + 1}~${
              currentCursor + kuchikomis.length
            }件`
          : ''
      }
    >
      <div>
        <div className="pb-8" ref={IndexSectionRef}>
          {filterGroupButtons}
        </div>
        <div className="pb-4">
          {kuchikomiLoading ? (
            <LoadingSpinner className="mx-auto mb-16" />
          ) : (
            kuchikomiElems
          )}
        </div>
        <div className="pb-4">{paginationCursor}</div>
      </div>
    </MyPageTemplate>
  )

  return content
}

export default MyPageBookmark
