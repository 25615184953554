import React from 'react'
import CommonTemplate from '../../components/templates/common_template'
import Head2 from '../../components/heads/head2'
import useInformationList from './hook/use_information_list'
import LoadingSpinner from '../../components/loading/loading_spinner'
import SimpleInformation from '../../components/card/simple_information'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const InformationListPage = () => {
  const PAGE_SIZE = 20
  const isSp = useSpBreakPoint()

  const [informations, size, currentCursor, isLoading, informationsHandler] =
    useInformationList({ getLimit: PAGE_SIZE })

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const informationList = (
    <div>
      <div className="mb-4" ref={IndexSectionRef}>
        <Head2
          title="お知らせ一覧"
          subTitle={
            informations.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + informations.length
                }件`
              : ''
          }
        />
      </div>
      {isLoading ? (
        <LoadingSpinner className="mx-auto my-12" />
      ) : (
        <>
          <div className="flex justify-start flex-wrap">
            <SimpleInformation informations={informations} />
          </div>
        </>
      )}
    </div>
  )

  const paginationCursor =
    informations.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          informationsHandler.load(currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          informationsHandler.load(currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          informationsHandler.load((pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <CommonTemplate isSp={isSp}>
      <div className="w-full pb-8">{informationList}</div>
      <div className="pb-8">{paginationCursor}</div>
    </CommonTemplate>
  )

  return content
}

export default InformationListPage
