import React, { FC } from 'react'
import BackgroundImage from '../../../../images/mypage/bg-l.png'
import { EvalurationStarIcon } from '../../../../images/icons/star_icon'
import { stringConversion } from '../../../../utils/numeric_util'

type KuchikomiSasukatsuCardProps = {
  kuchikomiCount: number
  kuchikomiEnterpriseCount: number
  pace: number
  evaluationAverage: number | null
  isSp?: boolean
}

const KuchikomiSasukatsuCard: FC<KuchikomiSasukatsuCardProps> = (props) => {
  return (
    <div
      className="rounded-xl bg-center bg-cover p-4"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <p className="text-lg font-bold text-white mb-2 sm:mb-0 ">
        クチコミ・サス活
      </p>
      <div className="flex justify-start flex-wrap pb-4">
        <div className="mb-2 sm:mb-0 pr-4">
          <p className="text-sm text-white">クチコミ数</p>
          <p className="text-5xl sm:text-6xl font-bold text-white">
            {stringConversion(props.kuchikomiCount)}
            <span className="text-white text-lg pl-2">回</span>
          </p>
        </div>
        <div className="mb-2 sm:mb-0 pr-4">
          <p className="text-sm text-white">クチコミした会社</p>
          <p className="text-5xl sm:text-6xl font-bold text-white">
            {stringConversion(props.kuchikomiEnterpriseCount)}
            <span className="text-white text-lg pl-2">社</span>
          </p>
        </div>
        <div className="mb-2 sm:mb-0 pr-4">
          <p className="text-sm text-white">ペース</p>
          <p className="text-5xl sm:text-6xl font-bold text-white">
            {props.pace}
            <span className="text-white text-lg pl-2">回/週</span>
          </p>
        </div>
      </div>
      <div>
        <p className="text-sm text-white">あなたがつけている平均評価</p>
        <div className="flex justify-start">
          <p
            className={`text-5xl sm:text-6xl text-white font-bold ${
              props.isSp ? 'pr-1' : 'pr-4'
            }`}
          >
            {props.evaluationAverage ? props.evaluationAverage.toFixed(2) : '-'}
          </p>
          <div className="flex flex-col justify-center">
            <EvalurationStarIcon
              evaluation={props.evaluationAverage}
              size="xl"
              color="white"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KuchikomiSasukatsuCard
