import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const NoticePage: FC = () => {
  const isSp = useSpBreakPoint()

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div className="bg-white rounded-xl w-full py-12 px-4 sm:px-16">
        <div className="mb-8">
          <p className="text-4xl text-main-blue font-bold">留意事項</p>
        </div>
        <div className="mb-12">
          <p>
            SDGs クチコミ Lodgeのβ版では、以下の機能が段階的に実装されています。
          </p>
          <p>
            これらの機能は、ユーザーからの貴重なフィードバックを反映させつつ、ユーザー体験を継続的に向上させ、「誰一人取り残さない」というSDGsの基本理念に基づいて最適化を進めるものです。
          </p>
          <p>ご理解いただき、ご使用時には次の点にご留意してください。</p>
        </div>
        {/* 評価モデル */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">評価モデル</h3>
          <div className="pl-4 mb-4">
            <p>
              現在の評価モデルは試験導入段階にあります。将来的には、より詳細で具体的なフィードバックを得られる評価モデルに進化させる予定です。
            </p>
          </div>
        </div>
        {/* 評価点数アルゴリズム */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">評価点数アルゴリズム</h3>
          <div className="pl-4 mb-4">
            <p>
              現在、評価点数アルゴリズムは初期段階にあり、より公平で透明性の高いシステムを目指していきます。
            </p>
          </div>
        </div>
        {/* いいねポイント */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">いいねポイント</h3>
          <div className="pl-4 mb-4">
            <p>
              SDGs クチコミ
              Lodgeで使われるポイントシステムは、実験的に運用しておりす。ユーザーからの貴重なフィードバックを基に、継続的に改良を加えていきます。
            </p>
          </div>
        </div>
        {/* 会員ランクの特典 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">会員ランクの特典</h3>
          <div className="pl-4 mb-4">
            <p>
              ランクが上がることの特典について、随時アップデートしていきます。
            </p>
            <p>何卒ご理解のほど、よろしくお願いします。</p>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )
}

export default NoticePage
