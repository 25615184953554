import { useCallback, useState } from 'react'
import {
  useForm,
  UseFormRegister,
  UseFormHandleSubmit,
  UseFormWatch,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Company from '../../../model/Enterprise'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type SearchFormProps = {
  keyword: string
  sdgsCategory: string
  enterpriseCategory: string
  industry: string
  region: string
}

type FormHandler = {
  register: UseFormRegister<SearchFormProps>
  watch: UseFormWatch<SearchFormProps>
  handleSubmit: UseFormHandleSubmit<SearchFormProps, undefined>
  submit: (data: SearchFormProps, cursor?: number) => Promise<void>
  setValue: UseFormSetValue<SearchFormProps>
  getValues: UseFormGetValues<SearchFormProps>
}

const useEnterpriseSearchForm = ({
  getLimit = 20,
}): [Array<Company>, number, number, boolean, boolean, FormHandler] => {
  // TODO: ページネーションを動かす
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false) // ローディング中かどうか
  const [isSearched, setIsSearched] = useState<boolean>(false) // 検索済みかどうか
  const { register, handleSubmit, watch, setValue, getValues } =
    useForm<SearchFormProps>({
      defaultValues: {
        keyword: '',
        sdgsCategory: '',
        enterpriseCategory: '',
        industry: '',
        region: '',
      },
      mode: 'onSubmit',
    })
  const [companies, setCompnaies] = useState<Array<Company>>([])

  const submit = useCallback(
    async (data: SearchFormProps, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      setIsSearched(true)

      await apiClient.enterprises
        .enterprisesList({
          cursor: cursor,
          limit: getLimit,
          keyword: data.keyword,
          sdgsCategory: data.sdgsCategory,
          enterpriseCategory: data.enterpriseCategory,
          industry: data.industry,
          region: data.region,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setCompnaies(result.data)
          }
          const addressQueryStr = new URLSearchParams(data).toString()
          window.history.pushState(
            null,
            '',
            window.location.pathname.split('?')[0] + '?' + addressQueryStr
          )
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    },
    [totalSize, cursor, companies]
  )

  return [
    companies,
    totalSize,
    cursor,
    isLoading,
    isSearched,
    {
      register: register,
      watch: watch,
      handleSubmit: handleSubmit,
      submit: submit,
      setValue: setValue,
      getValues: getValues,
    },
  ]
}

export default useEnterpriseSearchForm
