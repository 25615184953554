import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { MainTextInput } from '../../components/input/main_text_input'
import { CheckMessageInput } from '../../components/input/check_message_input'
import { MainTextarea } from '../../components/input/main_textarea'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

type ContactFormProps = {
  enterpriseName: string | null
  departmentName: string | null
  name: string
  email: string
  tel: string | null
  subject: string
  message: string
}

const ContactConfirmPage: FC = () => {
  const location = useLocation()
  const state = location.state as ContactFormProps
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()
  const apiClient = getAppClient()
  const navigate = useNavigate()
  const isSp = useSpBreakPoint()

  const post = async () => {
    showLoading()
    await apiClient.contacts
      .sendCreate(state)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        showSnackBar(['お問い合わせを送信しました。'], 'success')
        navigate('/contact/complete')
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <CommonTemplate isSp={isSp}>
      <p className="font-bold text-2xl mb-6">お問い合わせ内容確認</p>
      <div className="bg-white relative rounded-xl p-4 sm:p-10">
        <p className="mb-8">
          以下の内容でお問い合わせを送信します。内容に問題なければ確定して送信するボタンを押してください。
        </p>
        <div className="max-w-xl">
          <MainTextInput
            required={false}
            label="企業名・団体名"
            description=""
            value={state.enterpriseName ?? ''}
            readOnly
          />
          <MainTextInput
            required={false}
            label="部署名"
            description=""
            value={state.departmentName ?? ''}
            readOnly
          />
          <MainTextInput
            required
            label="名前"
            description=""
            value={state.name}
            readOnly
          />
          <MainTextInput
            required
            label="メールアドレス"
            description=""
            value={state.email}
            readOnly
          />
          {state.tel && (
            <MainTextInput
              required={false}
              label="電話番号"
              description=""
              value={state.tel}
              readOnly
            />
          )}
          <MainTextInput
            required
            label="件名"
            description=""
            value={state.subject}
            readOnly
          />
          <MainTextarea
            required
            label="お問い合わせ本文"
            description=""
            value={state.message ?? ''}
            readOnly
          />
        </div>
        <p className="font-bold text-lg mt-6">プライバシーポリシーについて</p>
        <CheckMessageInput
          label="上記のプライバシーポリシーについて同意する"
          readOnly={true}
          check={true}
        />
        <div className="flex max-sm:flex-col-reverse items-center justify-center pt-8">
          <FlatButton
            className="!mx-0 !bg-main-lightGray"
            onClick={() => navigate('/contact', { state })}
          >
            内容を修正する
          </FlatButton>
          <div className="sm:ml-8 sm:mb-0 mb-6 items-center ">
            <FlatButton className="!w-[200px]" onClick={post}>
              確定して送信する
            </FlatButton>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )
}

export default ContactConfirmPage
