import { useState } from 'react'
import {
  EnterpriseCode,
  IndustryCode,
  OccupationCode,
  RankingEnterpriseStatCode,
  RankingKuchikomiStatCode,
  RankingPeriodCode,
  RankingUserStatCode,
  SDGsCategory,
  BookmarkStatCode,
  GenderCode,
  BusinessCode,
} from '../model/Code'
import {
  ENTERPRISECODES,
  INDUSTRYCODE,
  OCCUPATIONCODE,
  PRFECTURES,
  RANKING_ENTERPRISE_STAT_CODE,
  RANKING_KUCHIKOMI_STAT_CODE,
  RANKING_PERIOD_CODE,
  RANKING_USER_STAT_CODE,
  SDGSCATEGORY,
  BOOKMARK_STAT_CODE,
  GENDERCODE,
  BUSINESS_PARENT_CODE,
  BUSINESS_CHILD_CODE,
  BUSINESS_CODE_RELATION,
} from '../constants/codes'

type CodeHandler = {
  filterRankingNoneCategory: (
    type: 'enterprise' | 'user' | 'kuchikomi',
    category: {
      value: string
      label: string
      group: string
    }
  ) => boolean
}

// TODO: 本当は全てサーバーから取得するかEnumで管理したい
type Codes = {
  rankingPeriodCodes: Array<RankingPeriodCode>
  rankingKuchikomiStatCodes: Array<RankingKuchikomiStatCode>
  rankingEnterpriseStatCodes: Array<RankingEnterpriseStatCode>
  rankingUserStatCodes: Array<RankingUserStatCode>
  bookmarkStatCodes: Array<BookmarkStatCode>
  enterpriseCodes: Array<EnterpriseCode>
  businessParentCodes: Array<BusinessCode>
  businessChildCodes: Array<BusinessCode>
  businessCodeRelations: { [key: string]: string[] }
  industryCodes: Array<IndustryCode>
  occupationCodes: Array<OccupationCode>
  sdgsCategories: Array<SDGsCategory>
  prefectures: Array<string>
  genderCodes: Array<GenderCode>
}

const useCodes = (): [Codes, CodeHandler] => {
  // 期間の選択肢
  const [rankingPeriodCodes] =
    useState<Array<RankingPeriodCode>>(RANKING_PERIOD_CODE)
  // 検索対象の値
  const [rankingKuchikomiStatCodes] = useState<Array<RankingKuchikomiStatCode>>(
    RANKING_KUCHIKOMI_STAT_CODE
  )
  const [rankingEnterpriseStatCodes] = useState<
    Array<RankingEnterpriseStatCode>
  >(RANKING_ENTERPRISE_STAT_CODE)
  const [rankingUserStatCodes] = useState<Array<RankingUserStatCode>>(
    RANKING_USER_STAT_CODE
  )
  const [bookmarkStatCodes] =
    useState<Array<BookmarkStatCode>>(BOOKMARK_STAT_CODE)
  // 検索キー
  const [occupationCodes] = useState<Array<OccupationCode>>(OCCUPATIONCODE)

  const [enterpriseCodes] = useState<Array<EnterpriseCode>>(ENTERPRISECODES)
  const [businessParentCodes] =
    useState<Array<EnterpriseCode>>(BUSINESS_PARENT_CODE)
  const [businessChildCodes] =
    useState<Array<EnterpriseCode>>(BUSINESS_CHILD_CODE)
  const [businessCodeRelations] = useState<{ [key: string]: string[] }>(
    BUSINESS_CODE_RELATION
  )
  const [industryCodes] = useState<Array<IndustryCode>>(INDUSTRYCODE)
  const [sdgsCategories] = useState<Array<SDGsCategory>>(SDGSCATEGORY)
  const [prefectures] = useState<Array<string>>(PRFECTURES)
  const [genderCodes] = useState<Array<GenderCode>>(GENDERCODE)

  // 企業、ユーザー、クチコミごとにgroup: noneのフィルターカテゴリは出し分ける必要があるため
  const filterRankingNoneCategory = (
    type: 'enterprise' | 'user' | 'kuchikomi',
    category: {
      value: string
      label: string
      group: string
    }
  ) => {
    switch (type) {
      case 'enterprise':
        return (
          category.group === 'none' &&
          category.value !== 'kuchikomi_enterprise' &&
          category.value !== 'given_point' &&
          category.value !== 'like' &&
          category.value !== 'comment'
        )
      case 'user':
        return (
          category.group === 'none' &&
          category.value !== 'like' &&
          category.value !== 'comment'
        )
      case 'kuchikomi':
        return (
          category.group === 'none' &&
          category.value !== 'kuchikomi' &&
          category.value !== 'follower' &&
          category.value !== 'kuchikomi_enterprise' &&
          category.value !== 'given_point'
        )
    }
  }

  return [
    {
      rankingPeriodCodes,
      rankingEnterpriseStatCodes,
      rankingKuchikomiStatCodes,
      rankingUserStatCodes,
      bookmarkStatCodes,
      enterpriseCodes,
      businessParentCodes,
      businessChildCodes,
      businessCodeRelations,
      industryCodes,
      occupationCodes,
      sdgsCategories,
      prefectures,
      genderCodes,
    },
    {
      filterRankingNoneCategory: filterRankingNoneCategory,
    },
  ]
}

export default useCodes
