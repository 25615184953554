import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import User from '../../../model/User'
import Enterprise from '../../../model/Enterprise'
import useFollow from '../../../hooks/use_follow'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
  follow: (
    id: string,
    set: boolean,
    type: 'enterprise' | 'user'
  ) => Promise<void>
}

const useUserDetail = (): [
  User | null,
  Array<User> | null,
  Array<User> | null,
  Array<Enterprise> | null,
  boolean,
  Handler,
] => {
  const GETLIMIT = 100
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [getFollowCursor, setGetFollowCursor] = useState(0)
  const [getFollowerCursor, setGetFollowerCursor] = useState(0)
  const [getEnterpriseCursor, setGetEnterpriseCursor] = useState(0)
  const [userDetail, setUserDetail] = useState<User | null>(null)
  const [follows, setFollows] = useState<Array<User> | null>(null)
  const [followers, setFollowers] = useState<Array<User> | null>(null)
  const [enterprises, setEnterprises] = useState<Array<Enterprise> | null>(null)
  const [followHandler] = useFollow()
  const { showSnackBar } = useSnackBar()

  const load = useCallback(async (id: string) => {
    try {
      const [userDetailRes, followsRes, followersRes, enterprisesRes] =
        await Promise.all([
          apiClient.users.usersDetail(id),
          apiClient.users.followsDetail(id, {
            cursor: getFollowCursor,
            limit: GETLIMIT,
          }),
          apiClient.users.followersDetail(id, {
            cursor: getFollowerCursor,
            limit: GETLIMIT,
          }),
          apiClient.users.enterprisesDetail(id, {
            cursor: getFollowCursor,
            limit: GETLIMIT,
          }),
        ])

      // ユーザー詳細
      setUserDetail(userDetailRes.data)

      // フォロー
      const followsData = followsRes.data
      setFollows(followsData.data)
      setGetFollowCursor(getFollowCursor + GETLIMIT)

      // フォロワー
      const followersData = followersRes.data
      setFollowers(followersData.data)
      setGetFollowerCursor(getFollowerCursor + GETLIMIT)

      // 企業
      setEnterprises(enterprisesRes.data)
      setGetEnterpriseCursor(getEnterpriseCursor + GETLIMIT)
    } catch (e) {
      const error = e as ApiErrorResponse
      handleError(error, showSnackBar)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const follow = useCallback(
    async (id: string, set: boolean, type: 'enterprise' | 'user') => {
      // 先に更新
      if (type === 'enterprise') {
        if (enterprises === null) {
          return
        }
        const targetIndex = enterprises.findIndex((item) => item.id === id)
        if (targetIndex >= 0) {
          const nextEnterprises = [...enterprises]
          nextEnterprises[targetIndex].followed = set
          setEnterprises(nextEnterprises)
        }
      } else {
        if (userDetail && userDetail.id === id) {
          const nextUserDetail = { ...userDetail }
          nextUserDetail.yourFollower = set
          setUserDetail(nextUserDetail)
        } else {
          let targetIndex = -1
          if (follows) {
            targetIndex = follows.findIndex((item) => item.id === id)
            if (targetIndex >= 0) {
              const nextFollows = [...follows]
              nextFollows[targetIndex].yourFollower = set
              setFollows(nextFollows)
              return
            }
          }

          if (followers) {
            targetIndex = followers.findIndex((item) => item.id === id)
            if (targetIndex >= 0) {
              const nextFollowers = [...followers]
              nextFollowers[targetIndex].yourFollower = set
              setFollowers(nextFollowers)
            }
          }
        }
      }

      // API連携
      await followHandler.follow(id, set, type).catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
    },
    [userDetail]
  )

  return [
    userDetail,
    follows,
    followers,
    enterprises,
    isLoading,
    { load: load, follow: follow },
  ]
}

export default useUserDetail
