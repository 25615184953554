import React, { FC } from 'react'

type IconProps = {
  color?: 'gray' | 'white'
}

export const ArrowIcon: FC<IconProps> = (props) => {
  let color = '#3b3b3b'
  switch (props.color) {
    case 'white':
      color = '#FFFFFF'
      break
    case 'gray':
      color = '#3b3b3b'
      break
    default:
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.99"
        height="9.9"
        viewBox="0 0 16.99 9.9"
      >
        <path
          id="パス_60"
          data-name="パス 60"
          d="M0,7.486,7,0l7.159,7.486"
          transform="translate(15.577 8.9) rotate(180)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </div>
  )
}
