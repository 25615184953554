import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'
import useKuchikomis from './hooks/use_kuchikomis'

const MyPageKuchikomi = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [userId, setUserId] = useState('')
  const [kuchikomis, size, currentCursor, loadingKuchikomis, kuchikomiHandler] =
    useKuchikomis({ getLimit: PAGE_SIZE })

  useEffect(() => {
    if (user) {
      setUserId(user.id)
      kuchikomiHandler.load(user.id)
    }
  }, [user])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const kuchikomiCount =
    kuchikomis.length > 0
      ? `全件${String(size)}中 ${currentCursor + 1}~${
          currentCursor + kuchikomis.length
        }件`
      : ''

  const kuchikomiElems = (
    <>
      <div className="bg-white rounded-xl overflow-hidden max-w-[720px]">
        {kuchikomis.map((kuchikomi, index, ary) => {
          return (
            <div key={`ranking-${String(index)}`}>
              <KuchikomiCard
                kuchikomi={kuchikomi}
                sendLike={kuchikomiHandler.like}
                sendReply={kuchikomiHandler.reply}
                onClickBookmark={kuchikomiHandler.bookmark}
                borderBottom={index !== ary.length - 1}
                deleteKuchikomi={kuchikomiHandler.delete}
              />
            </div>
          )
        })}
      </div>
      {kuchikomis.length === 0 && (
        <div className="text-center py-4 text-gray-400">
          クチコミがありません
        </div>
      )}
    </>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        numRange={3}
        next={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      head="あなたの新着クチコミ"
      user={user}
      userLoading={userLoading}
      isSp={isSp}
      subHead={kuchikomiCount}
    >
      <div ref={IndexSectionRef} />

      {loadingKuchikomis || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{kuchikomiElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageKuchikomi
