import React, { FC } from 'react'
import { FluctuationType } from '../../types/enterprise'

type RankFluctuationTextProps = {
  type: FluctuationType
}

export const RankFluctuationText: FC<RankFluctuationTextProps> = (props) => {
  let color = ''
  let text = ''
  switch (props.type) {
    case 'up':
      color = 'text-main-red'
      text = 'up!'
      break
    case 'down':
      color = 'text-main-blue'
      text = 'down.'
      break
    case 'stay':
      color = 'text-main-lightGray'
      text = 'still...'
      break
    case 'new':
      color = ''
      text = ''
      break
  }

  return <p className={`text-xs ${color}`}>{text}</p>
}
