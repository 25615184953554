import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { EnterpriseRanking } from '../../../model/Ranking'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const DEFAULT_LIMIT = 100

type UseEnterpriseRankingProps = {
  getLimit?: number
}

type Handler = {
  load: () => Promise<void>
}

const useEnterpriseRanking = (
  props: UseEnterpriseRankingProps
): [Array<EnterpriseRanking>, boolean, Handler] => {
  const limit = props.getLimit || DEFAULT_LIMIT
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [ranking, setRanking] = useState<Array<EnterpriseRanking>>([])

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.ranking
      .enterpriseList({
        cursor: apiCursor,
        limit: limit,
      })
      .then((res) => {
        const data = res.data.data
        const nextRanking = ranking.concat(data)
        setRanking(nextRanking)
        setApiCursor(apiCursor + limit)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, ranking])

  useEffect(() => {
    load()
  }, [])

  return [ranking, isLoading, { load: load }]
}

export default useEnterpriseRanking
