import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import UserPolicyContent from './content/user_policy_content'

const PolicyUserPage: FC = () => {
  const isSp = useSpBreakPoint()

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div className="bg-white rounded-xl w-full py-12 px-4 sm:px-16">
        <div className="mb-8">
          <p className="text-4xl text-main-blue font-bold">利用規約</p>
        </div>
        <UserPolicyContent />
      </div>
    </CommonTemplate>
  )
}

export default PolicyUserPage
