import React, { FC, ReactNode } from 'react'
import User from '../../model/User'
import {
  MemberRankIcon,
  getMemberRankColor,
} from '../../images/icons/member_rank_icon'
import { FacebookIcon } from '../../images/icons/facebook_icon'
import { WebIcon } from '../../images/icons/web_icon'
import { XIcon } from '../../images/icons/x_icon'
import { InstagramIcon } from '../../images/icons/instagram_icon'
import { TikTokIcon } from '../../images/icons/tiktok_icon'
import { GivedPointIcon } from '../../images/icons/gived_point_icon'
import { HoldPointIcon } from '../../images/icons/hold_point_icon'
import FlatButton from '../buttons/flat_button'
import { CrownIcon } from '../../images/icons/crown_icon'
import { getKiloString } from '../../utils/numeric_util'
import { useSdgsImage } from '../../hooks/use_sdgs_image'
import { useModal } from 'react-hooks-use-modal'
import { useAuth } from '../../hooks/use_auth'
import WhiteModalContent from '../modal/white_modal_content'

type UserDetailCardProps = {
  user: User
  followModal: ReactNode
  onFollow: (
    id: string,
    set: boolean,
    type: 'enterprise' | 'user'
  ) => Promise<void>
  setModalTab: (type: 'follow' | 'follower' | 'enterprise') => void
}

const UserDetailCard: FC<UserDetailCardProps> = (props) => {
  const [getSdgsImage] = useSdgsImage()
  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const { user } = useAuth()

  const PointElement = (
    <>
      <div className="flex justify-start mb-1">
        <div className="flex flex-col justify-center w-6">
          <GivedPointIcon />
        </div>
        <p className="text-xs text-main-blue whitespace-nowrap">
          ポイント贈呈数：
          {props.user.givedPointCount
            ? props.user.givedPointCount.toLocaleString()
            : 0}
        </p>
      </div>
      <div className="flex justify-start">
        <div className="flex flex-col justify-center w-6">
          <HoldPointIcon />
        </div>
        <p className="text-xs text-main-blue whitespace-nowrap">
          ポイント保有数：
          {props.user.holdPointCount
            ? props.user.holdPointCount.toLocaleString()
            : 0}
        </p>
      </div>
    </>
  )

  return (
    <>
      <Modal>
        <WhiteModalContent>
          <div className="pb-4">{props.followModal}</div>
          <div className="pb-4">
            <FlatButton bgColor="lightGray" onClick={close}>
              <p className="text-white">閉じる</p>
            </FlatButton>
          </div>
        </WhiteModalContent>
      </Modal>
      <div className="flex justify-between flex-wrap w-full bg-white rounded-xl px-4 py-4 relative">
        {/* フォローボタン */}
        <div className="absolute top-3 right-4">
          {user.isLogined() && user.isKuchikomiUser() && (
            <FlatButton
              bgColor={props.user.yourFollower ? 'lightGray' : 'blue'}
              onClick={() =>
                props.onFollow(props.user.id, !props.user.yourFollower, 'user')
              }
              className="min-w-[90px] sm:min-w-[120px]"
            >
              <p className="text-sm px-2 leading-4">
                {props.user.yourFollower ? 'フォロー中' : 'フォローする'}
              </p>
            </FlatButton>
          )}
        </div>
        {/* ユーザー情報 */}
        <div className="lg:w-[160px] w-fit relative mb-4 sm:mb-0">
          <div className="flex sm:justify-center">
            <div
              className="w-28 h-28 sm:w-32 sm:h-32 rounded-full bg-center bg-cover relative"
              style={{
                backgroundImage: `url(${props.user.iconUrl})`,
              }}
            >
              <div className="sm:hidden absolute left-[calc(100%+1rem)] bottom-3">
                {PointElement}
              </div>
            </div>
          </div>
          {(props.user.homepageUrl ||
            props.user.facebookUrl ||
            props.user.twitterUrl ||
            props.user.instagramUrl ||
            props.user.tiktokUrl) && (
            <div className="flex justify-center pt-4">
              {props.user.homepageUrl && (
                <a
                  href={props.user.homepageUrl}
                  target="blank"
                  className="[&_path]:fill-main-black px-1"
                >
                  <WebIcon />
                </a>
              )}
              {props.user.facebookUrl && (
                <a
                  href={props.user.facebookUrl}
                  target="blank"
                  className="[&_path]:fill-main-black px-1"
                >
                  <FacebookIcon />
                </a>
              )}
              {props.user.twitterUrl && (
                <a
                  href={props.user.twitterUrl}
                  target="blank"
                  className="[&_path]:fill-main-black px-1"
                >
                  <XIcon />
                </a>
              )}
              {props.user.instagramUrl && (
                <a
                  href={props.user.instagramUrl}
                  target="blank"
                  className="[&_path]:fill-main-black px-1"
                >
                  <InstagramIcon />
                </a>
              )}
              {props.user.tiktokUrl && (
                <a
                  href={props.user.tiktokUrl}
                  target="blank"
                  className="[&_path]:fill-main-black px-1"
                >
                  <TikTokIcon />
                </a>
              )}
            </div>
          )}
          <div className="hidden sm:block lg:pt-4 lg:pb-0 pb-4 w-fit mx-auto pt-4">
            {PointElement}
          </div>
        </div>
        <div className="lg:w-[calc(100%-160px)] w-fit lg:pl-4">
          <div className="flex justify-start mb-2">
            <div className="flex flex-col justify-center">
              <p className="text-xl font-bold">{props.user.displayName}</p>
            </div>
            <div className="flex flex-col justify-center pl-2">
              <div className="flex justify-start items-center">
                <MemberRankIcon rank={props.user.memberRank} />
                <p
                  className="pl-1 text-sm"
                  style={{
                    color: getMemberRankColor(props.user.memberRank),
                  }}
                >
                  {props.user.memberRank.value}
                </p>
              </div>
            </div>
          </div>
          <div className="pb-2">
            <p className="text-sm text-main-darkGray break-all">
              {props.user.catchCopy}
            </p>
          </div>
          <div className="flex flex-wrap justify-start sm:mb-1 mb-4">
            <div className="flex justify-start pr-4 flex-wrap mb-1 sm:mb-0">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  週間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.weeklyRank ?? '-'}位
              </p>
            </div>
            <div className="flex justify-start pr-4 flex-wrap mb-1 sm:mb-0">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  年間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.yearlyRank ?? '-'}位
              </p>
            </div>
            <div className="flex justify-start pr-4 flex-wrap mb-1 sm:mb-0">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  全期間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.wholePeriodRank ?? '-'}位
              </p>
            </div>
          </div>
          <div className="flex justify-start flex-wrap mb-4">
            <div
              className="md:flex md:justify-start pr-4 cursor-pointer"
              onClick={() => {
                props.setModalTab('follow')
                open()
              }}
            >
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.followCount
                  ? getKiloString(props.user.followCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end">
                <p className="text-xs text-main-darkGray pb-[4px] md:pl-[2px]">
                  フォロー
                </p>
              </div>
            </div>
            <div
              className="md:flex md:justify-start pr-4 cursor-pointer"
              onClick={() => {
                props.setModalTab('follower')
                open()
              }}
            >
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.followerCount
                  ? getKiloString(props.user.followerCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end">
                <p className="text-xs text-main-darkGray pb-[4px] md:pl-[2px]">
                  フォロワー
                </p>
              </div>
            </div>
            <div className="md:flex md:justify-start pr-4 cursor-pointer">
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.kuchikomiCount
                  ? getKiloString(props.user.kuchikomiCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end">
                <p className="text-xs text-main-darkGray pb-[4px] md:pl-[2px]">
                  クチコミ数
                </p>
              </div>
            </div>
            <div
              className="md:flex md:justify-start pr-4 cursor-pointer"
              onClick={() => {
                props.setModalTab('enterprise')
                open()
              }}
            >
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.kuchikomiEnterpriseCount
                  ? getKiloString(props.user.kuchikomiEnterpriseCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end">
                <p className="text-xs text-main-darkGray pb-[4px] md:pl-[2px]">
                  クチコミした企業・団体
                </p>
              </div>
            </div>
          </div>
          <div className="pb-2">
            <p>{props.user.profile}</p>
          </div>
          <div className="pb-2">
            <p className="font-bold">興味があるSDGs17の目標</p>
            <div className="flex justify-start flex-wrap">
              {props.user.interestingSDGs.map((item, index) => {
                return (
                  <div
                    key={`interesting-sdgs-${String(index)}`}
                    className="flex justify-start items-center py-2 pr-2"
                  >
                    <img src={getSdgsImage(item.code)} className="w-6 h-6" />
                    <p className="text-main-lightGray pl-2">{item.value}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetailCard
