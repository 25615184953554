import React, { FC } from 'react'
import {
  MemberRankIcon,
  getMemberRankColor,
} from '../../../../images/icons/member_rank_icon'
import { Link } from 'react-router-dom'
import BackgroundImage from '../../../../images/mypage/bg-m.png'
import { MemberRank } from '../../../../model/Code'

type MemberRankCardProps = {
  memberRank: MemberRank
}

const MemberRankCard: FC<MemberRankCardProps> = (props) => {
  return (
    <div
      className="rounded-xl bg-center bg-cover p-4 h-[250px]"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <p className="text-lg font-bold text-white">会員ランク</p>
      <div className="flex justify-center py-2">
        <MemberRankIcon rank={props.memberRank} size="xl" />
      </div>
      <div className="text-center">
        <p
          style={{
            color: getMemberRankColor(props.memberRank),
          }}
        >
          {props.memberRank.value}
        </p>
      </div>
      <div className="text-center">
        <Link to="/mypage/about-member-rank">
          <p className="text-xs text-white w-fit border-b border-b-white inline-block">
            会員ランクとは？
          </p>
        </Link>
      </div>
    </div>
  )
}

export default MemberRankCard
