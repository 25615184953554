import { useCallback, useEffect, useState } from 'react'
import { MyPageTop } from '../../../model/MyPageTop'
import { useLoading } from '../../../providers/loading_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const useTop = (): [MyPageTop | null, boolean] => {
  const apiClient = getAppClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showSnackBar } = useSnackBar()

  const [top, setTop] = useState<MyPageTop | null>(null)

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.mypage
      .getMypage()
      .then((res) => {
        const data = res.data
        setTop(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    load()
  }, [])

  return [top, isLoading]
}

export default useTop
