import React from 'react'

export const CrownIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        viewBox="0 0 15 12"
      >
        <path
          id="FontAwsome_crown_"
          data-name="FontAwsome (crown)"
          d="M12.375,10.5H2.625a.376.376,0,0,0-.375.375v.75A.376.376,0,0,0,2.625,12h9.75a.376.376,0,0,0,.375-.375v-.75A.376.376,0,0,0,12.375,10.5Zm1.5-7.5A1.125,1.125,0,0,0,12.75,4.125a1.1,1.1,0,0,0,.1.464l-1.7,1.017a.749.749,0,0,1-1.036-.272L8.21,1.992a1.125,1.125,0,1,0-1.42,0L4.88,5.334a.75.75,0,0,1-1.036.272L2.149,4.589a1.125,1.125,0,1,0-1.024.661,1.149,1.149,0,0,0,.18-.019L3,9.75h9l1.695-4.519a1.149,1.149,0,0,0,.18.019,1.125,1.125,0,0,0,0-2.25Z"
          fill="#282828"
        />
      </svg>
    </div>
  )
}
