import React, { FC } from 'react'
import ButtonBg from '../../images/post_button_bg.png'
import { PostIcon } from '../../images/icons/post_icon'
import { useAuth } from '../../hooks/use_auth'
import { useModal } from 'react-hooks-use-modal'
import SelectEnterpriseModal from '../../pages/kuchikomi/components/select_enterprise_modal'
import { useNavigate } from 'react-router-dom'
import Enterprise from '../../model/Enterprise'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { LicenseType } from '../../enums/LicenseType'

type PostButtonProps = {
  onClick?: () => void
}

const PostButton: FC<PostButtonProps> = ({ onClick }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  const [Modal, openModal, closeModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })

  const clickAction = () => {
    if (!user.isLogined()) {
      showSnackBar(
        ['クチコミを投稿するためには会員登録及びログインしてください。'],
        'success'
      )
      return navigate('/login')
    }
    if (onClick) {
      return onClick()
    }

    openModal()
  }

  const transferCreatePage = (enterprise: Enterprise) => {
    closeModal()
    navigate(`/kuchikomi/create/${enterprise.id}`)
  }

  // 一般ユーザーでない場合、何も表示しない
  if (user.isLogined() && user.licenseType !== LicenseType.NORMAL) {
    return <></>
  }
   
  return (
    <>
      <div
        className="xl:h-20 xl:w-20 w-16 h-16 py-3 xl:px-3 sm:px-1 bg-cover bg-no-repeat bg-center opacity-100 rounded-full sm:sticky sm:top-20 cursor-pointer hover:opacity-50 transition-opacity"
        style={{ backgroundImage: `url(${ButtonBg})` }}
        onClick={clickAction}
      >
        <div className="w-fit mx-auto">
          <PostIcon />
        </div>
        <p className="text-center text-white xl:text-sm text-xs">投稿する</p>
      </div>
      <Modal>
        <SelectEnterpriseModal
          close={closeModal}
          select={transferCreatePage}
        ></SelectEnterpriseModal>
      </Modal>
    </>
  )
}

export default PostButton
