import Image1 from '../images/sdgs_categories/1.png'
import Image2 from '../images/sdgs_categories/2.png'
import Image3 from '../images/sdgs_categories/3.png'
import Image4 from '../images/sdgs_categories/4.png'
import Image5 from '../images/sdgs_categories/5.png'
import Image6 from '../images/sdgs_categories/6.png'
import Image7 from '../images/sdgs_categories/7.png'
import Image8 from '../images/sdgs_categories/8.png'
import Image9 from '../images/sdgs_categories/9.png'
import Image10 from '../images/sdgs_categories/10.png'
import Image11 from '../images/sdgs_categories/11.png'
import Image12 from '../images/sdgs_categories/12.png'
import Image13 from '../images/sdgs_categories/13.png'
import Image14 from '../images/sdgs_categories/14.png'
import Image15 from '../images/sdgs_categories/15.png'
import Image16 from '../images/sdgs_categories/16.png'
import Image17 from '../images/sdgs_categories/17.png'

export const useSdgsImage = () => {
  const get = (code: string) => {
    if (code === '1') {
      return Image1
    } else if (code === '2') {
      return Image2
    } else if (code === '3') {
      return Image3
    } else if (code === '4') {
      return Image4
    } else if (code === '5') {
      return Image5
    } else if (code === '6') {
      return Image6
    } else if (code === '7') {
      return Image7
    } else if (code === '8') {
      return Image8
    } else if (code === '9') {
      return Image9
    } else if (code === '10') {
      return Image10
    } else if (code === '11') {
      return Image11
    } else if (code === '12') {
      return Image12
    } else if (code === '13') {
      return Image13
    } else if (code === '14') {
      return Image14
    } else if (code === '15') {
      return Image15
    } else if (code === '16') {
      return Image16
    } else if (code === '17') {
      return Image17
    }
  }

  return [get]
}
