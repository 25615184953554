import { useLoading } from '../providers/loading_provider'
import { useSnackBar } from '../providers/snack_bar_provider'
import { ApiErrorResponse, handleError } from '../utils/network_util'

type UseUploadPropd = {
  upload: (file: File) => Promise<string | null>
}

export const useUpload = (): UseUploadPropd => {
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()

  const upload = async (file: File): Promise<string | null> => {
    const fd = new FormData()
    fd.append('file', file)

    showLoading()

    const url = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/upload/image`,
      {
        method: 'POST',
        body: fd,
      }
    )
      .then((response) => response.json())
      .then((data) => data.url)
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        hideLoading()
        return null
      })
      .finally(() => {
        hideLoading()
      })

    return url
  }

  return {
    upload,
  }
}
