import React, { FC } from 'react';
import { InfoIcon } from '../../../images/icons/info_icon';

interface InfoTableCellProps {
  text: string;
  bgColor?: string;
}

const InfoTableCell: FC<InfoTableCellProps> = ({ text, bgColor = 'bg-main-skyBlue' }) => {
  return (
    <td className={`sm:pl-4 pl-2 sm:py-3 py-1 ${bgColor}`}>
      <div className="flex items-center">
        <span className='mr-2 sm:text-base text-xs'>{text}</span>
        <InfoIcon />
      </div>
    </td>
  );
};

export default InfoTableCell;
