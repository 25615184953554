import React, { FC } from 'react'
import parse from 'html-react-parser'
import { Notification } from '../../../../model/Notification'
import { FilledCommentIcon } from '../../../../images/icons/comment_icon'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'
import { FilledFavoriteIcon } from '../../../../images/icons/favorite_icon'
import { FollowerIcon } from '../../../../images/icons/follower_icon'
import { EvalurationStarIcon } from '../../../../images/icons/star_icon'
import { Link } from 'react-router-dom'
import { Kuchikomi } from '../../../../model/Kuchikomi'
import { useSdgsImage } from '../../../../hooks/use_sdgs_image'
import FavoriteButton from '../../../../components/buttons/favorite_button'
import CommentButton from '../../../../components/buttons/comment_button'
import BookmarkButton from '../../../../components/buttons/bookmark_button'
import { useAuth } from '../../../../hooks/use_auth'

type NotificationCardProps = {
  notification: Notification
  isSp?: boolean
}

const NotificationCard: FC<NotificationCardProps> = ({
  isSp = false,
  ...props
}) => {
  let iconElem = null
  let detailElem = null

  switch (props.notification.notificationType) {
    case 'follow':
      iconElem = <FollowerIcon color="blue" />
      break
    case 'point':
      iconElem = <HoldPointIcon color="blue" />
      break
    case 'kuchikomi_comment':
      iconElem = <FilledCommentIcon color="blue" />
      break
    case 'kuchikomi_point':
    case 'kuchikomi_good':
      iconElem = <FilledFavoriteIcon color="blue" />
      break
  }

  switch (props.notification.notificationType) {
    case 'kuchikomi_comment':
      detailElem = (
        <div>
          <p className="text-main-lightGray">
            {parse(props.notification.detail || '')}
          </p>
        </div>
      )
      break
    case 'kuchikomi_point':
    case 'kuchikomi_good':
      detailElem = props.notification.kuchikomi && (
        <KuchikomiDetail isSp={isSp} kuchikomi={props.notification.kuchikomi} />
      )
      break
  }

  return (
    <div className="p-4 border-b border-b-main-gray">
      <Link to={props.notification.url}>
        <div className="flex justify-start">
          <div className="flex flex-col justify-center pr-4">
            <div
              className="w-14 h-14 rounded-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${props.notification.userIcon})`,
              }}
            ></div>
            <div className="absolute">
              <div className="relative top-[16px] left-[40px] [&_svg]:w-4 [&_svg]:h-4">
                {iconElem}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <p>{parse(props.notification.message)}</p>
          </div>
        </div>
        <div className="ml-[72px]">{detailElem}</div>
      </Link>
    </div>
  )
}

export default NotificationCard

const KuchikomiDetail: FC<{ kuchikomi: Kuchikomi; isSp?: boolean }> = ({
  isSp = false,
  ...props
}) => {
  const [getSdgsImage] = useSdgsImage()
  const { user } = useAuth()

  return (
    <div>
      <div className="flex justify-start">
        <div className="flex flex-col justify-center">
          <p className={`text-main-lightGray pb-1 ${isSp ? 'text-sm' : ''}`}>
            あなたの評価：
          </p>
        </div>
        <div className="flex flex-col justify-center">
          <EvalurationStarIcon
            evaluation={props.kuchikomi.evaluation}
            color="gray"
          />
        </div>
      </div>
      <div className="pb-4">
        <p className="text-main-lightGray">{props.kuchikomi.kuchikomi}</p>
      </div>
      <div className="lg:flex lg:justify-between lg:flex-row-reverse flex-wrap lg:flex-nowrap">
        {props.kuchikomi.category !== null && (
          <Link
            to={`/search/kuchikomi?sdgs-category=${props.kuchikomi.category.code}`}
            className="flex lg:justify-end justify-start mb-4 lg:mb-0"
          >
            <div className="flex flex-col justify-center">
              <img
                alt={props.kuchikomi.category.value}
                src={getSdgsImage(props.kuchikomi.category.code)}
                width={20}
                height={20}
              />
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`text-main-lightGray text-sm pl-2 ${
                  isSp ? 'w-[140px]' : 'w-[200px]'
                }`}
              >
                {props.kuchikomi.category.value}
              </p>
            </div>
          </Link>
        )}
        <div className="flex justify-start">
          <div className="flex flex-col justify-center mr-2">
            <FavoriteButton
              isLiked={props.kuchikomi.liked}
              isSp={isSp}
              favoriteCount={props.kuchikomi.likeCount}
            />
          </div>
          {user && (
            <div className="ml-3 flex flex-col justify-center mr-2">
              <CommentButton isActive={false} isSp={isSp} />
            </div>
          )}
          {user && (
            <div className="ml-1 flex flex-col justify-center">
              <BookmarkButton
                isActive={props.kuchikomi.bookmarked}
                isSp={isSp}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
