import React from 'react'

const NotFoundPage = () => {
  const content = (
    <body>
      <h2>ご指定のページが見つかりませんでした。</h2>
      <>
        <p>
          アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
        </p>
      </>
    </body>
  )

  return content
}

export default NotFoundPage
