import React, { FC } from 'react'
import './loading_spinner.css'

type LoadingProps = {
  color?: 'blue' | 'white'
  size?: number
  border?: number
  className?: string
}

const LoadingSpinner: FC<LoadingProps> = ({
  color = 'blue',
  size = 32,
  border = 4,
  className,
}) => {
  const spinnerStyle = {
    width: size,
    height: `${size}px`,
    border: `${border}px solid var(--color-main-${color})`,
    borderTop: `${border}px solid transparent`,
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  }

  return <div style={spinnerStyle} className={className}></div>
}

export default LoadingSpinner
