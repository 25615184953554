import React, { FC } from 'react'
import './loading.css'

type LoadingProps = {
  className?: string
}

/**
 * ローディングアイコン
 * サイズは変更できない
 * NOTE: デザイン的にサイズ変更が大変だから最終的に使わなくなるかも、spinerの方はサイズも簡単に変えれる
 */
const Loading: FC<LoadingProps> = ({ className }) => {
  return <div id="loader" className={`text-white ${className}`}></div>
}
export default Loading
