import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useNavigate } from 'react-router-dom'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const PasswordResetCompletePage: FC = () => {
  const navigate = useNavigate()
  const isSp = useSpBreakPoint()

  const redirectLogin = () => {
    navigate('/login')
  }

  return (
    <CommonTemplate isSp={isSp}>
      <div className="bg-white rounded-xl w-full max-w-xl mx-auto p-6 text-left">
        <h2 className="text-2xl font-bold mb-8">パスワードリセット完了</h2>
        <p>パスワードリセットが完了しました。</p>
        <p className="mb-8">ログイン画面よりログインしてください。</p>
        <FlatButton bgColor="blue" onClick={redirectLogin}>
          <p>ログイン画面へ</p>
        </FlatButton>
      </div>
    </CommonTemplate>
  )
}

export default PasswordResetCompletePage
