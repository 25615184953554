import React, { FC } from 'react'

type GivedPointIconProps = {
  size?: 'sm' | 'md'
  color?: 'blue' | 'white'
}

export const GivedPointIcon: FC<GivedPointIconProps> = ({
  size = 'sm',
  color = 'blue',
}) => {
  let width = '22.407'
  let height = '19.232'
  let bg = '#002be3'
  let fill = '#fff'

  switch (size) {
    case 'sm':
      width = '22.407'
      height = '19.232'
      break
    case 'md':
      width = '32.13'
      height = '27.13'
      break
  }

  switch (color) {
    case 'blue':
      bg = '#002be3'
      fill = '#fff'
      break
    case 'white':
      bg = '#fff'
      fill = '#002be3'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 22.407 19.232"
      >
        <g
          id="グループ_1249"
          data-name="グループ 1249"
          transform="translate(-208.593 -429.768)"
        >
          <path
            id="FontAwsome_heart_"
            data-name="FontAwsome (heart)"
            d="M17.574,33.217a5.775,5.775,0,0,0-7.76.575,5.771,5.771,0,0,0-7.76-.575,5.8,5.8,0,0,0-.406,8.48l6.724,6.851a2.014,2.014,0,0,0,2.883,0L17.98,41.7A5.805,5.805,0,0,0,17.574,33.217Z"
            transform="translate(208.593 397.79)"
            fill={bg}
          />
          <g
            id="グループ_1248"
            data-name="グループ 1248"
            transform="translate(-9.836 -1.836)"
          >
            <circle
              id="楕円形_43"
              data-name="楕円形 43"
              cx="6.5"
              cy="6.5"
              r="6.5"
              transform="translate(227.836 437.836)"
              fill={fill}
            />
            <path
              id="FontAwsome_arrow-alt-circle-right_"
              data-name="FontAwsome (arrow-alt-circle-right)"
              d="M12.42,8A4.42,4.42,0,1,1,8,12.42,4.419,4.419,0,0,1,12.42,8Zm-2.067,5.2H12.42v1.264a.214.214,0,0,0,.365.151l2.037-2.048a.212.212,0,0,0,0-.3l-2.037-2.049a.214.214,0,0,0-.365.151v1.264H10.352a.214.214,0,0,0-.214.214V12.99A.214.214,0,0,0,10.352,13.2Z"
              transform="translate(221.998 431.998)"
              fill={bg}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
