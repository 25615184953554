import React, { FC } from 'react'

type EvaluationStarIconProps = {
  evaluation: number | null
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}

export const StarIcon: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.364 17.577"
      >
        <path
          id="FontAwsome_star_"
          data-name="FontAwsome (star)"
          d="M28.7.6,26.455,5.144l-5.015.731a1.1,1.1,0,0,0-.608,1.874l3.628,3.536L23.6,16.279A1.1,1.1,0,0,0,25.2,17.436l4.486-2.358,4.486,2.358a1.1,1.1,0,0,0,1.593-1.157L34.9,11.284,38.53,7.749a1.1,1.1,0,0,0-.608-1.874l-5.015-.731L30.666.6A1.1,1.1,0,0,0,28.7.6Z"
          transform="translate(-20.5 0.013)"
          fill={fill}
        />
      </svg>
    </div>
  )
}

export const EmptyStarIcon: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.754 18.001"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_172"
              data-name="長方形 172"
              width="3.8"
              height="18"
              transform="translate(-3627 -20930)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1015"
          data-name="グループ 1015"
          transform="translate(-812 -5274.524)"
        >
          <path
            id="FontAwsome_star_2"
            data-name="FontAwsome (star)"
            d="M38.293,6l-5.121-.747L30.883.612a1.123,1.123,0,0,0-2.012,0L26.582,5.253,21.46,6a1.122,1.122,0,0,0-.62,1.914l3.705,3.611-.876,5.1A1.121,1.121,0,0,0,25.3,17.806L29.877,15.4l4.582,2.408a1.122,1.122,0,0,0,1.627-1.181l-.876-5.1,3.705-3.611A1.122,1.122,0,0,0,38.293,6ZM33.4,10.935l.831,4.852L29.877,13.5l-4.357,2.289.831-4.852L22.824,7.5,27.7,6.792l2.18-4.417,2.18,4.417L36.93,7.5,33.4,10.935Z"
            transform="translate(791.5 5274.537)"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  )
}

const DecimalStar1: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.754 18.001"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_172"
              data-name="長方形 172"
              width="3.8"
              height="18"
              transform="translate(-3627 -20930)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1015"
          data-name="グループ 1015"
          transform="translate(-812 -5274.524)"
        >
          <g
            id="マスクグループ_3"
            data-name="マスクグループ 3"
            transform="translate(4439 26204.525)"
            clipPath="url(#clip-path)"
          >
            <path
              id="FontAwsome_star_"
              data-name="FontAwsome (star)"
              d="M28.737.6,26.485,5.169l-5.04.735a1.1,1.1,0,0,0-.611,1.883L24.48,11.34l-.862,5.019a1.1,1.1,0,0,0,1.6,1.163l4.509-2.37,4.509,2.37a1.1,1.1,0,0,0,1.6-1.163l-.862-5.019L38.62,7.787A1.1,1.1,0,0,0,38.01,5.9l-5.04-.735L30.717.6A1.1,1.1,0,0,0,28.737.6Z"
              transform="translate(-3647.5 -20929.703)"
              fill={fill}
            />
          </g>
          <path
            id="FontAwsome_star_2"
            data-name="FontAwsome (star)"
            d="M38.293,6l-5.121-.747L30.883.612a1.123,1.123,0,0,0-2.012,0L26.582,5.253,21.46,6a1.122,1.122,0,0,0-.62,1.914l3.705,3.611-.876,5.1A1.121,1.121,0,0,0,25.3,17.806L29.877,15.4l4.582,2.408a1.122,1.122,0,0,0,1.627-1.181l-.876-5.1,3.705-3.611A1.122,1.122,0,0,0,38.293,6ZM33.4,10.935l.831,4.852L29.877,13.5l-4.357,2.289.831-4.852L22.824,7.5,27.7,6.792l2.18-4.417,2.18,4.417L36.93,7.5,33.4,10.935Z"
            transform="translate(791.5 5274.537)"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  )
}

const DecimalStar2: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.754 18.001"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_171"
              data-name="長方形 171"
              width="7.6"
              height="18"
              transform="translate(-3627 -20907)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1014"
          data-name="グループ 1014"
          transform="translate(-812 -5297.524)"
        >
          <g
            id="マスクグループ_4"
            data-name="マスクグループ 4"
            transform="translate(4439 26204.525)"
            clipPath="url(#clip-path)"
          >
            <path
              id="FontAwsome_star_"
              data-name="FontAwsome (star)"
              d="M28.737.6,26.485,5.169l-5.04.735a1.1,1.1,0,0,0-.611,1.883L24.48,11.34l-.862,5.019a1.1,1.1,0,0,0,1.6,1.163l4.509-2.37,4.509,2.37a1.1,1.1,0,0,0,1.6-1.163l-.862-5.019L38.62,7.787A1.1,1.1,0,0,0,38.01,5.9l-5.04-.735L30.717.6A1.1,1.1,0,0,0,28.737.6Z"
              transform="translate(-3647.5 -20906.703)"
              fill={fill}
            />
          </g>
          <path
            id="FontAwsome_star_2"
            data-name="FontAwsome (star)"
            d="M38.293,6l-5.121-.747L30.883.612a1.123,1.123,0,0,0-2.012,0L26.582,5.253,21.46,6a1.122,1.122,0,0,0-.62,1.914l3.705,3.611-.876,5.1A1.121,1.121,0,0,0,25.3,17.806L29.877,15.4l4.582,2.408a1.122,1.122,0,0,0,1.627-1.181l-.876-5.1,3.705-3.611A1.122,1.122,0,0,0,38.293,6ZM33.4,10.935l.831,4.852L29.877,13.5l-4.357,2.289.831-4.852L22.824,7.5,27.7,6.792l2.18-4.417,2.18,4.417L36.93,7.5,33.4,10.935Z"
            transform="translate(791.5 5297.537)"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  )
}

const DecimalStar3: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.754 18.001"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_170"
              data-name="長方形 170"
              width="11.4"
              height="18"
              transform="translate(-3627 -20880)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1016"
          data-name="グループ 1016"
          transform="translate(-812 -5324.524)"
        >
          <g
            id="マスクグループ_5"
            data-name="マスクグループ 5"
            transform="translate(4439 26204.525)"
            clipPath="url(#clip-path)"
          >
            <path
              id="FontAwsome_star_"
              data-name="FontAwsome (star)"
              d="M28.737.6,26.485,5.169l-5.04.735a1.1,1.1,0,0,0-.611,1.883L24.48,11.34l-.862,5.019a1.1,1.1,0,0,0,1.6,1.163l4.509-2.37,4.509,2.37a1.1,1.1,0,0,0,1.6-1.163l-.862-5.019L38.62,7.787A1.1,1.1,0,0,0,38.01,5.9l-5.04-.735L30.717.6A1.1,1.1,0,0,0,28.737.6Z"
              transform="translate(-3647.5 -20879.703)"
              fill={fill}
            />
          </g>
          <path
            id="FontAwsome_star_2"
            data-name="FontAwsome (star)"
            d="M38.293,6l-5.121-.747L30.883.612a1.123,1.123,0,0,0-2.012,0L26.582,5.253,21.46,6a1.122,1.122,0,0,0-.62,1.914l3.705,3.611-.876,5.1A1.121,1.121,0,0,0,25.3,17.806L29.877,15.4l4.582,2.408a1.122,1.122,0,0,0,1.627-1.181l-.876-5.1,3.705-3.611A1.122,1.122,0,0,0,38.293,6ZM33.4,10.935l.831,4.852L29.877,13.5l-4.357,2.289.831-4.852L22.824,7.5,27.7,6.792l2.18-4.417,2.18,4.417L36.93,7.5,33.4,10.935Z"
            transform="translate(791.5 5324.537)"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  )
}

const DecimalStar4: FC<{
  color?: 'blue' | 'white' | 'gray'
  size?: 'md' | 'xl'
}> = ({ size = 'md', color = 'blue' }) => {
  let width = '15.364'
  let height = '15.577'
  let fill = 'var(--color-main-blue)'

  switch (size) {
    case 'md':
      width = '15.364'
      height = '15.577'
      break
    case 'xl':
      width = '35.17'
      height = '33.66'
      break
  }

  switch (color) {
    case 'blue':
      fill = 'var(--color-main-blue)'
      break
    case 'white':
      fill = '#fff'
      break
    case 'gray':
      fill = '#b9b9b9'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18.754 18.001"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_169"
              data-name="長方形 169"
              width="15.2"
              height="18"
              transform="translate(-3627 -20849)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1017"
          data-name="グループ 1017"
          transform="translate(-812 -5355.524)"
        >
          <g
            id="マスクグループ_6"
            data-name="マスクグループ 6"
            transform="translate(4439 26204.525)"
            clipPath="url(#clip-path)"
          >
            <path
              id="FontAwsome_star_"
              data-name="FontAwsome (star)"
              d="M28.737.6,26.485,5.169l-5.04.735a1.1,1.1,0,0,0-.611,1.883L24.48,11.34l-.862,5.019a1.1,1.1,0,0,0,1.6,1.163l4.509-2.37,4.509,2.37a1.1,1.1,0,0,0,1.6-1.163l-.862-5.019L38.62,7.787A1.1,1.1,0,0,0,38.01,5.9l-5.04-.735L30.717.6A1.1,1.1,0,0,0,28.737.6Z"
              transform="translate(-3647.5 -20848.703)"
              fill={fill}
            />
          </g>
          <path
            id="FontAwsome_star_2"
            data-name="FontAwsome (star)"
            d="M38.293,6l-5.121-.747L30.883.612a1.123,1.123,0,0,0-2.012,0L26.582,5.253,21.46,6a1.122,1.122,0,0,0-.62,1.914l3.705,3.611-.876,5.1A1.121,1.121,0,0,0,25.3,17.806L29.877,15.4l4.582,2.408a1.122,1.122,0,0,0,1.627-1.181l-.876-5.1,3.705-3.611A1.122,1.122,0,0,0,38.293,6ZM33.4,10.935l.831,4.852L29.877,13.5l-4.357,2.289.831-4.852L22.824,7.5,27.7,6.792l2.18-4.417,2.18,4.417L36.93,7.5,33.4,10.935Z"
            transform="translate(791.5 5355.537)"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  )
}

export const EvalurationStarIcon: FC<EvaluationStarIconProps> = ({
  size = 'md',
  color = 'blue',
  evaluation,
}) => {
  if (evaluation === null) {
    return (
      <div className="flex justify-start opacity-25">
        {[...Array(5)].map((_, index) => {
          return (
            <div key={`star-evaluation-${String(index)}`} className="mr-1">
              <StarIcon size={size} color={color} />
            </div>
          )
        })}
      </div>
    )
  }

  const integer = Math.floor(evaluation)
  const decimal = evaluation - integer

  let decimalStarElem = <StarIcon size={size} color={color} />
  if (decimal === 0.0) {
    decimalStarElem = <EmptyStarIcon size={size} color={color} />
  } else if (decimal < 0.2) {
    decimalStarElem = <DecimalStar1 size={size} color={color} />
  } else if (decimal < 0.4) {
    decimalStarElem = <DecimalStar2 size={size} color={color} />
  } else if (decimal < 0.6) {
    decimalStarElem = <DecimalStar3 size={size} color={color} />
  } else if (decimal < 0.8) {
    decimalStarElem = <DecimalStar4 size={size} color={color} />
  }

  return (
    <div className="flex justify-start">
      {[...Array(integer)].map((_, index) => {
        return (
          <div key={`star-evaluation-${String(index)}`} className="mr-1">
            <StarIcon size={size} color={color} />
          </div>
        )
      })}
      {integer < 5 && (
        <>
          <div className="mr-1">{decimalStarElem}</div>
          {[...Array(4 - integer)].map((_, index) => {
            return (
              <div key={`empty-${String(index)}`} className="mr-1">
                <EmptyStarIcon size={size} color={color} />
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
