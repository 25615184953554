/**
 * 会員種別
 */
export const LicenseType = {
  /** 一般ユーザー */
  NORMAL: 1,

  /** 企業・団体ユーザー */
  CORP: 2,
} as const

export const LicenseTypeValues = [1, 2]

export type LicenseTypeValue = (typeof LicenseType)[keyof typeof LicenseType]

export const LicenseTypeLabel = {
  [LicenseType.NORMAL]: '一般ユーザー',
  [LicenseType.CORP]: '企業・団体ユーザー',
}
