import React, { FC } from 'react';

interface TableCellProps {
  children: React.ReactNode;
  textAlign?: string;
  bgColor?: string;
  textSize?: string;
}

const TableCell: FC<TableCellProps> = ({
	children,
	textAlign = 'text-center',
	bgColor = 'bg-main-skyBlue',
	textSize = 'text-sm',
  }) => {
  return (
    <td className={`sm:p-3 p-1 ${textAlign} ${bgColor} sm:text-base ${textSize}`}>
      {children}
    </td>
  );
};

export default TableCell;
