import React, { FC } from 'react'
import { Hint } from '../../../../model/MyPageTop'
import BackgroundImage from '../../../../images/mypage/bg-l.png'
import { useToggle } from 'react-use'
import { ArrowIcon } from '../../../../images/icons/arrow_icon'
import { HintIcon } from '../../../../images/icons/hint_icon'

type HintCardProps = {
  hint: Hint
}

const HintCard: FC<HintCardProps> = (props) => {
  const [isOpen, toggle] = useToggle(false)

  return (
    <div
      className="rounded-xl bg-center bg-cover duration-200"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <div
        className="w-full flex justify-between px-4 py-4 cursor-pointer"
        onClick={() => toggle()}
      >
        <div className="flex justify-start">
          <div className="flex flex-col justify-center pr-2">
            <HintIcon />
          </div>
          <p className="text-white text-xl sm:text-2xl">{props.hint.title}</p>
        </div>
        <div className="flex flex-col justify-center">
          <div
            className="duration-200"
            style={isOpen ? { rotate: '-180deg' } : { rotate: '0deg' }}
          >
            <ArrowIcon color="white" />
          </div>
        </div>
      </div>
      <div
        className="duration-200 overflow-hidden px-6"
        style={
          isOpen
            ? { height: 'auto', paddingTop: '16px', paddingBottom: '16px' }
            : { height: '0px', paddingTop: '0px', paddingBottom: '0px' }
        }
      >
        <p className="text-white whitespace-pre-wrap">{props.hint.content}</p>
      </div>
    </div>
  )
}

export default HintCard
