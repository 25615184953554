import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import TransparencyImg from '../../images/guide/transparency.png'
import LearningImg from '../../images/guide/learning.png'
import PointUseImg from '../../images/guide/point_use.png'

const ForUserPage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp} isShowPostButton={false}>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-2">
        SDGs クチコミ Lodgeとは
      </p>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
        ユーザーの方へ
      </p>
      <div className="bg-white rounded-xl w-full lg:px-20 lg:py-12 sm:px-12 px-4 py-8">
        <p className="mb-12">
          SDGs クチコミ
          Lodgeは、持続可能な開発目標に貢献する・団体活動に対し、ユーザーの声を直接届け、評価することができる山小屋（プラットフォーム）
          ※
          です。人々の情熱が、企業・団体の取り組みを形作り、社会全体の持続可能な未来への変革を促進します。
        </p>
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-2">
          直接、企業・団体へ声を届ける
        </p>
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-16">
          〜あなたのクチコミがイノベーションの鍵に〜
        </p>
        <p className="mb-16">
          あなたのクチコミが、企業・団体のSDGsへの取り組みに直接届きます。オープンな議論を通じて企業や団体の新しい社会的価値を創造する力を生み出します。あなたの声が、明日のイノベーションにつながります。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-16">
          SDGs クチコミ Lodgeがユーザーに選ばれる3つの理由
        </p>
        <p className="sm:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 1
        </p>
        <div className="sm:flex p-8 mb-12">
          <div className="sm:p-4 sm:mr-8">
            <p className="sm:text-4xl text-2xl text-main-blue font-bold mb-8">
              SDGs情報の透明化
            </p>
            <p className="mb-6">
              SDGsクチコミLodgeは、企業・団体とユーザーを直接つなぎ、SDGsに対する取り組みの透明化を図ります。あなたのクチコミが、ユーザーのエシカルな製品選択やエコフレンドリーな企業・団体への投資を後押しし、持続可能な世界への一歩となります。
            </p>
          </div>
          <img
            src={TransparencyImg}
            className="mt-4 sm:ml-0 ml-auto sm:w-64 sm:h-64 w-48 h-48"
          />
        </div>
        <p className="sm:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 2
        </p>
        <div className="sm:flex p-8 mb-12">
          <div className="sm:p-4 sm:mr-8">
            <p className="sm:text-4xl text-2xl text-main-blue font-bold mb-8">
              SDGs学習と啓発
            </p>
            <p className="mb-6">
              SDGsについて学ぶことは、未来への投資です。クチコミを通じて、SDGsが抱える課題をやアイデアを共有し、深く理解することで、持続可能な未来に向けたあなた自身の理解を一層深めることができます。
            </p>
          </div>
          <img
            src={LearningImg}
            className="mt-4 sm:ml-0 ml-auto sm:w-64 sm:h-64 w-48 h-48"
          />
        </div>
        <p className="sm:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 3
        </p>
        <div className="sm:flex p-8">
          <div className="sm:p-4 sm:mr-8">
            <p className="sm:text-4xl text-2xl text-main-blue font-bold mb-8">
              SDGsのポイ活
            </p>
            <p className="mb-6">
              クチコミの投稿や活動を通じて獲得した「いいねポイント」を使い、様々なリワードや特典に交換できます。（Coming
              Soon…）
            </p>
          </div>
          <img
            src={PointUseImg}
            className="mt-4 sm:ml-0 ml-auto sm:w-64 sm:h-64 w-48 h-48"
          />
        </div>
      </div>
    </CommonTemplate>
  )
  return content
}

export default ForUserPage
