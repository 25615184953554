import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Information from '../../../model/Information'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
}

const useInformationDetail = (): [Information | null, boolean, Handler] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const [loading, setLoading] = useState<boolean>(false)
  const [information, setInformation] = useState<Information | null>(null)

  const load = useCallback(async (id: string) => {
    setLoading(true)
    await apiClient.informations
      .informationsDetail(id)
      .then((res) => {
        setInformation(res.data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return [information, loading, { load: load }]
}

export default useInformationDetail
