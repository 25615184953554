import React from 'react'

export const XIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.713"
        height="22.19"
        viewBox="0 0 21.713 22.19"
      >
        <path
          id="logo"
          d="M12.922,9.4,21.005,0H19.09L12.071,8.158,6.465,0H0L8.477,12.337,0,22.19H1.916l7.412-8.615,5.92,8.615h6.465L12.922,9.4ZM10.3,12.446,9.44,11.217,2.606,1.442H5.548l5.515,7.889.859,1.228,7.169,10.254H16.148L10.3,12.446Z"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
