import React, { FC } from 'react'
import { MemberRank } from '../../model/Code'

type MemberRankIconProps = {
  rank: MemberRank
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export const MemberRankIcon: FC<MemberRankIconProps> = ({
  size = 'sm',
  ...props
}) => {
  let elem = null
  const color = getMemberRankColor(props.rank)
  switch (props.rank.code) {
    case 'platina':
      elem = <RankIcon text="P" size={size} color={color} />
      break
    case 'gold':
      elem = <RankIcon text="G" size={size} color={color} />
      break
    case 'silver':
      elem = <RankIcon text="S" size={size} color={color} />
      break
    case 'bronze':
      elem = <RankIcon text="B" size={size} color={color} />
      break
    case 'regular':
      elem = <RankIcon text="R" size={size} color={color} />
      break
  }

  return elem
}

export const getMemberRankColor = (rank: MemberRank) => {
  switch (rank.code) {
    case 'platina':
      return '#002be3'
    case 'gold':
      return '#E3C000'
    case 'silver':
      return '#B9B9B9'
    case 'bronze':
      return '#B23525'
    case 'regular':
      return '#3BA3FA'
  }

  return ''
}

const RankIcon: FC<{
  size: 'sm' | 'md' | 'lg' | 'xl'
  color: string
  text: string
}> = (props) => {
  let width = '22.333'
  let height = '22.333'
  const bg = props.color
  const fill = '#fff'

  switch (props.size) {
    case 'sm':
      width = '22.333'
      height = '22.333'
      break
    case 'md':
      break
    case 'lg':
      width = '58.48'
      height = '58.48'
      break
    case 'xl':
      width = '103.667'
      height = '103.667'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 22.333 22.333"
      >
        <g
          id="グループ_1109"
          data-name="グループ 1109"
          transform="translate(-563 -162)"
        >
          <path
            id="FontAwsome_certificate_"
            data-name="FontAwsome (certificate)"
            d="M20.005,11.163,22.011,9.2a1.039,1.039,0,0,0-.465-1.76l-2.733-.7.77-2.705A1.057,1.057,0,0,0,18.29,2.743l-2.7.771L14.888.78A1.047,1.047,0,0,0,13.129.315L11.167,2.336,9.2.315A1.047,1.047,0,0,0,7.445.78l-.7,2.734-2.7-.771A1.057,1.057,0,0,0,2.75,4.038l.77,2.705-2.733.7A1.039,1.039,0,0,0,.322,9.2l2.006,1.963L.322,13.126a1.039,1.039,0,0,0,.465,1.76l2.733.7-.77,2.705a1.057,1.057,0,0,0,1.294,1.294l2.7-.771.7,2.734a1.037,1.037,0,0,0,1.759.465L11.167,20l1.962,2.007a1.041,1.041,0,0,0,1.759-.465l.7-2.734,2.7.771a1.057,1.057,0,0,0,1.294-1.294l-.77-2.705,2.733-.7a1.039,1.039,0,0,0,.465-1.76l-2.006-1.963Z"
            transform="translate(563 162)"
            fill={bg}
          />
          <text
            id="D"
            transform="translate(570.536 177.321)"
            fill={fill}
            fontSize="11"
            fontFamily="YuGo-Bold, YuGothic"
            fontWeight="700"
          >
            <tspan x="0" y="0">
              {props.text}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}
