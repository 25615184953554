import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { Kuchikomi } from '../../../model/Kuchikomi'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { EnterpriseSimple } from '../../../clients/api_client'

type Handler = {
  load: () => Promise<void>
}

const useEnterpriseTopSliderContent = (): [
  Array<{ enterprise: EnterpriseSimple; kuchikomis: Kuchikomi[] }>,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [enterpriseTopSliderContent, setEnterpriseTopSlider] = useState<
    Array<{ enterprise: EnterpriseSimple; kuchikomis: Kuchikomi[] }>
  >([])

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.enterprises
      .topSliderList()
      .then((res) => {
        const data = res.data

        setEnterpriseTopSlider(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    load()
  }, [])

  return [enterpriseTopSliderContent, isLoading, { load: load }]
}

export default useEnterpriseTopSliderContent
