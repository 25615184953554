import React from 'react'

export const TikTokIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.374"
        height="21.713"
        viewBox="0 0 19.374 21.713"
      >
        <g
          id="Button_No_outline"
          data-name="Button_No outline"
          transform="translate(0 0.001)"
        >
          <path
            id="パス_827"
            data-name="パス 827"
            d="M27.572,27.938a3.179,3.179,0,0,1-3.2,3.02,3.247,3.247,0,0,1-1.124-.2,3.248,3.248,0,0,0,1.124.2,3.18,3.18,0,0,0,3.2-3.02l.006-14.96h2.8a5.2,5.2,0,0,0,2.33,3.405h0a5.369,5.369,0,0,0,2.893.84v.831h0v2.9a9.227,9.227,0,0,1-5.314-1.67v7.586a6.96,6.96,0,0,1-7.03,6.871A7.1,7.1,0,0,1,19.225,32.5l0,0a6.822,6.822,0,0,1-3-5.624A6.96,6.96,0,0,1,23.254,20a7.192,7.192,0,0,1,.947.067v.884a6.979,6.979,0,0,0-6.821,6.1,6.979,6.979,0,0,1,6.821-6.1V23.88a3.231,3.231,0,0,0-.947-.145,3.179,3.179,0,0,0-3.211,3.138,3.131,3.131,0,0,0,1.721,2.777h0a3.247,3.247,0,0,0,1.489.36,3.18,3.18,0,0,0,3.2-3.02l.006-14.96h3.82a5.083,5.083,0,0,0,.09.947h-2.8Z"
            transform="translate(-16.224 -12.031)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </div>
  )
}
