import { useCallback, useState } from 'react'
import { Kuchikomi } from '../../../model/Kuchikomi'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { useAuth } from '../../../hooks/use_auth'

type Handler = {
  load: (userId: string, cursor?: number) => Promise<void>
  like: (kuchikomiId: string, point: number) => Promise<void>
  bookmark: (kuchikomiId: string, set: boolean) => Promise<void>
  reply: (kuchikomiId: string, message: string) => Promise<void>
  delete: (kuchikomiId: string) => Promise<void>
}

const useKuchikomis = ({
  getLimit = 20,
}): [Array<Kuchikomi>, number, number, boolean, Handler] => {
  const apiClient = getAppClient()

  const [totalSize, setTotalSize] = useState(0)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [kuchikomis, setKuchikomis] = useState<Array<Kuchikomi>>([])
  const { showSnackBar } = useSnackBar()
  const { user } = useAuth()
  const { showLoading, hideLoading } = useLoading()

  const load = useCallback(
    async (userId: string, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.users
        .kuchikomisDetail(userId, {
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setKuchikomis(result.data)
            setApiCursor(cursor)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => setIsLoading(false))
    },
    [totalSize, apiCursor, kuchikomis]
  )

  const like = useCallback(
    async (kuchikomiId: string, point: number) => {
      if (!user.isLogined()) {
        return
      }

      // 先に更新
      const nextKuhikomis = [...kuchikomis]
      const targetIdx = nextKuhikomis.findIndex(
        (item) => item.id === kuchikomiId
      )
      const beforeLiked = nextKuhikomis[targetIdx].liked
      nextKuhikomis[targetIdx].likeCount += point
      nextKuhikomis[targetIdx].liked = true
      setKuchikomis(nextKuhikomis)

      await apiClient.kuchikomis
        .likeUpdate(kuchikomiId, { point: point })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)

          // 元に戻す
          const nextKuhikomis = [...kuchikomis]
          if (!beforeLiked) {
            nextKuhikomis[targetIdx].liked = false
          }
          nextKuhikomis[targetIdx].likeCount -= point
          setKuchikomis(nextKuhikomis)

          return null
        })
    },
    [kuchikomis]
  )

  /**
   * ブックマークする
   * ローディング画面は出さず、非同期で処理する
   */
  const bookmark = useCallback(
    async (kuchikomiId: string, set: boolean) => {
      if (!user.isLogined()) {
        return
      }

      // 先に画面を更新
      const nextKuhikomis = [...kuchikomis]
      const targetIdx = nextKuhikomis.findIndex(
        (item) => item.id === kuchikomiId
      )
      nextKuhikomis[targetIdx].bookmarked = set
      setKuchikomis(nextKuhikomis)

      await apiClient.kuchikomis
        .bookmarkUpdate(kuchikomiId, { set: set })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
    },
    [kuchikomis]
  )

  /**
   * 返信する
   */
  const reply = useCallback(
    async (kuchikomiId: string, message: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .replyUpdate(kuchikomiId, { message: message })
        .then((res) => {
          const createReply = res.data.reply
          const nextKuhikomis = [...kuchikomis]
          const targetIdx = nextKuhikomis.findIndex(
            (item) => item.id === kuchikomiId
          )
          nextKuhikomis[targetIdx].replies.push(createReply)
          setKuchikomis(nextKuhikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomis]
  )

  const deleteKuchikomi = useCallback(
    async (kuchikomiId: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      await apiClient.kuchikomis
        .deleteCreate(kuchikomiId)
        .then(() => {
          const nextKuhikomis = kuchikomis.filter(
            (item) => item.id !== kuchikomiId
          )
          setKuchikomis(nextKuhikomis)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
        })
    },
    [kuchikomis]
  )

  return [
    kuchikomis,
    totalSize,
    apiCursor,
    isLoading,
    {
      load: load,
      like: like,
      bookmark: bookmark,
      reply: reply,
      delete: deleteKuchikomi,
    },
  ]
}

export default useKuchikomis
