import React, { FC } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { MainInputTag } from './main_input_tag'

const BASEINPUTWIDTH = 296

export const MainTextarea: FC<{
  required: boolean
  label: string
  description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseFormRegisterReturn<any>
  rows?: number
  value?: string
  readOnly?: boolean
  error?: string
}> = (props) => {
  return (
    <div className="w-full py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <MainInputTag required={props.required} />
      </div>
      <textarea
        readOnly={props.readOnly}
        className="rounded-lg px-2 py-1 outline-none resize-none border border-main-lightGray w-full"
        {...props.options}
        rows={props.rows ?? 5}
      >
        {props.value}
      </textarea>
      {props.description && (
        <p className="text-main-lightGray text-sm py-2">{props.description}</p>
      )}
      {props.error && (
        <p className="text-main-red text-sm mb-2">{props.error}</p>
      )}
    </div>
  )
}
