import React, { FC } from 'react'
import FAQ from '../../../model/FAQ'
import { ArrowIcon } from '../../../images/icons/arrow_icon'
import { useToggle } from 'react-use'

type FaqBlockProps = {
  category: string
  faqs: Array<FAQ>
}

const FaqBlock: FC<FaqBlockProps> = (props) => {
  const [isOpen, toggle] = useToggle(false)

  return (
    <div className="mb-8">
      <div
        className="w-full bg-main-blue rounded-lg flex justify-between px-4 py-4 cursor-pointer"
        onClick={() => toggle()}
      >
        <p className="text-white text-xl">{props.category}</p>
        <div className="flex flex-col justify-center">
          <div
            className="duration-200"
            style={isOpen ? { rotate: '-180deg' } : { rotate: '0deg' }}
          >
            <ArrowIcon color="white" />
          </div>
        </div>
      </div>
      <div
        className="duration-200 overflow-hidden sm:px-6 px-2 mt-3"
        style={
          isOpen
            ? { height: 'auto', paddingTop: '16px', paddingBottom: '16px' }
            : { height: '0px', paddingTop: '0px', paddingBottom: '0px' }
        }
      >
        {props.faqs.map((item, index) => {
          return (
            <FaqItem
              key={`faq-item-${String(index)}`}
              index={index}
              {...item}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FaqBlock

const FaqItem: FC<FAQ & { key: string; index: number }> = (props) => {
  const [isOpen, toggle] = useToggle(false)

  return (
    <div key={props.key} className="rounded-lg bg-main-gray p-4 mb-6">
      <div
        className="flex justify-between flex-nowrap cursor-pointer"
        onClick={toggle}
      >
        <div className="flex justify-start">
          <div className="flex flex-col justify-center">
            <p className="sm:text-4xl text-2xl pr-4">Q</p>
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-bold">
              {String(props.index + 1).padStart(2, '0')}.{props.question}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div
            className="duration-200"
            style={isOpen ? { rotate: '-180deg' } : { rotate: '0deg' }}
          >
            <ArrowIcon color="gray" />
          </div>
        </div>
      </div>
      <div
        className="flex justify-start overflow-hidden duration-200"
        style={
          isOpen
            ? { height: 'auto', paddingTop: '16px', paddingBottom: '16px' }
            : { height: '0px', paddingTop: '0px', paddingBottom: '0px' }
        }
      >
        <div className="flex flex-col justify-center">
          <p className="sm:text-4xl text-2xl pr-4">A</p>
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-sm">{props.answer}</p>
        </div>
      </div>
    </div>
  )
}
