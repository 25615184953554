import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
type SelectProps = {
  id: string
  placeholder: string
  items: Array<SelectItems>
  currentValue: string
  currentGroup: string
  brankLabel?: string
  onSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  additionalClass?: string
}
type SelectItems = {
  value: string
  label: string
}

const Select: FC<SelectProps> = (props) => {
  return (
    <div className="select__container">
      <select
        name=""
        id={`ranking-category-select-${props.id}`}
        className={`rounded-lg max-w-[100%] xs:max-w-[250px] ${props.additionalClass}`}
        value={props.id === props.currentGroup ? props.currentValue : ''}
        onChange={props.onSelect}
      >
        <option value="" disabled selected className="hidden">
          {props.placeholder}
        </option>
        {props.brankLabel && <option value="">{props.brankLabel}</option>}
        {props.items.map((item, index) => {
          return (
            <option
              key={`select-category-${props.id}-${String(index)}`}
              value={item.value}
            >
              {item.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Select
