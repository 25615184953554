import React, { FC, useState, ChangeEvent } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { UseFormRegisterReturn } from 'react-hook-form'
import { RenderKuchikomiText } from './render_kuchikomi_text'

interface KuchikomiReplyInputTextareaProps {
  register: UseFormRegisterReturn
  value: string
  placeholder?: string
}

export const KuchikomiReplyInputTextarea: FC<
  KuchikomiReplyInputTextareaProps
> = ({ register, placeholder = 'コメントを追加...', value }) => {
  const [text, setText] = useState<string>(value)

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
    register.onChange(event) // react-hook-formのonChangeをトリガー
  }

  return (
    <div className="w-full relative">
      <div className="absolute top-0 left-0 w-full h-[100%] border-b-2 border-main-lightGray outline-none">
        <RenderKuchikomiText inputText={text} isCreatePage={true} />
      </div>
      <TextareaAutosize
        {...register}
        className="relative w-full h-40 rounded-lg z-50 outline-none"
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
        style={{
          color: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
          caretColor: 'black',
        }}
      />
    </div>
  )
}
