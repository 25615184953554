import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'

export type ServerityType = 'success' | 'info' | 'warning' | 'error'

type SnackBarContextProps = {
  isOpen: boolean
  messages: string[]
  serverity?: ServerityType
  showSnackBar: (messages: string[], serverity: ServerityType) => void
  hideSnackBar: () => void
}

type SnackBarProviderProps = {
  children: ReactNode
}

const SnackBarContext = createContext<SnackBarContextProps>(
  {} as SnackBarContextProps
)

export const SnackBarProvider: FC<SnackBarProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const [serverity, setServerity] = useState<ServerityType>('success')

  const showSnackBar = (messages: string[], serverity: ServerityType) => {
    setIsOpen(true)
    setMessages(messages)
    setServerity(serverity)
  }

  const hideSnackBar = () => setIsOpen(false)

  const backgroundColor = (serverity: ServerityType) => {
    switch (serverity) {
      case 'error':
        return 'var(--color-main-red)'
      case 'warning':
        return '#ff9800'
      case 'info':
        return 'var(--color-main-blue)'
      case 'success':
        return 'var(--color-main-blue)'
    }
  }

  return (
    <SnackBarContext.Provider
      value={{
        isOpen,
        messages,
        serverity,
        showSnackBar,
        hideSnackBar,
      }}
    >
      <Snackbar
        open={isOpen}
        autoHideDuration={4000}
        onClose={hideSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ maxWidth: '90vw', width: '800px' }}
      >
        <Alert
          onClose={hideSnackBar}
          severity={serverity}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: backgroundColor(serverity),
            color: '#fff',
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideSnackBar}
              sx={{
                padding: '0 16px 4px 0',
                color: '#fff',
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          iconMapping={{
            error: <ErrorIcon style={{ color: '#fff' }} />,
            warning: <ErrorIcon style={{ color: '#fff' }} />,
            info: <CheckIcon style={{ color: '#fff' }} />,
            success: <CheckIcon style={{ color: '#fff' }} />,
          }}
        >
          {messages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </Alert>
      </Snackbar>

      {children}
    </SnackBarContext.Provider>
  )
}

export const useSnackBar = () => useContext(SnackBarContext)
