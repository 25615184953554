import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import FlatButton from '../../../../components/buttons/flat_button'
import { Link, useNavigate } from 'react-router-dom'
import { FormErrorMessage } from '../../../../constants/message'
import { useAuth } from '../../../../hooks/use_auth'
import { useLoading } from '../../../../providers/loading_provider'
import { useSnackBar } from '../../../../providers/snack_bar_provider'

type LoginFormProps = {
  email: string
  password: string
}

// TODO SNSログインを追加する
const LoginForm: FC = () => {
  const { emailSignIn } = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  const { register, handleSubmit, formState } = useForm<LoginFormProps>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
  })

  const { errors } = formState

  const emailLogin = async (data: LoginFormProps) => {
    showLoading()
    const isLogined = await emailSignIn(data.email, data.password)
      .then((res) => {
        return res
      })
      .finally(() => {
        hideLoading()
      })

    if (isLogined) {
      showSnackBar(['ログインしました。'], 'success')
      navigate('/')
    }
  }

  return (
    <div>
      <form
        action="submit"
        className="py-4"
        onSubmit={handleSubmit(emailLogin)}
      >
        <p className="text-main-black font-bold mb-4">
          メールアドレスでログイン
        </p>
        <div className="mb-3">
          <label htmlFor="your-email" className="block text-xs mb-1">
            メールアドレス
          </label>
          <input
            id="your-email"
            type="email"
            className="w-full py-1 px-2 border border-gray-500 rounded-lg outline-none"
            placeholder="メールアドレスを入力してください"
            {...register('email', {
              required: FormErrorMessage.required,
              maxLength: {
                value: 100,
                message: FormErrorMessage.maxLength(100),
              },
            })}
          />
          {errors.email && (
            <p className="error-message">{errors.email.message}</p>
          )}
        </div>
        <div className="mb-6">
          <label htmlFor="your-password" className="block text-xs mb-1">
            パスワード
          </label>
          <input
            id="your-password"
            type="password"
            className="w-full py-1 px-2 border border-gray-500 rounded-lg outline-none"
            placeholder="パスワードを入力してください"
            {...register('password', {
              required: FormErrorMessage.required,
              maxLength: {
                value: 100,
                message: FormErrorMessage.maxLength(100),
              },
            })}
          />
          {errors.password && (
            <p className="error-message">{errors.password.message}</p>
          )}
        </div>

        <FlatButton size="md" type="submit">
          ログイン
        </FlatButton>
      </form>
      <div className="flex justify-center mb-2">
        <div className="w-fit">
          <Link to="/password/reset/request">
            <p className="text-center text-main-blue text-xs underline">
              パスワードをお忘れの方はこちら
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
