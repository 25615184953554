import { useMedia } from 'react-use'

const mediaQueries = {
  sp: '(max-width: 639px)',
  pc: '(min-width: 640px)',
}

export const useSpBreakPoint = () => {
  const isSp = useMedia(mediaQueries.sp)

  return isSp
}
