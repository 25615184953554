import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { UserRanking } from '../../../model/Ranking'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const DEFAULT_LIMIT = 100

type UseUserRankingProps = {
  getLimit?: number
}

type Handler = {
  load: () => Promise<void>
}

const useUserRanking = (
  props: UseUserRankingProps
): [Array<UserRanking>, boolean, Handler] => {
  const limit = props.getLimit || DEFAULT_LIMIT
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [ranking, setRanking] = useState<Array<UserRanking>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.ranking
      .userList({
        cursor: apiCursor,
        limit: limit,
      })
      .then((res) => {
        const data = res.data.data
        setRanking(data)
        setApiCursor(apiCursor + limit)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, ranking])

  useEffect(() => {
    load()
  }, [])

  return [ranking, isLoading, { load: load }]
}

export default useUserRanking
