import React, { FC } from 'react'
import UserPolicyContent from '../policy/content/user_policy_content'
import PrivacyPolicyContent from '../policy/content/privacy_policy_content'
import PRUserPolicyContent from '../policy/content/pr_user_policy_content'
import { Link } from 'react-router-dom'

const RegisterPolicyForm: FC = () => {
  const content = (
    <div className="pt-12 pb-6 ">
      {/* クチコミ利用規約 */}
      <div className="mb-8">
        <p className="font-bold">SDGs クチコミ Lodge 利用規約</p>
        <p className="mb-1">
          <Link
            target="_blank"
            to="/policy/user"
            className="text-main-blue underline text-sm mb-1"
          >
            新規タブを開いて利用規約を確認する
          </Link>
        </p>
        <div className="w-full h-64 border overflow-y-auto p-2">
          <UserPolicyContent />
        </div>
      </div>
      {/* プライバシーポリシー */}
      <div className="mb-8">
        <p className="font-bold">SDGs PR Lodge 利用規約</p>
        <a
          target="_blank"
          href="https://sdgs-pr-lodge.jp/policy/user"
          className="text-main-blue underline text-sm mb-1"
          rel="noreferrer"
        >
          新規タブを開いて利用規約を確認する
        </a>
        <div className="w-full h-64 border overflow-y-auto p-2">
          <PRUserPolicyContent />
        </div>
      </div>
      {/* プライバシーポリシー */}
      <div className="mb-8">
        <p className="font-bold">プライバシーポリシー</p>
        <p className="mb-1">
          <Link
            target="_blank"
            to="/policy/privacy"
            className="text-main-blue underline text-sm mb-1"
          >
            新規タブを開いて利用規約を確認する
          </Link>
        </p>
        <div className="w-full h-64 border overflow-y-auto p-2">
          <PrivacyPolicyContent />
        </div>
      </div>
    </div>
  )

  return <div>{content}</div>
}

export default RegisterPolicyForm
