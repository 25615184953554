import React, { FC, useEffect } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import Head2 from '../../components/heads/head2'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import SearchTab from './components/tab/search_tab'
import useKuchikomiSearchForm from './hooks/use_kuchikomi_search_form'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import { useToggle } from 'react-use'
import KuchikomiSearchForm from './components/form/kuchikomi_search_form'
import CommonTemplate from '../../components/templates/common_template'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from '../../components/loading/loading_spinner'

// TODO 他検索ページといい感じに共通化したい
const KuchikomiSearchPage: FC = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [
    kuchikomis,
    size,
    currentCursor,
    isLoading,
    isSearched,
    kuchikomiSearchHandler,
    kuchikomiHandler,
  ] = useKuchikomiSearchForm({ getLimit: PAGE_SIZE })
  const [formShow, toggle] = useToggle(true)
  const [queryParam] = useSearchParams()

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (kuchikomis.length === 0) {
      toggle(true)
    }
  }, [kuchikomis])

  useEffect(() => {
    const searchParams = {
      keyword: '',
      sdgsCategory: '',
      corporateCategory: '',
      industry: '',
      region: '',
    }
    let isQuery = false
    const querySearchKeyMap = [
      {
        queryParamKey: 'keyword',
        searchParamKey: 'keyword',
      },
      {
        queryParamKey: 'sdgs-category',
        searchParamKey: 'sdgsCategory',
      },
      {
        queryParamKey: 'corporate-category',
        searchParamKey: 'corporateCategory',
      },
      {
        queryParamKey: 'industry',
        searchParamKey: 'industry',
      },
      {
        queryParamKey: 'region',
        searchParamKey: 'region',
      },
    ] as const
    querySearchKeyMap.forEach((item) => {
      if (queryParam.has(item.queryParamKey)) {
        const val = queryParam.get(item.queryParamKey) as string
        kuchikomiSearchHandler.setValue(item.searchParamKey, val)
        searchParams[item.searchParamKey] = val
        isQuery = true
      }
    })

    if (queryParam.has('search')) {
      isQuery = true
    }

    if (isQuery) {
      kuchikomiSearchHandler.submit(searchParams)
    }
  }, [])

  const searchResultElem = (
    <div>
      <div className="mb-4 px-4">
        <Head2
          title="クチコミ一覧"
          subTitle={
            kuchikomis.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + kuchikomis.length
                }件`
              : ''
          }
        />
      </div>
      <div>
        {kuchikomis.length > 0 ? (
          <div className="bg-white rounded-2xl overflow-hidden">
            {kuchikomis.map((item, index) => {
              return (
                <KuchikomiCard
                  key={`kuchikomi-${String(index)}`}
                  kuchikomi={item}
                  borderBottom={index !== kuchikomis.length - 1}
                  isSp={isSp}
                  sendLike={kuchikomiHandler.like}
                  sendReply={kuchikomiHandler.reply}
                  onClickBookmark={kuchikomiHandler.bookmark}
                  deleteKuchikomi={kuchikomiHandler.delete}
                />
              )
            })}
          </div>
        ) : (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索結果がありません
            </p>
          </div>
        )}
      </div>
    </div>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          kuchikomiSearchHandler.submit(
            kuchikomiSearchHandler.getValues(),
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection()
          kuchikomiSearchHandler.submit(
            kuchikomiSearchHandler.getValues(),
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          kuchikomiSearchHandler.submit(
            kuchikomiSearchHandler.getValues(),
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  const searchTab = (
    <SearchTab
      isSp={isSp}
      activeIndex={1}
      isFormShow={formShow}
      toggleContent={toggle}
      form={
        <KuchikomiSearchForm
          keywordOptions={kuchikomiSearchHandler.register('keyword')}
          sdgsCategoryOptions={kuchikomiSearchHandler.register('sdgsCategory')}
          corporateCategoryOptions={kuchikomiSearchHandler.register(
            'corporateCategory'
          )}
          industryOptions={kuchikomiSearchHandler.register('industry')}
          regionOptions={kuchikomiSearchHandler.register('region')}
          onSubmit={kuchikomiSearchHandler.handleSubmit((data) =>
            kuchikomiSearchHandler.submit(data)
          )}
          toggleForm={toggle}
        />
      }
    />
  )

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      <div>
        <div className="mt-4 mb-8">{searchTab}</div>
        {isLoading && <LoadingSpinner className="mx-auto" />}

        {isSearched && !isLoading && (
          <>
            <div ref={IndexSectionRef} />
            <div className="mb-8">{searchResultElem}</div>
            <div className="pb-8">{paginationCursor}</div>
          </>
        )}
        {!isSearched && (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索条件を入力してください
            </p>
          </div>
        )}
      </div>
    </CommonTemplate>
  )
  return content
}

export default KuchikomiSearchPage
