import React, { FC } from 'react'
import User from '../../../../model/User'
import {
  MemberRankIcon,
  getMemberRankColor,
} from '../../../../images/icons/member_rank_icon'
import FlatButton from '../../../../components/buttons/flat_button'
import { CrownIcon } from '../../../../images/icons/crown_icon'
import { getKiloString } from '../../../../utils/numeric_util'
import { FacebookIcon } from '../../../../images/icons/facebook_icon'
import { WebIcon } from '../../../../images/icons/web_icon'
import { XIcon } from '../../../../images/icons/x_icon'
import { InstagramIcon } from '../../../../images/icons/instagram_icon'
import { TikTokIcon } from '../../../../images/icons/tiktok_icon'
import { GivedPointIcon } from '../../../../images/icons/gived_point_icon'
import { Link } from 'react-router-dom'
import { RankIcon } from '../../../../images/icons/rank_icon'
import { useAuth } from '../../../../hooks/use_auth'

type UserDetailCardProps = {
  rank: number
  user: User
  onFollow: (
    id: string,
    set: boolean,
    type: 'enterprise' | 'user'
  ) => Promise<void>
}

const UserDetailCard: FC<UserDetailCardProps> = (props) => {
  const { user } = useAuth()

  return (
    <Link to={`/users/${props.user.id}`}>
      <div className="relative flex sm:justify-between justify-around flex-wrap w-full bg-white rounded-xl px-2 xs:px-4 py-4">
        <div className="relative w-[48px] xs:w-[90px] sm:w-[156px] md:w-[196px]">
          <div
            className="w-[48px] h-[48px] xs:w-[90px] xs:h-[90px] sm:w-[120px] sm:h-[120px] md:w-[160px] md:h-[160px] rounded-full bg-cover bg-center mx-auto"
            style={{
              backgroundImage: `url(${props.user.iconUrl})`,
            }}
          />
        </div>
        <div className="absolute left-[2px] -top-1 xs:-top-2 sm:-left-2 z-0">
          <RankIcon rank={props.rank} />
        </div>
        <div className="w-[calc(100%-48px)] xs:w-[calc(100%-90px)] sm:w-[calc(100%-156px)] md:w-[calc(100%-196px)] pl-2 xs:pl-4">
          <div className="flex justify-between pb-2">
            <div className="md:flex justify-start">
              <div className="flex flex-col justify-center md:pb-0 pb-2 mr-4">
                <p className="text-lg xs:text-xl font-bold">
                  {props.user.displayName}
                </p>
              </div>
              <div className="flex flex-col justify-center sm;:pl-2  md:pb-0 pb-2">
                <div className="flex justify-start">
                  <MemberRankIcon rank={props.user.memberRank} />
                  <p
                    className="pl-1 text-sm"
                    style={{
                      color: getMemberRankColor(props.user.memberRank),
                    }}
                  >
                    {props.user.memberRank?.value} Lank
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                {user.isLogined() && user.isKuchikomiUser() && (
                  <FlatButton
                    size="sm"
                    className="min-w-[90px] sm:min-w-[120px]"
                    bgColor={props.user.yourFollower ? 'lightGray' : 'blue'}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault()

                      props.onFollow(
                        props.user.id,
                        !props.user.yourFollower,
                        'user'
                      )
                    }}
                  >
                    <p className="sm:text-sm text-xs sm:px-2 px-1 leading-4">
                      {props.user.yourFollower ? 'フォロー中' : 'フォローする'}
                    </p>
                  </FlatButton>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-start flex-wrap pb-2">
            <div className="flex justify-start pr-4 flex-wrap pb-1">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  週間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.weeklyRank ?? '-'}位
              </p>
            </div>
            <div className="flex justify-start pr-4 flex-wrap pb-1">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  年間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.yearlyRank ?? '-'}位
              </p>
            </div>
            <div className="flex justify-start pr-4 flex-wrap">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <CrownIcon />
                </div>
                <p className="text-sm text-main-darkGray md:pl-2">
                  全期間ランキング
                </p>
              </div>
              <p className="text-sm text-main-darkGray md:pl-2">
                {props.user.wholePeriodRank ?? '-'}位
              </p>
            </div>
          </div>
          <div className="flex justify-start flex-wrap">
            <div className="flex justify-start pr-4">
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.followCount
                  ? getKiloString(props.user.followCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end pb-[4px] pl-[2px]">
                <p className="text-sm text-main-darkGray">フォロー</p>
              </div>
            </div>
            <div className="flex justify-start pr-4">
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.followerCount
                  ? getKiloString(props.user.followerCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end pb-[4px] pl-[2px]">
                <p className="text-sm text-main-darkGray">フォロワー</p>
              </div>
            </div>
            <div className="flex justify-start pr-4">
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.kuchikomiCount
                  ? getKiloString(props.user.kuchikomiCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end pb-[4px] pl-[2px]">
                <p className="text-sm text-main-darkGray">クチコミ数</p>
              </div>
            </div>
            <div className="flex justify-start pr-4">
              <p className="text-lg text-main-darkGray font-bold">
                {props.user.kuchikomiEnterpriseCount
                  ? getKiloString(props.user.kuchikomiEnterpriseCount)
                  : '0'}
              </p>
              <div className="flex flex-col justify-end pb-[4px] pl-[2px]">
                <p className="text-sm text-main-darkGray">
                  クチコミした企業・団体
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-start pt-4">
            {props.user.homepageUrl && (
              <a
                href={props.user.homepageUrl}
                className="[&_path]:fill-main-black px-1"
              >
                <WebIcon />
              </a>
            )}
            {props.user.facebookUrl && (
              <a
                href={props.user.facebookUrl}
                className="[&_path]:fill-main-black px-1"
              >
                <FacebookIcon />
              </a>
            )}
            {props.user.twitterUrl && (
              <a
                href={props.user.twitterUrl}
                className="[&_path]:fill-main-black px-1"
              >
                <XIcon />
              </a>
            )}
            {props.user.instagramUrl && (
              <a
                href={props.user.instagramUrl}
                className="[&_path]:fill-main-black px-1"
              >
                <InstagramIcon />
              </a>
            )}
            {props.user.tiktokUrl && (
              <a
                href={props.user.tiktokUrl}
                className="[&_path]:fill-main-black px-1"
              >
                <TikTokIcon />
              </a>
            )}
          </div>
          <div className="sm:pt-4 pt-0">
            <div className="flex justify-start">
              <div className="flex flex-col justify-center w-6">
                <GivedPointIcon />
              </div>
              <p className="text-xs text-main-blue whitespace-nowrap">
                ポイント贈呈数：
                {props.user.givedPointCount
                  ? props.user.givedPointCount.toLocaleString()
                  : 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default UserDetailCard
