import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const RatingPage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp} isShowPostButton={false}>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
        レイティングについて
      </p>
      <div className="bg-white rounded-xl w-full lg:px-20 lg:py-12 sm:px-12 sm:py-8 p-4">
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          SDGs クチコミ Lodgeの評価点数システム
        </p>
        <p>
          SDGs クチコミ
          Lodgeでは、・団体がどれだけSDGsに取り組んでいるかを可視化するための評価システムの開発を進めております。
        </p>
        <p>
          当システムは、企業・団体の持続可能な実践を評価し、公正かつ信頼性の高い情報を提供することで、ユーザーが企業・団体のSDGsへの取り組み度合いを容易に理解できることを目的としております。
        </p>
        <p>
          進行中の開発では、ユーザーからの貴重なフィードバックを取り入れ、評価基準の最適化に努めていきます。
        </p>
        <p className="mb-16">
          完成に向けて、進捗状況を共有し、継続的に改善を行う予定です。皆様の理解とご協力をお願い申し上げます。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          評価点数の算出方法
        </p>
        <p>
          ユーザーからの投稿：
          企業・団体のSDGsに関する取り組みについてのクチコミや評価が基本データとなります。
        </p>
        <p className="mb-16">
          専門家のレビュー：
          SDGs分野の専門家によるレビューを取り入れ、評価の精度を向上させます。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          評価点の算出要素
        </p>
        <p>
          企業・団体のSDGsへの取り組み度合い：
          企業・団体がどの程度SDGsの目標に沿った活動をしているかを評価します。
        </p>
        <p>
          透明性と説明責任：
          企業・団体がどれだけ情報を透明に開示しているか、またその説明責任をどのように果たしているかを評価します。
        </p>
        <p className="mb-16">
          影響度の測定：
          企業・団体の活動が具体的にどのような社会的、環境的影響をもたらしているかを評価します。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          ユーザー影響度の設定
        </p>
        <p>
          繰り返し投稿者への評価重視：
          SDGsへの継続的な投稿を行うユーザーの影響度を高く設定し、より経験豊かな意見が評価に反映されるようにします。
        </p>
        <p className="mb-16">
          不正対策：
          ステルスマーケティングや偽装投稿を防ぐため、不正が疑われるユーザーの評価は除外します。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          評価の更新
        </p>
        <p>
          定期的な見直し：
          SDGsに関する新しい情報や、ユーザーからの新たな評価が反映されるように、評価は定期的に更新されます。これにより、最新かつ正確な情報が提供され続けます。
        </p>
        <p>
          SDGs クチコミ
          Lodgeは、・団体がどのように持続可能な未来に貢献しているかを明らかにし、ユーザーが情報に基づいた意思決定を支援するための信頼できるサービスを提供します。
        </p>
      </div>
    </CommonTemplate>
  )
  return content
}

export default RatingPage
