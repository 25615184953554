import React, { FC, useEffect, useState } from 'react'
import BaseTemplate from '../../components/templates/base_template'
import Head2 from '../../components/heads/head2'
import { FormErrorMessage } from '../../constants/message'
import useCodes from '../../hooks/use_codes'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CheckInputContainer,
  ReadonlyInput,
  SelectInputContainer,
  SimpleDateInput,
  SimpleInput,
  SimpleTextarea,
  StackInput,
  Tag,
} from '../../components/form/form_item'
import { useForm } from 'react-hook-form'
import { useLoading } from '../../providers/loading_provider'
import { useUpload } from '../../hooks/use_upload'
import FlatButton from '../../components/buttons/flat_button'
import LoadingSpinner from '../../components/loading/loading_spinner'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { useModal } from 'react-hooks-use-modal'
import RegisterPolicyForm from './register_policy_form'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import WhiteModalContent from '../../components/modal/white_modal_content'

type EnterpriseRegisterFormPorps = {
  token: string
  iconUrl: string
  name: string
  description: string
  delegateLastName: string
  delegateFirstName: string
  delegatePosition: string
  responsiblePersonName: string
  establishDate: string
  postCode: string
  address: string
  tel: string
  stockCode: string
  capitalPrice: number | null
  employeeNum: number | null
  industryCode: string
  interestingSDGs: Array<string>
  enterpriseCode: string
  businessCode1: string
  businessCode2: string
  businessCode3: string
  businessChildCode1: string
  businessChildCode2: string
  businessChildCode3: string
  statusCode: string
  officialUrl: string
  twitterUrl: string
  facebookUrl: string
  lineUrl: string
  tiktokUrl: string
  publicDisplayFlag: boolean
  email: string
  password: string
  passwordConfirmation: string
  agree: boolean
}

const RegisterEnterprisePage: FC = () => {
  const navigate = useNavigate()
  const { upload } = useUpload()
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()
  const [codes] = useCodes()
  const [loading, setLoading] = useState(true)
  const pathParams = useParams<{ token: string }>()
  const apiClient = getAppClient()
  const [ConfirmModal, openConfirmModal, closeConfirmModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const isSp = useSpBreakPoint()

  const { register, handleSubmit, watch, getValues, setValue, formState } =
    useForm<EnterpriseRegisterFormPorps>({
      defaultValues: {
        token: '',
        iconUrl: `${process.env.REACT_APP_PR_BASE_URL}/assets/kuchikomi/img/default_enterprise_profile.png`,
        name: '',
        description: '',
        delegateLastName: '',
        delegateFirstName: '',
        delegatePosition: '',
        responsiblePersonName: '',
        establishDate: '',
        postCode: '',
        address: '',
        tel: '',
        stockCode: '',
        capitalPrice: null,
        employeeNum: null,
        industryCode: '',
        interestingSDGs: [],
        enterpriseCode: '',
        businessCode1: '',
        businessChildCode1: '',
        businessCode2: '',
        businessChildCode2: '',
        businessCode3: '',
        businessChildCode3: '',
        statusCode: '',
        officialUrl: '',
        twitterUrl: '',
        facebookUrl: '',
        lineUrl: '',
        tiktokUrl: '',
        publicDisplayFlag: false,
        email: '',
        password: '',
        passwordConfirmation: '',
        agree: false,
      },
      mode: 'onSubmit',
    })

  useEffect(() => {
    if (pathParams.token) {
      checkToken(pathParams.token)
    }
  }, [pathParams.token])

  const { errors } = formState

  const registerEnterprise = async (data: EnterpriseRegisterFormPorps) => {
    if (data.password !== data.passwordConfirmation) {
      showSnackBar(['パスワードと確認用パスワードが一致しません'], 'error')
      return
    }

    showLoading()

    const responseData = await apiClient.enterprises
      .registerCreate({
        token: data.token,
        name: data.name,
        description: data.description,
        delegateLastName: data.delegateLastName,
        delegateFirstName: data.delegateFirstName,
        delegatePosition: data.delegatePosition,
        responsiblePersonName: data.responsiblePersonName,
        establishDate: data.establishDate,
        postCode: data.postCode,
        address: data.address,
        tel: data.tel,
        stockCode: data.statusCode,
        capitalPrice: data.capitalPrice,
        employeeNum: data.employeeNum,
        industryCode: data.industryCode,
        enterpriseCode: data.enterpriseCode,
        businessCode: [
          data.businessCode1,
          data.businessCode2,
          data.businessCode3,
        ].filter((code) => code !== ''),
        businessChildCode: [
          data.businessChildCode1,
          data.businessChildCode2,
          data.businessChildCode3,
        ].filter((code) => code !== ''),
        sdgsCategory: data.interestingSDGs,
        logoUrl: data.iconUrl,
        officialUrl: data.officialUrl,
        twitterUrl: data.twitterUrl,
        facebookUrl: data.facebookUrl,
        lineUrl: data.lineUrl,
        tiktokUrl: data.tiktokUrl,
        publicDisplayFlag: data.publicDisplayFlag,
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        const data = res.data
        return data
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        hideLoading()
      })

    if (responseData === null) {
      return
    }

    navigate('/register/enterprise/complete')
  }

  // トークンのチェック
  const checkToken = async (token: string | undefined) => {
    if (!token) return
    await apiClient.enterprises
      .registerTokenCheckCreate({
        token: token,
      })
      .then((response) => {
        setValue('token', token)
        setValue('email', response.data.email)
        setLoading(false)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        navigate('/login')
      })
  }

  // 企業カテゴリーの子カテゴリーを取得
  const getChildBusinessCategories = (parentCode: string) => {
    const targetChildCodeStrs = codes.businessCodeRelations[parentCode] ?? []
    return codes.businessChildCodes.filter((child) =>
      targetChildCodeStrs.includes(child.code)
    )
  }

  // アイコンをアップロード
  const uploadIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files?.length === 0) return

    showLoading()
    const nextFile = files[0]
    const url = await upload(nextFile)

    if (url !== null) {
      setValue('iconUrl', url)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showConfirmModal = async (data: EnterpriseRegisterFormPorps) => {
    openConfirmModal()
  }

  const enterpriseForm = (
    <div className="max-w-[640px] w-full bg-white rounded-xl md:px-4 px-2 py-12">
      <form action="submit">
        {/* ロゴ */}
        <div className="mb-4">
          <div className="flex justify-start mb-2">
            <div className="rounded-full w-fit">
              <div
                className="w-24 sm:w-32 h-24 sm:h-32 bg-cover rounded-full bg-center"
                style={{
                  backgroundImage: `url(${watch('iconUrl')})`,
                }}
              ></div>
            </div>
            <div className="flex flex-col justify-center ml-4">
              <label
                htmlFor="image-upload"
                className="text-main-lightGray p-2 border border-main-lightGray rounded-lg cursor-pointer"
              >
                画像をアップロード
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={uploadIcon}
              />
            </div>
          </div>
          <div className="text-sm">※データ形式はjpg、pngのみ対応</div>
        </div>

        <ReadonlyInput required label="メールアドレス" value={watch('email')} />

        <SimpleInput
          required
          label="企業・団体名"
          description="企業・団体名をご入力ください。"
          options={register('name', {
            required: FormErrorMessage.required,
            maxLength: {
              value: 50,
              message: FormErrorMessage.maxLength(50),
            },
          })}
        />
        {errors.name && <p className="error-message">{errors.name.message}</p>}

        <SimpleTextarea
          required={false}
          label="企業・団体説明"
          description="企業・団体説明をご入力ください。"
          options={register('description', {
            maxLength: {
              value: 200,
              message: FormErrorMessage.maxLength(200),
            },
          })}
        />
        {errors.description && (
          <p className="error-message">{errors.description.message}</p>
        )}

        <StackInput
          required
          label1="代表者名前（姓）"
          label2="代表者名前（名）"
          description="代表者氏名をご入力ください。"
          options1={register('delegateLastName', {
            required: FormErrorMessage.required,
            maxLength: {
              value: 50,
              message: FormErrorMessage.maxLength(50),
            },
          })}
          options2={register('delegateFirstName', {
            required: FormErrorMessage.required,
            maxLength: {
              value: 50,
              message: FormErrorMessage.maxLength(50),
            },
          })}
        />
        {errors.delegateLastName && (
          <p className="error-message">{errors.delegateLastName.message}</p>
        )}
        {errors.delegateFirstName && (
          <p className="error-message">{errors.delegateFirstName.message}</p>
        )}

        <SimpleInput
          required
          label="代表者役職"
          description="役職をご入力ください。"
          options={register('delegatePosition', {
            required: FormErrorMessage.required,
            maxLength: {
              value: 50,
              message: FormErrorMessage.maxLength(50),
            },
          })}
        />
        {errors.delegatePosition && (
          <p className="error-message">{errors.delegatePosition.message}</p>
        )}

        <SimpleInput
          required
          label="担当者名"
          description="担当者名をご入力ください。"
          options={register('responsiblePersonName', {
            required: FormErrorMessage.required,
            maxLength: {
              value: 50,
              message: FormErrorMessage.maxLength(50),
            },
          })}
        />
        {errors.responsiblePersonName && (
          <p className="error-message">
            {errors.responsiblePersonName.message}
          </p>
        )}

        <SimpleDateInput
          required
          label="会社設立日"
          description="会社設立日をご入力ください。"
          options={register('establishDate', {
            required: FormErrorMessage.required,
          })}
        />
        {errors.establishDate && (
          <p className="error-message">{errors.establishDate.message}</p>
        )}

        <SimpleInput
          required
          label="本社所在地（郵便番号）"
          description="郵便番号をご入力ください。"
          options={register('postCode', {
            required: FormErrorMessage.required,
            minLength: {
              value: 7,
              message: FormErrorMessage.minLength(7),
            },
            maxLength: {
              value: 7,
              message: FormErrorMessage.maxLength(7),
            },
          })}
        />
        {errors.postCode && (
          <p className="error-message">{errors.postCode.message}</p>
        )}

        <SimpleInput
          required
          label="本社所在地（住所）"
          description="住所をご入力ください。"
          options={register('address', {
            required: FormErrorMessage.required,
          })}
          width="100%"
        />
        {errors.address && (
          <p className="error-message">{errors.address.message}</p>
        )}

        <SimpleInput
          required
          label="代表電話番号"
          description="代表電話番号をご入力ください。"
          options={register('tel', {
            required: FormErrorMessage.required,
            minLength: {
              value: 11,
              message: FormErrorMessage.minLength(11),
            },
            maxLength: {
              value: 15,
              message: FormErrorMessage.maxLength(15),
            },
          })}
        />
        {errors.tel && <p className="error-message">{errors.tel.message}</p>}

        <SimpleInput
          required={false}
          label="証券コード"
          description="証券コードをご入力ください。"
          options={register('stockCode')}
        />
        {errors.stockCode && (
          <p className="error-message">{errors.stockCode.message}</p>
        )}

        <SimpleInput
          required={false}
          label="資本金"
          description="資本金をご入力ください。"
          unitStr="円"
          type="number"
          options={register('capitalPrice')}
        />
        {errors.capitalPrice && (
          <p className="error-message">{errors.capitalPrice.message}</p>
        )}

        <SimpleInput
          required={false}
          type="number"
          label="社員数"
          description="社員数をご入力ください。"
          unitStr="人"
          options={register('employeeNum')}
        />
        {errors.employeeNum && (
          <p className="error-message">{errors.employeeNum.message}</p>
        )}

        <SimpleInput
          required={false}
          label="公式サイトURL"
          description="公式サイトURLをご入力ください。"
          options={register('officialUrl')}
          width="100%"
        />
        {errors.officialUrl && (
          <p className="error-message">{errors.officialUrl.message}</p>
        )}

        <SimpleInput
          required={false}
          label="X（旧Twitter）URL"
          description="X（旧Twitter）URLをご入力ください。"
          options={register('twitterUrl')}
          width="100%"
        />
        {errors.twitterUrl && (
          <p className="error-message">{errors.twitterUrl.message}</p>
        )}

        <SimpleInput
          required={false}
          label="Facebook URL"
          description="Facebook URLをご入力ください。"
          options={register('facebookUrl')}
          width="100%"
        />
        {errors.facebookUrl && (
          <p className="error-message">{errors.facebookUrl.message}</p>
        )}

        <SimpleInput
          required={false}
          label="公式LINE URL"
          description="公式LINE URLをご入力ください。"
          options={register('lineUrl')}
          width="100%"
        />
        {errors.lineUrl && (
          <p className="error-message">{errors.lineUrl.message}</p>
        )}

        <SimpleInput
          required={false}
          label="TikTok URL"
          description="TikTok URLをご入力ください。"
          options={register('tiktokUrl')}
          width="100%"
        />
        {errors.tiktokUrl && (
          <p className="error-message">{errors.tiktokUrl.message}</p>
        )}

        <CheckInputContainer
          title="業種カテゴリー"
          keyName="industry"
          type="radio"
          required
          values={[
            {
              options: register('industryCode', {
                required: FormErrorMessage.required,
              }),
              checks: codes.industryCodes.map((item) => {
                return {
                  code: item.code,
                  label: item.value,
                }
              }),
            },
          ]}
        />
        {errors.industryCode && (
          <p className="error-message">{errors.industryCode.message}</p>
        )}

        <CheckInputContainer
          title="企業・団体カテゴリー"
          keyName="company"
          type="radio"
          required
          values={[
            {
              options: register('enterpriseCode', {
                required: FormErrorMessage.required,
              }),
              checks: codes.enterpriseCodes.map((item) => {
                return {
                  code: item.code,
                  label: item.value,
                }
              }),
            },
          ]}
        />
        {errors.enterpriseCode && (
          <p className="error-message">{errors.enterpriseCode.message}</p>
        )}

        <div className="flex justify-start">
          <p className="w-[9rem] whitespace-nowrap font-bold">
            ビジネスカテゴリー
          </p>
          <Tag required />
        </div>
        <div className="mb-3">
          <div className="flex justify-between flex-wrap">
            <SelectInputContainer
              key="businessCode1"
              className=""
              required
              brankLabel="選択してください"
              values={[
                {
                  options: register('businessCode1', {
                    required: FormErrorMessage.required,
                  }),
                  checks: codes.businessParentCodes.map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
            <SelectInputContainer
              key="businessChildCode1"
              className=""
              brankLabel="選択してください"
              required
              values={[
                {
                  options: register('businessChildCode1', {
                    required:
                      watch('businessCode1') !== ''
                        ? FormErrorMessage.required
                        : undefined,
                  }),
                  checks: getChildBusinessCategories(
                    watch('businessCode1')
                  ).map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
          </div>
          <div className="flex justify-between flex-wrap">
            <SelectInputContainer
              key="businessCode2"
              required
              brankLabel="選択してください"
              className=""
              values={[
                {
                  options: register('businessCode2'),
                  checks: codes.businessParentCodes.map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
            <SelectInputContainer
              key="businessChildCode2"
              brankLabel="選択してください"
              required
              className=""
              values={[
                {
                  options: register('businessChildCode2', {
                    required:
                      watch('businessCode2') !== ''
                        ? FormErrorMessage.required
                        : undefined,
                  }),
                  checks: getChildBusinessCategories(
                    watch('businessCode2')
                  ).map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
          </div>
          <div className="flex justify-between flex-wrap">
            <SelectInputContainer
              key="businessCode3"
              required
              brankLabel="選択してください"
              values={[
                {
                  options: register('businessCode3'),
                  checks: codes.businessParentCodes.map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
            <SelectInputContainer
              key="businessChildCode3"
              brankLabel="選択してください"
              required
              values={[
                {
                  options: register('businessChildCode3', {
                    required:
                      watch('businessCode3') !== ''
                        ? FormErrorMessage.required
                        : undefined,
                  }),
                  checks: getChildBusinessCategories(
                    watch('businessCode3')
                  ).map((item) => {
                    return {
                      code: item.code,
                      label: item.value,
                    }
                  }),
                },
              ]}
            />
          </div>
        </div>
        {errors.businessCode1 && (
          <p className="error-message">{errors.businessCode1.message}</p>
        )}
        {errors.businessCode2 && (
          <p className="error-message">{errors.businessCode2.message}</p>
        )}
        {errors.businessCode3 && (
          <p className="error-message">{errors.businessCode3.message}</p>
        )}

        <CheckInputContainer
          title="SDGsカテゴリー"
          titleWidth="14rem"
          keyName="sdgs"
          type="checkbox"
          required
          optionPosition="block"
          values={[
            {
              options: register('interestingSDGs', {
                required: FormErrorMessage.required,
              }),
              checks: codes.sdgsCategories.map((item) => {
                return {
                  code: item.code,
                  label: item.code + '. ' + item.value,
                }
              }),
            },
          ]}
        />
        {errors.interestingSDGs && (
          <p className="error-message">{errors.interestingSDGs.message}</p>
        )}

        <SimpleInput
          required
          label="パスワード"
          type="password"
          description="パスワードをご入力ください。"
          options={register('password', {
            required: FormErrorMessage.required,
            minLength: {
              value: 8,
              message: FormErrorMessage.minLength(8),
            },
          })}
        />
        {errors.password && (
          <p className="error-message">{errors.password.message}</p>
        )}

        <SimpleInput
          required
          label="確認用パスワード"
          type="password"
          description="確認用パスワードをご入力ください。"
          options={register('passwordConfirmation', {
            required: FormErrorMessage.required,
            minLength: {
              value: 8,
              message: FormErrorMessage.minLength(8),
            },
          })}
        />
        {errors.passwordConfirmation && (
          <p className="error-message">{errors.passwordConfirmation.message}</p>
        )}

        <RegisterPolicyForm />

        <div className="mb-12">
          <label className="flex items-start justify-center">
            <input
              type="checkbox"
              className="mt-[10px] mr-[4px]"
              {...register('agree', {
                required: '必ず同意してください',
              })}
            />
            <span className="pl-1">
              上記の利用規約、プライバシーポリシーについて同意する
            </span>
          </label>
          {errors.agree && (
            <p className="error-message text-center">{errors.agree.message}</p>
          )}
        </div>

        <div className="flex justify-center mt-8">
          <FlatButton
            size="md"
            bgColor="blue"
            type="submit"
            onClick={handleSubmit(showConfirmModal)}
          >
            確認する
          </FlatButton>
        </div>
      </form>
    </div>
  )

  const confirmContent = (
    <WhiteModalContent additionalClass="py-8 px-8 w-[640px]">
      <p className="mb-4">以下の内容で会員登録します。</p>
      <div className="mb-8">
        <div className="mb-4">
          <div className="text-main-lightGray">プロフィール画像</div>
          <div>
            <div
              className="w-16 h-16 bg-cover rounded-full bg-center"
              style={{
                backgroundImage: `url(${getValues('iconUrl')})`,
              }}
            ></div>
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">メールアドレス</div>
          <div>{getValues('email')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">企業・団体名</div>
          <div>{getValues('name')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">企業・団体説明</div>
          <div>{getValues('description')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">代表者名前</div>
          <div>
            {getValues('delegateLastName')} {getValues('delegateFirstName')}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">代表者役職</div>
          <div>{getValues('delegatePosition')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">担当者名</div>
          <div>{getValues('responsiblePersonName')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">会社設立日</div>
          <div>{getValues('establishDate').replaceAll('-', '/')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">本社所在地（郵便番号）</div>
          <div>{getValues('postCode')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">本社所在地（住所）</div>
          <div>{getValues('address')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">代表電話番号</div>
          <div>{getValues('tel')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">証券コード</div>
          <div>{getValues('stockCode')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">資本金</div>
          <div>
            {getValues('capitalPrice') !== null
              ? getValues('capitalPrice')?.toLocaleString() + '円'
              : ''}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">社員数</div>
          <div>
            {getValues('employeeNum') !== null
              ? getValues('employeeNum')?.toLocaleString() + '円'
              : ''}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">公式サイトURL</div>
          <div>{getValues('officialUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">X（旧Twitter）URL</div>
          <div>{getValues('twitterUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">Facebook URL</div>
          <div>{getValues('facebookUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">公式LINE URL</div>
          <div>{getValues('lineUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">TikTok URL</div>
          <div>{getValues('tiktokUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">業種カテゴリー</div>
          <div>
            {
              codes.industryCodes.find(
                (item) => item.code === getValues('industryCode')
              )?.value
            }
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">企業・団体カテゴリー</div>
          <div>
            {
              codes.enterpriseCodes.find(
                (item) => item.code === getValues('enterpriseCode')
              )?.value
            }
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">ビジネスカテゴリー</div>
          <div>
            {getValues('businessCode1') && (
              <div className="w-full flex flex-wrap mb-3">
                <p className="w-32 pr-2">
                  {
                    codes.businessParentCodes.find(
                      (item) => item.code === getValues('businessCode1')
                    )?.value
                  }
                </p>
                <p className="">
                  {
                    codes.businessChildCodes.find(
                      (item) => item.code === getValues('businessChildCode1')
                    )?.value
                  }
                </p>
              </div>
            )}
            {getValues('businessCode2') && (
              <div className="w-full flex flex-wrap mb-3">
                <p className="w-32 pr-2">
                  {
                    codes.businessParentCodes.find(
                      (item) => item.code === getValues('businessCode2')
                    )?.value
                  }
                </p>
                <p className="w-32">
                  {
                    codes.businessChildCodes.find(
                      (item) => item.code === getValues('businessChildCode2')
                    )?.value
                  }
                </p>
              </div>
            )}
            {getValues('businessCode3') && (
              <div className="w-full flex flex-wrap mb-3">
                <p className="w-32 pr-2">
                  {
                    codes.businessParentCodes.find(
                      (item) => item.code === getValues('businessCode3')
                    )?.value
                  }
                </p>
                <p className="w-32">
                  {
                    codes.businessChildCodes.find(
                      (item) => item.code === getValues('businessChildCode3')
                    )?.value
                  }
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">SDGsカテゴリー</div>
          <div>
            {getValues('interestingSDGs').map((item) => {
              const sdgsCategory = codes.sdgsCategories.find(
                (sdgs) => sdgs.code === item
              )

              return sdgsCategory ? <p>{sdgsCategory.value}</p> : <></>
            })}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">パスワード</div>
          <div>{getValues('password').replace(/./g, '*')}</div>
        </div>
      </div>
      <div className="sm:flex justify-center mt-8">
        <FlatButton
          size="md"
          bgColor="blue"
          type="submit"
          className="mx-auto sm:mx-3 mb-4"
          onClick={handleSubmit(registerEnterprise)}
        >
          会員登録する
        </FlatButton>

        <FlatButton
          size="md"
          bgColor="lightGray"
          onClick={closeConfirmModal}
          className="mx-auto sm:mx-3 mb-4"
        >
          閉じる
        </FlatButton>
      </div>
    </WhiteModalContent>
  )

  const content = (
    <CommonTemplate isSp={isSp}>
      <div className="flex justify-center">
        <div>
          <div className="mb-8">
            <Head2 title="新規会員登録" />
          </div>
          {loading ? <LoadingSpinner className="mx-auto" /> : enterpriseForm}
        </div>
        <ConfirmModal>{confirmContent}</ConfirmModal>
      </div>
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default RegisterEnterprisePage
