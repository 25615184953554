import React from 'react'

export const HeartGiftIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="100" viewBox="0 0 112.263 107.712">
		<g id="グループ_1595" data-name="グループ 1595" transform="translate(-363.598 -840.672)">
			<path id="パス_973" data-name="パス 973" d="M169.871,86.18a114.624,114.624,0,0,0,19.182,10.08,14.065,14.065,0,0,0,3.027.6,14.2,14.2,0,0,0,3.034-.6A114.921,114.921,0,0,0,214.3,86.18c12.936-8.818,33.916-26.131,33.916-50.526,0-24.754-14-35.969-29.4-35.648-11.745.218-18.686,7.156-23.341,14a3.766,3.766,0,0,1-6.771,0c-4.662-6.842-11.6-13.779-23.341-14C149.95-.315,135.948,10.9,135.948,35.654,135.948,60.049,156.935,77.363,169.871,86.18Z" transform="translate(227.65 840.672)" fill="#2791EB"/>
			<g id="グループ_1594" data-name="グループ 1594" transform="translate(430.252 906.235)">
			<circle id="楕円形_43" data-name="楕円形 43" cx="21.075" cy="21.075" r="21.075" fill="#fff"/>
			<path id="FontAwsome_arrow-alt-circle-right_" data-name="FontAwsome (arrow-alt-circle-right)" d="M22.33,8A14.33,14.33,0,1,1,8,22.33,14.327,14.327,0,0,1,22.33,8Zm-6.7,16.872h6.7v4.1a.694.694,0,0,0,1.185.491l6.6-6.639a.687.687,0,0,0,0-.977l-6.6-6.645a.693.693,0,0,0-1.185.491v4.1h-6.7a.7.7,0,0,0-.693.693v3.7A.7.7,0,0,0,15.627,24.872Z" transform="translate(-0.989 -0.989)" fill="#2791EB"/>
			</g>
		</g>
	  </svg>
    </div>
  )
}
