import React, { FC } from 'react'
import LogoWhite from '../../images/logo_white.png'
import FooterBg from '../../images/footer_bg.png'
import { Link } from 'react-router-dom'
import { XIcon } from '../../images/icons/x_icon'
import { useAuth } from '../../hooks/use_auth'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { useNavigate } from 'react-router-dom'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import { LicenseType } from '../../enums/LicenseType'

type LinkItemProps = {
  to: string
  text: string
  isBlank?: boolean
  onClick?: () => void
}
const LinkText: FC<LinkItemProps> = (props) => {
  return (
    <div className="mb-2">
      <Link
        onClick={props.onClick}
        to={props.to}
        target={props.isBlank === true ? '_blank' : '_self'}
      >
        <p className="text-white leading-6">{props.text}</p>
      </Link>
    </div>
  )
}

const MainFooter: React.FC = () => {
  const { user, signOut } = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()
  const isSp = useSpBreakPoint()
  const navigate = useNavigate()

  const logout = async () => {
    showLoading()

    // 1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 1000))

    signOut()
    hideLoading()
    navigate('/')
    showSnackBar(['ログアウトしました'], 'success')
  }

  return (
    <div
      className="w-full bg-cover bg-no-repeat bg-center opacity-100 pb-16 sm:pb-0"
      style={{ backgroundImage: `url(${FooterBg})` }}
    >
      <div className="w-11/12 max-w-6xl py-12 mx-auto text-white">
        <div className="">
          <Link to="/">
            <div className="w-fit mx-auto">
              <img src={LogoWhite} width={isSp ? '142px' : '192px'}></img>
            </div>
          </Link>
          <p className="text-white font-bold text-center">
            クチコミでつくる、やさしい世界
          </p>
          <div className="flex justify-center py-4">
            <div className="mr-2">
              {/* <Link to="#">
                <FacebookIcon />
              </Link> */}
            </div>
            <div className="mr-2">
              <Link to="https://x.com/SDGsKuchikom" target="_blank">
                <XIcon />
              </Link>
            </div>
            {/* <div className="mr-2">
              <Link to="#">
                <InstagramIcon />
              </Link>
            </div>
            <div className="mr-2">
              <Link to="#">
                <TikTokIcon />
              </Link>
            </div> */}
          </div>
        </div>

        <div className="pt-8 mb-16 md:flex md:justify-start">
          <div className="mr-6 md:w-1/3">
            <LinkText to="/" text="TOP画面" />
            <LinkText to="/search/enterprise" text="詳細検索" />
            {user.isLogined() && user.licenseType === LicenseType.NORMAL && (
              <LinkText to="/mypage" text="マイページ" />
            )}
            <LinkText
              to="/ranking/enterprise"
              text="企業・団体ランキング一覧"
            />
            <LinkText to="/ranking/user" text="ユーザーランキング一覧" />
            <LinkText to="/ranking/kuchikomi" text="クチコミランキング一覧" />
          </div>
          <div className="mr-6 md:w-1/3">
            <LinkText to="/informations" text="お知らせ" />
            <LinkText to="/guide/user" text="ユーザーの方へ" />
            <LinkText to="/guide/enterprise" text="企業・団体の方へ" />
            <LinkText to="/faq" text="FAQ" />
            <LinkText to="/guide/like/point" text="いいねポイントについて" />
            <LinkText to="/guide/rating" text="レイティングについて" />
            <LinkText to="/guide/plan/price" text="料金プランについて" />
          </div>
          <div className="mr-6 md:w-1/3">
            <LinkText to="/contact" text="お問合せ" />
            <LinkText
              to="https://the-lodges.jp/"
              text="運営会社"
              isBlank={true}
            />
            <LinkText to="/policy/user" text="利用規約" />
            <LinkText to="/policy/privacy" text="プライバシーポリシー" />
            <LinkText to="/policy/law" text="特定商取引法に基づく表記" />
            {user.isLogined() && (
              <LinkText to="" onClick={logout} text="ログアウト" />
            )}
          </div>
        </div>
      </div>
      <div className="w-full sm:w-11/12 mx-auto">
        <div className="py-6 border-t border-white">
          <div className="w-11/12 sm:w-full max-w-5xl text-white mx-auto">
            <p className="font-bold text-lg mb-4 sm:mb-2">
              クチコミでつくる、やさしい世界
            </p>
            <p className="text-sm leading-loose">
              SDGs クチコミ
              Lodgeは、世界中の企業・団体が取り組むSDGs活動をクチコミを通じて応援し合うプラットフォームです。一つ一つのクチコミが、世界をやさしく変える力になり、人類が繁栄を続けるために避けて通れない難題に対して、皆でスクラムを組み、One
              Teamになることを目指しております。
            </p>
          </div>
        </div>
        <div className="py-4 border-t border-white">
          <p className="text-white text-center text-xs">
            Copyright © 2023 The Lodges. Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MainFooter
