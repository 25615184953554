import React, { FC } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { MainInputTag } from './main_input_tag'

export const MainTextInput: FC<{
  required: boolean
  label: string
  description?: string
  unitStr?: string
  type?: 'text' | 'email' | 'password'
  error?: string
  readOnly?: boolean
  value?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseFormRegisterReturn<any>
  inputWidth?: number
}> = (props) => {
  return (
    <div className="w-full py-2">
      <div className="flex justify-start">
        <label htmlFor="" className="w-[9rem] whitespace-nowrap font-bold">
          {props.label}
        </label>
        <MainInputTag required={props.required} />
      </div>
      <div className="flex justify-center">
        <input
          type={props.type ?? 'text'}
          className="rounded-lg px-2 py-1 outline-none border border-main-lightGray"
          style={{ width: props.inputWidth ?? '100%' }}
          readOnly={props.readOnly}
          value={props.value}
          {...props.options}
        />
        {props.unitStr ? (
          <div className="flex flex-col justify-center">
            <p className="pl-1 whitespace-nowrap">{props.unitStr}</p>
          </div>
        ) : null}
      </div>
      {props.description && (
        <p className="text-main-lightGray text-sm py-2">{props.description}</p>
      )}
      {props.error && (
        <p className="text-main-red text-sm mb-2">{props.error}</p>
      )}
    </div>
  )
}
