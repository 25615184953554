import React, { useEffect } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import useCodes from '../../hooks/use_codes'
import FilterButton from '../../components/buttons/filter_button'
import { RankingKuchikomiStat, RankingPeriod } from '../../clients/api_client'
import { RankingKuchikomiStatCode, RankingPeriodCode } from '../../model/Code'
import LoadingSpinner from '../../components/loading/loading_spinner'
import useMyKuchikomiRanking from './hooks/use_my_kuchikomi_ranking'

const MyPageKuchikomiRanking = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [codes] = useCodes()
  const [user, userLoading] = useUser()
  const [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    size,
    currentCursor,
    rankingLoading,
    rankingHandler,
  ] = useMyKuchikomiRanking({
    period: RankingPeriod.All,
    stat: RankingKuchikomiStat.GoodCount,
    getLimit: PAGE_SIZE,
  })

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (user) {
      rankingHandler.search(
        currentPeriod,
        currentStat,
        currentGroup,
        currentGroupValue
      )
    }
  }, [user])

  const kuchikomiCount =
    ranking.length > 0
      ? `全件${String(size)}中 ${currentCursor + 1}~${
          currentCursor + ranking.length
        }件`
      : ''

  const filterButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingPeriodCodes.map((period: RankingPeriodCode) => {
        return (
          <div key={`ranking-period-${period.code}`} className="pr-2 pb-2">
            <FilterButton
              label={period.label}
              value={period.code}
              isActive={period.code === currentPeriod.toString()}
              onClick={() =>
                rankingHandler.search(
                  period.code,
                  currentStat,
                  currentGroup,
                  currentGroupValue
                )
              }
            />
          </div>
        )
      })}
    </div>
  )

  const selectFilterButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingKuchikomiStatCodes.map((stat: RankingKuchikomiStatCode) => {
        return (
          <div key={`ranking-stat-${stat.code}`} className="pr-2 pb-2">
            <FilterButton
              label={stat.label}
              value={stat.code}
              isActive={stat.code === currentStat.toString()}
              onClick={() =>
                rankingHandler.search(
                  currentPeriod,
                  stat.code,
                  currentGroup,
                  currentGroupValue
                )
              }
            />
          </div>
        )
      })}
    </div>
  )

  const kuchikomiElems = (
    <>
      <div className="bg-white rounded-xl overflow-hidden max-w-[720px]">
        {ranking.map((item, index, ary) => {
          return (
            <div key={`ranking-${String(index)}`}>
              <KuchikomiCard
                kuchikomi={item.kuchikomi}
                sendLike={rankingHandler.like}
                sendReply={rankingHandler.reply}
                onClickBookmark={rankingHandler.bookmark}
                borderBottom={index !== ary.length - 1}
                deleteKuchikomi={rankingHandler.delete}
              />
            </div>
          )
        })}
      </div>
      {ranking.length === 0 && (
        <div className="text-center py-4 text-gray-400">
          クチコミがありません
        </div>
      )}
    </>
  )

  const paginationCursor =
    ranking.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          rankingHandler.search(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="あなたのクチコミランキング"
      user={user}
      isSp={isSp}
      subHead={kuchikomiCount}
    >
      <div className="">{filterButtons}</div>
      <div className="pb-6" ref={IndexSectionRef}>
        {selectFilterButtons}
      </div>
      {rankingLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{kuchikomiElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageKuchikomiRanking
