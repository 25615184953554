export const getKiloString = (num: number) => {
  return num / 1000 > 1 ? String(Math.floor(num / 1000)) + 'K' : String(num)
}

export const stringConversion = (num: number) => {
  const digit = String(num).length
  if (digit > 3) {
    return num / 1000 > 1 ? String(Math.floor(num / 1000)) + 'K' : String(num)
  } else if (digit > 6) {
    return num / 1000000 > 1
      ? String(Math.floor(num / 1000000)) + 'M'
      : String(num)
  }
  return String(num)
}
