import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ArrowIcon } from '../../../../images/icons/arrow_icon'

type SearchTabProps = {
  isSp: boolean
  activeIndex: number
  form: ReactNode
  isFormShow: boolean
  toggleContent: () => void
}

// 検索対象ごとにコンポーネントを分けるようにしているので、タブは見かけだけ用意する
const SearchTab: FC<SearchTabProps> = (props) => {
  const menus = [
    {
      label: `企業・団体${props.isSp ? '' : 'を検索する'}`,
      path: 'enterprise',
    },
    {
      label: `クチコミ${props.isSp ? '' : 'を検索する'}`,
      path: 'kuchikomi',
    },
    {
      label: `ユーザー${props.isSp ? '' : 'を検索する'}`,
      path: 'user',
    },
  ]

  const menuBtns = menus.map((item, index) => {
    const styles = [
      'md:px-6',
      'px-2',
      'py-2',
      'sm:py-1',
      'cursor-pointer',
      'md:text-base',
      'text-sm',
    ]
    if (index === props.activeIndex) {
      styles.push('bg-main-blue')
    } else {
      styles.push('bg-main-lightGray')
    }
    if (index === 0) {
      styles.push('sm:rounded-tl-lg')
    } else if (index === menus.length - 1) {
      styles.push('sm:rounded-tr-lg')
    } else {
      styles.push('border-r border-r-white border-l border-l-white')
    }

    return (
      <Link
        key={`search-menu-${item.path}`}
        to={`/search/${item.path}`}
        className="w-1/3 sm:w-auto"
      >
        <div
          key={`tab-menu-${String(index)}`}
          className={styles.join(' ')}
          style={{ transitionDuration: '300' }}
        >
          <p className="text-center text-white sm:min-w-[8rem]">{item.label}</p>
        </div>
      </Link>
    )
  })

  return (
    <div className="w-full">
      <div className="flex justify-start">{menuBtns}</div>
      <div className="w-full rounded-bl-xl rounded-br-xl sm:rounded-tr-xl bg-white py-6 px-4">
        <div
          className="overflow-hidden"
          style={props.isFormShow ? { height: 'auto' } : { height: 0 }}
        >
          {props.form}
        </div>
        <div
          className="flex justify-between overflow-hidden cursor-pointer"
          style={props.isFormShow ? { height: 0 } : { height: 'auto' }}
          onClick={props.toggleContent}
        >
          <p className="font-bold">検索したい項目を選択してください。</p>
          <div className="flex flex-col justify-center">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchTab
