import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import InfoTableCell from './components/info_table_cell'
import TableCell from './components/table_cell'

const PlanPricePage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp} isShowPostButton={false}>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
        料金プランについて
      </p>
      <div className="bg-white rounded-xl w-full lg:px-20 lg:py-12 sm:px-12 sm:py-8 p-4">
        <p className="mb-6">
          SDGs クチコミ Lodgeをご利用頂くと、SDGs PR Lodgeもご利用頂けます。
        </p>
        <p className="mb-6">
          SDGs PR Lodge（SDGs 専門のプレスリリースサイト）とSDGs クチコミ
          Lodgeを共用するための主要な特徴は以下の通りです：
        </p>
        <p>両方のプラットフォームアクセス</p>
        <p>マルチチャネルコミュニケーション</p>
        <p>ユーザー体験の向上</p>
        <p>リアルタイムのインサイト</p>
        <p className="mb-6">参加とエンゲージメントの促進</p>
        <p className="sm:mb-16 mb-8">
          これらの機能を利用することで、企業はSDGsに関する取り組みをより幅広いオーディエンスにアピールし、エンゲージメントと満足感を高めることができます。
        </p>
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center sm:mb-12 mb-6">
          料金表
        </p>
        <div className="overflow-x-auto mt-8 sm:mb-16 mb-8">
          <table className="bg-white w-[576px] sm:w-[768px] lg:w-full">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th className="lg:text-lg sm:text-base text-sm p-2 text-center text-white bg-main-blue rounded-tl-lg">
                  Free
                  <br />
                  0円/月
                </th>
                <th className="lg:text-lg sm:text-base text-sm p-2 text-center text-white bg-main-blue">
                  1ヶ月契約
                  <br />
                  8万円/月
                </th>
                <th className="lg:text-lg sm:text-base text-sm p-2 text-center text-white bg-main-blue">
                  6ヶ月契約
                  <br />
                  7.5万円/月
                </th>
                <th className="lg:text-lg sm:text-base text-sm p-2 text-center text-white bg-main-blue rounded-tr-lg">
                  12ヶ月契約
                  <br />
                  7万円/月
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="sm:text-2xl sm:p-3 p-1 bg-main-blue text-white text-center font-bold sm:w-16 w-6"
                  rowSpan={7}
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'upright',
                    letterSpacing: '0.6rem',
                  }}
                >
                  クチコミ
                </td>
                <InfoTableCell text="クチコミ閲覧" />
                <TableCell>-</TableCell>
                <TableCell>100回/月</TableCell>
                <TableCell>無制限</TableCell>
                <TableCell>無制限</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="クチコミ非表示権限" />
                <TableCell>-</TableCell>
                <TableCell>100回/月</TableCell>
                <TableCell>無制限</TableCell>
                <TableCell>無制限</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="クチコミコメント返信" />
                <TableCell>-</TableCell>
                <TableCell>100回/月</TableCell>
                <TableCell>無制限</TableCell>
                <TableCell>無制限</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="ユーザーへのポイント付与数" />
                <TableCell>10pt</TableCell>
                <TableCell>100pt/月</TableCell>
                <TableCell>500pt/月</TableCell>
                <TableCell>500pt/月</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="分布図" />
                <TableCell>-</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="ユーザー層" />
                <TableCell>-</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
              </tr>
              <tr>
                <InfoTableCell text="チケット掲載" />
                <TableCell>-</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
                <TableCell>○</TableCell>
              </tr>
              <tr>
                <td
                  className="sm:text-2xl sm:p-3 p-1 bg-main-green text-white font-bold text-center sm:w-16 w-6"
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'upright',
                  }}
                >
                  PR
                </td>
                <InfoTableCell
                  bgColor="bg-main-lightGreen"
                  text="プレスリリース投稿"
                />
                <TableCell bgColor="bg-main-lightGreen">1回3万円</TableCell>
                <TableCell bgColor="bg-main-lightGreen">無制限</TableCell>
                <TableCell bgColor="bg-main-lightGreen">無制限</TableCell>
                <TableCell bgColor="bg-main-lightGreen">無制限</TableCell>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center sm:mb-12 mb-6">
          オプション
        </p>
        <table className="min-w-full border-collapse overflow-hidden">
          <tbody>
            <tr>
              <td
                className="sm:text-2xl sm:p-3 p-1 bg-main-blue text-white font-bold text-center sm:w-16 w-6"
                style={{
                  writingMode: 'vertical-rl',
                  textOrientation: 'upright',
                }}
              >
                全般
              </td>
              <InfoTableCell
                bgColor="bg-main-lightPurple"
                text="表現力アップ/PR支援"
              />
              <TableCell
                textAlign="left"
                bgColor="bg-main-lightPurple"
                textSize="text-xs"
              >
                1万円/月（近日導入予定）
              </TableCell>
            </tr>
            <tr>
              <td className="sm:text-2xl text-xs px-1 bg-main-blue text-white font-bold text-center sm:w-16 w-6">
                クチコミ
              </td>
              <InfoTableCell text="SDGs取組み状況報告ブログ" />
              <TableCell textAlign="left" textSize="text-xs">
                検討中（近日導入予定）
              </TableCell>
            </tr>
          </tbody>
        </table> */}
      </div>
    </CommonTemplate>
  )
  return content
}

export default PlanPricePage
