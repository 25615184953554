import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
type SwitchProps = {
  toggleVal: boolean
  toggle: () => void
}

const Switch: FC<SwitchProps> = (props) => {
  return (
    <div className="w-full flex justify-center">
      <div
        className={`w-1/2 py-1 rounded-tl-lg rounded-bl-lg border-r border-r-white cursor-pointer ${
          props.toggleVal ? 'bg-main-blue' : 'bg-main-lightGray'
        }`}
        onClick={props.toggle}
      >
        <p className="text-center text-white">ログイン</p>
      </div>
      <div
        className={`w-1/2 py-1 rounded-tr-lg rounded-br-lg border-l border-l-white cursor-pointer ${
          props.toggleVal ? 'bg-main-lightGray' : 'bg-main-blue'
        }`}
        onClick={props.toggle}
      >
        <p className="text-center text-white">新規登録</p>
      </div>
    </div>
  )
}

export default Switch
