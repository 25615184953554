import React, { FC, ReactNode } from 'react'
import BaseTemplate from './base_template'
import PostButton from '../buttons/post_button'

type CommonTemplateProps = {
  children: ReactNode
  additonalTopNode?: ReactNode
  isPadding?: boolean
  isShowHeader?: boolean
  isSp?: boolean
  onClickPostButton?: () => void
  isShowPostButton?: boolean
}

const CommonTemplate: FC<CommonTemplateProps> = ({
  children,
  additonalTopNode,
  isPadding = true,
  isShowHeader = true,
  isSp = false,
  onClickPostButton,
  isShowPostButton = true,
}) => {
  const content = (
    <>
      <div className="w-11/12 max-w-6xl py-12 mx-auto">{children}</div>
      <div className="fixed bottom-8 right-12 w-fit z-10">
        {isShowPostButton && <PostButton onClick={onClickPostButton} />}
      </div>
    </>
  )
  const spContent = (
    <div className="w-full relative">
      <div className="fixed bottom-[74px] sm:top-[70vh] right-[10px] sm:right-[6%] w-fit z-10">
        {isShowPostButton && <PostButton onClick={onClickPostButton} />}
      </div>
      {children}
    </div>
  )

  return (
    <BaseTemplate
      isPadding={isPadding}
      isSp={isSp}
      additonalTopNode={additonalTopNode}
      isShowHeader={isShowHeader}
    >
      {isSp ? spContent : content}
    </BaseTemplate>
  )
}

export default CommonTemplate
