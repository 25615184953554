import React from 'react'

export const InfoIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="15.111" height="15.111" viewBox="0 0 15.111 15.111">
        <path id="FontAwsome_info-circle_" data-name="FontAwsome (info-circle)" d="M15.556,8a7.556,7.556,0,1,0,7.556,7.556A7.557,7.557,0,0,0,15.556,8Zm0,3.351a1.28,1.28,0,1,1-1.28,1.28A1.28,1.28,0,0,1,15.556,11.351Zm1.706,7.738a.366.366,0,0,1-.366.366H14.215a.366.366,0,0,1-.366-.366v-.731a.366.366,0,0,1,.366-.366h.366v-1.95h-.366a.366.366,0,0,1-.366-.366v-.731a.366.366,0,0,1,.366-.366h1.95a.366.366,0,0,1,.366.366v3.047H16.9a.366.366,0,0,1,.366.366Z" transform="translate(-8 -8)" fill="#c4c4c4"/>
      </svg>
    </div>
  )
}
