import React from 'react'

export const WebIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g
          id="グループ_1564"
          data-name="グループ 1564"
          transform="translate(-207 -353)"
        >
          <path
            id="楕円形_44"
            data-name="楕円形 44"
            d="M11,1A10,10,0,1,0,21,11,10.011,10.011,0,0,0,11,1m0-1A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
            transform="translate(207 353)"
            fill="#282828"
          />
          <path
            id="線_221"
            data-name="線 221"
            d="M.5,21h-1V0h1Z"
            transform="translate(218 353.5)"
            fill="#282828"
          />
          <path
            id="線_224"
            data-name="線 224"
            d="M21,.5H0v-1H21Z"
            transform="translate(207.5 364)"
            fill="#282828"
          />
          <path
            id="楕円形_45"
            data-name="楕円形 45"
            d="M6,1c-1.2,0-2.429.984-3.365,2.7A15.555,15.555,0,0,0,1,11a15.555,15.555,0,0,0,1.635,7.3C3.571,20.016,4.8,21,6,21s2.429-.984,3.365-2.7A15.555,15.555,0,0,0,11,11,15.555,15.555,0,0,0,9.365,3.7C8.429,1.984,7.2,1,6,1M6,0c3.314,0,6,4.925,6,11S9.314,22,6,22,0,17.075,0,11,2.686,0,6,0Z"
            transform="translate(212 353)"
            fill="#282828"
          />
          <path
            id="線_225"
            data-name="線 225"
            d="M18,.5H0v-1H18Z"
            transform="translate(209 358.5)"
            fill="#282828"
          />
          <path
            id="線_226"
            data-name="線 226"
            d="M18,.5H0v-1H18Z"
            transform="translate(209 369.5)"
            fill="#282828"
          />
        </g>
      </svg>
    </div>
  )
}
