import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/use_auth'

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth()
  const currentPath = useLocation().pathname
  const navigate = useNavigate()

  useEffect(() => {
    if (user.authStatus == 'loading') return
    if (user.authStatus === 'unauthenticated') {
      navigate('/login')
    }
  }, [currentPath, user])

  if (user.authStatus === 'authenticated') {
    return <>{children}</>
  } else if (user.authStatus === 'loading') {
    return <div></div>
  } else {
    return null
  }
}

export default AuthGuard
