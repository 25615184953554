import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import UserCard from './components/cards/user_card'
import useFollowers from './hooks/use_followers'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'

const MyPageFollower = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [userId, setUserId] = useState('')
  const [followers, size, currentCursor, loadingFollowers, followersHandler] =
    useFollowers()
  useEffect(() => {
    if (user) {
      setUserId(user.id)
      followersHandler.load(user?.id)
    }
  }, [user])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const followerElems = (
    <>
      <div className="rounded-xl overflow-hidden bg-white">
        {followers?.map((item, index) => {
          return (
            <div key={`follower-${String(index)}`}>
              <UserCard
                user={item}
                isFollower
                onFollow={followersHandler.follow}
              />
            </div>
          )
        })}
      </div>
      {followers.length === 0 && !userLoading && (
        <div className="text-center py-4 text-gray-400">
          フォローされているユーザーはいません
        </div>
      )}
    </>
  )

  const paginationCursor =
    followers.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          followersHandler.load(userId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          followersHandler.load(userId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          followersHandler.load(userId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="フォロワー"
      user={user}
      isSp={isSp}
      subHead={`${size.toLocaleString()}ユーザー`}
    >
      <div ref={IndexSectionRef} />
      {/* <InfiniteScroller
          load={async () => {
            if (user) {
              await followersHandler.load(user.id)
            }
          }}
          loading={isLoading}
          hasMore={hasMore}
        >
          <div>{followerElems}</div>
        </InfiniteScroller> */}
      {loadingFollowers || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{followerElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageFollower
