import React, { FC } from 'react'
import { useAuth } from '../../hooks/use_auth'
import FlatButton from '../buttons/flat_button'
import { useForm } from 'react-hook-form'
import { FormErrorMessage } from '../../constants/message'
import { KuchikomiReplyInputTextarea } from './kuchikomi_reply_input_textarea'

type KuchikomiReplyFormProps = {
  message: string
}

const KuchikomiReplyForm: FC<{
  close: () => void
  initialMessage?: string
  sendReply: (replyMessage: string) => void
}> = ({ close, sendReply, initialMessage }) => {
  const { user } = useAuth()
  if (!user.isLogined()) {
    return <></>
  }

  const { register, handleSubmit, reset } = useForm<KuchikomiReplyFormProps>({
    defaultValues: {
      message: initialMessage,
    },
    mode: 'onSubmit',
  })

  const send = (data: KuchikomiReplyFormProps) => {
    sendReply(data.message)
    reset()
    close()
  }

  return (
    <div className="w-full">
      <div className="flex items-center sm:pl-4">
        <div
          className="w-8 h-8 min-w-[2rem] rounded-full bg-cover bg-center mr-2"
          style={{ backgroundImage: `url(${user.iconUrl})` }}
        />
        <KuchikomiReplyInputTextarea
          value={initialMessage ?? ''}
          register={register('message', {
            required: FormErrorMessage.required,
          })}
        />
      </div>
      <div className="flex justify-end w-full py-4">
        <FlatButton
          size="sm"
          className="!rounded-full !bg-main-lightGray !mx-0"
          onClick={close}
        >
          キャンセル
        </FlatButton>
        <FlatButton
          size="sm"
          className="!rounded-full !ml-2 !mr-0"
          onClick={handleSubmit(send)}
        >
          送信
        </FlatButton>
      </div>
    </div>
  )
}

export default KuchikomiReplyForm
