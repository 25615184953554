import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import User from '../../model/User'
import { RankIcon } from '../../images/icons/rank_icon'
import { FilledFavoriteIcon } from '../../images/icons/favorite_icon'
import { FollowerIcon } from '../../images/icons/follower_icon'

type UserCardProps = {
  showRank?: boolean
  flex?: boolean
  user: User
  rank?: number
  imgSize: string
  className?: string
}

const UserCard: FC<UserCardProps> = ({
  showRank = false,
  flex = false,
  ...props
}) => {
  return (
    <Link to={`/users/${props.user.id}`}>
      <div
        className={`bg-white px-2 sm:px-4 relative rounded-xl ${
          flex
            ? 'w-[300px] flex justify-between py-4'
            : 'sm:w-[200px] w-[150px] sm:h-[330px] h-[300px] flex flex-col justify-between pt-4 sm:pt-6 pb-1'
        } ${props.className ?? ''}`}
      >
        {showRank ? (
          <div className="flex flex-col justify-center absolute -top-2 -left-2">
            <RankIcon rank={props.rank || props.user.wholePeriodRank || 0} />
          </div>
        ) : null}
        <div className={`flex justify-center mb-2`}>
          <div
            className={`rounded-full bg-cover bg-center mx-auto ${props.imgSize}`}
            style={{
              backgroundImage: `url(${props.user.iconUrl})`,
            }}
          />
        </div>
        <div className="flex-grow">
          <div
            className={`h-full flex flex-col justify-between ${
              flex ? 'pl-2' : ''
            }`}
          >
            <div>
              <p
                className={`font-bold text-main-black break-words whitespace-pre-wrap ${
                  flex ? '' : 'text-center'
                }`}
              >
                {`${props.user.displayName}`}
              </p>
            </div>
            <div className="flex-grow">
              <p
                className={`text-main-black break-words whitespace-pre-wrap text-sm ${
                  flex ? 'line-clamp-2 max-w-[135px]' : 'line-clamp-3'
                }`}
              >
                {props.user.catchCopy}
              </p>
            </div>
            <div className="flex justify-start">
              <div className="flex justify-start pr-4">
                <div className="flex flex-col justify-center pr-1">
                  <FilledFavoriteIcon size="md" />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-main-lightGray">
                    {props.user.givedPointCount}
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-col justify-center pr-1">
                  <FollowerIcon size="md" />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-main-lightGray">
                    {props.user.followerCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default UserCard
