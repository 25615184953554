import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import { HeartGiftIcon } from '../../images/icons/heart_gift_icon'
import { HandshakeSupportIcon } from '../../images/icons/handshake_support_icon'
import { PointTicketIcon } from '../../images/icons/point_ticket_icon'

const LikePointPage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp} isShowPostButton={false}>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
        いいねポイントについて
      </p>
      <div className="bg-white rounded-xl w-full lg:px-20 lg:py-12 sm:px-12 sm:py-8 p-4">
        <p className="mb-6">
          SDGsクチコミLodgeでは、ユーザー同士がポイントを贈り合う仕組みを導入しています。この取り組みは、SDGsのテーマである「誰一人取り残さない」という理念を体現するものです。「いいねポイント」の狙いは、互いの努力を認め合い、励まし合い、さらにはインスピレーションを与え合うことにあります。
        </p>
        <p className="mb-16">
          また、他のSNSで時々見かける、ネガティブなコメントや行動を、ポジティブで建設的なものに変えようという試みも含んでおります。
        </p>
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center sm:mb-16 mb-4">
          いいねポイントの特徴
        </p>
        <div className="sm:flex justify-between sm:mb-16 mb-12">
          <div className="text-center sm:p-4 p-8 flex-1">
            <div className="flex justify-center mb-6">
              <HeartGiftIcon />
            </div>
            <p className="text-main-blue font-bold text-center mb-4">
              贈与を重視する
            </p>
            <p className="text-left">
              「贈与」を重視し、持つ者が他人にポイントを贈ることを奨励します。
            </p>
          </div>
          <div className="text-center sm:p-4 p-8 flex-1">
            <div className="flex justify-center mb-6">
              <HandshakeSupportIcon />
            </div>
            <p className="text-main-blue font-bold text-center mb-4">
              サポートする文化を作る
            </p>
            <p className="text-left">
              組織や個人に関係なく、互いにリスペクトし合い、サポートする文化を目指します。
            </p>
          </div>
          <div className="text-center sm:p-4 p-8 flex-1">
            <div className="flex justify-center mb-6">
              <PointTicketIcon />
            </div>
            <p className="text-main-blue font-bold text-center mb-4">
              ポイント交換
            </p>
            <p className="text-left">
              ポイントは商品・サービスのクーポンや体験会のチケットに交換可能になります。（近日公開予定）
            </p>
          </div>
        </div>
        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
          追加予定機能
        </p>
        <div className="mb-6">
          <h4 className="text-main-blue font-bold mb-2">
            1. ポイントを分け与えた総数でランキング
          </h4>
          <ul className="list-disc list-inside">
            <li>
              ユーザー同士のいいねポイントの贈呈はリアルタイムで行われます。
            </li>
            <li>投稿によるポイントは夜にまとめて付与します。</li>
          </ul>
        </div>
        <div className="mb-6">
          <h4 className="text-main-blue font-bold mb-2">
            2.「いいねポイント」でチケット交換
          </h4>
          <ul className="list-disc list-inside">
            <li>
              ポイントは商品・サービスのクーポンや体験会のチケットに交換可能になります。（近日公開予定）
            </li>
          </ul>
        </div>
        <div className="bg-main-gray p-4 rounded-lg">
          <p>原資：The Lodges</p>
          <p>初期登録時：1000 Pt</p>
          <p>ランキング：累計支援Pt</p>
          <p>有効期限：90日</p>
        </div>
      </div>
    </CommonTemplate>
  )
  return content
}

export default LikePointPage
