import { useCallback, useEffect, useState } from 'react'
import FAQ from '../../../model/FAQ'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const useFaq = (): [Array<{ faqs: Array<FAQ>; category: string }>, boolean] => {
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  const [loading, setLoading] = useState(false)
  const [faq, setFaq] = useState<Array<{ faqs: Array<FAQ>; category: string }>>(
    []
  )

  const load = useCallback(async () => {
    setLoading(true)
    await apiClient.faqs
      .faqsList()
      .then((res) => {
        const data = res.data
        const categories = new Set(data.map((item) => item.category))
        setFaq(
          Array.from(categories).map((category) => {
            return {
              category: category,
              faqs: data.filter((faq) => faq.category === category),
            }
          })
        )
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }, [faq])

  useEffect(() => {
    load()
  }, [])

  return [faq, loading]
}

export default useFaq
