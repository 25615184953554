import React, { FC } from 'react'
import { EmptyStarIcon, StarIcon } from '../../images/icons/star_icon'
import { UseFormRegisterReturn } from 'react-hook-form'

type StarEvaluationInputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputOptions: UseFormRegisterReturn<any>
  updateEvaluation: (evaluation: number) => void
  currentEvaluation: number
}

const StarEvaluationInput: FC<StarEvaluationInputProps> = ({
  currentEvaluation,
  updateEvaluation,
  inputOptions,
}) => {
  return (
    <>
      <div className="flex justify-center">
        {[1, 2, 3, 4, 5].map((val) => {
          return (
            <StarButton
              key={`star-${val}`}
              evaluation={val}
              currentEvaluation={currentEvaluation}
              onClick={() => updateEvaluation(val)}
            />
          )
        })}
      </div>
      <input className="hidden" type="number" {...inputOptions} />
    </>
  )
}

const StarButton: FC<{
  evaluation: number
  currentEvaluation: number
  onClick: () => void
}> = ({ evaluation, currentEvaluation, onClick }) => {
  return (
    <div onClick={onClick} className="px-[6px] cursor-pointer">
      <div className="scale-150">
        {currentEvaluation >= evaluation ? <StarIcon /> : <EmptyStarIcon />}
      </div>
    </div>
  )
}

export default StarEvaluationInput
