import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { KuchikomiRanking } from '../../../model/Ranking'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import {
  RankingKuchikomiStat,
  RankingPeriod,
} from '../../../clients/api_client'
import { useAuth } from '../../../hooks/use_auth'

type UseMyKuchikomiRankingProps = {
  period: RankingPeriod
  stat: RankingKuchikomiStat
  group?: string
  groupValue?: string
  getLimit?: number
}

type Handler = {
  search: (
    period: RankingPeriod,
    stat: RankingKuchikomiStat,
    group?: string,
    groupValue?: string,
    cursor?: number
  ) => Promise<void>
  like: (kuchikomiId: string, point: number) => Promise<void>
  bookmark: (kuchikomiId: string, set: boolean) => Promise<void>
  reply: (kuchikomiId: string, message: string) => Promise<void>
  delete: (kuchikomiId: string) => Promise<void>
}

const useMyKuchikomiRanking = ({
  getLimit = 20,
  ...props
}: UseMyKuchikomiRankingProps): [
  Array<KuchikomiRanking>,
  RankingPeriod,
  RankingKuchikomiStat,
  string | undefined,
  string | undefined,
  number,
  number,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()
  const [isLoading, setIsLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState<RankingPeriod>(
    props.period
  )
  const [currentStat, setCurrentStat] = useState<RankingKuchikomiStat>(
    props.stat
  )
  const [currentGroup, setCurrentGroup] = useState<string | undefined>(
    props.group
  )
  const [currentGroupValue, setCurrentGroupValue] = useState<
    string | undefined
  >(props.groupValue)

  const [ranking, setRanking] = useState<Array<KuchikomiRanking>>([])
  const { user } = useAuth()

  const search = useCallback(
    async (
      period: RankingPeriod,
      stat: RankingKuchikomiStat,
      group?: string,
      groupValue?: string,
      cursor = 0
    ) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)

      setCurrentPeriod(period)
      setCurrentStat(stat)
      setCurrentGroup(group)
      setCurrentGroupValue(groupValue)

      await apiClient.ranking
        .kuchikomiOwnList({
          period: period,
          stat: stat,
          group: group,
          groupValue: groupValue,
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setRanking(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, ranking]
  )

  const like = useCallback(
    async (kuchikomiId: string, point: number) => {
      if (!user.isLogined()) {
        return
      }

      setIsLoading(true)

      // 先に更新
      const nextRanking = [...ranking]
      const targetIdx = nextRanking.findIndex(
        (item) => item.kuchikomi.id === kuchikomiId
      )
      const beforeLiked = nextRanking[targetIdx].kuchikomi.liked
      nextRanking[targetIdx].kuchikomi.likeCount += point
      nextRanking[targetIdx].kuchikomi.liked = true
      setRanking(nextRanking)

      await apiClient.kuchikomis
        .likeUpdate(kuchikomiId, { point: point })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)

          // 元に戻す
          const nextRanking = [...ranking]
          const targetIdx = nextRanking.findIndex(
            (item) => item.kuchikomi.id === kuchikomiId
          )
          if (!beforeLiked) {
            nextRanking[targetIdx].kuchikomi.liked = false
          }
          nextRanking[targetIdx].kuchikomi.likeCount -= point
          setRanking(nextRanking)

          return null
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [ranking]
  )

  /**
   * ブックマークする
   * ローディング画面は出さず、非同期で処理する
   */
  const bookmark = useCallback(
    async (kuchikomiId: string, set: boolean) => {
      if (!user.isLogined()) {
        return
      }

      // 先に画面を更新
      const nextRanking = [...ranking]
      const targetIdx = nextRanking.findIndex(
        (item) => item.kuchikomi.id === kuchikomiId
      )
      nextRanking[targetIdx].kuchikomi.bookmarked = set
      setRanking(nextRanking)

      await apiClient.kuchikomis
        .bookmarkUpdate(kuchikomiId, { set: set })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
    },
    [ranking]
  )

  /**
   * 返信する
   */
  const reply = useCallback(
    async (kuchikomiId: string, message: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      setIsLoading(true)
      await apiClient.kuchikomis
        .replyUpdate(kuchikomiId, { message: message })
        .then((res) => {
          const createReply = res.data.reply

          const nextRanking = [...ranking]
          const targetIdx = nextRanking.findIndex(
            (item) => item.kuchikomi.id === kuchikomiId
          )
          nextRanking[targetIdx].kuchikomi.replies.push(createReply)
          setRanking(nextRanking)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
          setIsLoading(false)
        })
    },
    [ranking]
  )

  const deleteKuchikomi = useCallback(
    async (kuchikomiId: string) => {
      if (!user.isLogined()) {
        return
      }

      showLoading()
      setIsLoading(true)
      await apiClient.kuchikomis
        .deleteCreate(kuchikomiId)
        .then(() => {
          const nextRanking = ranking.filter(
            (item) => item.kuchikomi.id !== kuchikomiId
          )
          setRanking(nextRanking)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          hideLoading()
          setIsLoading(false)
        })
    },
    [ranking]
  )

  useEffect(() => {
    search(
      RankingPeriod.All,
      RankingKuchikomiStat.GoodCount,
      undefined,
      undefined
    )
  }, [])

  return [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    totalSize,
    cursor,
    isLoading,
    {
      search: search,
      like: like,
      bookmark: bookmark,
      reply: reply,
      delete: deleteKuchikomi,
    },
  ]
}

export default useMyKuchikomiRanking
