import React, { FC } from 'react'
import BackgroundImage from '../../../../images/mypage/bg-m.png'
import { GivedPointIcon } from '../../../../images/icons/gived_point_icon'
import { HoldPointIcon } from '../../../../images/icons/hold_point_icon'

type PointCardProps = {
  givedPoint: number
  holdPoint: number
}

const PointCard: FC<PointCardProps> = (props) => {
  return (
    <div
      className="rounded-xl bg-center bg-cover p-4 h-[250px] flex flex-col justify-start"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <p className="text-lg font-bold text-white">ポイント数</p>
      <div className="flex-grow flex flex-col justify-end">
        <div className="py-2">
          <div className="flex justify-center">
            <GivedPointIcon size="md" color="white" />
          </div>
          <div className="text-center">
            <p className="text-sm text-white">ポイント贈呈数</p>
            <p className="text-xl text-white font-bold">
              {props.givedPoint.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="py-2">
          <div className="flex justify-center">
            <HoldPointIcon size="md" color="white" />
          </div>
          <div className="text-center">
            <p className="text-sm text-white">ポイント保有数</p>
            <p className="text-xl text-white font-bold">
              {props.holdPoint.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PointCard
