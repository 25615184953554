import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'
import Loading from '../components/loading/loading'

type LoadingContextProps = {
  isLoading: boolean
  showLoading: () => void
  hideLoading: () => void
}

type LoadingProviderProps = {
  children: ReactNode
}

const LoadingContext = createContext<LoadingContextProps>(
  {} as LoadingContextProps
)

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState(false)

  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {isLoading && (
        <div
          className="w-screen h-screen flex justify-center items-center fixed top-0 left-0"
          style={{ zIndex: 999999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          <Loading />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => useContext(LoadingContext)
