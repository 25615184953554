import React, { FC } from 'react'
import Trend from '../../../../model/Trend'
import { RankIcon } from '../../../../images/icons/rank_icon'
import { Link } from 'react-router-dom'

type SearchTrendCardProps = {
  trend: Trend
  isLastItem: boolean
}

const SearchTrendCard: FC<SearchTrendCardProps> = (props) => {
  return (
    <Link
      to={`/search/kuchikomi?sdgs-category=${props.trend.sdgsCategory.code}`}
    >
      <div
        className={`bg-white py-6 px-4 flex justify-start w-full ${
          props.isLastItem ? '' : 'border-b border-b-main-gray '
        }`}
      >
        <div className="flex flex-col justify-center">
          <RankIcon rank={props.trend.rank} width={26} height={28} />
        </div>
        <div className="pl-3">
          <p className="text-main-black text-sm font-bold leading-5 pb-2">
            {props.trend.sdgsCategory.value}
          </p>
          <p className="text-main-lightGray text-sm leading-4">
            {props.trend.postCount.toLocaleString()}posts
          </p>
        </div>
      </div>
    </Link>
  )
}

export default SearchTrendCard
