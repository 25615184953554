import { useCallback, useEffect, useState } from 'react'
import Information from '../../../model/Information'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const DEFAULT_LIMIT = 100

type UseInformationProps = {
  getLimit?: number
}

type Handler = {
  load: () => Promise<void>
}

const useInformation = (
  props: UseInformationProps
): [Array<Information>, boolean, Handler] => {
  const limit = props.getLimit || DEFAULT_LIMIT
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [informations, setInformations] = useState<Array<Information>>([])

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.informations
      .getInformations({
        cursor: apiCursor,
        limit: limit,
      })
      .then((res) => {
        const data = res.data.data
        const nextInformations = informations.concat(data)
        setInformations(nextInformations)
        setApiCursor(apiCursor + limit)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, informations])

  useEffect(() => {
    load()
  }, [])

  return [informations, isLoading, { load: load }]
}

export default useInformation
