import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { bannerKb, Banner } from '../../../model/Banner'

type Handler = {
  getBanners: (bannerKb: bannerKb) => Banner[]
}

const useTopBanner = (): [Handler] => {
  const apiClient = getAppClient()

  const { showSnackBar } = useSnackBar()
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  const [banners, setBanners] = useState<Banner[]>([])

  const load = useCallback(async () => {
    // setIsLoading(true)
    await apiClient.banners
      .bannersList()
      .then((res) => {
        setBanners(res.data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
    // .finally(() => {
    //   setIsLoading(false)
    // })
  }, [])

  useEffect(() => {
    load()
  }, [])

  const getBanners = useCallback(
    (bannerKb: bannerKb): Banner[] => {
      return banners.filter((banner) => banner.bannerKb === bannerKb)
    },
    [banners]
  )

  return [{ getBanners: getBanners }]
}

export default useTopBanner
