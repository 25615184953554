import React, { FC, useEffect, useState } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import { Link, useParams } from 'react-router-dom'
import useUserDetail from './hooks/use_user_detail'
import UserDetailCard from '../../components/card/user_detail_card'
import CommonTemplate from '../../components/templates/common_template'
import Head2 from '../../components/heads/head2'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import FlatButton from '../../components/buttons/flat_button'
import useUserKuchikomi from '../../hooks/use_user_kuchikomi'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import useKeyword from './hooks/use_keyword'
import useRelatedUsers from './hooks/use_related_users'
import UserCard from '../../components/card/user_card'
import FollowModal from '../../components/modal/follow/follow_modal'
import LoadingSpinner from '../../components/loading/loading_spinner'

const UserDetailPage: FC = () => {
  const PAGE_SIZE = 20
  const INITIALPOPULARKUCHIKOMICOUNT = 4
  const ATTENTIONKEYWORDCOUNTLIMIT = 17

  const isSp = useSpBreakPoint()
  const [userId, setUserId] = useState('')
  const pathParams = useParams<{ id: string }>()
  const [
    detail,
    follows,
    followers,
    enterprises,
    detailLoading,
    detailHandler,
  ] = useUserDetail()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [keywords, keywordLoading, keywordHandler] = useKeyword()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [relatedUsers, relatedUsersLoading, relatedUsersHandler] =
    useRelatedUsers()
  const [
    popularKuchikomis,
    ,
    ,
    popularKuchikomiLoading,
    popularKuchikomHandler,
  ] = useUserKuchikomi({
    type: 'POPULAR',
    getLimit: PAGE_SIZE,
  })
  const [kuchikomis, size, currentCursor, kuchikomiLoading, kuchikomiHandler] =
    useUserKuchikomi({
      type: 'LIST',
      getLimit: PAGE_SIZE,
    })
  const [openPopularKuchikomi, setOpenPopularKuchikomi] = useState(false)
  const [modalTab, setModalTab] = useState<
    'follow' | 'follower' | 'enterprise'
  >('follow')

  const followModal = (
    <FollowModal
      follows={follows}
      followers={followers}
      enterprises={enterprises}
      tab={modalTab}
      onChangeTab={(next: 'follow' | 'follower' | 'enterprise') =>
        setModalTab(next)
      }
      onFollow={detailHandler.follow}
    />
  )
  const KuchikomiIndexSectionRef = React.createRef<HTMLDivElement>()

  const scrollToKuchikomiIndexSection = () => {
    if (KuchikomiIndexSectionRef.current) {
      const elementPosition =
        KuchikomiIndexSectionRef.current.getBoundingClientRect().top +
        window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const userDetailCardElem = !detailLoading ? (
    detail ? (
      <UserDetailCard
        user={detail}
        followModal={followModal}
        onFollow={detailHandler.follow}
        setModalTab={(type: 'follow' | 'follower' | 'enterprise') =>
          setModalTab(type)
        }
      />
    ) : (
      <div></div>
    )
  ) : (
    <LoadingSpinner className="mx-auto my-16" />
  )

  const popularKuchikomiElem = (
    <div>
      <div className="px-4 mb-4">
        <Head2 title="人気クチコミ" />
      </div>
      {!popularKuchikomiLoading ? (
        popularKuchikomis.length ? (
          <div>
            <div className="flex xl:justify-start sm:justify-center flex-wrap">
              {popularKuchikomis
                .slice(
                  0,
                  openPopularKuchikomi
                    ? popularKuchikomis.length
                    : INITIALPOPULARKUCHIKOMICOUNT
                )
                .map((item, index) => {
                  return (
                    <div
                      key={`popular-kuchikomi-${String(index)}`}
                      className="w-full xl:w-1/2 sm:p-2"
                    >
                      <KuchikomiCard
                        kuchikomi={item}
                        rounded
                        sendLike={popularKuchikomHandler.like}
                        onClickBookmark={popularKuchikomHandler.bookmark}
                        sendReply={popularKuchikomHandler.reply}
                        deleteKuchikomi={kuchikomiHandler.delete}
                        isSp={true}
                      />
                    </div>
                  )
                })}
            </div>
            <div className="mt-8">
              <FlatButton
                size="md"
                onClick={() => setOpenPopularKuchikomi(!openPopularKuchikomi)}
              >
                {openPopularKuchikomi ? '人気のクチコミを隠す' : 'もっと見る'}
              </FlatButton>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <p className="text-center text-sm text-main-lightGray">
              クチコミがありません
            </p>
          </div>
        )
      ) : (
        <LoadingSpinner className="mx-auto mb-16" />
      )}
    </div>
  )

  const kuchikomiListElem = (
    <div ref={KuchikomiIndexSectionRef}>
      <div className="px-4 mb-7">
        <Head2
          title="クチコミ一覧"
          subTitle={
            kuchikomis.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + kuchikomis.length
                }件`
              : ''
          }
        />
      </div>
      {!kuchikomiLoading ? (
        <div className="overflow-hidden rounded-lg">
          {kuchikomis.length > 0 ? (
            kuchikomis.map((item, index) => {
              return (
                <div key={`kuchikomi-${String(index)}`}>
                  <KuchikomiCard
                    kuchikomi={item}
                    borderBottom={index !== kuchikomis.length - 1}
                    isSp={isSp}
                    sendLike={kuchikomiHandler.like}
                    onClickBookmark={kuchikomiHandler.bookmark}
                    sendReply={kuchikomiHandler.reply}
                    deleteKuchikomi={kuchikomiHandler.delete}
                  />
                </div>
              )
            })
          ) : (
            <div className="w-full">
              <p className="text-center text-main-lightGray">
                クチコミがありません
              </p>
            </div>
          )}
        </div>
      ) : (
        <LoadingSpinner className="mx-auto mb-16" />
      )}
    </div>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        numRange={3}
        next={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(userId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(userId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(userId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const keywordElem = (
    <>
      {keywords.length > 0 && (
        <>
          <div className="px-4 mb-16 sm:mb-12">
            <p className="text-sm text-main-lightGray">
              {detail?.displayName || ''}がよく使う
            </p>
            <Head2 title="17のキーワード" />
          </div>
          <div className="bg-white rounded-xl overflow-hidden py-4 px-4 flex justify-start flex-wrap">
            {keywords
              .slice(0, ATTENTIONKEYWORDCOUNTLIMIT)
              .map((item, index) => {
                return (
                  <div
                    key={`attention-keyword-${String(index)}`}
                    className="m-2 max-w-[8rem]"
                  >
                    <Link to={`/search/kuchikomi?keyword=${item}`}>
                      <p className="rounded-xl bg-main-gray text-main-black px-3 py-1 text-sm leading-4 overflow-hidden text-ellipsis whitespace-nowrap">
                        {item}
                      </p>
                    </Link>
                  </div>
                )
              })}
          </div>
        </>
      )}
    </>
  )

  const relatedUsersElem = (
    <div>
      {relatedUsers.length > 0 && (
        <>
          <div className="pt-5 lg:pt-0 px-4 mb-7">
            <Head2 title="関連ユーザー" />
          </div>
          <div className="pl-4 sm:pl-0 sm:flex justify-start sm:flex-wrap sm:overflow-auto flex-nowrap overflow-x-scroll hidden-scrollbar">
            {relatedUsers.map((item, index) => {
              return (
                <div
                  key={`user-ranking-${String(index)}`}
                  className="lg:pr-0 pr-4 pb-4 w-full sm:w-1/2 lg:w-full"
                >
                  <UserCard
                    user={item}
                    flex
                    imgSize="md:w-[100px] md:h-[100px] sm:w-[70px] sm:h-[70px] w-[80px] h-[80px]"
                    className="!w-full"
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp} additonalTopNode>
      <div>
        <div className="mb-16 sm:mb-12">{userDetailCardElem}</div>
        <div className="mb-16 sm:mb-12">{popularKuchikomiElem}</div>
        <div className="sm:flex justify-between mb-12 sm:flex-wrap lg:flex-nowrap">
          <div className="lg:w-[calc(100%-300px-1rem)] lg:pb-0 sm:w-full mb-16 sm:mb-12">
            <div className="mb-8">{kuchikomiListElem}</div>
            <div className="flex justify-center">{paginationCursor}</div>
          </div>
          <div className="lg:w-[300px] sm:w-full lg:block sm:flex sm:justify-between">
            <div>{keywordElem}</div>
            <div>{relatedUsersElem}</div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )

  useEffect(() => {
    if (pathParams.id) {
      setUserId(pathParams.id)
      detailHandler.load(pathParams.id)
      popularKuchikomHandler.load(pathParams.id)
      kuchikomiHandler.load(pathParams.id)
      keywordHandler.load(pathParams.id)
      relatedUsersHandler.load(pathParams.id)
    }
  }, [pathParams.id])

  return content
}

export default UserDetailPage
