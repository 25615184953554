import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Enterprise from '../../../model/Enterprise'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (keyword?: string) => Promise<void>
}

const useSearchEnterprise = (): [
  Array<Enterprise>,
  boolean,
  boolean,
  Handler,
] => {
  const GETLIMIT = 10
  const [loading, setLoading] = useState<boolean>(false)
  const { showSnackBar } = useSnackBar()
  const [cursor, setCursor] = useState<number>(0)
  const [isLoadAll, setIsLoadAll] = useState<boolean>(false)
  const [currentKeyword, setCurrentKeyword] = useState<string | undefined>(
    undefined
  )
  const apiClient = getAppClient()

  const [enterprises, setEnterprises] = useState<Array<Enterprise>>([])

  const load = useCallback(
    async (keyword?: string) => {
      setLoading(true)

      let queryEnterprises = enterprises
      let queryKeyword = currentKeyword
      let queryCursor = cursor

      if (keyword !== currentKeyword) {
        queryEnterprises = []
        queryCursor = 0
        queryKeyword = keyword
        setEnterprises([])
      }

      await apiClient.enterprises
        .enterprisesList({
          limit: GETLIMIT,
          keyword: queryKeyword,
          cursor: queryCursor,
        })
        .then((res) => {
          const data = res.data.data
          setEnterprises([...queryEnterprises, ...data])
          setCurrentKeyword(queryKeyword)
          setCursor(queryCursor + data.length)

          if (data.length < GETLIMIT) {
            setIsLoadAll(true)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [currentKeyword, cursor]
  )

  return [enterprises, loading, isLoadAll, { load: load }]
}

export default useSearchEnterprise
