import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import useFaq from './hooks/use_faq'
import FaqBlock from './components/faq_block'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import LoadingSpinner from '../../components/loading/loading_spinner'

const FaqPage: FC = () => {
  const [faq, loading] = useFaq()
  const isSp = useSpBreakPoint()

  const faqBlocks = (
    <div className="bg-white rounded-xl w-full p-6">
      <div className="text-center mb-8">
        <p className="text-4xl text-main-blue font-bold">FAQ</p>
      </div>
      {loading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          {faq.map((item, index) => {
            return (
              <div key={`faq-${String(index)}`}>
                <FaqBlock category={item.category} faqs={item.faqs} />
              </div>
            )
          })}
          {faq.length === 0 && (
            <div className="text-center">
              <p>FAQがありません</p>
            </div>
          )}
        </>
      )}
    </div>
  )
  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div>{faqBlocks}</div>
    </CommonTemplate>
  )
  return content
}

export default FaqPage
