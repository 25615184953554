import React from 'react'

export const PointTicketIcon = () => {
  return (
    <div className="min-w-[16px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="100" viewBox="0 0 163.459 88.932">
		<g id="チケットのアイコン素材" transform="translate(0 -116.719)">
			<path id="パス_969" data-name="パス 969" d="M167.035,301.819H67.315l-20.066-.01,0,.01H47.2l6.207,29.831,114.646-23.914.159-.033h0l.05-.011Z" transform="translate(-32.13 -125.999)" fill="#2791EB"/>
			<path id="パス_970" data-name="パス 970" d="M445.036,150.415h-5.107a7.024,7.024,0,1,0,0,14.047h.612a12.2,12.2,0,0,1,9.6-13.052l-3-14.455-.006-.027h0l-.011-.05-2.094.434v13.1Z" transform="translate(-294.68 -13.723)" fill="#2791EB"/>
			<path id="パス_971" data-name="パス 971" d="M464.407,266.251l.05-.011-3.011-14.393-.018-.09h0l-.01-.05c-.37.077-.739.124-1.108.166-.149.017-.3.035-.447.046a12.044,12.044,0,0,1-5.986-1.089c-.136-.065-.276-.12-.408-.19-.236-.12-.463-.256-.69-.39a11.118,11.118,0,0,1-1.374-.969v.036c-.017-.014-.034-.024-.05-.036v16.779h-7.613l-1.531-.01,0,.01h-.052l.945,4.623,21.276-4.428.025,0Z" transform="translate(-300.998 -90.242)" fill="#2791EB"/>
			<path id="パス_972" data-name="パス 972" d="M117.307,170.712v-6.129h6.13v6.129h21.812V155.848a12.132,12.132,0,1,1,0-24.263V116.719H123.437v6.131h-6.13v-6.131H0v53.993H117.307Zm0-12.387V147.087h6.13v11.237Zm6.13-29.117h0v11.11h-6.13V129.106h2.027Zm-21.1,7.254v18.109H96.7V136.462H91.384v-5.516h16.294v5.516H102.34Zm-12.234-.306-1.482.306H80.682v3.4h0v.051H88.7v5.517h-8.02V149h0v.051h9.425v5.517H75.012V130.946H90.107Zm-23.114-5.21h4.343l2.605.03L66.507,140.6l.23.434,5.148,9.409,2.231,4.128h-6.51L62.728,145.3l-1.66,2.12v7.152H55.423V130.946h5.644v4.343l-.041,3.519.041-.055v.085Zm-13.746,8.428H47.53l-.153-.639a4.14,4.14,0,0,0-.92-1.864,2.1,2.1,0,0,0-1.66-.612,2.593,2.593,0,0,0-1.124.23,2.247,2.247,0,0,0-.766.612,1.909,1.909,0,0,0-.358.563,4.6,4.6,0,0,0-.255.919,12.3,12.3,0,0,0-.153,1.635c-.051.663-.051,1.507-.051,2.554,0,1.021,0,1.864.051,2.554,0,.281.026.561.051.817a6.65,6.65,0,0,0,.1.792,4.559,4.559,0,0,0,.255.919,2.522,2.522,0,0,0,.332.563,2.66,2.66,0,0,0,.792.614,2.61,2.61,0,0,0,1.124.23,2.2,2.2,0,0,0,1.685-.614,4.127,4.127,0,0,0,.92-1.864l.153-.639h4.985l.709.007-.152.964a9.3,9.3,0,0,1-2.886,5.694,8.68,8.68,0,0,1-8.863,1.252,8.2,8.2,0,0,1-2.631-1.891,7.1,7.1,0,0,1-1.327-1.94,9.334,9.334,0,0,1-.69-2.171,6.326,6.326,0,0,1-.1-.741c-.077-.536-.1-1.1-.128-1.685-.026-.869-.051-1.813-.051-2.861,0-1.072.026-2.018.051-2.861a14.211,14.211,0,0,1,.255-2.452,8.28,8.28,0,0,1,.665-2.171,7.1,7.1,0,0,1,1.327-1.94,7.994,7.994,0,0,1,6.079-2.58,8.089,8.089,0,0,1,5.414,1.942,9.368,9.368,0,0,1,2.886,5.7l.139.878Zm-19.2-8.428v23.625H28.4V130.946Zm-13.1,5.516v18.109H15.3V136.461H9.96v-5.516H26.281v5.516H20.943Z" fill="#2791EB"/>
		</g>
	  </svg>
    </div>
  )
}
