import React, { FC } from 'react'

type FilterButtonProps = {
  label: string
  value: string
  isActive: boolean
  onClick?: () => void
}

const FilterButton: FC<FilterButtonProps> = (props) => {
  const textColor = 'text-white'
  let bg = 'bg-main-lightGray'
  if (props.isActive) {
    bg = 'bg-main-blue'
  }
  return (
    <button
      className={`rounded-lg ${bg} ${textColor} px-4 py-1 leading-6`}
      value={props.value}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  )
}

export default FilterButton
