import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const CommunityGuidePage: FC = () => {
  const isSp = useSpBreakPoint()

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div className="bg-white rounded-xl w-full py-12 px-4 sm:px-16">
        <div className="mb-8">
          <p className="text-4xl text-main-blue font-bold">
            コミュニティガイドライン
          </p>
        </div>
        <div className="mb-12">
          <h3 className="font-bold mb-4 text-lg">
            SDGs クチコミ Lodgeが目指すもの
          </h3>
          <p>
            SDGs クチコミ
            Lodgeでは、持続可能な未来を一緒に創り上げていくことを目指しています。私たちは、行政、企業、そして市民の皆さんが一体となって、地球にやさしく、人々が豊かに暮らせる社会を実現するための思いやアイデアを共有する場を提供しています。
          </p>
          <p>
            ここでは、さまざまな背景を持つ人々が、互いの知見を交換し、新たな解決策を模索することができます。また、私たちはそれぞれの声を大切にし、「誰一人取り残さない」というSDGsの精神に基づき、誰もが参加しやすい、開かれたコミュニティを築いていきたいと考えています。
          </p>
          <p>
            SDGs クチコミ
            Lodgeを通じて、社会や世界の課題に対する意識が高まり、より良い未来への一歩を踏み出すきっかけとなることを願っています。
          </p>
        </div>
        {/* 1. ガイドラインの目的と範囲 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">1. ガイドラインの目的と範囲</h3>
          <h4 className="font-bold">目的</h4>
          <div className="pl-4 mb-4">
            <p>
              SDGsクチコミLodge（以下、本サービス）は、「誰一人取り残さない」というSDGsの根本的なコンセプトに基づき設計されています。本サービスでは、行政、企業、市民を含むすべてのステークホルダーが垣根を越えて交流し、公正で信頼性の高い情報を共有するための場を提供します。ユーザー間の意見交換を促進し、持続可能な開発目標に対する広範な理解と行動を支援することが目的です。
            </p>
          </div>
          <h4 className="font-bold">範囲</h4>
          <div className="pl-4 mb-4">
            <p>
              このガイドラインは、SDGsクチコミLodge上でのすべてのユーザー行動に適用されます。クチコミ、質問応答、ブログ投稿、画像やビデオの共有を含む、すべての投稿活動に対してガイドラインが適用されます。ユーザーは、クチコミを投稿する際、正確で誠実な情報提供を心がけることが求められます。
            </p>
          </div>
          <h4 className="font-bold">適用</h4>
          <div className="pl-4 mb-4">
            <p>
              本ガイドラインは、すべての登録ユーザーおよび訪問者に適用されます。SDGsクチコミLodgeを利用することにより、ユーザーは自動的にこのガイドラインに同意したものとみなされます。ガイドラインに違反した場合、コンテンツの削除やアカウントの停止など、必要に応じて管理者が適切な措置を取ることがあります。
            </p>
          </div>
          <h4 className="font-bold">更新</h4>
          <div className="pl-4 mb-4">
            <p>
              SDGsクチコミLodgeは、継続的にガイドラインを見直し、必要に応じて更新します。ガイドラインの変更は、本サービス上で事前に通知され、変更後のガイドラインもすべてのユーザーに適用されます。ユーザーは定期的にガイドラインを確認し、最新のルールを理解し遵守する責任があります。
            </p>
          </div>
        </div>
        {/* 2. ユーザーの責任 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">2. ユーザーの責任</h3>
          <h4 className="font-bold">誠実な情報提供</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは、自身が投稿する情報が真実かつ正確であることを保証する責任があります。情報は誤解を招かないよう明確に表現し、事実に基づいている必要があります。誠実なレビューを心がけてください。
            </p>
          </div>
          <h4 className="font-bold">公共の場での礼節</h4>
          <div className="pl-4 mb-4">
            <p>
              SDGsクチコミLodgeは開かれた場であり、すべてのユーザーが尊重し合う行動を取ることが期待されています。他のユーザーに対する誹謗中傷、不快な言動、攻撃的なコメントは厳禁です。意見の相違がある場合でも、建設的な議論を心がけてください。
            </p>
          </div>
          <h4 className="font-bold">個人情報の保護</h4>
          <div className="pl-4 mb-4">
            <p>
              個人情報やプライバシーに関する情報の取り扱いには、特に慎重に行動してください。自分自身の情報だけでなく、他人の個人情報も無断で公開しないようにします。また、他ユーザーから個人情報を収集したり、利用したりする行為は禁止されています。
            </p>
          </div>
          <h4 className="font-bold">コンテンツの適切な使用</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは、SDGsクチコミLodgeで共有されるコンテンツを適切に使用する責任があります。不適切な内容（暴力的、性的、差別的など）の投稿は禁止されており、違法行為に関連する情報の投稿や推奨も禁止されています。
            </p>
          </div>
          <h4 className="font-bold">プラットフォームの正当な使用</h4>
          <div className="pl-4 mb-4">
            <p>
              SDGsクチコミLodgeを利用する際は、プラットフォームの規約に従って適正に使用することが求められます。不正行為、システムの操作、その他サービスの運営を妨げる行為は禁止されています。
            </p>
          </div>
        </div>
        {/* 3. 禁止事項 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">3. 禁止事項</h3>
          <p className="mb-4">
            SDGsクチコミLodgeの利用規約に基づき、以下の行為は厳しく禁止されています。これらの禁止事項は、コミュニティの健全な環境を維持し、全ユーザーが安心して利用できるよう保護するために設けられています。
          </p>
          <h4 className="font-bold">不正行為</h4>
          <div className="pl-4 mb-4">
            <p>偽情報の投稿や誤解を招く情報の提供。</p>
            <p>スパム行為や無関係な内容の投稿。</p>
            <p>システムを操作する行為やその他の技術的悪用。</p>
          </div>
          <h4 className="font-bold">プライバシー侵害</h4>
          <div className="pl-4 mb-4">
            <p>他人の個人情報を無断で収集、公開、または共有する行為。</p>
            <p>ユーザーのプライバシーを侵害するような行為。</p>
          </div>
          <h4 className="font-bold">不適切なコンテンツ</h4>
          <div className="pl-4 mb-4">
            <p>性的、暴力的、差別的な内容を含む投稿。</p>
            <p>犯罪や自傷行為、自殺を助長する内容。</p>
            <p>ヘイトスピーチやハラスメントを含む投稿。</p>
          </div>
          <h4 className="font-bold">知的財産権の侵害</h4>
          <div className="pl-4 mb-4">
            <p>著作権、商標権、その他の知的財産権を侵害する内容の投稿。</p>
            <p>許可なく第三者の作品を使用する行為。</p>
          </div>
          <h4 className="font-bold">コミュニティガイドラインに反する行為</h4>
          <div className="pl-4 mb-4">
            <p>コミュニティの目的や精神に反する行為。</p>
            <p>他のユーザーに不快感を与える投稿やコメント。</p>
            <p>SDGsの原則に反する製品やサービスの宣伝。</p>
          </div>
          <h4 className="font-bold">商業行為</h4>
          <div className="pl-4 mb-4">
            <p>許可されていない商業的広告や宣伝。</p>
            <p>販売活動や直接的な商品の勧誘。</p>
          </div>
          <h4 className="font-bold">法令違反</h4>
          <div className="pl-4 mb-4">
            <p>法律、規制、または公的規則に違反する行為。</p>
          </div>
        </div>
        {/* 4. クチコミの内容の要件 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">4. クチコミの内容の要件</h3>
          <p className="mb-4">
            SDGsクチコミLodgeでは、クオリティの高い、信頼性のあるコミュニケーションを保証するため、以下の内容要件に基づいてクチコミの投稿をお願いしています。これらの要件は、他のプラットフォームのベストプラクティスを基に設定されています。
          </p>
          <h4 className="font-bold">客観性と透明性</h4>
          <div className="pl-4 mb-4">
            <p>
              具体性:
              投稿は具体的な経験や観察に基づくものでなければなりません。感想やご意見は詳細に、具体的な例を交えて記述してください。
            </p>
            <p>
              透明性:
              広告やスポンサーからの影響がある場合は、その旨を明確に記載することが求められます。
            </p>
          </div>
          <h4 className="font-bold">正確性と公平性</h4>
          <div className="pl-4 mb-4">
            <p>
              事実確認:
              投稿内容は事実に基づいていなければなりません。虚偽の情報や誇張表現は避け、正確な情報提供を心がけてください。
            </p>
            <p>
              バランスの取れたレビュー:
              良い点だけでなく、改善点や問題点についても公平に記述することが重要です。
            </p>
          </div>
          <h4 className="font-bold">ユーザーへの配慮</h4>
          <div className="pl-4 mb-4">
            <p>
              尊重の精神:
              他のユーザーや団体、企業に対する尊重を持って接すること。人種、宗教、性別などに基づく差別的な内容は厳禁です。
            </p>
            <p>
              安全性の確保:
              個人情報の保護を徹底し、自身や他人の個人情報を公開または共有しないようにしてください。
            </p>
          </div>
          <h4 className="font-bold">コンテンツの節度</h4>
          <div className="pl-4 mb-4">
            <p>
              適切な表現の使用:
              不適切な言葉遣いや攻撃的な表現は避け、建設的な批評を心がけてください。わいせつな内容や不快感を与える表現は控えてください。
            </p>
          </div>
          <h4 className="font-bold">ガイドラインの遵守</h4>
          <div className="pl-4 mb-4">
            <p>
              ガイドラインとの一致:
              投稿はすべてSDGsクチコミLodgeのガイドラインに遵守している必要があります。これに反する内容は、修正または削除の対象となります。
            </p>
          </div>
        </div>
        {/* 5. コミュニティの健全性 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">5. コミュニティの健全性</h3>
          <p className="mb-4">
            SDGsクチコミLodgeでは、持続可能なコミュニティの発展を目指し、すべてのユーザーが健全かつ建設的な環境で活動できるよう、以下のポリシーを設けています。
          </p>
          <h4 className="font-bold">互いの尊重</h4>
          <div className="pl-4 mb-4">
            <p>
              すべてのユーザーは互いに尊重し、礼儀をもって行動することが求められます。誹謗中傷、個人攻撃、差別的な発言は厳禁です。
            </p>
          </div>
          <h4 className="font-bold">安全なコミュニティ</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは他人を危険にさらすような行動を避け、安全を第一に考えた投稿を心がけてください。特に、個人情報の不適切な公開や、危険行為の助長は禁止されています。
            </p>
          </div>
          <h4 className="font-bold">責任ある投稿</h4>
          <div className="pl-4 mb-4">
            <p>
              投稿される情報は、正確で信頼できるものであることが重要です。虚偽の情報や誤解を招くようなコンテンツの投稿は避けてください。
            </p>
          </div>
          <h4 className="font-bold">トラブルの適切な対応</h4>
          <div className="pl-4 mb-4">
            <p>
              トラブルや問題が生じた場合、適切なチャンネルを通じて報告し、解決に導くことが推奨されます。管理者やモデレータへの適切な報告がコミュニティの健全性を保つ鍵となります。
            </p>
          </div>
          <h4 className="font-bold">良識の尊重</h4>
          <div className="pl-4 mb-4">
            <p>
              投稿内容は、一般的な道徳観念や社会的な規範を尊重し、公序良俗に反するものではないよう注意してください。
            </p>
          </div>
          <h4 className="font-bold">アクティブな参加</h4>
          <div className="pl-4 mb-4">
            <p>
              コミュニティメンバーとして、積極的かつ建設的に参加することで、健全で活動的なコミュニティの維持に貢献してください。また、他のユーザーを支援し、励ますことも重要です。
            </p>
          </div>
        </div>
        {/* 6. クチコミの編集と削除 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">6. クチコミの編集と削除</h3>
          <p className="mb-4">
            SDGsクチコミLodgeでは、コミュニティの透明性と公平性を保持するため、クチコミの編集と削除に関する明確なガイドラインを設けています。
          </p>
          <h4 className="font-bold">クチコミの編集</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは自分の投稿したクチコミをいつでも編集することができます。編集は主に誤字脱字の訂正、情報の追加、または誤解を招く可能性のある表現の改善の目的に利用してください。
            </p>
            <p>
              編集が行われた場合、その事実がクチコミに表示されます。これにより、他のユーザーに対して編集が行われたことが透明に伝えられます。
            </p>
          </div>
          <h4 className="font-bold">クチコミの削除</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは自分の投稿したクチコミを任意に削除することができます。ただし、頻繁な削除はコミュニティの信頼を損ねるため、慎重に行う必要があります。{' '}
            </p>
            <p>
              SDGsクチコミLodgeの運営チームは、ガイドライン違反が確認されたクチコミを削除する権利を有しています。これには以下のような内容が含まれます。{' '}
            </p>
            <div className="pl-4">
              <p>不適切または攻撃的な言葉遣い</p>
              <p>個人のプライバシーを侵害する情報</p>
              <p>虚偽の情報や、誤解を招く可能性のある内容</p>
              <p>違法な内容や、違法行為を助長する内容</p>
            </div>
          </div>
          <h4 className="font-bold">透明性の確保（削除の通知）</h4>
          <div className="pl-4 mb-4">
            <p>
              クチコミが運営チームによって削除された場合、投稿者にはその理由が通知されます。
            </p>
          </div>
          <h4 className="font-bold">再投稿のガイドライン</h4>
          <div className="pl-4 mb-4">
            <p>
              クチコミが削除された場合でも、問題点を修正した上での再投稿は許可されます。再投稿前には、削除の原因となった問題が解消されていることを確認する必要があります。
            </p>
          </div>
        </div>
        {/* 7. 法的遵守 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">7. 法的遵守</h3>
          <p className="mb-4">
            SDGsクチコミLodgeでは、すべてのユーザーが遵守すべき法的な枠組みを明確に設定しています。これは、ユーザーが安心してサービスを利用できるようにするため、そして運営側が法的責任を果たすために不可欠です。
          </p>
          <h4 className="font-bold">法令遵守</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは、SDGsクチコミLodgeを利用する際に、自身の居住国またはサービスを利用する国の法律に従う必要があります。これには、著作権法、プライバシー保護法、公序良俗に関する法律などが含まれます。
            </p>
          </div>
          <h4 className="font-bold">著作権の尊重</h4>
          <div className="pl-4 mb-4">
            <p>
              投稿される全てのコンテンツ（テキスト、画像、ビデオなど）は、著作権を尊重して利用されるものでなければなりません。ユーザーは、自らが権利を有する、または正当な使用許可を得たコンテンツのみを投稿することが義務付けられています。
            </p>
          </div>
          <h4 className="font-bold">プライバシーと個人情報の保護</h4>
          <div className="pl-4 mb-4">
            <p>
              個人情報の公開は、その情報の所有者からの明示的な許可がある場合にのみ許されます。無断で他人の個人情報を公開することは禁止されており、違反した場合には法的な責任を問われることがあります。
            </p>
          </div>
          <h4 className="font-bold">不正行為の防止</h4>
          <div className="pl-4 mb-4">
            <p>
              すべてのユーザーは、スパム行為、不正アクセス、その他のサービスの運営を妨害する行為を行わないことが求められます。これに違反した場合、アカウントの停止や法的措置が取られる可能性があります。
            </p>
          </div>
          <h4 className="font-bold">知的財産権の尊重</h4>
          <div className="pl-4 mb-4">
            <p>
              ユーザーは他人の商標、著作権、特許などの知的財産権を侵害してはなりません。また、これらの権利に関する偽の主張を行うことも禁止されています。
            </p>
          </div>
          <h4 className="font-bold">合法的なコンテンツの投稿</h4>
          <div className="pl-4 mb-4">
            <p>
              政治的、宗教的、性的な過激な内容や、暴力を助長する内容、違法な活動を促進する内容の投稿は厳しく禁じられています。
            </p>
          </div>
        </div>
        {/* 8. 国に関するクチコミの記載について */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">
            8. 国に関するクチコミの記載について
          </h3>
          <div className="mb-4">
            <p>
              SDGs クチコミ
              Lodgeでは、国、行政、企業、団体がSDGsにどのように貢献しているかに関するクチコミを歓迎します。
            </p>
            <p>
              特に、「国」に関するクチコミを投稿する際には、対象となる国に対して日本がどのようなSDGsの貢献が検討可能であるかについて焦点を当てて記述してくださることをお願いします。
            </p>
            <p>
              皆様から提供されたクチコミは、他の国々や団体に対する参考になるだけでなく、より良い未来に向けた具体的なアクションプランを共有し、議論するための貴重な情報源となります。
            </p>
            <p>
              皆様からの知見を元に、SDGsの達成に向けたグローバルな協力と理解を深めることが目的です。{' '}
            </p>
            <p>
              投稿する際には、具体性と建設的な批評を心がけ、公平かつ尊重の精神をもって参加していただくようお願いします。
            </p>
          </div>
        </div>
        {/* 9. 改定と更新 */}
        <div className="mb-12">
          <h3 className="font-bold mb-4">9. 改定と更新</h3>
          <p className="mb-4">
            SDGsクチコミLodgeでは、サービスを適切に運営し、ユーザーに最良の体験を提供するため、ガイドラインおよび関連する規約の改定と更新を行うことがあります。
          </p>
          <h4 className="font-bold">改定のプロセス</h4>
          <div className="pl-4 mb-4">
            <p>
              ガイドラインの変更や更新は、ユーザーに適切な事前通知を行います。改定内容は、SDGsクチコミLodgeのウェブサイトおよびアプリケーションを通じて公表されます。
            </p>
          </div>
          <h4 className="font-bold">法的遵守</h4>
          <div className="pl-4 mb-4">
            <p>
              改定は、適用される法律や規制の変更を反映するために必要な場合があります。これにより、サービスが常に現行の法律に準拠して運営されることを保証します。
            </p>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )
}

export default CommunityGuidePage
