import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useNavigate, useLocation } from 'react-router-dom'
import { FormErrorMessage } from '../../constants/message'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

type PasswordResetRequestFormProps = {
  email: string
}

const PasswordResetRequestPage: FC = () => {
  const { showLoading, hideLoading } = useLoading()
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const apiClient = getAppClient()
  const location = useLocation()
  const isSp = useSpBreakPoint()

  const { register, handleSubmit, formState } =
    useForm<PasswordResetRequestFormProps>({
      defaultValues: {
        email: '',
      },
      mode: 'onSubmit',
    })

  const { errors } = formState

  useEffect(() => {
    // URLパラメータからエラーメッセージを取得
    const searchParams = new URLSearchParams(location.search)
    const error = searchParams.get('error_message')
    if (error) {
      showSnackBar([decodeURIComponent(error)], 'error')
    }
  }, [location, showSnackBar])

  const passwordResetRequest = async (data: PasswordResetRequestFormProps) => {
    showLoading()
    await apiClient.passwords
      .resetSendCreate({ email: data.email })
      .then(() => {
        showSnackBar(['パスワードリセットのURLを送信しました。'], 'success')
        navigate('/password/reset/send', { state: { email: data.email } })
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <CommonTemplate isSp={isSp}>
      <div className="bg-white rounded-xl w-full max-w-xl mx-auto p-6">
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-8 text-left">
            パスワードリセット
          </h2>
          <p className="text-left">
            ご登録しているメールアドレスを入力してください。
          </p>
          <p className="mb-8 text-left">
            入力したメールアドレス宛にパスワードリセットのURLを送信します。
          </p>
        </div>
        <form onSubmit={handleSubmit(passwordResetRequest)}>
          <p className="mb-2 font-bold text-left">メールアドレス</p>
          <input
            className="w-full mb-8 py-1 px-2 border border-gray-500 rounded-lg outline-none"
            type="email"
            placeholder="メールアドレスを入力してください"
            {...register('email', {
              required: FormErrorMessage.required,
              maxLength: {
                value: 100,
                message: FormErrorMessage.maxLength(100),
              },
            })}
          />
          {errors.email && (
            <p className="error-message text-left">{errors.email.message}</p>
          )}

          <div className="py-4 text-center">
            <FlatButton size="md" bgColor="blue" type="submit">
              送信
            </FlatButton>
          </div>
        </form>
      </div>
    </CommonTemplate>
  )
}

export default PasswordResetRequestPage
