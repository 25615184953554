import React, { ReactNode } from 'react'

type FlatButtonProps = {
  children: ReactNode
  className?: string
  size?: 'sm' | 'md' | 'lg'
  bgColor?: 'white' | 'blue' | 'lightGray'
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e: React.MouseEvent) => void
}

const FlatButton: React.FC<FlatButtonProps> = ({
  children,
  onClick,
  className,
  size = 'md',
  bgColor = 'blue',
  type = 'button',
}) => {
  let bg = 'bg-main-blue'
  let text = 'text-white'
  switch (bgColor) {
    case 'white':
      bg = 'bg-white'
      text = 'text-main-blue'
      break
    case 'blue':
      break
    case 'lightGray':
      bg = 'bg-main-lightGray'
      break
    default:
      bg = 'bg-main-blue'
      text = 'text-main-blue'
  }

  let defaultClassName = `block mx-auto w-full text-center ${bg} rounded-lg ${text}`

  switch (size) {
    case 'sm':
      defaultClassName += ' max-w-[132px] py-2 text-sm'
      break
    case 'md':
      defaultClassName += ' max-w-[200px] py-3 text-base'
      break
    case 'lg':
      defaultClassName += ' max-w-[300px] py-4 text-lg font-bold'
      break
  }

  const combinedClassName = `${defaultClassName} ${className ?? ''}`

  return (
    <button onClick={onClick} type={type} className={combinedClassName}>
      {children}
    </button>
  )
}

export default FlatButton
