import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { LoadingIcon } from '../../images/icons/loading_icon'

type InfiniteScrollerProps = {
  load: () => Promise<void>
  loading: boolean
  hasMore: boolean
  children: ReactNode
}

const InfiniteScroller: FC<InfiniteScrollerProps> = (props) => {
  const observerTarget = useRef(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (props.hasMore && !props.loading) {
            observer.disconnect()
            setPage(() => page + 1)
          }
        }
      },
      { threshold: 1.0 }
    )

    if (observerTarget.current && props.hasMore && !props.loading) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [page, props.loading, props.hasMore, observerTarget])

  useEffect(() => {
    if (page > 0) {
      props.load()
    }
  }, [page])

  return (
    <div>
      <div>{props.children}</div>
      <div ref={observerTarget}>
        {props.loading && (
          <div className="w-full flex justify-center py-4">
            <div className="flex flex-col justify-center">
              <LoadingIcon />
            </div>
            <p className="text-main-lightGray text-sm pl-2">
              データを読み込んでいます•••
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfiniteScroller
