import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Information from '../../../model/Information'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type UseInformationListProps = {
  getLimit?: number
}

type Handler = {
  load: (cursor?: number) => Promise<void>
}

const useInformationList = ({
  getLimit = 20,
  ...props
}: UseInformationListProps): [
  Array<Information>,
  number,
  number,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [informations, setInformations] = useState<Array<Information>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(
    async (cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.informations
        .informationsList({
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setInformations(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, informations]
  )

  useEffect(() => {
    load()
  }, [])

  return [informations, totalSize, cursor, isLoading, { load: load }]
}

export default useInformationList
