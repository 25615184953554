import React, { FC } from 'react'
import { SearchIcon } from '../../../../images/icons/search_icon'
import FlatButton from '../../../../components/buttons/flat_button'
import { UseFormRegisterReturn } from 'react-hook-form'
import useCodes from '../../../../hooks/use_codes'

type UserSearchFormProps = {
  keywordOptions: UseFormRegisterReturn<'keyword'>
  sdgsCategoryOptions: UseFormRegisterReturn<'sdgsCategory'>
  occupationOptions: UseFormRegisterReturn<'occupation'>
  onSubmit?: React.FormEventHandler<HTMLFormElement>
  toggleForm: () => void
}

const UserSearchForm: FC<UserSearchFormProps> = (props) => {
  const [codes] = useCodes()

  return (
    <form action="submit" onSubmit={props.onSubmit}>
      <div className="max-w-[1024px] flex justify-center flex-wrap">
        <div className="w-[460px]">
          <div className="mb-4">
            <label
              htmlFor="keyword-search"
              className="text-main-black font-bold"
            >
              キーワード検索
            </label>
            <div className="flex justify-between px-2 border-[1.5px] border-main-lightGray rounded-lg">
              <input
                id="keyword-search"
                type="text"
                className="w-full py-2 outline-none leading-4 text-sm"
                placeholder="検索するキーワードを入力"
                {...props.keywordOptions}
              />
              <button type="button">
                <SearchIcon color="LIGHTGRAY" size="SM" />
              </button>
            </div>
          </div>
          <div>
            <p className="text-main-black font-bold">カテゴリーで検索</p>
            <div className="flex justify-between pb-4">
              <div className="border-[1.5px] border-main-lightGray w-[47.5%] mr-[5%] rounded-lg px-2">
                <select
                  id="sdgs-select"
                  className="outline-none text-sm w-full"
                  {...props.sdgsCategoryOptions}
                >
                  <option value="" disabled selected>
                    SDGs17の目標
                  </option>
                  <option value="">全て</option>
                  {codes.sdgsCategories.map((item, index) => {
                    return (
                      <option
                        key={`sdgs-code-${String(index)}`}
                        value={item.code}
                      >
                        {`${item.code}.${item.value}`}
                      </option>
                    )
                  })}
                </select>
              </div>
              {/* リリースに伴い一旦非表示 */}
              {/* <div className="border-[1.5px] border-main-lightGray w-[47.5%] rounded-lg px-2">
                <select
                  id="occupation-category-select"
                  className="outline-none text-sm w-full"
                  {...props.occupationOptions}
                >
                  <option value="" disabled selected>
                    働いている業種
                  </option>
                  <option value="">全て</option>
                  {codes.occupationCodes.map((item, index) => {
                    return (
                      <option
                        key={`sdgs-code-${String(index)}`}
                        value={item.code}
                      >
                        {item.value}
                      </option>
                    )
                  })}
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <div className="min-w-[256px] mt-8">
            <div className="mb-4">
              <FlatButton
                size="md"
                bgColor="blue"
                type="submit"
                onClick={() => {
                  props.toggleForm()
                }}
              >
                検索する
              </FlatButton>
            </div>
            <div>
              <FlatButton
                size="md"
                bgColor="lightGray"
                onClick={props.toggleForm}
              >
                閉じる
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default UserSearchForm
