import React, { FC } from 'react'
import Enterprise from '../../../../model/Enterprise'
import { Link } from 'react-router-dom'
import FlatButton from '../../../../components/buttons/flat_button'
import { EvalurationStarIcon } from '../../../../images/icons/star_icon'

type EnterpriseCardProps = {
  enterprise: Enterprise
}

const EnterpriseCard: FC<EnterpriseCardProps> = (props) => {
  return (
    <Link to={`/enterprises/${props.enterprise.id}`}>
      <div className="bg-white flex justify-between p-4 border-b border-b-main-gray">
        <div className="grow">
          <p className="font-bold">{props.enterprise.name}</p>
          <p className="text-main-lightGray">
            {props.enterprise.industryCode.value}
          </p>
          <div className="flex justify-start">
            <EvalurationStarIcon
              evaluation={props.enterprise.evaluationAverage}
            />
            <p className="text-xs">
              {props.enterprise.evaluationAverage
                ? props.enterprise.evaluationAverage.toFixed(2)
                : '-'}
            </p>
            <p className="text-xs text-main-lightGray">
              （{props.enterprise.kuchikomiCount?.toLocaleString()}件）
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <FlatButton>
            <p className="text-sm text-white px-2">企業・団体ページへ</p>
          </FlatButton>
        </div>
      </div>
    </Link>
  )
}

export default EnterpriseCard
