import React, { FC } from 'react'

type NextActionIconProps = {
  size?: 'lg'
  color?: 'white'
}

export const NextActionIcon: FC<NextActionIconProps> = ({
  size = 'lg',
  color = 'white',
}) => {
  let width = '19.629'
  let height = '17.18'
  let fill = '#fff'

  switch (size) {
    case 'lg':
      width = '57.114'
      height = '49.975'
      break
  }

  switch (color) {
    case 'white':
      fill = '#fff'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 57.114 49.975"
      >
        <path
          id="FontAwsome_comment-medical_"
          data-name="FontAwsome (comment-medical)"
          d="M28.557,32C12.786,32,0,42.388,0,55.2A20.625,20.625,0,0,0,6.358,69.787C4.96,75.4.3,80.413.245,80.469a.892.892,0,0,0,.647,1.506,25.5,25.5,0,0,0,15.684-5.732,33.985,33.985,0,0,0,11.981,2.162c15.772,0,28.557-10.388,28.557-23.2S44.329,32,28.557,32ZM39.266,57.88a.892.892,0,0,1-.892.892H32.127v6.247a.892.892,0,0,1-.892.892H25.88a.892.892,0,0,1-.892-.892V58.772H18.741a.892.892,0,0,1-.892-.892V52.525a.892.892,0,0,1,.892-.892h6.247V45.386a.892.892,0,0,1,.892-.892h5.354a.892.892,0,0,1,.892.892v6.247h6.247a.892.892,0,0,1,.892.892Z"
          transform="translate(0 -32)"
          fill={fill}
        />
      </svg>
    </div>
  )
}
