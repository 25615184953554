import React, { FC } from 'react'
import Head2 from '../../components/heads/head2'
import { Link } from 'react-router-dom'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const RegisterUserCompletePage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      <div className="flex justify-center">
        <div>
          <div className="mb-8">
            <Head2 title="会員登録完了" />
          </div>
          <div className="max-w-[640px] bg-white rounded-xl md:px-6 px-2 py-12">
            <div className="flex justify-start pb-4">
              <p className="text-main-black text-2xl font-bold">会員種別：</p>
              <p className="text-main-blue text-2xl font-bold">ユーザー</p>
            </div>
            <div className="pb-4">
              <p>
                会員登録が完了しました。
                <br />
                このたびは、会員登録をして頂き誠にありがとうございます。
                <br />
                ご記入いただいたメールアドレスへ、自動返信の確認メールをお送りしております。
                <br />
                <br />
                しばらく経ってもメールが届かない場合は、入力頂いたメールアドレスが間違っているか、迷惑メールフォルダに振り分けられている可能性がございます。
                <br />
                また、ドメイン指定をされている場合は、「info@sdgs-lodges.jp」からのメールが受信できるようあらかじめ設定をお願いいたします。
              </p>
            </div>
            <div className="flex justify-center">
              <div className="w-fit px-4 py-2 bg-main-blue rounded-lg text-white">
                <Link to="/">TOPページへ戻る</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default RegisterUserCompletePage
