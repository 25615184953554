import React, { FC, ReactNode } from 'react'

type SpeechBubbleProps = {
  content: ReactNode
}

const SpeechBubble: FC<SpeechBubbleProps> = (props) => {
  return (
    <div
      className={[
        'rounded-xl',
        'bg-main-gray',
        'px-3',
        'py-1',
        'text-main-black',
        'text-sm',
        'relative',
        "before:content-['']",
        'before:absolute',
        'before:right-[-14px]',
        'before:top-[50%]',
        'before:-translate-y-1/2',
        'before:border-8',
        'before:border-transparent',
        'before:border-l-main-gray',
      ].join(' ')}
    >
      {props.content}
    </div>
  )
}

export default SpeechBubble
