import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Press from '../../../model/Press'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
}

const usePress = (): [Array<Press> | null, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [presses, setPresses] = useState<Array<Press>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(async (id: string) => {
    setIsLoading(true)
    await apiClient.enterprises
      .pressesDetail(id)
      .then((res) => {
        const data = res.data.data
        setPresses(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return [presses, isLoading, { load: load }]
}

export default usePress
