import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useSearchEnterprise from '../hooks/use_search_enterprise'
import FlatButton from '../../../components/buttons/flat_button'
import { SearchIcon } from '../../../images/icons/search_icon'
import LoadingSpinner from '../../../components/loading/loading_spinner'
import Enterprise from '../../../model/Enterprise'
import { debounce } from 'lodash'
import WhiteModalContent from '../../../components/modal/white_modal_content'
import { EvalurationStarIcon } from '../../../images/icons/star_icon'

type SelectEnterpriseModalProps = {
  close: () => void
  select: (enterprise: Enterprise) => void
}

type KeywordProps = {
  keyword: string
}

const SelectEnterpriseModal: FC<SelectEnterpriseModalProps> = ({
  close,
  select,
}) => {
  const [enterprises, isLoading, isLoadAll, Handler] = useSearchEnterprise()
  const [initialized, setInitialized] = useState(false)

  const { register, handleSubmit, watch } = useForm<KeywordProps>({
    defaultValues: {
      keyword: '',
    },
    mode: 'onSubmit',
  })

  const inputKeyword = watch('keyword')

  const debouncedLoad = useCallback(
    debounce((keyword?: string) => {
      Handler.load(keyword).then(() => {
        setInitialized(true)
      })
    }, 300),
    []
  )

  useEffect(() => {
    debouncedLoad(inputKeyword)

    return () => {
      debouncedLoad.cancel()
    }
  }, [inputKeyword, debouncedLoad])

  const search = (data: KeywordProps) => {
    Handler.load(data.keyword)
  }

  const selectEnterprise = (enterprise: Enterprise) => {
    select(enterprise)
    close()
  }

  const content = (
    <WhiteModalContent additionalClass="w-[500px]">
      <div className="py-2 xs:py-6 text-center xs:text-lg">
        クチコミする企業・団体を選ぶ
      </div>
      <hr className="border-main-gray border-2" />
      <div className="py-3 px-2 xs:px-6 relative">
        <input
          type="text"
          className="bg-main-gray py-2 xs:py-3 px-3 rounded-md w-full"
          {...register('keyword')}
        />
        <div className="absolute top-5 xs:top-7 right-5 xs:right-9">
          <SearchIcon color="LIGHTGRAY"></SearchIcon>
        </div>
      </div>
      {/* 企業 */}
      <div className="py-6 h-[50vh] overflow-y-auto px-2 xs:px-4">
        <div>
          {enterprises.map((enterprise, index) => {
            return (
              <div
                key={`ep-${index}`}
                className="py-3 xs:px-6 flex items-center justify-between border-b border-main-gray"
              >
                <div className="flex mb-0 items-center">
                  <img
                    src={enterprise.logoUrl}
                    className="w-14 object-cover mb-0"
                  />
                  <div className="px-2 xs:px-4">
                    <p className="font-bold">{enterprise.name}</p>
                    <div className="text-sm flex items-center text-main-blue mb-2">
                      <EvalurationStarIcon
                        evaluation={enterprise.evaluationAverage}
                      />
                      {enterprise.evaluationAverage
                        ? enterprise.evaluationAverage.toFixed(2)
                        : '-'}
                    </div>
                    <p className="text-xs">
                      クチコミ件数：
                      {enterprise.kuchikomiCount
                        ? enterprise.kuchikomiCount.toLocaleString()
                        : '-'}
                    </p>
                  </div>
                </div>
                <FlatButton
                  className="!py-2 !w-[60px] !mx-0"
                  size="sm"
                  onClick={() => selectEnterprise(enterprise)}
                >
                  選択
                </FlatButton>
              </div>
            )
          })}
          {isLoading && (
            <div className="py-4">
              <LoadingSpinner className="mx-auto" />
            </div>
          )}
          {!isLoading && initialized && enterprises.length === 0 && (
            <p className="text-center">データがありません。</p>
          )}
          {!isLoading && initialized && !isLoadAll && (
            <div className="py-4">
              <FlatButton size="sm" onClick={handleSubmit(search)}>
                追加で読み込む
              </FlatButton>
            </div>
          )}
        </div>
      </div>
      <hr className="border-main-gray border-2" />
      <div className="py-3">
        <FlatButton className="!bg-main-lightGray" onClick={close}>
          閉じる
        </FlatButton>
      </div>
    </WhiteModalContent>
  )
  return content
}

export default SelectEnterpriseModal
