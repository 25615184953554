import { useCallback } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../utils/network_util'
import { useSnackBar } from '../providers/snack_bar_provider'

type Handler = {
  follow: (
    id: string,
    set: boolean,
    type: 'enterprise' | 'user'
  ) => Promise<void>
}

const useFollow = (): [Handler] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const follow = useCallback(
    async (id: string, set: boolean, type: 'enterprise' | 'user') => {
      if (type === 'enterprise') {
        await apiClient.follow
          .enterpriseCreate({
            id: id,
            set: set,
          })
          .catch((e: ApiErrorResponse) => {
            handleError(e, showSnackBar)
            return null
          })
      } else if (type === 'user') {
        await apiClient.follow
          .userCreate({
            id: id,
            set: set,
          })
          .catch((e: ApiErrorResponse) => {
            handleError(e, showSnackBar)
            return null
          })
      }
    },
    []
  )

  return [{ follow: follow }]
}

export default useFollow
