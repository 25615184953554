import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const usePageViews = () => {
  const location = useLocation()
  const firstLoad = useRef(true)
  useEffect(() => {
    // 最初のレンダリングを無視
    if (firstLoad.current) {
      firstLoad.current = false
      return
    }

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location])
}

export default usePageViews
