import React, { FC } from 'react'

type SearchIconProps = {
  color: 'WHITE' | 'LIGHTGRAY'
  size?: 'SM' | 'MD' | 'LG'
}

export const SearchIcon: FC<SearchIconProps> = ({ size = 'MD', ...props }) => {
  let fillColor = '#FFFFFF'
  switch (props.color) {
    case 'WHITE':
      break
    case 'LIGHTGRAY':
      fillColor = '#b9b9b9'
      break
    default:
      fillColor = '#FFFFFF'
      break
  }
  let width = 24
  let height = 24
  switch (size) {
    case 'SM':
      width = 16
      height = 16
      break
    case 'MD':
      width = 24
      height = 24
      break
    case 'LG':
      width = 32
      height = 32
      break
    default:
      width = 24
      height = 24
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 23.996 24"
      >
        <path
          id="FontAwsome_search_"
          data-name="FontAwsome (search)"
          d="M23.67,20.75,19,16.077a1.124,1.124,0,0,0-.8-.328h-.764a9.745,9.745,0,1,0-1.687,1.687V18.2a1.124,1.124,0,0,0,.328.8L20.75,23.67a1.12,1.12,0,0,0,1.589,0l1.326-1.326A1.13,1.13,0,0,0,23.67,20.75Zm-13.921-5a6,6,0,1,1,6-6A6,6,0,0,1,9.749,15.748Z"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}
