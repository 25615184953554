import React, { FC } from 'react'
import Head2 from '../../components/heads/head2'
import { Link } from 'react-router-dom'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const ContactCompletePage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp}>
      <div className="mx-auto max-w-[640px]">
        <div className="mb-8 w-auto">
          <Head2
            title="お問い合わせ、ありがとうございました。"
            textSize="sm:text-2xl text-xl"
          />
        </div>
        <div className="w-full bg-white rounded-xl md:px-6 px-2 py-12">
          <div className="pb-4">
            <p>
              お問い合わせありがとうございます。
              <br />
              このたびは、SDGs クチコミ
              Lodgeへお問い合わせ頂き誠にありがとうございます。
              <br />
              お送り頂きました内容を確認の上、3営業日以内に折り返しご連絡させて頂きます。
              <br />
              また、ご記入頂いたメールアドレスへ、自動返信の確認メールをお送りしております。
              <br />
              <br />
              しばらく経ってもメールが届かない場合は、入力頂いたメールアドレスが間違っているか、迷惑メールフォルダに振り分けられている可能性がございます。
              <br />
              また、ドメイン指定をされている場合は、「info@sdgs-lodges.jp」からのメールが受信できるようあらかじめ設定をお願いいたします。
              <br />
              以上の内容をご確認の上、お手数ですがもう一度フォームよりお問い合わせ頂きますようお願い申し上げます。
            </p>
          </div>
          <div className="flex justify-center">
            <div className="w-fit px-4 py-2 bg-main-lightGray rounded-lg text-white">
              <Link to="/">TOPページへ戻る</Link>
            </div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default ContactCompletePage
