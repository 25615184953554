import React, { FC } from 'react'

type Head2Props = {
  title: string
  subTitle?: string
  between?: boolean
  textSize?: string
}

const Head2: FC<Head2Props> = ({
  between = false,
  textSize = 'text-2xl',
  ...props
}) => {
  return (
    <div
      className={`flex flex-wrap ${between ? 'justify-between' : 'justify-start'}`}
    >
      <h2 className={`text-main-black font-bold mr-4 py-1 ${textSize}`}>
        {props.title}
      </h2>
      {props.subTitle ? (
        <div className="flex flex-col justify-center py-1">
          <p className="text-main-darkGray text-sm">{props.subTitle}</p>
        </div>
      ) : null}
    </div>
  )
}

export default Head2
