import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { Link } from 'react-router-dom'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const PolicyLawPage: FC = () => {
  const isSp = useSpBreakPoint()

  const tableStyle: React.CSSProperties = {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: '3rem',
    textAlign: 'left',
  }

  const cellStyle: React.CSSProperties = {
    border: '1px solid black',
    padding: '0.5rem 0.75rem',
  }

  const thStyle: React.CSSProperties = {
    ...cellStyle,
    fontWeight: 'normal',
  }

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div className="bg-white rounded-xl w-full py-12 px-4 sm:px-16">
        <div className="mb-8">
          <p className="text-4xl text-main-blue font-bold">
            特定商取引法に基づく表記
          </p>
        </div>
        <div className="w-11/12 max-w-4xl mx-auto">
          <table
            className="md:w-2/3 md:mx-auto text-sm md:text-base w-full"
            style={tableStyle}
          >
            <tbody>
              <tr>
                <th style={thStyle}>販売業者</th>
                <td style={cellStyle}>株式会社 The Lodges</td>
              </tr>
              <tr>
                <th style={thStyle}>運営統括責任者</th>
                <td style={cellStyle}>代表取締役 長澤 奏喜</td>
              </tr>
              <tr>
                <th style={thStyle}>ホームページ</th>
                <td style={cellStyle}>
                  <Link to="https://the-lodges.jp/" className="text-main-blue">
                    the-lodges.jp
                  </Link>
                </td>
              </tr>
              <tr>
                <th style={thStyle}>所在地</th>
                <td style={cellStyle}>
                  〒650-0035 兵庫県神戸市中央区浪花町56 KiP内
                </td>
              </tr>
              <tr>
                <th style={thStyle}>電話番号</th>
                <td style={cellStyle}>050-1808-4630</td>
              </tr>
              <tr>
                <th style={thStyle}>メールアドレス</th>
                <td style={cellStyle}>cs@the-lodges.jp</td>
              </tr>
              <tr>
                <th style={thStyle}>追加手数料等の追加料金</th>
                <td style={cellStyle}>消費税、振込手数料</td>
              </tr>
              <tr>
                <th style={thStyle}>交換および返品（返金ポリシー）</th>
                <td style={cellStyle}>
                  本サービスはクチコミサービスであるため、交換及び返品はございません。
                </td>
              </tr>
              <tr>
                <th style={thStyle}>引渡時期</th>
                <td style={cellStyle}>
                  本サービスはクチコミサービスであるため、引渡しはございません。
                </td>
              </tr>
              <tr>
                <th style={thStyle}>受け付け可能な決済手段</th>
                <td style={cellStyle}>クレジットカード</td>
              </tr>
              <tr>
                <th style={thStyle}>決済期間</th>
                <td style={cellStyle}>
                  クレジットカード決済の場合はただちに処理されます
                </td>
              </tr>
              <tr>
                <th style={thStyle}>販売価格</th>
                <td style={cellStyle}>
                  <Link to={'/guide/plan/price'} className="text-main-blue">
                    料金プラン
                  </Link>
                  に記載。
                </td>
              </tr>
              <tr>
                <th style={thStyle}>お問い合わせ先</th>
                <td style={cellStyle}>
                  <Link to="/contact" className="text-main-blue">
                    こちら
                  </Link>
                  よりお願いします。
                </td>
              </tr>
              <tr>
                <th style={thStyle}>利用上の注意</th>
                <td style={cellStyle}>
                  <p className="mb-4">
                    会員の都合による利用中止または中途解約の場合の返金はお受けできません。
                    当社が認める場合または法令等により払戻しが義務付けられている場合を除き、返金はお受けできません。
                  </p>
                  <p className="mb-4">毎月20日に翌月分の決済が実行されます。</p>
                  <p className="mb-4">
                    19日以前に有料プランを登録した場合、当月分の日割り金額が初回登録時に決済されます。
                  </p>
                  <p className="mb-4">
                    20日以降に有料プランを登録した場合、当月分の日割り金額と翌月分の金額が初回登録時に決済されます。
                  </p>
                  <p className="mb-4">
                    プラン変更の申請がない場合、プランは自動更新されます。
                  </p>
                  <p className="mb-4">
                    プラン変更する場合は、契約プランの終了月の1日〜
                    19日までに変更をお願いします。
                  </p>
                  <p>例）2023年8月31日までプランを契約していた場合、</p>
                  <p>
                    ・2023年8月19日までに別のプランへの変更申請を行うと、翌月1日の2023年9月1日から変更後のプランが適用されます。
                  </p>
                  <p>
                    ・プランの変更申請が行われなかった場合、当月20日の2023年8月20日に同プランの1ヶ月分の利用料金が決済され、プランが自動更新されます。
                  </p>
                </td>
              </tr>
              <tr>
                <th style={thStyle}>利用規約</th>
                <td style={cellStyle}>
                  利用規約は
                  <Link to="/policy/user" className="text-main-blue">
                    こちら
                  </Link>
                  よりご参照ください。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CommonTemplate>
  )
}

export default PolicyLawPage
