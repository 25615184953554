import React, { FC } from 'react'
import TopSliderBg from '../../../../images/top-slider-bg.png'
import 'slick-carousel/slick/slick.css'
import Slider from 'react-slick'
import MainHeader from '../../../../components/headers/main_header'
import LogoWhite from '../../../../images/logo_white.png'
import FlatButton from '../../../../components/buttons/flat_button'
import { Kuchikomi } from '../../../../model/Kuchikomi'
import EnterpriseKuchikomiCard from '../kuchikomi/enterprise_kuchikomi_card'
import LoadingSpinner from '../../../../components/loading/loading_spinner'
import { useAuth } from '../../../../hooks/use_auth'
import { useNavigate } from 'react-router-dom'
import { EnterpriseSimple } from '../../../../clients/api_client'

// TODO 画面サイズに応じてスライダーのpaddingの設定やcardのmarginの調整が必要かも
// TODO スライドとして表示できる要素が2つ以下のときにはreact-slickを使わず、純jsxで書く必要がある(表示がバグる)

type TopSliderProps = {
  enterpriseKuchikomis: Array<{
    enterprise: EnterpriseSimple
    kuchikomis: Array<Kuchikomi>
  }>
  isLoading: boolean
  isSp?: boolean
}

const TOPSLIDERPROPS = {
  dots: false,
  infinite: true,
  autoplay: true,
  fade: false,
  arrows: false,
  speed: 1500,
  slidesToScroll: 1,
  pauseOnFocus: false,
  centerMode: true,
  slidesToShow: 3,
  centerPadding: '10%',
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '0%',
        slidesToShow: 1,
      },
    },
  ],
}

const TopSlider: FC<TopSliderProps> = ({ isSp = false, ...props }) => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const sliderPages = props.enterpriseKuchikomis.map((item, index) => {
    return (
      <div key={`top-slider-content-${String(index)}`}>
        <div className="flex justify-center">
          <EnterpriseKuchikomiCard
            enterprise={item.enterprise}
            relatedKuchikomis={item.kuchikomis}
          />
        </div>
      </div>
    )
  })

  // 要素数が2以下だと表示が想定通りにならないため、独自定義の要素を利用する
  const sliderElem =
    sliderPages.length < 3 ? (
      <div className="flex justify-center">{sliderPages}</div>
    ) : (
      <Slider {...TOPSLIDERPROPS}>{sliderPages}</Slider>
    )

  return (
    <div
      className="w-full bg-cover bg-no-repeat bg-center opacity-100 min-h-screen sm:h-auto sm:min-h-fit sm:block sm:py-0 py-6 flex flex-col sm:justify-between"
      style={{ backgroundImage: `url(${TopSliderBg})` }}
    >
      <div className="pt-6 sm:pt-12 px-8">
        {isSp ? (
          <div className="flex justify-center">
            <img src={LogoWhite} width="160px" />
          </div>
        ) : (
          <MainHeader bgTransparent />
        )}
        <div className="w-full sm:pt-12 pt-8">
          {isSp ? (
            <p className="text-white text-3xl font-bold text-center leading-10">
              クチコミでつくる
              <br />
              やさしい世界
            </p>
          ) : (
            <p className="text-white text-4xl font-bold text-center">
              クチコミでつくる、やさしい世界
            </p>
          )}
        </div>
      </div>
      <div className="w-full sm:pt-12 pt-10 sm:pb-16 sm:mb-0 mb-16">
        {props.isLoading ? (
          <LoadingSpinner className="mx-auto my-32" />
        ) : (
          sliderElem
        )}
      </div>
      {isSp && !user.isLogined() ? (
        <div>
          <div className="mb-4">
            <FlatButton size="md" onClick={() => navigate('/login')}>
              ログイン
            </FlatButton>
          </div>
          <div>
            <FlatButton
              size="md"
              bgColor="white"
              onClick={() => navigate('/login?register=1')}
            >
              新規登録
            </FlatButton>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TopSlider
