import React from 'react'

export const PostIcon = () => {
  return (
    <div className="min-w-[16px] w-fit">
      <svg
        className="xl:w-10 xl:h-8 w-8 h-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.218 35.321"
      >
        <path
          id="FontAwsome_comment-medical_"
          data-name="FontAwsome (comment-medical)"
          d="M21.609,33c11.934,0,21.609,6.613,21.609,16.31,0,4.183-1.807,6.772-4.811,9.788a21.568,21.568,0,0,0,4.626,8.083.675.675,0,0,1-.49,1.14,19.3,19.3,0,0,1-11.868-4.337,25.716,25.716,0,0,1-9.066,1.636C9.674,65.62,0,59.007,0,49.31S9.674,33,21.609,33Zm-8.1,18.336a.675.675,0,0,0,.675.675h4.727v4.727a.675.675,0,0,0,.675.675h4.052a.675.675,0,0,0,.675-.675V52.011h4.727a.675.675,0,0,0,.675-.675V47.284a.675.675,0,0,0-.675-.675H24.31V41.882a.675.675,0,0,0-.675-.675H19.583a.675.675,0,0,0-.675.675v4.727H14.181a.675.675,0,0,0-.675.675Z"
          transform="translate(0 -33)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
