import { useCallback, useState } from 'react'
import User from '../../../model/User'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string, cursor?: number) => Promise<void>
}

const useFollows = (
  getLimit = 20
): [Array<User>, number, number, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [follows, setFollows] = useState<Array<User>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(
    async (id: string, cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.users
        .followsDetail(id, { cursor: cursor, limit: getLimit })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setFollows(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, follows]
  )

  return [follows, totalSize, cursor, isLoading, { load: load }]
}

export default useFollows
