import React, { useEffect, useState } from 'react'
import Bg from '../../images/login/bg.png'
import LoginForm from './components/form/login_form'
import LogoWhite from '../../images/logo_white.png'
import Switch from './components/switch/switch'
import RegisterForm from './components/form/register_form'
import { useAuth } from '../../hooks/use_auth'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackBar } from '../../providers/snack_bar_provider'

const LoginPage = () => {
  const [menuToggle, setMenuToggle] = useState(true)
  const { user } = useAuth()
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search)
    if (queryParam.get('register') === '1') {
      setMenuToggle(false)
    }
  }, [])

  useEffect(() => {
    if (user.isLogined()) {
      showSnackBar(['すでにログイン済みです'], 'success')
      navigate('/')
    }
  }, [user])

  const form = menuToggle ? <LoginForm /> : <RegisterForm />

  const card = (
    <div className="rounded-xl sm:w-[360px] w-[296px] bg-white py-4 px-4">
      <div className="py-2">
        <Switch
          toggleVal={menuToggle}
          toggle={() => setMenuToggle(!menuToggle)}
        />
      </div>
      {form}
    </div>
  )

  const content = (
    <div
      className="relative w-screen min-h-screen bg-cover bg-no-repeat bg-center opacity-100 flex flex-col justify-start py-[5%]"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="">
        <div className="flex justify-center">
          <Link to="/">
            <img src={LogoWhite} width="168" />
          </Link>
        </div>
        <p className="text-white text-xl text-center pt-2">
          クチコミでつくる、やさしい世界
        </p>
      </div>
      <div className="flex justify-center px-[5%] py-4 py-auto">{card}</div>
    </div>
  )

  return content
}

export default LoginPage
