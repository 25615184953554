import React, { FC } from 'react'

type BookmarkIconProps = {
  isActive: boolean
}

export const BookmarkIcon: FC<BookmarkIconProps> = (props) => {
  return props.isActive ? <Active /> : <Disactive />
}

const Disactive = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.514"
        height="22.515"
        viewBox="0 0 22.514 22.515"
        transform="rotate(135)"
      >
        <path
          id="パス_925"
          data-name="パス 925"
          d="M22.525,11.148a3.336,3.336,0,0,0-.981-2.366L14.172,1.41a3.346,3.346,0,0,0-2.2-.975L3.469,0l.061,0C3.477,0,3.42,0,3.358,0A3.348,3.348,0,0,0,.011,3.347c0,.056,0,.112,0,.169l.429,8.45a3.352,3.352,0,0,0,.976,2.2l7.372,7.372a3.345,3.345,0,0,0,4.731,0l8.021-8.02-.617-.617.618.616A3.343,3.343,0,0,0,22.525,11.148ZM12.29,20.3a1.6,1.6,0,0,1-2.264,0L2.654,12.928a1.606,1.606,0,0,1-.467-1.051L1.758,3.429l0-.081a1.6,1.6,0,0,1,1.6-1.6l.078,0,8.452.431a1.6,1.6,0,0,1,1.051.466l7.372,7.372a1.6,1.6,0,0,1,0,2.264h0Z"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}

const Active = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.514"
        height="22.515"
        viewBox="0 0 22.514 22.515"
        transform="rotate(135)"
      >
        <path
          id="パス_925"
          data-name="パス 925"
          d="M22.525,11.148a3.336,3.336,0,0,0-.981-2.366L14.172,1.41a3.346,3.346,0,0,0-2.2-.975L3.469,0l.061,0C3.477,0,3.42,0,3.358,0A3.348,3.348,0,0,0,.011,3.347c0,.056,0,.112,0,.169l.429,8.45a3.352,3.352,0,0,0,.976,2.2l7.372,7.372a3.345,3.345,0,0,0,4.731,0l8.021-8.02-.617-.617.618.616A3.343,3.343,0,0,0,22.525,11.148Z"
          transform="translate(-0.011)"
          fill="#2791eb"
        />
      </svg>
    </div>
  )
}
