import React, { FC, useEffect } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import useInformationDetail from './hook/use_information_detail'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { getKuchikomiDate } from '../../utils/datetime_util'
import FlatButton from '../../components/buttons/flat_button'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const InformationDetailPage: FC = () => {
  const [information, isLoading, handler] = useInformationDetail()
  const pathParams = useParams<{ id: string }>()
  const isSp = useSpBreakPoint()

  useEffect(() => {
    if (pathParams.id) {
      handler.load(pathParams.id)
    }
  }, [pathParams.id])

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      {isLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          {information ? (
            <div className="bg-white relative rounded-xl p-6">
              <h2 className="font-bold text-2xl mb-2">{information.title}</h2>
              <p className="mb-6 text-main-lightGray">
                {getKuchikomiDate(information.createdAt)}
              </p>
              <div dangerouslySetInnerHTML={{ __html: information.content }} />
            </div>
          ) : (
            <p className="text-center">
              URLに誤りがあるか、お知らせが非公開です。
            </p>
          )}
          <FlatButton className="mt-12" onClick={() => window.history.back()}>
            戻る
          </FlatButton>
        </>
      )}
    </CommonTemplate>
  )
  return content
}

export default InformationDetailPage
