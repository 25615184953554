import React, { useEffect, useRef, useState } from 'react'
import BaseTemplate from '../../components/templates/base_template'
import TopSlider from './components/slider/top_slider'
import Head2 from '../../components/heads/head2'
import FlatButton from '../../components/buttons/flat_button'
import useTrend from './hooks/use_trend'
import SearchTrendCard from './components/trend/search_trend_card'
import useAttentionKeyword from './hooks/use_attention_keyword'
import useUserRanking from './hooks/use_user_ranking'
import useKuchikomi from '../../hooks/use_kuchikomi'
import useInformation from './hooks/use_information'
import SimpleInformation from '../../components/card/simple_information'
import { Link, useNavigate } from 'react-router-dom'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useEnterpriseRanking from './hooks/use_enterprise_ranking'
import EnterpriseRankingCard from './components/ranking/enterprise_ranking_card'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import PostButton from '../../components/buttons/post_button'
import useEnterpriseTopSliderContent from './hooks/use_enterprise_top_slider'
import UserCard from '../../components/card/user_card'
import LoadingSpinner from '../../components/loading/loading_spinner'
import useTopBanner from './hooks/use_top_banner'

const TopPage = () => {
  const INITIALKUCHIKOMICOUT = 5
  const TRENDCOUTLIMIT = 5
  const ATTENTIONKEYWORDCOUNTLIMIT = 24
  const INITIALCOMPAYNYRANKINGCOUNT = 5
  const INITIALUSERRANKINGCOUNT = 5
  const INITIALPOPULARKUCHIKOMICOUNT = 6
  const INFORMATIONDISPLAYCOUNT = 3

  const [showHeadder, setShowHeader] = useState(false)
  const mainRef = useRef<HTMLDivElement>(null)
  const isSp = useSpBreakPoint()

  const [newKuchikomis, newKuchikomoLoading, newKuchikomiHandler] =
    useKuchikomi({ type: 'NEW', getLimit: INITIALKUCHIKOMICOUT })
  const [popularKuchikomis, popularKuchikomiLoading, popularKuchikomHandler] =
    useKuchikomi({ type: 'POPULAR', getLimit: INITIALPOPULARKUCHIKOMICOUNT })
  const [trends] = useTrend()
  const [attentionKeywords, attentionKeywordLoading] = useAttentionKeyword({
    getLimit: ATTENTIONKEYWORDCOUNTLIMIT,
  })
  const [userRanking, userRankingLoading] = useUserRanking({
    getLimit: INITIALUSERRANKINGCOUNT,
  })
  const [enterpriseRanking, enterpriseRankingLoading] = useEnterpriseRanking({
    getLimit: INITIALCOMPAYNYRANKINGCOUNT,
  })
  const [userTopSliderContent, useTopSliderContentLoading] =
    useEnterpriseTopSliderContent()
  const [informations, informationLoading] = useInformation({
    getLimit: INFORMATIONDISPLAYCOUNT,
  })
  const [bannerHandler] = useTopBanner()

  const navigate = useNavigate()

  const sliderElem = (
    <TopSlider
      isLoading={useTopSliderContentLoading}
      enterpriseKuchikomis={userTopSliderContent}
      isSp={isSp}
    />
  )

  const sidePcBannerElem = (
    <>
      {bannerHandler.getBanners('pc_side').length > 0 && (
        <div className="mb-8">
          {bannerHandler.getBanners('pc_side').map((item, index) => {
            return (
              <div key={`side-pc-banner-${String(index)}`} className="mb-4">
                <a href={item.linkUrl}>
                  <img src={item.imageUrl} />
                </a>
              </div>
            )
          })}
        </div>
      )}
    </>
  )

  const topPcBannerElem = (
    <>
      {bannerHandler.getBanners('pc_header').map((item, index) => {
        return (
          <div key={`top-pc-banner-${String(index)}`} className="mb-8">
            <a href={item.linkUrl}>
              <img src={item.imageUrl} />
            </a>
          </div>
        )
      })}
    </>
  )

  const topSpBannerElem = (
    <>
      {bannerHandler.getBanners('sp_header').map((item, index) => {
        return (
          <div key={`top-sp-banner-${String(index)}`}>
            <a href={item.linkUrl}>
              <img src={item.imageUrl} className="w-full" />
            </a>
          </div>
        )
      })}
    </>
  )

  const newKuchikomiElem = (
    <div className="">
      {isSp ? null : (
        <div className="mb-4">
          <Head2 title="新着クチコミ" />
        </div>
      )}
      {newKuchikomoLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="bg-white rounded-xl overflow-hidden py-4">
            {newKuchikomis
              .slice(0, INITIALKUCHIKOMICOUT)
              .map((item, index, ary) => {
                return (
                  <div key={`new-kuchikomi-${String(index)}`}>
                    <KuchikomiCard
                      kuchikomi={item}
                      borderBottom={index !== ary.length - 1}
                      isSp={isSp}
                      sendLike={newKuchikomiHandler.like}
                      onClickBookmark={newKuchikomiHandler.bookmark}
                      sendReply={newKuchikomiHandler.reply}
                      deleteKuchikomi={newKuchikomiHandler.delete}
                    />
                  </div>
                )
              })}
          </div>
          <div className="mt-8">
            <FlatButton
              size="md"
              onClick={() => navigate('/search/kuchikomi?search=true')}
            >
              もっと見る
            </FlatButton>
          </div>
        </>
      )}
    </div>
  )

  const seachTrendElem = (
    <div className="">
      <div className="mb-4">
        <Head2 title="検索トレンド" />
      </div>
      <div className="bg-white rounded-xl overflow-hidden">
        {trends.slice(0, TRENDCOUTLIMIT).map((item, index) => {
          return (
            <div key={`trend-${String(index)}`}>
              <SearchTrendCard
                trend={item}
                isLastItem={
                  index === INITIALKUCHIKOMICOUT - 1 ||
                  trends.length - 1 === index
                }
              />
            </div>
          )
        })}
      </div>
    </div>
  )

  const attentionKeywordElem = (
    <>
      {attentionKeywordLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          {attentionKeywords.length > 0 && (
            <>
              <div className="mb-4">
                <Head2 title="注目キーワード" />
              </div>
              <div className="bg-white rounded-xl overflow-hidden py-4 px-4 flex justify-start flex-wrap">
                {attentionKeywords.map((item, index) => {
                  return (
                    <div
                      key={`attention-keyword-${String(index)}`}
                      className="m-2 max-w-[8rem]"
                    >
                      <Link to={`/search/kuchikomi?keyword=${item}`}>
                        <p className="rounded-xl bg-main-gray text-main-black px-3 py-1 text-sm leading-4 overflow-hidden text-ellipsis whitespace-nowrap">
                          {item}
                        </p>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </>
      )}
    </>
  )

  const overallRankingElem = (
    <div>
      <div className="mb-4">
        <Head2 title="総合ランキング" />
      </div>
      {enterpriseRankingLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="flex justify-start sm:flex-wrap sm:overflow-auto flex-nowrap overflow-x-scroll hidden-scrollbar">
            {enterpriseRanking.map((item, index) => {
              return (
                <div
                  key={`overall-company-ranking-${String(index)}`}
                  className="w-1/5 p-2 min-w-[196px]"
                >
                  <EnterpriseRankingCard ranking={item} />
                </div>
              )
            })}
          </div>
          <div className="mt-8">
            <FlatButton
              size="md"
              onClick={() => navigate('/ranking/enterprise')}
            >
              もっと見る
            </FlatButton>
          </div>
        </>
      )}
    </div>
  )

  const userRankingElem = (
    <div>
      <div className="mb-4">
        <Head2 title="ユーザーランキング" />
      </div>
      {userRankingLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="flex justify-start sm:flex-wrap sm:overflow-auto flex-nowrap overflow-x-scroll hidden-scrollbar">
            {userRanking.map((item, index) => {
              return (
                <div
                  key={`user-ranking-${String(index)}`}
                  className="xl:w-1/5 p-2 min-w-[180px]"
                >
                  <UserCard
                    showRank
                    user={item.user}
                    imgSize="sm:w-[160px] sm:h-[160px] w-[120px] h-[120px]"
                  />
                </div>
              )
            })}
          </div>
          <div className="mt-8">
            <FlatButton size="md" onClick={() => navigate('/ranking/user')}>
              もっと見る
            </FlatButton>
          </div>
        </>
      )}
    </div>
  )

  // SPでの表示なし
  const popularKuchikomiElem = (
    <div>
      <div className="mb-4">
        <Head2 title="人気クチコミ" />
      </div>
      {popularKuchikomiLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="flex xl:justify-start sm:justify-center flex-wrap">
            {popularKuchikomis.map((item, index) => {
              return (
                <div
                  key={`popular-kuchikomi-${String(index)}`}
                  className="w-full xl:w-1/2 p-2"
                >
                  <KuchikomiCard
                    kuchikomi={item}
                    rounded
                    sendLike={popularKuchikomHandler.like}
                    onClickBookmark={popularKuchikomHandler.bookmark}
                    sendReply={popularKuchikomHandler.reply}
                    deleteKuchikomi={popularKuchikomHandler.delete}
                  />
                </div>
              )
            })}
          </div>
          <div className="mt-8">
            <FlatButton
              size="md"
              onClick={() => navigate('/ranking/kuchikomi')}
            >
              もっと見る
            </FlatButton>
          </div>
        </>
      )}
    </div>
  )

  const informationElem = (
    <div>
      <div className="mb-4">
        <Head2 title="お知らせ" />
      </div>
      {informationLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="flex justify-start flex-wrap">
            <SimpleInformation informations={informations} />
          </div>
          <div className="mt-8">
            <FlatButton size="md" onClick={() => navigate('/informations')}>
              もっと見る
            </FlatButton>
          </div>
        </>
      )}
    </div>
  )

  useEffect(() => {
    // スライダー表示の関係で、トップページのみヘッダーの表示非表示を切り替える
    if (mainRef !== null) {
      window.addEventListener('scroll', () => {
        const offsetTop = mainRef.current?.offsetTop as number
        if (window.scrollY >= offsetTop) {
          setShowHeader(true)
        } else {
          setShowHeader(false)
        }
      })
    }
  }, [mainRef])

  const content = (
    <BaseTemplate isPadding={false} isShowHeader={showHeadder} isSp={isSp}>
      {sliderElem}
      {/* base_templateのwrapperClassに合わせる */}
      <div className="w-11/12 max-w-6xl py-12 mx-auto">
        <div className="flex justify-between mb-12 sm:flex-wrap lg:flex-nowrap">
          <div className="lg:w-[70%] lg:pb-0 sm:w-full sm:pb-8">
            <div>{topPcBannerElem}</div>
            {newKuchikomiElem}
          </div>
          <div className="lg:w-[25%] sm:w-full lg:block sm:flex sm:justify-between">
            <div className="hidden lg:block">{sidePcBannerElem}</div>
            <div className="lg:pb-8 sm:w-[50%] lg:w-auto sm:mx-6 lg:mx-0">
              {seachTrendElem}
            </div>
            <div className="sm:w-[50%] lg:w-auto">{attentionKeywordElem}</div>
          </div>
        </div>
        <div className="mb-12">{overallRankingElem}</div>
        <div className="mb-12">{userRankingElem}</div>
        <div className="mb-12">{popularKuchikomiElem}</div>
        <div className="mb-12">{informationElem}</div>
      </div>
      <div className="fixed bottom-8 right-12 w-fit z-10">
        <PostButton />
      </div>
    </BaseTemplate>
  )

  const spContent = (
    <BaseTemplate isPadding={false} isShowHeader={showHeadder} isSp={isSp}>
      {sliderElem}
      <div className="w-full relative" ref={mainRef}>
        {showHeadder ? (
          <div className="fixed bottom-[74px] sm:top-[70vh] right-[10px] sm:right-[6%] w-fit z-10">
            {<PostButton />}
          </div>
        ) : null}

        <div className="w-full sm:pt-4">{topSpBannerElem}</div>

        <div className="w-full mb-12">{newKuchikomiElem}</div>
        <div className="mb-12 px-4">{overallRankingElem}</div>
        <div className="mb-12 px-4">{userRankingElem}</div>
        <div className="mb-12 px-4">{informationElem}</div>
        <div className="mb-12 px-4">{seachTrendElem}</div>
        <div className="pb-12 px-4">{attentionKeywordElem}</div>
      </div>
    </BaseTemplate>
  )

  return isSp ? spContent : content
}

export default TopPage
