import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import FlatButton from '../../../components/buttons/flat_button'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useLoading } from '../../../providers/loading_provider'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import WhiteModalContent from '../white_modal_content'

type ReportModalProps = {
  kuchikomiId: string
  closeReportModal: () => void
  openReportCompleteModal: (reportNo: string) => void
}

type ReportFormProps = {
  kuchikomiReportCode: string
  message: string
}

const ReportModalContent: FC<ReportModalProps> = ({
  kuchikomiId,
  closeReportModal,
  openReportCompleteModal,
}) => {
  const apiClient = getAppClient()
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportFormProps>({
    defaultValues: {
      kuchikomiReportCode: '',
      message: '',
    },
    mode: 'onSubmit',
  })

  const report = async (formData: ReportFormProps) => {
    const data = {
      kuchikomiId,
      kuchikomiReportCode: formData.kuchikomiReportCode,
      message: formData.message,
    }
    showLoading()
    await apiClient.kuchikomis
      .reportCreate(data)
      .then((res) => {
        openReportCompleteModal(res.data.reportNumber)
        closeReportModal()
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <>
      <WhiteModalContent additionalClass="w-[700px]">
        <div className="relative bg-main-blue">
          <p className="py-4 text-white text-center text-2xl font-bold">
            クチコミ報告フォーム
          </p>
          <button
            onClick={closeReportModal}
            className="absolute top-0 right-0 mt-4 mr-4 text-white text-3xl font-bold"
          >
            ×
          </button>
        </div>
        <div className="p-4 md:p-8 pb-8">
          <div className="bg-main-gray text-center mb-6 p-4">
            <p className="text-main-red font-bold text-lg">
              はじめに、ご確認ください。
            </p>
            <p>
              クチコミ報告フォームからいただいた内容へのご返信はいたしかねます。
            </p>
            <p>予めご了承ください。</p>
          </div>
          <form onSubmit={handleSubmit(report)}>
            <div className="mb-6">
              <label className="text-left font-bold">報告の概要</label>
              <select
                className="w-full border border-main-lightGray rounded-md p-2"
                {...register('kuchikomiReportCode', {
                  required: '報告する内容を選択してください',
                })}
              >
                <option value="">報告する内容を選択してください</option>
                <option value="factually_incorrect">事実と異なる</option>
                <option value="defamation">誹謗中傷</option>
                <option value="confidential_information">社外秘情報</option>
                <option value="personal_information_leakage">
                  個人情報漏洩
                </option>
                <option value="unrelated_content">
                  該当企業・団体に関連のない内容
                </option>
                <option value="other">その他</option>
              </select>
              {errors.kuchikomiReportCode && (
                <p className="error-message">
                  {errors.kuchikomiReportCode.message}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-left font-bold">報告の詳細</label>
              <textarea
                className="w-full border border-main-lightGray rounded-md p-2"
                rows={4}
                placeholder="報告の詳細を入力してください"
                {...register('message', { required: '詳細を入力してください' })}
              />
              {errors.message && (
                <p className="error-message">{errors.message.message}</p>
              )}
            </div>

            <div className="flex justify-center">
              <FlatButton className="!bg-main-blue" size="lg" type="submit">
                上記の内容で、事務局に報告する
              </FlatButton>
            </div>
          </form>
        </div>
      </WhiteModalContent>
    </>
  )
}

export default ReportModalContent
