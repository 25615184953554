import React, { FC, useEffect, useState } from 'react'
import LoadingSpinner from '../loading/loading_spinner'
import { ApiErrorResponse, getAppClient } from '../../utils/network_util'

type OGPProps = {
  url: string
}

/**
 * OGP画像を取得して表示する関数
 * @param inputText
 * @returns
 */
export const KuchikomiOGPImage: FC<OGPProps> = (props): JSX.Element => {
  const [imageURL, setImageURL] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hidden, setHidden] = useState<boolean>(false)
  const api = getAppClient()

  useEffect(() => {
    getOGPImage(props.url)
  }, [props.url])

  const getOGPImage = async (url: string) => {
    await api.ogp
      .imageCreate({
        url: url,
      })
      .then((res) => {
        const data = res.data
        setImageURL(data.url)
        setTitle(data.title)
        setDescription(data.description)
      })
      .catch((e: ApiErrorResponse) => {
        console.log(e)
        // エラー発生時は表示しない
        setHidden(true)

        return null
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (hidden) {
    return <></>
  }

  return (
    <div className="w-full mb-3">
      {isLoading ? (
        <div className="py-4">
          <LoadingSpinner className="mx-auto" />
        </div>
      ) : (
        <a
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
          className="block sm:flex relative text-sm w-full rounded-md h-fit border-main-gray border-2"
        >
          <div
            className="w-full sm:w-[150px] h-[150px] xs:h-[200px] sm:h-[150px] bg-center bg-cover rounded-t-md sm:rounded-l-md sm:rounded-r-none"
            style={{
              backgroundImage: `url(${imageURL})`,
            }}
          />
          <div className="w-full sm:w-[calc(100%-150px)] p-3">
            <p className="font-bold line-clamp-2 sm:line-clamp-1">{title}</p>
            {description && (
              <p className="text-xs leading-normal mb-1 line-clamp-2 xs:line-clamp-4">
                {description}
              </p>
            )}
            <p className="text-main-lightGray text-xs line-clamp-1 pt-1 sm:pt-0">
              {props.url}
            </p>
          </div>
        </a>
      )}
    </div>
  )
}
