import React, { FC, ReactNode } from 'react'
import CommonTemplate from '../../../../components/templates/common_template'
import Head2 from '../../../../components/heads/head2'
import User from '../../../../model/User'
import MyPageNavigation from '../navigation/mypage_navigation'
import LoadingSpinner from '../../../../components/loading/loading_spinner'

type MyPageTemplateProps = {
  head: string
  subHead?: string
  user: User | null
  userLoading: boolean
  children: ReactNode
  isSp?: boolean
}

const MyPageTemplate: FC<MyPageTemplateProps> = (props) => {
  const navigation = (
    <div>
      {props.userLoading && <LoadingSpinner className="mx-auto" />}
      {props.user ? (
        <MyPageNavigation user={props.user} isSp={props.isSp} />
      ) : null}
    </div>
  )
  const content = <div>{props.children}</div>

  return (
    <CommonTemplate isSp={props.isSp}>
      <div className="pb-4">
        <Head2 title={props.head} between subTitle={props.subHead} />
      </div>
      <div className="md:flex md:justify-between">
        <div className="md:min-w-[280px] md:max-w-[350px] md:w-[30%] md:pr-6 md:pb-0 w-full pb-4">
          {navigation}
        </div>
        <div className="md:w-[70%] w-full">{content}</div>
      </div>
    </CommonTemplate>
  )
}

export default MyPageTemplate
