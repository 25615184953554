import React, { FC } from 'react'
import { FluctuationType } from '../../types/enterprise'

type RankFluctuationIconProps = {
  type: FluctuationType
}

export const RankFluctuationIcon: FC<RankFluctuationIconProps> = (props) => {
  let elem = <RankUp />
  switch (props.type) {
    case 'up':
      elem = <RankUp />
      break
    case 'down':
      elem = <RankDown />
      break
    case 'stay':
      elem = <RankStay />
      break
    case 'new':
      elem = <RankNew />
      break
  }

  return elem
}

const RankUp = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.448"
        height="37.448"
        viewBox="0 0 37.448 37.448"
      >
        <path
          id="FontAwsome_arrow-down_"
          data-name="FontAwsome (arrow-down)"
          d="M24.389,11.4l1.329,1.329a1.431,1.431,0,0,1,0,2.029L14.088,26.4a1.431,1.431,0,0,1-2.029,0L.422,14.761a1.431,1.431,0,0,1,0-2.029L1.751,11.4a1.438,1.438,0,0,1,2.053.024l6.872,7.213V1.437A1.433,1.433,0,0,1,12.112,0h1.915a1.433,1.433,0,0,1,1.437,1.437v17.2l6.872-7.213a1.428,1.428,0,0,1,2.053-.024Z"
          transform="translate(18.484 37.448) rotate(-135)"
          fill="#264ceb"
        />
      </svg>
    </div>
  )
}

const RankDown = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.448"
        height="37.448"
        viewBox="0 0 37.448 37.448"
      >
        <path
          id="FontAwsome_arrow-down_"
          data-name="FontAwsome (arrow-down)"
          d="M24.389,11.4l1.329,1.329a1.431,1.431,0,0,1,0,2.029L14.088,26.4a1.431,1.431,0,0,1-2.029,0L.422,14.761a1.431,1.431,0,0,1,0-2.029L1.751,11.4a1.438,1.438,0,0,1,2.053.024l6.872,7.213V1.437A1.433,1.433,0,0,1,12.112,0h1.915a1.433,1.433,0,0,1,1.437,1.437v17.2l6.872-7.213a1.428,1.428,0,0,1,2.053-.024Z"
          transform="translate(0 18.484) rotate(-45)"
          fill="#e32929"
        />
      </svg>
    </div>
  )
}

const RankStay = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.819"
        height="26.14"
        viewBox="0 0 26.819 26.14"
      >
        <path
          id="FontAwsome_arrow-down_"
          data-name="FontAwsome (arrow-down)"
          d="M24.389,11.4l1.329,1.329a1.431,1.431,0,0,1,0,2.029L14.088,26.4a1.431,1.431,0,0,1-2.029,0L.422,14.761a1.431,1.431,0,0,1,0-2.029L1.751,11.4a1.438,1.438,0,0,1,2.053.024l6.872,7.213V1.437A1.433,1.433,0,0,1,12.112,0h1.915a1.433,1.433,0,0,1,1.437,1.437v17.2l6.872-7.213a1.428,1.428,0,0,1,2.053-.024Z"
          transform="translate(0 26.14) rotate(-90)"
          fill="#b9b9b9"
        />
      </svg>
    </div>
  )
}

const RankNew = () => {
  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="11"
        viewBox="0 0 26 11"
      >
        <text
          id="NEW"
          transform="translate(13 10)"
          fill="#e32929"
          fontSize="11"
          fontFamily="YuGo-Bold, YuGothic"
          fontWeight="700"
        >
          <tspan x="-12.887" y="0">
            NEW
          </tspan>
        </text>
      </svg>
    </div>
  )
}
