import React, { FC, useState } from 'react'
import { KuchikomiOGPImage } from './kuchikomi_ogp_image'

type RenderKuchikomiTextProps = {
  inputText: string
  onClickHashtag?: (hashtag: string) => void
  isCreatePage?: boolean
}

/**
 * textarea、DBに保存されているテキストを解析してハッシュタグをスタイリングする関数
 * @param inputText
 * @returns
 */
export const RenderKuchikomiText: FC<RenderKuchikomiTextProps> = (
  props
): JSX.Element => {
  const [urlList, setUrlList] = useState<string[]>([])

  const clickHashtag = (hashtag: string) => {
    if (props.onClickHashtag) {
      return props.onClickHashtag(hashtag)
    }
  }

  // nとtで改行
  const lines = props.inputText.split(/\n/)
  const content = lines.map((line, lineIndex) => {
    if (line === '') {
      // HTMLで空白行を表示するために空白スペースを挿入
      return <div key={lineIndex}>&nbsp;</div>
    }

    const parts = line.split(/(\s+)/).map((part, partIndex) => {
      if (part === '\t') {
        return (
          <span key={`${lineIndex}-${partIndex}`} style={{ whiteSpace: 'pre' }}>
            {'\t'}
          </span>
        )
      }
      if (part.startsWith('#')) {
        return (
          <span
            key={`${lineIndex}-${partIndex}`}
            style={{ color: 'blue' }}
            onClick={() => clickHashtag(part)}
            className={`${props.onClickHashtag ? 'cursor-pointer' : ''}`}
          >
            {part}
          </span>
        )
      }
      if (part.startsWith('@')) {
        return (
          <span key={`${lineIndex}-${partIndex}`} style={{ color: 'blue' }}>
            {part}
          </span>
        )
      }
      // URLの場合リンクにする
      if (part.match(/(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/)) {
        if (!urlList.includes(part)) {
          setUrlList([...urlList, part])
        }
        return (
          <a
            key={`${lineIndex}-${partIndex}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-main-blue underline"
          >
            {part}
          </a>
        )
      }

      return <span key={`${lineIndex}-${partIndex}`}>{part}</span>
    })

    // ここでdivとして各行をラップします。これにより改行が正しく反映されます。
    return <div key={lineIndex}>{parts}</div>
  })

  return (
    <>
      {content}

      {!props.isCreatePage && urlList.length > 0 && (
        <div className="pt-4">
          {urlList.map((url, index) => {
            return <KuchikomiOGPImage url={url} key={index}></KuchikomiOGPImage>
          })}
        </div>
      )}
    </>
  )
}
