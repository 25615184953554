import React, { FC } from 'react'
import Head2 from '../../components/heads/head2'
import { Link } from 'react-router-dom'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const RegisterEnterpriseCompletePage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      <div className="flex justify-center">
        <div>
          <div className="mb-8">
            <Head2 title="会員登録申請完了" />
          </div>
          <div className="max-w-[640px] bg-white rounded-xl md:px-6 px-2 py-12">
            <div className="pb-4">
              <p>
                会員登録申請が完了しました。
                <br />
                この度はSDGs クチコミ
                Lodgeに企業・団体登録をお申し込みくださいまして、誠にありがとうございます。
                <br />
                お客様の入力いただいた情報をもとに登録審査を行なわせていただきます。
                <br />
                <br />
                審査の結果につきましては、1〜3営業日以内にメールにてご案内いたしますので、しばらくお待ちくださいますようお願いいたします。
                <br />
                ご入力いただいたメールアドレス宛に自動返信メールを送信しております。ご確認ください。
                <br />
                <br />
                また、ドメイン指定をされている場合は、「info@sdgs-lodges.jp」からのメールが受信できるようあらかじめ設定をお願いいたします。
              </p>
            </div>
            <div className="mb-8">
              <p>
                ご入力いただきました内容を確認後、担当者よりご連絡させていただきます。
              </p>
              <p>
                お急ぎのご連絡やご不明点などございましたら、
                <Link to="/contact">
                  <span className="text-main-blue">こちら</span>
                </Link>
                からご連絡ください。
              </p>
            </div>
            <div className="flex justify-center">
              <div className="w-fit px-4 py-2 bg-main-blue rounded-lg text-white">
                <Link to="/">TOPページへ戻る</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default RegisterEnterpriseCompletePage
