import React, { FC, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import UserIcon from '../../images/icons/user.png'
import { useAuth } from '../../hooks/use_auth'
import { useNavigate } from 'react-router-dom'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { LicenseType } from '../../enums/LicenseType'
import { getAppClient } from '../../utils/network_util'
import { useLoading } from '../../providers/loading_provider'

const MypageLink: FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()

  const [formToken, setFormToken] = useState('')
  const [formKey, setFormKey] = useState('')
  const [formUserId, setFormUserId] = useState('')

  const formRef = useRef<HTMLFormElement>(null)

  const transferMypage = () => {
    if (!user.isLogined()) {
      showSnackBar(['ログインしてください'], 'error')
      navigate('login')
      return
    }

    // ユーザーの場合
    if (user.licenseType === LicenseType.NORMAL) {
      navigate('/mypage')
      return
    }

    // 企業の場合
    if (user.licenseType === LicenseType.CORP) {
      transferDashboard()
      return
    }

    // その他
    showSnackBar(
      [
        'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
      ],
      'error'
    )
  }

  // ダッシュボードへ遷移する
  const transferDashboard = async () => {
    if (!user.isLogined() || user.licenseType !== LicenseType.CORP) {
      return
    }

    showLoading()

    try {
      const api = getAppClient()

      // キーの設定
      const key = uuidv4()
      setFormKey(key)

      // トークンの設定
      const response = await api.mypage
        .tokenDashboardCreate({
          key: key,
        })
        .then((response) => {
          return response.data
        })
      setFormToken(response.token)
      setFormUserId(response.userId)

      // フォームの送信
      setTimeout(() => {
        hideLoading()
        formRef.current?.submit()
      }, 100)
    } catch (e) {
      hideLoading()
      console.error(e)
      showSnackBar(
        [
          'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
        ],
        'error'
      )
    }
  }

  return (
    <>
      {user.isLogined() && (
        <div
          className="mx-2 px-1 text-center cursor-pointer"
          onClick={transferMypage}
        >
          <img src={UserIcon} className="h-[24px] inline-block" />
          <p className="text-white text-xs">マイページ</p>
          {user.licenseType === LicenseType.CORP && (
            <form
              ref={formRef}
              action={`${process.env.REACT_APP_DASHBOARD_BASE_URL}/auth/token/dashboard/check`}
              className="hidden"
              method="post"
            >
              <input type="hidden" name="token" value={formToken} />
              <input type="hidden" name="key" value={formKey} />
              <input type="hidden" name="user_id" value={formUserId} />
            </form>
          )}
        </div>
      )}
    </>
  )
}

export default MypageLink
