import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import PrivacyPolicyContent from './content/privacy_policy_content'

const PolicyPrivacyPage: FC = () => {
  const isSp = useSpBreakPoint()

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <div className="bg-white rounded-xl w-full py-12 px-4 sm:px-16">
        <div className="mb-8">
          <p className="text-4xl text-main-blue font-bold">
            プライバシーポリシー（個人情報保護規定）
          </p>
        </div>
        <PrivacyPolicyContent />
      </div>
    </CommonTemplate>
  )
}

export default PolicyPrivacyPage
