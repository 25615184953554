import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Enterprise from '../../../model/Enterprise'
import useFollow from '../../../hooks/use_follow'
import { useAuth } from '../../../hooks/use_auth'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (id: string) => Promise<void>
  follow: (set: boolean) => Promise<void>
}

const useEnterpriseDetail = (): [Enterprise | null, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setLoading] = useState(false)
  const [enterpriseDetail, setEnterpriseDetail] = useState<Enterprise | null>(
    null
  )
  const [followHandler] = useFollow()
  const { user } = useAuth()
  const { showSnackBar } = useSnackBar()

  const follow = useCallback(
    async (set: boolean) => {
      if (enterpriseDetail === null || !user.isLogined()) {
        return
      }
      // 先に更新
      const nextEnterpriseDetail = { ...enterpriseDetail }
      nextEnterpriseDetail.followed = set
      setEnterpriseDetail(nextEnterpriseDetail)

      await followHandler
        .follow(enterpriseDetail.id, set, 'enterprise')
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
    },
    [enterpriseDetail]
  )

  const load = useCallback(async (id: string) => {
    setLoading(true)
    await apiClient.enterprises
      .enterprisesDetail(id)
      .then((res) => {
        const data = res.data
        setEnterpriseDetail(data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return [enterpriseDetail, isLoading, { load: load, follow: follow }]
}

export default useEnterpriseDetail
