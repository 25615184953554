import { Api } from '../clients/api_client'
import { ServerityType } from '../providers/snack_bar_provider'

export const getAppClient = (): Api<unknown> => {
  const token = window.localStorage.getItem('ACCESS-TOKEN')
  return new Api({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    baseApiParams: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  })
}

export interface ApiErrorResponse extends Response {
  error: {
    errors: {
      [key: string]: string[]
    }
    message: string | null
  }
}

export const handleError = (
  errorResponse: ApiErrorResponse,
  showSnackBar: (messages: string[], type: ServerityType) => void
) => {
  if (errorResponse.status === 500) {
    showSnackBar(
      [
        'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
      ],
      'error'
    )
  } else if (errorResponse.status === 400) {
    if (Object.keys(errorResponse.error.errors).length > 0) {
      showSnackBar(Object.values(errorResponse.error.errors).flat(), 'error')
    } else if (errorResponse.error.message) {
      showSnackBar([errorResponse.error.message], 'error')
    } else {
      showSnackBar(
        [
          'エラーが発生しました。お手数ですがお問い合わせよりお問い合わせください。',
        ],
        'error'
      )
    }
  } else {
    // 500と400以外のエラー
    showSnackBar(
      ['エラーが発生しました。通信環境をご確認の上、再度お試しください。'],
      'error'
    )
  }
}
