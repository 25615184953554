import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

const DEFAULT_LIMIT = 100

type UseAttentionKeywordProps = {
  getLimit?: number
}

type Handler = {
  load: () => Promise<void>
}

const useAttentionKeyword = (
  props: UseAttentionKeywordProps
): [Array<string>, boolean, Handler] => {
  const limit = props.getLimit || DEFAULT_LIMIT
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [keywords, setKeywords] = useState<Array<string>>([])

  const load = useCallback(async () => {
    setIsLoading(true)

    await apiClient.keywords
      .attentionList({
        limit: limit,
      })
      .then((res) => {
        const data = res.data.data
          .map((item) => item.keyword)
          .filter((item) => item) as Array<string>
        const nextKeywords = keywords.concat(data)
        setKeywords(nextKeywords)
        setApiCursor(apiCursor + limit)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, keywords])

  useEffect(() => {
    load()
  }, [])

  return [keywords, isLoading, { load: load }]
}

export default useAttentionKeyword
