import { useCallback, useEffect, useState } from 'react'
import Trend from '../../../model/Trend'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: () => Promise<void>
}

const useTrend = (): [Array<Trend>, boolean, Handler] => {
  const GETLIMIT = 100
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiCursor, setApiCursor] = useState<number>(0)
  const [trends, setTrends] = useState<Array<Trend>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(async () => {
    setIsLoading(true)
    await apiClient.trends
      .trendsList({
        cursor: apiCursor,
        limit: GETLIMIT,
      })
      .then((res) => {
        const data = res.data.data
        const nextTrends = trends.concat(data)
        setTrends(nextTrends)
        setApiCursor(apiCursor + GETLIMIT)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiCursor, trends])

  useEffect(() => {
    load()
  }, [])

  return [trends, isLoading, { load: load }]
}

export default useTrend
