import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (userId: string) => Promise<void>
}

const useKeyword = (): [Array<string>, boolean, Handler] => {
  const GETLIMIT = 100
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [keywords, setKeywords] = useState<Array<string>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(
    async (userId: string) => {
      setIsLoading(true)
      await apiClient.keywords
        .attentionList({
          limit: GETLIMIT,
          userId: userId,
        })
        .then((res) => {
          const data = res.data.data
            .map((item) => item.keyword)
            .filter((item) => item) as Array<string>
          const nextKeywords = keywords.concat(data)
          setKeywords(nextKeywords)
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [keywords]
  )

  return [keywords, isLoading, { load: load }]
}

export default useKeyword
