import { getRecoil, setRecoil } from 'recoil-nexus'
import { currentUser } from './atoms'
import { LicenseType, LicenseTypeValue } from '../../enums/LicenseType'
import { CurrentUser } from './type'

export const AuthModule = {
  init: () => {
    setRecoil(
      currentUser,
      new CurrentUser('user', '', LicenseType.NORMAL, 'loading', null, false)
    )
  },
  activate: (
    displayName: string,
    iconUrl: string,
    licenseType: LicenseTypeValue,
    kuchikomiEnterpriseId: number | null,
    isPaidPlan: boolean
  ) => {
    setRecoil(
      currentUser,
      new CurrentUser(
        displayName,
        iconUrl,
        licenseType,
        'authenticated',
        kuchikomiEnterpriseId,
        isPaidPlan
      )
    )
  },
  deactivate: () => {
    setRecoil(
      currentUser,

      new CurrentUser(null, null, null, 'unauthenticated', null, false)
    )
  },
  update: (name: string, iconUrl: string) => {
    const current = getRecoil(currentUser)

    if (current === null) {
      throw new Error('User is not authenticated')
    }

    setRecoil(
      currentUser,
      new CurrentUser(
        name,
        iconUrl,
        current.licenseType,
        current.authStatus,
        current.kuchikomiEnterpriseId,
        current.isPaidPlan
      )
    )
  },
}
