import React, { FC } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useForm } from 'react-hook-form'
import { FormErrorMessage } from '../../constants/message'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { MainTextInput } from '../../components/input/main_text_input'
import { MainTextarea } from '../../components/input/main_textarea'
import { CheckMessageInput } from '../../components/input/check_message_input'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { useSnackBar } from '../../providers/snack_bar_provider'
import { useLoading } from '../../providers/loading_provider'
import PolicyContent from '../policy/content/privacy_policy_content'

type ContactFormProps = {
  enterpriseName: string | null
  departmentName: string | null
  name: string
  email: string
  tel: string | null
  subject: string
  message: string
  privacyPolicyAgreed: boolean
}

const ContactPage: FC = () => {
  const isSp = useSpBreakPoint()
  const navigate = useNavigate()
  const location = useLocation()
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()

  const initialValues = (location.state as ContactFormProps) || {
    enterpriseName: '',
    departmentName: '',
    name: '',
    email: '',
    tel: '',
    subject: '',
    message: '',
    privacyPolicyAgreed: false,
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormProps>({
    defaultValues: initialValues,
    mode: 'onSubmit',
  })

  const onSubmit = async (data: ContactFormProps) => {
    showLoading()
    await apiClient.contacts
      .confirmCreate(data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        navigate('/contact/confirm', { state: data })
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  return (
    <CommonTemplate isSp={isSp}>
      <p className="font-bold text-2xl mb-6">お問い合わせ</p>
      <div className="bg-white relative rounded-xl">
        <div className="p-4 sm:p-10">
          <div className="max-w-xl">
            <MainTextInput
              type="text"
              required={false}
              label="企業名・団体名"
              options={register('enterpriseName')}
            />
            <MainTextInput
              type="text"
              required={false}
              label="部署名"
              options={register('departmentName')}
            />
            <MainTextInput
              type="text"
              required
              label="名前"
              options={register('name', {
                required: FormErrorMessage.required,
              })}
              error={errors.name?.message}
            />
            <MainTextInput
              type="email"
              required
              label="メールアドレス"
              options={register('email', {
                required: FormErrorMessage.required,
              })}
              error={errors.email?.message}
            />
            <MainTextInput
              type="text"
              required={false}
              label="電話番号"
              options={register('tel')}
            />
            <MainTextInput
              type="text"
              required
              label="件名"
              options={register('subject', {
                required: FormErrorMessage.required,
              })}
              error={errors.subject?.message}
            />
            <MainTextarea
              required
              label="お問い合わせ本文"
              options={register('message', {
                required: FormErrorMessage.required,
              })}
              error={errors.message?.message}
            />
          </div>
          <p className="font-bold text-lg mt-4 mb-2">
            プライバシーポリシーについて
          </p>
          <div className="flex w-auto mb-6">
            <Link
              to="/policy/privacy"
              target="_blank"
              className="flex items-center"
            >
              <span className="font-bold text-main-blue underline">
                新規タブを開いて、プライバシーポリシーを確認する
              </span>
            </Link>
          </div>
          <div className="border border-black mb-4 p-6 max-h-[200px] overflow-y-auto">
            <PolicyContent />
          </div>
          <CheckMessageInput
            label="上記のプライバシーポリシーについて同意する"
            options={register('privacyPolicyAgreed', {
              required: true,
            })}
          />
          {errors.privacyPolicyAgreed && (
            <p className="error-message text-center">
              プライバシーポリシーへの同意は必須です。
            </p>
          )}
          <div className="flex max-sm:flex-col-reverse items-center justify-center pt-8">
            <FlatButton
              className="!mx-0 !bg-main-lightGray"
              onClick={() => navigate('/')}
            >
              TOPページへ戻る
            </FlatButton>
            <div className="sm:ml-8 sm:mb-0 mb-6 items-center">
              <FlatButton
                className="!w-[200px]"
                onClick={handleSubmit(onSubmit)}
              >
                確認する
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )
}

export default ContactPage
