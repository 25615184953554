import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CommonTemplate from '../../components/templates/common_template'
import FlatButton from '../../components/buttons/flat_button'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'

const PasswordResetSendPage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { email } = location.state
  const isSp = useSpBreakPoint()

  return (
    <CommonTemplate isSp={isSp}>
      <div className="bg-white rounded-xl w-full max-w-xl mx-auto p-6 text-left">
        <h2 className="text-2xl font-bold mb-8">メール送信完了</h2>
        <p>{email} 宛にメールを送信しました。</p>
        <p className="mb-8">メールボックスよりご確認ください。</p>
        <FlatButton
          className="!mx-auto !bg-main-lightGray"
          onClick={() => navigate('/')}
        >
          TOPページへ戻る
        </FlatButton>
      </div>
    </CommonTemplate>
  )
}

export default PasswordResetSendPage
