import React, { FC, useEffect } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import EnterpriseSearchForm from './components/form/enterprise_search_form'
import Head2 from '../../components/heads/head2'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import SearchTab from './components/tab/search_tab'
import EnterpriseCard from '../../components/card/enterprise_card'
import { useToggle } from 'react-use'
import useEnterpriseSearchForm from './hooks/use_enterprise_search_form'
import CommonTemplate from '../../components/templates/common_template'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from '../../components/loading/loading_spinner'

// TODO 他検索ページといい感じに共通化したい
const EnterpriseSearchPage: FC = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [
    companies,
    size,
    currentCursor,
    isLoading,
    isSearched,
    corporateSearchHandler,
  ] = useEnterpriseSearchForm({ getLimit: PAGE_SIZE })
  const [formShow, toggle] = useToggle(true)
  const [queryParam] = useSearchParams()

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (companies.length === 0) {
      toggle(true)
    }
  }, [companies])

  useEffect(() => {
    const searchParams = {
      keyword: '',
      sdgsCategory: '',
      enterpriseCategory: '',
      industry: '',
      region: '',
    }
    let isQuery = false
    const querySearchKeyMap = [
      {
        queryParamKey: 'keyword',
        searchParamKey: 'keyword',
      },
      {
        queryParamKey: 'sdgs-category',
        searchParamKey: 'sdgsCategory',
      },
      {
        queryParamKey: 'enterprise-category',
        searchParamKey: 'enterpriseCategory',
      },
      {
        queryParamKey: 'industry',
        searchParamKey: 'industry',
      },
      {
        queryParamKey: 'region',
        searchParamKey: 'region',
      },
    ] as const

    querySearchKeyMap.forEach((item) => {
      if (queryParam.has(item.queryParamKey)) {
        const val = queryParam.get(item.queryParamKey) as string
        corporateSearchHandler.setValue(item.searchParamKey, val)
        searchParams[item.searchParamKey] = val
        isQuery = true
      }
    })

    if (isQuery) {
      corporateSearchHandler.submit(searchParams)
    }
  }, [])

  const searchResultElem = (
    <div>
      <div className="mb-4 px-4">
        <Head2
          title="企業・団体一覧"
          subTitle={
            companies.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + companies.length
                }件`
              : ''
          }
        />
      </div>
      <div className="flex md:justify-between justify-center flex-wrap">
        {companies.length > 0 ? (
          companies.map((item, index) => {
            return (
              <div
                key={`company-${String(index)}`}
                className="p-2 md:p-3 lg:p-4 w-full md:w-1/2"
              >
                <EnterpriseCard isSp={isSp} enterprise={item} />
              </div>
            )
          })
        ) : (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索結果がありません
            </p>
          </div>
        )}
      </div>
    </div>
  )

  const paginationCursor =
    companies.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection()
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  const searchTab = (
    <SearchTab
      isSp={isSp}
      activeIndex={0}
      isFormShow={formShow}
      toggleContent={toggle}
      form={
        <EnterpriseSearchForm
          keywordOptions={corporateSearchHandler.register('keyword')}
          sdgsCategoryOptions={corporateSearchHandler.register('sdgsCategory')}
          enterpriseCategoryOptions={corporateSearchHandler.register(
            'enterpriseCategory'
          )}
          industryOptions={corporateSearchHandler.register('industry')}
          regionOptions={corporateSearchHandler.register('region')}
          onSubmit={corporateSearchHandler.handleSubmit((data) =>
            corporateSearchHandler.submit(data)
          )}
          toggleForm={toggle}
        />
      }
    />
  )

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      <div>
        <div className="mt-4 mb-8">{searchTab}</div>
        {isLoading && <LoadingSpinner className="mx-auto" />}

        {isSearched && !isLoading && (
          <>
            <div ref={IndexSectionRef} />
            <div className="mb-8">{searchResultElem}</div>
            <div className="pb-8">{paginationCursor}</div>
          </>
        )}
        {!isSearched && (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索条件を入力してください
            </p>
          </div>
        )}
      </div>
    </CommonTemplate>
  )

  return content
}

export default EnterpriseSearchPage
