import React from 'react'
import PlatinumMemberImg from '../../../../images/member_rank/platinum.png'
import GoldMemberImg from '../../../../images/member_rank/gold.png'
import SilverMemberImg from '../../../../images/member_rank/silver.png'
import BronzeMemberImg from '../../../../images/member_rank/bronze.png'
import RegularMemberImg from '../../../../images/member_rank/regular.png'

const AboutMemberRankCard = () => {
  return (
    <div className="p-4 bg-white">
      <div>
        <p className="text-lg font-bold pb-4">会員ランクについて</p>
      </div>
      <div className="px-2">
        <div className="pb-6">
          <p className="leading-6">
            SDGs クチコミ
            Lodgeでは、コミュニティの活性化を促進し、ユーザーの持続的なエンゲージメントを
            <wbr />
            サポートするために、会員ランクシステムを導入しています。
            <wbr />
            このシステムは、いいねポイントの累計贈呈数によって定められます。
            <br />
            会員ランクは以下のように構成されています：
          </p>
        </div>
        <div className="pb-6">
          <table className="w-full">
            <thead className="border-b border-b-main-lightGray">
              <tr className="w-full">
                <th className="py-2 text-sm font-bold w-[20%] text-center">
                  会員ランク
                </th>
                <th className="py-2 text-sm font-bold w-[40%] text-center">
                  ランクアップに必要な
                  <wbr />
                  累計ポイント数
                </th>
                <th className="py-2 text-sm font-bold w-[40%] text-center">
                  ランクアップ時に
                  <wbr />
                  ポイントを贈呈
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="w-full py-2 border-b border-b-main-lightGray">
                <td className="py-2">
                  <div className="flex justify-center p-1">
                    <img src={PlatinumMemberImg} width="56px"></img>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">50,000</p>
                    <p className="text-xs my-auto">
                      ポイント
                      <wbr />
                      以上
                    </p>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">10,000</p>
                    <p className="text-xs my-auto">ポイント</p>
                  </div>
                </td>
              </tr>
              <tr className="w-full py-2 border-b border-b-main-lightGray">
                <td className="py-2">
                  <div className="flex justify-center p-1">
                    <img src={GoldMemberImg} width="56px"></img>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">30,000</p>
                    <p className="text-xs my-auto">
                      ポイント
                      <wbr />
                      以上
                    </p>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">5,000</p>
                    <p className="text-xs my-auto">ポイント</p>
                  </div>
                </td>
              </tr>
              <tr className="w-full py-2 border-b border-b-main-lightGray">
                <td className="py-2">
                  <div className="flex justify-center p-1">
                    <img src={SilverMemberImg} width="56px"></img>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">10,000</p>
                    <p className="text-xs my-auto">
                      ポイント
                      <wbr />
                      以上
                    </p>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">3,000</p>
                    <p className="text-xs my-auto">ポイント</p>
                  </div>
                </td>
              </tr>
              <tr className="w-full py-2 border-b border-b-main-lightGray">
                <td className="py-2">
                  <div className="flex justify-center p-1">
                    <img src={BronzeMemberImg} width="56px"></img>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">1,000</p>
                    <p className="text-xs my-auto">
                      ポイント
                      <wbr />
                      以上
                    </p>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold pr-2">1,000</p>
                    <p className="text-xs my-auto">ポイント</p>
                  </div>
                </td>
              </tr>
              <tr className="w-full py-2 border-b border-b-main-lightGray">
                <td className="py-2">
                  <div className="flex justify-center p-1">
                    <img src={RegularMemberImg} width="56px"></img>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold">-</p>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex justify-center flex-wrap">
                    <p className="text-2xl font-bold">-</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pb-6">
          <p className="leading-6">
            ランクが上がるごとに、ユーザーはさまざまな特典や認識の証としてポイントが付与され、
            <wbr />
            これらのポイントはSDGs クチコミ
            Lodge内での特別なサービスやイベントへのアクセスに使用できます。
            <wbr />
            随時、アップデート情報については更新していきます。
          </p>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default AboutMemberRankCard
