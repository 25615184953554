import React, { FC } from 'react'

type HomeIconProps = {
  color: 'WHITE' | 'LIGHTGRAY'
}

export const HomeIcon: FC<HomeIconProps> = (props) => {
  let fillColor = '#FFFFFF'
  switch (props.color) {
    case 'WHITE':
      break
    case 'LIGHTGRAY':
      fillColor = '#b9b9b9'
      break
    default:
      fillColor = '#FFFFFF'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="18.663"
        viewBox="0 0 24 18.663"
      >
        <path
          id="FontAwsome_home_"
          data-name="FontAwsome (home)"
          d="M11.667,36.892,3.985,43.218v6.828a.667.667,0,0,0,.667.667L9.32,50.7a.667.667,0,0,0,.663-.667V46.047a.667.667,0,0,1,.667-.667h2.666a.667.667,0,0,1,.667.667v3.985a.667.667,0,0,0,.667.669l4.667.013a.667.667,0,0,0,.667-.667V43.214L12.3,36.892a.508.508,0,0,0-.637,0Zm12.134,4.3-3.483-2.871V32.55a.5.5,0,0,0-.5-.5H17.484a.5.5,0,0,0-.5.5v3.025l-3.73-3.069a2,2,0,0,0-2.541,0L.166,41.192a.5.5,0,0,0-.067.7l1.062,1.292a.5.5,0,0,0,.7.068l9.8-8.072a.508.508,0,0,1,.637,0l9.8,8.072a.5.5,0,0,0,.7-.067L23.871,41.9a.5.5,0,0,0-.071-.705Z"
          transform="translate(0.015 -32.05)"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}
