import React, { FC } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

export const CheckMessageInput: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseFormRegisterReturn<any>
  label: string
  readOnly?: boolean
  check?: boolean
}> = (props) => {
  return (
    <div className="flex justify-center items-center">
      <label className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="cursor-pointer mr-2"
          {...props.options}
          disabled={props.readOnly}
          checked={props.check}
        />
        <p>{props.label}</p>
      </label>
    </div>
  )
}
