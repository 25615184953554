import React from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import AboutMemberRankCard from './components/cards/about_member_rank_card'

const MyPageAboutMemberRank = () => {
  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()

  const cardElem = (
    <div>
      <AboutMemberRankCard />
    </div>
  )

  const content = (
    <MyPageTemplate
      head="マイページ"
      user={user}
      isSp={isSp}
      userLoading={userLoading}
    >
      <div className="rounded-xl overflow-hidden bg-white">{cardElem}</div>
    </MyPageTemplate>
  )

  return content
}

export default MyPageAboutMemberRank
