import React, { FC, useEffect, useState } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import EnterpriseDetailCard from '../../components/card/enterprise_detail_card'
import useEnterpriseDetail from './hooks/use_enterprise_detail'
import { useNavigate, useParams } from 'react-router-dom'
import usePress from './hooks/use_press'
import useEnterpriseKuchikomi from './hooks/use_enterprise_kuchikomi'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import Head2 from '../../components/heads/head2'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import EnterpriseSummaryCard from '../../components/card/enterprise_summary_card'
import useRelatedEnterprise from './hooks/use_related_enterprise'
import EnterpriseCard from '../../components/card/enterprise_card'
import LoadingSpinner from '../../components/loading/loading_spinner'

const EnterpriseDetailPage: FC = () => {
  const PAGE_SIZE = 20
  const navigate = useNavigate()
  const isSp = useSpBreakPoint()
  const pathParams = useParams<{ id: string }>()
  const [enterpriseId, setEnterpriseId] = useState<string>('')
  const [detail, detailLoading, detailHandler] = useEnterpriseDetail()
  const [press, pressLoading, pressHandler] = usePress()
  const KuchikomiIndexSectionRef = React.createRef<HTMLDivElement>()
  const [kuchikomis, size, currentCursor, kuchikomiLoading, kuchikomiHandler] =
    useEnterpriseKuchikomi({ getLimit: PAGE_SIZE })
  const [
    relatedEnterprises,
    relatedEnterprisesLoading,
    relatedEnterprisesHandler,
  ] = useRelatedEnterprise()

  const scrollToKuchikomiIndexSection = () => {
    if (KuchikomiIndexSectionRef.current) {
      const elementPosition =
        KuchikomiIndexSectionRef.current.getBoundingClientRect().top +
        window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const enterpriseDetailCardElem = !detailLoading ? (
    detail ? (
      <EnterpriseDetailCard
        enterprise={detail}
        press={press}
        pressLoading={pressLoading}
        follow={detailHandler.follow}
      />
    ) : (
      <p className="text-center text-sm text-main-lightGray">
        会社情報の読み込みに失敗しました
      </p>
    )
  ) : (
    <LoadingSpinner className="mx-auto mb-16" />
  )

  const newKuchikomiElem = (
    <div className="">
      {isSp ? null : (
        <div className="mb-4" ref={KuchikomiIndexSectionRef}>
          <Head2
            title="クチコミ一覧"
            subTitle={
              kuchikomis.length > 0
                ? `全件${String(size)}中 ${currentCursor + 1}~${
                    currentCursor + kuchikomis.length
                  }件`
                : ''
            }
          />
        </div>
      )}
      {!kuchikomiLoading ? (
        kuchikomis.length > 0 ? (
          <div className="bg-white rounded-xl overflow-hidden py-4">
            {kuchikomis.slice(0, 10).map((item, index, ary) => {
              return (
                <div key={`kuchikomi-${String(index)}`}>
                  <KuchikomiCard
                    kuchikomi={item}
                    borderBottom={index !== ary.length - 1}
                    isSp={isSp}
                    sendLike={kuchikomiHandler.like}
                    sendReply={kuchikomiHandler.reply}
                    deleteKuchikomi={kuchikomiHandler.delete}
                    onClickBookmark={kuchikomiHandler.bookmark}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <p className="text-center text-sm text-main-lightGray">
            クチコミはありません
          </p>
        )
      ) : (
        <LoadingSpinner className="mx-auto mb-16" />
      )}
    </div>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(enterpriseId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(enterpriseId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(enterpriseId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const enterpriseSummaryElem = (
    <div>
      <div className="mb-4">
        <Head2 title="情報" />
      </div>
      <div>
        {!detailLoading ? (
          detail ? (
            <EnterpriseSummaryCard enterprise={detail} />
          ) : (
            <p className="text-center text-sm text-main-lightGray">
              情報の読み込みに失敗しました
            </p>
          )
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
    </div>
  )

  const relatedEnterprisesElem = (
    <div>
      <div className="mb-4">
        <Head2 title="関連企業・団体" />
      </div>
      <div>
        {!relatedEnterprisesLoading ? (
          relatedEnterprises.length > 0 ? (
            relatedEnterprises.map((item, index) => {
              return (
                <div
                  className="mb-4"
                  key={`related-enterprise-${String(index)}`}
                >
                  <EnterpriseCard isSp={isSp} enterprise={item} />
                </div>
              )
            })
          ) : (
            <p className="text-center text-sm text-main-lightGray">
              関連企業・団体はありません
            </p>
          )
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
    </div>
  )

  useEffect(() => {
    if (pathParams.id) {
      setEnterpriseId(pathParams.id)
      detailHandler.load(pathParams.id)
      pressHandler.load(pathParams.id)
      kuchikomiHandler.load(pathParams.id)
      relatedEnterprisesHandler.load(pathParams.id)
    }
  }, [pathParams.id])

  const transgerKuchikomiCreatePage = () => {
    navigate(`/kuchikomi/create/${enterpriseId}`)
  }

  const content = (
    <CommonTemplate
      isPadding={!isSp}
      isSp={isSp}
      onClickPostButton={transgerKuchikomiCreatePage}
      additonalTopNode
    >
      <div>
        <div className="mb-12">{enterpriseDetailCardElem}</div>
        <div className="lg:flex justify-between">
          <div className="lg:w-[calc(100%-300px)] md:pr-4">
            <div className="mb-8">{newKuchikomiElem}</div>
            <div className="pb-8">{paginationCursor}</div>
          </div>
          <div className="lg:w-[300px] pl-4 md:pr-0 pr-4 md:pb-0 pb-8">
            <div className="mb-12">{enterpriseSummaryElem}</div>
            <div>{relatedEnterprisesElem}</div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  )

  return content
}

export default EnterpriseDetailPage
