import { LicenseType, LicenseTypeValue } from '../../enums/LicenseType'

export class CurrentUser {
  public displayName: string | null
  public iconUrl: string | null
  public licenseType: LicenseTypeValue | null
  public authStatus: AuthStatus
  public kuchikomiEnterpriseId: number | null
  public isPaidPlan: boolean

  constructor(
    displayName: string | null,
    iconUrl: string | null,
    licenseType: LicenseTypeValue | null,
    authStatus: AuthStatus,
    kuchikomiEnterpriseId: number | null,
    isPaidPlan: boolean
  ) {
    this.displayName = displayName
    this.iconUrl = iconUrl
    this.licenseType = licenseType
    this.authStatus = authStatus
    this.kuchikomiEnterpriseId = kuchikomiEnterpriseId
    this.isPaidPlan = isPaidPlan
  }

  public isLogined(): boolean {
    return this.authStatus === 'authenticated'
  }
  public isNoAuth(): boolean {
    return this.authStatus === 'unauthenticated'
  }
  public isKuchikomiUser(): boolean {
    return this.licenseType === LicenseType.NORMAL
  }
  public isEnterpriseUser(): boolean {
    return this.licenseType === LicenseType.CORP
  }
}

export type AuthStatus = 'unauthenticated' | 'loading' | 'authenticated'
