import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import BackgroundImage from '../../../../images/mypage/bg-m.png'
import { NextActionIcon } from '../../../../images/icons/next_action_icon'
import { NextAction } from '../../../../model/MyPageTop'

type NextActionCardProps = {
  nextActions: Array<NextAction>
}

const NextActionCard: FC<NextActionCardProps> = (props) => {
  return (
    <div
      className="rounded-xl bg-center bg-cover p-4 h-[250px]"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <p className="text-lg font-bold text-white">Nextアクション</p>
      <div className="flex justify-center py-2">
        <NextActionIcon size="lg" color="white" />
      </div>
      <div>
        <ul>
          {props.nextActions
            .sort((item) => item.index)
            .map((item) => {
              return (
                <li
                  key={`next-action-${String(item.index)}`}
                  className={item.complete ? 'opacity-75' : ''}
                >
                  <Link to={item.link}>
                    <div className="flex justify-start text-sm text-white w-fit">
                      <p className="underline">{String(item.index)}.&nbsp;</p>
                      <p className="break-all whitespace-pre-wrap underline">
                        {item.title}
                      </p>
                    </div>
                  </Link>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default NextActionCard
