import React, { FC, useState } from 'react'
import LogoWhite from '../../images/logo_white.png'
import LogoBlack from '../../images/logo_black.png'
import LogoLongWhite from '../../images/logo_long_white.png'
import HomeIcon from '../../images/icons/home.png'
import SearchIcon from '../../images/icons/search.png'
import ChartIcon from '../../images/icons/chart.png'
// import BellIcon from '../../images/icons/bell.png'
import UserIcon from '../../images/icons/user.png'
import { useAuth } from '../../hooks/use_auth'
import HeaderBg from '../../images/header-bg.png'
import { Link } from 'react-router-dom'
import { AccountIcon } from '../../images/icons/account_icon'
import './main_header.css'
import { XIcon } from '../../images/icons/x_icon'
import HamBg from '../../images/ham_bg.png'
import MypageLink from './mypage_link'

type LeftItemProps = {
  icon: string
  text: string
  linkTo: string
  onClick?: () => void
}

type HeaderProps = {
  bgTransparent?: boolean
  isSp?: boolean
  isShowHeader?: boolean
}

const MainHeader: FC<HeaderProps> = ({
  bgTransparent = false,
  isSp = false,
  isShowHeader = true,
}) => {
  const { user } = useAuth()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const header = (
    <div
      className={`${
        bgTransparent
          ? ''
          : 'w-full p-3 bg-cover bg-no-repeat bg-center opacity-100 sticky top-0 z-10'
      }`}
      style={bgTransparent ? {} : { backgroundImage: `url(${HeaderBg})` }}
    >
      <div className="w-full flex justify-between items-center">
        <Link to="/">
          <img src={LogoLongWhite} width="300px"></img>
        </Link>
        <div className="flex">
          <LeftItem icon={HomeIcon} text="ホーム" linkTo="/" />
          <LeftItem icon={SearchIcon} text="検索" linkTo="/search/enterprise" />
          <LeftItem
            icon={ChartIcon}
            text="ランキング"
            linkTo="/ranking/enterprise"
          />

          {user.isLogined() && user.isKuchikomiUser() && (
            <>
              {/* TODO: リンク */}
              {/* <LeftItem icon={BellIcon} text="通知" linkTo="/" /> */}
            </>
          )}
          {user.isLogined() && (
            <>
              <MypageLink />
            </>
          )}
          {user.isNoAuth() && (
            <LeftItem icon={UserIcon} text="ログイン" linkTo="/login" />
          )}
        </div>
      </div>
    </div>
  )

  type LinkItemProps = {
    to: string
    text: string
    isBlank?: boolean
    onClick?: () => void
  }
  const LinkText: FC<LinkItemProps> = (props) => {
    return (
      <div className="mb-2">
        <Link
          onClick={props.onClick}
          to={props.to}
          target={props.isBlank === true ? '_blank' : '_self'}
        >
          <p className="text-white leading-6">{props.text}</p>
        </Link>
      </div>
    )
  }

  const spHeader = (
    <>
      {/* ヘッダー */}
      <div className="w-full p-3 bg-white fixed top-0 z-10 border-b border-b-main-lightGray spheader">
        <div className="w-full flex justify-between items-center">
          <div className="cursor-pointer">
            {user.isLogined() ? (
              <Link to={'/mypage'}>
                <div
                  className="w-[30px] h-[30px] rounded-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${user.iconUrl})` }}
                ></div>
              </Link>
            ) : (
              <Link to={'/login'}>
                <AccountIcon />
              </Link>
            )}
          </div>
          <div>
            <Link to="/">
              <img src={LogoBlack} width="144px"></img>
            </Link>
          </div>
          <div
            id="ham_btn"
            className={isMenuOpen ? 'open' : ''}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="h-[55px]"></div>
    </>
  )

  const hamContent = (
    <div
      className="w-screen h-[calc(100vh-55px)] fixed z-50 top-[55px] left-0 bg-cover bg-center overflow-y-scroll"
      style={{ backgroundImage: `url(${HamBg})` }}
    >
      <div className="w-11/12 max-w-6xl py-12 mx-auto text-white">
        <div className="">
          <Link to="/">
            <div className="w-fit mx-auto sm:ml-0">
              <img src={LogoWhite} width="192px"></img>
            </div>
            <p className="sm:text-sm text-white font-bold sm:text-start text-center">
              クチコミでつくる、やさしい世界
            </p>
          </Link>
          <div className="flex justify-center sm:justify-start py-4">
            <div className="mr-2">
              {/* <Link to="#">
                <FacebookIcon />
              </Link> */}
            </div>
            <div className="mr-2">
              <Link to="https://x.com/SDGsKuchikom" target="_blank">
                <XIcon />
              </Link>
            </div>
            {/* <div className="mr-2">
              <Link to="#">
                <InstagramIcon />
              </Link>
            </div>
            <div className="mr-2">
              <Link to="#">
                <TikTokIcon />
              </Link>
            </div> */}
          </div>
        </div>

        <div className="pt-8 mb-16 sm:flex sm:justify-start">
          <div className="mr-20">
            <LinkText to="/" text="TOP画面" />
            <LinkText to="/search/enterprise" text="詳細検索" />
            {user.isLogined() && <LinkText to="/mypage" text="マイページ" />}
            <LinkText
              to="/ranking/enterprise"
              text="企業・団体ランキング一覧"
            />
            <LinkText to="/ranking/user" text="ユーザーランキング一覧" />
            <LinkText to="/ranking/kuchikomi" text="クチコミランキング一覧" />
          </div>
          <div className="mr-20">
            <LinkText to="/informations" text="お知らせ" />
            <LinkText to="/guide/user" text="ユーザーの方へ" />
            <LinkText to="/guide/enterprise" text="企業・団体の方へ" />
            <LinkText to="/faq" text="FAQ" />
            <LinkText to="/guide/like/point" text="いいねポイントについて" />
            <LinkText to="/guide/rating" text="レイティングについて" />
            <LinkText to="/guide/plan/price" text="料金プランについて" />
          </div>
          <div className="mr-20">
            <LinkText to="/contact" text="お問合せ" />
            <LinkText
              to="https://the-lodges.jp/"
              text="運営会社"
              isBlank={true}
            />
            <LinkText to="/policy/user" text="利用規約" />
            <LinkText to="/policy/privacy" text="プライバシーポリシー" />
            <LinkText to="/policy/law" text="特定商取引法に基づく表記" />
          </div>
        </div>
        <div className="pt-4 border-t border-white">
          <p className="text-white text-center">
            Copyright © 2023 The Lodges. Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {(isShowHeader || isMenuOpen) && <>{isSp ? spHeader : header}</>}

      {/* ハンバーガーメニュー */}
      {isSp && isMenuOpen && hamContent}
    </>
  )
}

const LeftItem: React.FC<LeftItemProps> = ({ icon, text, linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="mx-1 md:mx-2 px-1 text-center cursor-pointer">
        <img src={icon} className="h-[24px] inline-block" />
        <p className="text-white text-xs">{text}</p>
      </div>
    </Link>
  )
}

export default MainHeader
